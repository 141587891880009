import { useContext } from "react";
import { ChallengeContext } from ".";

export const useChallenge = () => {
  const context = useContext(ChallengeContext);
  if (!context)
    throw new Error("useChallenge must be used within a ChallengeProvider");

  return context;
};
