import { useContext } from "react";
import { GlobalScrollContext } from "./Component";

const useGlobalScroll = () => {
  const context = useContext(GlobalScrollContext);
  if (!context)
    throw new Error(
      "useGlobalScroll must be used within a GlobalScrollProvider"
    );

  return context;
};

export default useGlobalScroll;
