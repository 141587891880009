export const menu = {
  menu: {
    downloadPlanPdf: "Jadwal Bacaan Alkitab(JaBa dalam bentuk PDF",
    downloadingPlanPdf: "Mengunduh dalam bentuk PDF",
    editPlan: "Mengunduh...",
    shareBibleJourney: "Silahkan bagikan Bible Journey",
    giveFeedback: "Memberikan umpan balik Anda!",
    donate: "Donasi",
  },
};
