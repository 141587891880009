import React, { Fragment } from "react";

const App = (props) => {
  return (
    <Fragment>
      <main className={`app bg-${props.bg} color-${props.bg}`}>
        <div className="app__container">{props.children}</div>
      </main>
    </Fragment>
  );
};

export default App;
