import React, { useCallback, useMemo } from "react";
import { alternativesMsg, editQuestion, back } from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useQuestion } from "contexts/Challenge/Question";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";

const Owner = () => {
  const { viewingChallenge, isOwner } = useChallenge();
  const {
    id: questionId,
    alternatives,
    statement,
    answer,
    isLoadingQuestion,
  } = useQuestion();
  const history = useHistory();

  const backTo = useMemo(() => {
    return `/challenge/${viewingChallenge}/questions`;
  }, [viewingChallenge]);

  const onEditClick = useCallback(() => {
    history.push(`/challenge/${viewingChallenge}/question/${questionId}/edit`);
  }, [history, questionId, viewingChallenge]);

  if (isLoadingQuestion) return <Loader />;

  return (
    <div className="view-question form">
      <div className="view-question__title">
        <p>{statement}</p>
      </div>
      <div className="view-question__alternatives-title">{alternativesMsg}</div>
      <div className="view-question__alternatives">
        {alternatives?.map((alternative, index) => (
          <div
            className={classNames("view-question__alternative", {
              active: answer === alternative && isOwner,
            })}
            key={index}
          >
            <p>{alternative}</p>
          </div>
        ))}
        <div className={"view-question__edit-question"} onClick={onEditClick}>
          <EditIcon />
          <p>{editQuestion}</p>
        </div>
      </div>

      <div className="view-question__controls">
        <Link to={backTo} className="new-challenge__controls-jump ">
          {back}
        </Link>
      </div>
    </div>
  );
};

export default Owner;
