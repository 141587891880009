import { completeBibleArray } from "utils/plan";

export const getInitialReading = (planv2) => {
  let found;
  completeBibleArray.find((item) => {
    return planv2.some((day) => {
      if (
        day.readingStart.book === item.book &&
        day.readingStart.chapter === item.chapter
      ) {
        found = day;
        return true;
      }

      return false;
    });
  });
  return found;
};

export const getNextReadingArrayIndex = (plan, reading) => {
  let found;
  completeBibleArray.find((item, index, array) => {
    const nextItem =
      array[index === array.length - 1 ? array.length - 1 : index + 1];

    if (item.book === reading.book && item.chapter === reading.chapter) {
      plan.some((day, index) => {
        if (
          day.readingDetails.some(
            ({ book, chapter }) =>
              book === nextItem.book && chapter === nextItem.chapter
          )
        ) {
          found = index;
          return true;
        }

        return false;
      });
    }

    return false;
  });

  return Number.isSafeInteger(found) ? found : 0;
};
