import React, { Fragment, memo } from "react";
import { ReactComponent as HamburgerIcon } from "assets/icons/icon-hamburger.svg";
import { menu } from "../messages";
import { NavLink } from "react-router-dom";

const MenuButton = () => {
  return (
    <Fragment>
      <NavLink to="/menu" className="bottom-bar__icon bottom-bar__icon--menu">
        <div className="bottom-bar__icon-icon bottom-bar__icon-svg--stroke-fill">
          <HamburgerIcon />
        </div>
        <div className="bottom-bar__icon-text">{menu}</div>
      </NavLink>
    </Fragment>
  );
};

export default memo(MenuButton);
