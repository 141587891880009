export const reminderDefined = (
  subscriptionToken,
  definedHour,
  definedMinute
) => ({
  type: "REMINDER_DEFINED",
  subscriptionToken,
  definedHour,
  definedMinute,
});

export const reminderLater = () => ({
  type: "REMIND_LATER",
});

export const storePermissionDenied = () => ({
  type: "STORE_PERMISSION_DENIED",
});

export const stopReminders = () => ({
  type: "STOP_REMINDERS",
});

export const saveSubscription = (subscriptionToken) => ({
  type: "SAVE_SUBSCRIPTION",
  subscriptionToken,
});
