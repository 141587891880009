import React, { useEffect } from "react";
import App from "components/App";
import { Navbar } from "components/Navbar";
import withChallenge from "HOCs/withChallenge";
import Header from "./Header";
import Component from "./Component";

import HeaderLoading from "./components/HeaderLoading";
import { useChallenge } from "contexts/Challenge/useChallenge";

const Ranking = () => {
  const { isLoadingChallenge } = useChallenge();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <App>
      <Navbar backTo="/challenges" title="Ranking" />
      {isLoadingChallenge ? <HeaderLoading /> : <Header />}

      <Component />
    </App>
  );
};

export default withChallenge(Ranking);
