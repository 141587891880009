import { i18n } from "translate/i18n";

export const home = i18n.t(`messages.bottomBar.home`);
export const createAChallenge = i18n.t(`messages.bottomBar.createAChallenge`);
export const menu = i18n.t(`messages.bottomBar.menu`);
export const challenges = i18n.t(`messages.bottomBar.challenges`);
export const ranking = i18n.t(`messages.bottomBar.ranking`);
export const plan = i18n.t(`messages.bottomBar.plan`);
export const questions = i18n.t(`messages.bottomBar.questions`);
export const configs = i18n.t(`messages.bottomBar.configs`);
