import Modal from "components/Modal";
import React, { useCallback } from "react";
import { usePlan } from "../Context";
import {
  askConfirmationFirstButtonText,
  askConfirmationSecondButtonText,
  askConfirmationSubtitle,
  askConfirmationTitle,
} from "./messages";

const ExceptionModal = () => {
  const {
    shouldAskConfirmation,
    decideOnPlanCreationFunction,
    setShouldAskConfirmation,
  } = usePlan();

  const onFirstButtonClick = useCallback(() => {
    setShouldAskConfirmation(false);
  }, [setShouldAskConfirmation]);

  const onSecondButtonClick = useCallback(() => {
    setShouldAskConfirmation(false);
    decideOnPlanCreationFunction();
  }, [setShouldAskConfirmation, decideOnPlanCreationFunction]);

  return (
    <Modal
      active={shouldAskConfirmation}
      title={askConfirmationTitle}
      subtitle={askConfirmationSubtitle}
      onFirstButtonClick={onFirstButtonClick}
      onSecondButtonClick={onSecondButtonClick}
      firstButtonText={askConfirmationFirstButtonText}
      secondButtonText={askConfirmationSecondButtonText}
    />
  );
};

export default ExceptionModal;
