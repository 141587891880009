export const bibleBooks = {
  bibleBooks: {
    0: {
      name: "Kejadian",
      abbreviation: "Kej",
      testament: "Perjanjian Lama",
    },
    genesis: {
      name: "Kejadian",
      abbreviation: "Kej",
      testament: "Perjanjian Lama",
    },
    1: {
      name: "Keluaran",
      abbreviation: "Kel",
      testament: "Perjanjian Lama",
    },
    exodus: {
      name: "Keluaran",
      abbreviation: "Kel",
      testament: "Perjanjian Lama",
    },
    2: {
      name: "Imamat",
      abbreviation: "Im",
      testament: "Perjanjian Lama",
    },
    leviticus: {
      name: "Imamat",
      abbreviation: "Im",
      testament: "Perjanjian Lama",
    },
    3: {
      name: "Bilangan",
      abbreviation: "Bil",
      testament: "Perjanjian Lama",
    },
    numbers: {
      name: "Bilangan",
      abbreviation: "Bil",
      testament: "Perjanjian Lama",
    },
    4: {
      name: "Ulangan",
      abbreviation: "Ul",
      testament: "Perjanjian Lama",
    },
    deuteronomy: {
      name: "Ulangan",
      abbreviation: "Ul",
      testament: "Perjanjian Lama",
    },
    5: {
      name: "Yosua",
      abbreviation: "Yos",
      testament: "Perjanjian Lama",
    },
    joshua: {
      name: "Yosua",
      abbreviation: "Yos",
      testament: "Perjanjian Lama",
    },
    6: {
      name: "Hakim-Hakim",
      abbreviation: "Hak",
      testament: "Perjanjian Lama",
    },
    judges: {
      name: "Hakim-Hakim",
      abbreviation: "Hak",
      testament: "Perjanjian Lama",
    },
    7: {
      name: "Rut",
      abbreviation: "Rut",
      testament: "Perjanjian Lama",
    },
    ruth: {
      name: "Rut",
      abbreviation: "Rut",
      testament: "Perjanjian Lama",
    },
    8: {
      name: "1 Samuel",
      abbreviation: "1Sam",
      testament: "Perjanjian Lama",
    },
    samuel1: {
      name: "1 Samuel",
      abbreviation: "1Sam",
      testament: "Perjanjian Lama",
    },
    9: {
      name: "2 Samuel",
      abbreviation: "2Sam",
      testament: "Perjanjian Lama",
    },
    samuel2: {
      name: "2 Samuel",
      abbreviation: "2Sam",
      testament: "Perjanjian Lama",
    },
    10: {
      name: "1 Raja-Raja",
      abbreviation: "1Raj",
      testament: "Perjanjian Lama",
    },
    kings1: {
      name: "1 Raja-Raja",
      abbreviation: "1Raj",
      testament: "Perjanjian Lama",
    },
    11: {
      name: "2 Raja-Raja",
      abbreviation: "2Raj",
      testament: "Perjanjian Lama",
    },
    kings2: {
      name: "2 Raja-Raja",
      abbreviation: "2Raj",
      testament: "Perjanjian Lama",
    },
    12: {
      name: "1 Tawarikh",
      abbreviation: "1Taw",
      testament: "Perjanjian Lama",
    },
    chronicles1: {
      name: "1 Tawarikh",
      abbreviation: "1Taw",
      testament: "Perjanjian Lama",
    },
    13: {
      name: "2 Tawarikh",
      abbreviation: "2Taw",
      testament: "Perjanjian Lama",
    },
    chronicles2: {
      name: "2 Tawarikh",
      abbreviation: "2Taw",
      testament: "Perjanjian Lama",
    },
    14: {
      name: "Ezra",
      abbreviation: "Ezr",
      testament: "Perjanjian Lama",
    },
    ezra: {
      name: "Ezra",
      abbreviation: "Ezr",
      testament: "Perjanjian Lama",
    },
    15: {
      name: "Nehemia",
      abbreviation: "Neh",
      testament: "Perjanjian Lama",
    },
    nehemiah: {
      name: "Nehemia",
      abbreviation: "Neh",
      testament: "Perjanjian Lama",
    },
    16: {
      name: "Ester",
      abbreviation: "Est",
      testament: "Perjanjian Lama",
    },
    esther: {
      name: "Ester",
      abbreviation: "Est",
      testament: "Perjanjian Lama",
    },
    17: {
      name: "Ayub",
      abbreviation: "Ayb",
      testament: "Perjanjian Lama",
    },
    job: {
      name: "Ayub",
      abbreviation: "Ayb",
      testament: "Perjanjian Lama",
    },
    18: {
      name: "Mazmur",
      abbreviation: "Mzm",
      testament: "Perjanjian Lama",
    },
    psalms: {
      name: "Mazmur",
      abbreviation: "Mzm",
      testament: "Perjanjian Lama",
    },
    19: {
      name: "Amsal",
      abbreviation: "Ams",
      testament: "Perjanjian Lama",
    },
    proverbs: {
      name: "Amsal",
      abbreviation: "Ams",
      testament: "Perjanjian Lama",
    },
    20: {
      name: "Pengkhotbah",
      abbreviation: "Pkh",
      testament: "Perjanjian Lama",
    },
    ecclesiastes: {
      name: "Pengkhotbah",
      abbreviation: "Pkh",
      testament: "Perjanjian Lama",
    },
    21: {
      name: "Kidung Agung",
      abbreviation: "Kid",
      testament: "Perjanjian Lama",
    },
    solomonsongof: {
      name: "Kidung Agung",
      abbreviation: "Kid",
      testament: "Perjanjian Lama",
    },
    22: {
      name: "Yesaya",
      abbreviation: "Yes",
      testament: "Perjanjian Lama",
    },
    isaiah: {
      name: "Yesaya",
      abbreviation: "Yes",
      testament: "Perjanjian Lama",
    },
    23: {
      name: "Yeremia",
      abbreviation: "Yer",
      testament: "Perjanjian Lama",
    },
    jeremiah: {
      name: "Yeremia",
      abbreviation: "Yer",
      testament: "Perjanjian Lama",
    },
    24: {
      name: "Ratapan",
      abbreviation: "Rat",
      testament: "Perjanjian Lama",
    },
    lamentations: {
      name: "Ratapan",
      abbreviation: "Rat",
      testament: "Perjanjian Lama",
    },
    25: {
      name: "Yehezkiel",
      abbreviation: "Yeh",
      testament: "Perjanjian Lama",
    },
    ezekiel: {
      name: "Yehezkiel",
      abbreviation: "Yeh",
      testament: "Perjanjian Lama",
    },
    26: {
      name: "Daniel",
      abbreviation: "Dan",
      testament: "Perjanjian Lama",
    },
    daniel: {
      name: "Daniel",
      abbreviation: "Dan",
      testament: "Perjanjian Lama",
    },
    27: {
      name: "Hosea",
      abbreviation: "Hos",
      testament: "Perjanjian Lama",
    },
    hosea: {
      name: "Hosea",
      abbreviation: "Hos",
      testament: "Perjanjian Lama",
    },
    28: {
      name: "Yoël",
      abbreviation: "Yl",
      testament: "Perjanjian Lama",
    },
    joel: {
      name: "Yoël",
      abbreviation: "Yl",
      testament: "Perjanjian Lama",
    },
    29: {
      name: "Amos",
      abbreviation: "Am",
      testament: "Perjanjian Lama",
    },
    amos: {
      name: "Amos",
      abbreviation: "Am",
      testament: "Perjanjian Lama",
    },
    30: {
      name: "Obaja",
      abbreviation: "Ob",
      testament: "Perjanjian Lama",
    },
    obadiah: {
      name: "Obaja",
      abbreviation: "Ob",
      testament: "Perjanjian Lama",
    },
    31: {
      name: "Yunus",
      abbreviation: "Yun",
      testament: "Perjanjian Lama",
    },
    jonah: {
      name: "Yunus",
      abbreviation: "Yun",
      testament: "Perjanjian Lama",
    },
    32: {
      name: "Mikha",
      abbreviation: "Mi",
      testament: "Perjanjian Lama",
    },
    micah: {
      name: "Mikha",
      abbreviation: "Mi",
      testament: "Perjanjian Lama",
    },
    33: {
      name: "Nahum",
      abbreviation: "Nah",
      testament: "Perjanjian Lama",
    },
    nahum: {
      name: "Nahum",
      abbreviation: "Nah",
      testament: "Perjanjian Lama",
    },
    34: {
      name: "Habakuk",
      abbreviation: "Hab",
      testament: "Perjanjian Lama",
    },
    habakkuk: {
      name: "Habakuk",
      abbreviation: "Hab",
      testament: "Perjanjian Lama",
    },
    35: {
      name: "Zefanya",
      abbreviation: "Zef",
      testament: "Perjanjian Lama",
    },
    zephaniah: {
      name: "Zefanya",
      abbreviation: "Zef",
      testament: "Perjanjian Lama",
    },
    36: {
      name: "Hagai",
      abbreviation: "Hag",
      testament: "Perjanjian Lama",
    },
    haggai: {
      name: "Hagai",
      abbreviation: "Hag",
      testament: "Perjanjian Lama",
    },
    37: {
      name: "Zakharia",
      abbreviation: "Za",
      testament: "Perjanjian Lama",
    },
    zechariah: {
      name: "Zakharia",
      abbreviation: "Za",
      testament: "Perjanjian Lama",
    },
    38: {
      name: "Maleakhi",
      abbreviation: "Mal",
      testament: "Perjanjian Lama",
    },
    malachi: {
      name: "Maleakhi",
      abbreviation: "Mal",
      testament: "Perjanjian Lama",
    },
    39: {
      name: "Matius",
      abbreviation: "Mat",
      testament: "Perjanjian Baru",
    },
    matthew: {
      name: "Matius",
      abbreviation: "Mat",
      testament: "Perjanjian Baru",
    },
    40: {
      name: "Markus",
      abbreviation: "Mrk",
      testament: "Perjanjian Baru",
    },
    mark: {
      name: "Markus",
      abbreviation: "Mrk",
      testament: "Perjanjian Baru",
    },
    41: {
      name: "Lukas",
      abbreviation: "Luk",
      testament: "Perjanjian Baru",
    },
    luke: {
      name: "Lukas",
      abbreviation: "Luk",
      testament: "Perjanjian Baru",
    },
    42: {
      name: "Yohanes",
      abbreviation: "Yoh",
      testament: "Perjanjian Baru",
    },
    john: {
      name: "Yohanes",
      abbreviation: "Yoh",
      testament: "Perjanjian Baru",
    },
    43: {
      name: "Kisah Para Rasul",
      abbreviation: "Kis",
      testament: "Perjanjian Baru",
    },
    acts: {
      name: "Kisah Para Rasul",
      abbreviation: "Kis",
      testament: "Perjanjian Baru",
    },
    44: {
      name: "Roma",
      abbreviation: "Rm",
      testament: "Perjanjian Baru",
    },
    romans: {
      name: "Roma",
      abbreviation: "Rm",
      testament: "Perjanjian Baru",
    },
    45: {
      name: "1 Korintus",
      abbreviation: "1Kor",
      testament: "Perjanjian Baru",
    },
    corinthians1: {
      name: "1 Korintus",
      abbreviation: "1Kor",
      testament: "Perjanjian Baru",
    },
    46: {
      name: "2 Korintus",
      abbreviation: "2Kor",
      testament: "Perjanjian Baru",
    },
    corinthians2: {
      name: "2 Korintus",
      abbreviation: "2Kor",
      testament: "Perjanjian Baru",
    },
    47: {
      name: "Galatia",
      abbreviation: "Gal",
      testament: "Perjanjian Baru",
    },
    galatians: {
      name: "Galatia",
      abbreviation: "Gal",
      testament: "Perjanjian Baru",
    },
    48: {
      name: "Efesus",
      abbreviation: "Ef",
      testament: "Perjanjian Baru",
    },
    ephesians: {
      name: "Efesus",
      abbreviation: "Ef",
      testament: "Perjanjian Baru",
    },
    49: {
      name: "Filipi",
      abbreviation: "Flp",
      testament: "Perjanjian Baru",
    },
    philippians: {
      name: "Filipi",
      abbreviation: "Flp",
      testament: "Perjanjian Baru",
    },
    50: {
      name: "Kolose",
      abbreviation: "Kol",
      testament: "Perjanjian Baru",
    },
    colossians: {
      name: "Kolose",
      abbreviation: "Kol",
      testament: "Perjanjian Baru",
    },
    51: {
      name: "1 Tesalonika",
      abbreviation: "1Tes",
      testament: "Perjanjian Baru",
    },
    thessalonians1: {
      name: "1 Tesalonika",
      abbreviation: "1Tes",
      testament: "Perjanjian Baru",
    },
    52: {
      name: "2 Tesalonika",
      abbreviation: "2Tes",
      testament: "Perjanjian Baru",
    },
    thessalonians2: {
      name: "2 Tesalonika",
      abbreviation: "2Tes",
      testament: "Perjanjian Baru",
    },
    53: {
      name: "1 Timotius",
      abbreviation: "1Tim",
      testament: "Perjanjian Baru",
    },
    timothy1: {
      name: "1 Timotius",
      abbreviation: "1Tim",
      testament: "Perjanjian Baru",
    },
    54: {
      name: "2 Timotius",
      abbreviation: "2Tim",
      testament: "Perjanjian Baru",
    },
    timothy2: {
      name: "2 Timotius",
      abbreviation: "2Tim",
      testament: "Perjanjian Baru",
    },
    55: {
      name: "Titus",
      abbreviation: "Tit",
      testament: "Perjanjian Baru",
    },
    titus: {
      name: "Titus",
      abbreviation: "Tit",
      testament: "Perjanjian Baru",
    },
    56: {
      name: "Filemon",
      abbreviation: "Flm",
      testament: "Perjanjian Baru",
    },
    philemon: {
      name: "Filemon",
      abbreviation: "Flm",
      testament: "Perjanjian Baru",
    },
    57: {
      name: "Ibrani",
      abbreviation: "Ibr",
      testament: "Perjanjian Baru",
    },
    hebrews: {
      name: "Ibrani",
      abbreviation: "Ibr",
      testament: "Perjanjian Baru",
    },
    58: {
      name: "Yakobus",
      abbreviation: "Yak",
      testament: "Perjanjian Baru",
    },
    james: {
      name: "Yakobus",
      abbreviation: "Yak",
      testament: "Perjanjian Baru",
    },
    59: {
      name: "1 Petrus",
      abbreviation: "1Ptr",
      testament: "Perjanjian Baru",
    },
    peter1: {
      name: "1 Petrus",
      abbreviation: "1Ptr",
      testament: "Perjanjian Baru",
    },
    60: {
      name: "2 Petrus",
      abbreviation: "2Ptr",
      testament: "Perjanjian Baru",
    },
    peter2: {
      name: "2 Petrus",
      abbreviation: "2Ptr",
      testament: "Perjanjian Baru",
    },
    61: {
      name: "1 Yohanes",
      abbreviation: "1Yoh",
      testament: "Perjanjian Baru",
    },
    john1: {
      name: "1 Yohanes",
      abbreviation: "1Yoh",
      testament: "Perjanjian Baru",
    },
    62: {
      name: "2 Yohanes",
      abbreviation: "2 Yoh",
      testament: "Perjanjian Baru",
    },
    john2: {
      name: "2 Yohanes",
      abbreviation: "2 Yoh",
      testament: "Perjanjian Baru",
    },
    63: {
      name: "3 Yohanes",
      abbreviation: "3 Yoh",
      testament: "Perjanjian Baru",
    },
    john3: {
      name: "3 Yohanes",
      abbreviation: "3 Yoh",
      testament: "Perjanjian Baru",
    },
    64: {
      name: "Yudas",
      abbreviation: "Yud",
      testament: "Perjanjian Baru",
    },
    jude: {
      name: "Yudas",
      abbreviation: "Yud",
      testament: "Perjanjian Baru",
    },
    65: {
      name: "Wahyu",
      abbreviation: "Why",
      testament: "Perjanjian Baru",
    },
    revelation: {
      name: "Wahyu",
      abbreviation: "Why",
      testament: "Perjanjian Baru",
    },
  },
};
