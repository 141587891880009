import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api, sendErrorEmail } from "utils";
import { ChallengeInvites as ChallengeInvitesProvider } from "./ChallengeInvites";
import { useHeaders } from "hooks";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import {
  errorOnFetchingChallenges,
  errorOnFetchingChallenge,
} from "./messages";
import { useHistory, withRouter } from "react-router";
import { ChallengePlanProvider } from "./ChallengePlan";
import { UserPointsProvider } from "./UserPoints";
import { QuestionProvider } from "./Question";
import { useUser } from "contexts/User";
import { PlanProgressProvider } from "./PlanProgress";
import { RankingProvider } from "./Ranking";

export const ChallengeContext = createContext();

const pagesBlockList = ["/plan", "/logout", "/login", "/register"];

const ChallengesProvider = ({ children }) => {
  const [viewingChallenge, setViewingChallenge] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState({});
  const [isLoadingChallenge, setIsLoadingChallenge] = useState(true);
  const [challengeIsLoaded, setChallengeIsLoaded] = useState(false);
  const [challenges, setChallenges] = useState({});
  const [isOwner, setIsOwner] = useState(null);
  const headers = useHeaders();
  const { openCustomSnackbar } = useSnackbarNotification();
  const [rankingId, setRankingId] = useState(null);
  const [isFetchingChallenges, setIsFetchingChallenges] = useState(true);

  const history = useHistory();
  const { isLogged } = useUser();

  const deleteAllChallenges = () => {
    setChallenges([]);
    challenges.owner.forEach((challenge) => {
      headers.headers.challenge_id = challenge._id;
      api.delete("/challenge/delete", headers);
    });
  };

  useEffect(() => {
    if (!isOwner && isOwner !== null && isLogged) {
      api.get("/challenge/ranking/user/id", headers).then(({ data }) => {
        setRankingId(data.rankingId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOwner, viewingChallenge]);

  const challengeHeaders = useMemo(() => {
    if (viewingChallenge) {
      headers.headers.challenge_id = viewingChallenge;
    }
    if (currentChallenge.planTemplate) {
      headers.headers.plan_template = currentChallenge.planTemplate;
    }

    return headers;
  }, [headers, viewingChallenge, currentChallenge.planTemplate]);

  const request = useMemo(() => {
    return {
      get: async (path) => api.get(`/challenge/${path}`, challengeHeaders),
      post: async (path, data) =>
        api.post(`/challenge/${path}`, data, challengeHeaders),
      put: async (path, data) =>
        api.put(`/challenge/${path}`, data, challengeHeaders),
      delete: async (path) =>
        api.delete(`/challenge/${path}`, challengeHeaders),
    };
  }, [challengeHeaders]);

  const fetchChallenges = useCallback(
    async (then = () => {}) => {
      setIsFetchingChallenges(true);
      try {
        await api.get("/challenges", headers).then(({ data }) => {
          setChallenges(data);
        });
      } catch (err) {
        sendErrorEmail(err, "on fetchChallenges function");
        openCustomSnackbar({
          variant: "error",
          message: errorOnFetchingChallenges,
        });
      } finally {
        then();
        setIsFetchingChallenges(false);
      }
    },
    [headers, openCustomSnackbar]
  );

  const fetchChallenge = useCallback(async () => {
    setIsLoadingChallenge(true);

    try {
      const res = await api.get("/challenge", challengeHeaders);
      setCurrentChallenge(res.data.challenge);
      setIsOwner(res.data.isOwner);
    } catch (err) {
      sendErrorEmail(err, "on fetchChallenge function");
      openCustomSnackbar({
        variant: "error",
        message: errorOnFetchingChallenge,
      });
    } finally {
      setIsLoadingChallenge(false);
      setChallengeIsLoaded(true);
    }
  }, [challengeHeaders, openCustomSnackbar]);

  useEffect(() => {
    if (viewingChallenge) {
      fetchChallenge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingChallenge]);

  const isOnBlockedPath = useMemo(
    () => pagesBlockList.some((path) => history.location.pathname === path),
    [history.location.pathname]
  );

  useEffect(() => {
    if (isOnBlockedPath) setViewingChallenge(null);
  }, [isOnBlockedPath]);

  const questionsPage = useMemo(() => {
    return `/challenge/${viewingChallenge}/questions`;
  }, [viewingChallenge]);

  const planPage = useMemo(() => {
    return `/challenge/${viewingChallenge}/plan`;
  }, [viewingChallenge]);

  const configsPage = useMemo(() => {
    return `/challenge/${viewingChallenge}/configs`;
  }, [viewingChallenge]);

  const updateChallengeTitle = useCallback(
    (newTitle) => {
      setCurrentChallenge({ ...currentChallenge, title: newTitle });
    },
    [currentChallenge]
  );

  return (
    <ChallengeContext.Provider
      value={{
        setViewingChallenge,
        fetchChallenges,
        updateChallengeTitle,
        deleteAllChallenges,
        questionsPage,
        request,
        viewingChallenge,
        isLoadingChallenge,
        currentChallenge,
        challenges,
        isOwner,
        challengeHeaders,
        rankingId,
        configsPage,
        planPage,
        isFetchingChallenges,
        challengeIsLoaded,
      }}
    >
      <ChallengeInvitesProvider>
        <ChallengePlanProvider>
          <UserPointsProvider>
            <QuestionProvider>
              <PlanProgressProvider>
                <RankingProvider>{children}</RankingProvider>
              </PlanProgressProvider>
            </QuestionProvider>
          </UserPointsProvider>
        </ChallengePlanProvider>
      </ChallengeInvitesProvider>
    </ChallengeContext.Provider>
  );
};

export default withRouter(ChallengesProvider);
