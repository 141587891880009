import { useContext } from "react";
import { ChallengePlanContext } from "./Component";

const useChallengePlan = () => {
  const context = useContext(ChallengePlanContext);
  if (!context)
    throw new Error(
      "useChallengePlan must be used within a ChallengePlanProvider"
    );

  return context;
};

export default useChallengePlan;
