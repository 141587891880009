import React, { memo, useCallback } from "react";
import ExceptionModal from "components/ExceptionModal";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";

const Exception = () => {
  const { showException, setShowException } = useChallengePlan();

  const onSecondButtonClick = useCallback(
    () => setShowException(false),
    [setShowException]
  );

  const props = { active: showException, onSecondButtonClick };

  return <ExceptionModal {...props} />;
};

export default memo(Exception);
