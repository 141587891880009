import React, { useEffect, useRef } from "react";
import sadLook from "assets/images/sad-look.gif";
import classNames from "classnames";
import Footer from "components/Footer";

const MainCard = ({
  showBrandFooter,
  centerTitle,
  title,
  containerOnly,
  children,
  error,
  bodyScroll,
}) => {
  const mainCardRef = useRef();
  const mainCardBodyRef = useRef();

  useEffect(() => {
    if (mainCardRef.current) {
      setTimeout(() => {
        if (mainCardRef.current) {
          mainCardRef.current.style.minHeight = `${
            window.innerHeight - mainCardRef.current.offsetTop
          }px`;
        }
      }, 500);
    }
  }, [mainCardRef]);

  useEffect(() => {
    if (bodyScroll && mainCardBodyRef.current) {
      setTimeout(() => {
        if (mainCardBodyRef.current) {
          mainCardBodyRef.current.style.maxHeight = `${
            window.innerHeight -
            mainCardBodyRef.current.getBoundingClientRect().top -
            53
          }px`;
        }
      }, 500);
    }
  }, [bodyScroll]);

  if (error)
    return (
      <div className="main-card " ref={mainCardRef}>
        <p className="error__title">{error}</p>
        <img className="error__img" src={sadLook} alt="not found" />
      </div>
    );

  return containerOnly ? (
    <div className="main-card " ref={mainCardRef}>
      {children}
    </div>
  ) : (
    <div className="main-card " ref={mainCardRef}>
      {title && (
        <div className="main-card__header">
          <div
            className={classNames("main-card__title ", {
              "text-centered": centerTitle,
            })}
          >
            <h2 className="bold">{title}</h2>
          </div>
        </div>
      )}
      <div
        className={classNames("main-card__body", { bodyScroll })}
        ref={mainCardBodyRef}
      >
        {children}
      </div>
      {showBrandFooter && <Footer />}
    </div>
  );
};

export default MainCard;
