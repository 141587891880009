import React from "react";
import withChallenge from "HOCs/withChallenge";
import Wrapper from "./Wrapper";
import { configurations, dangerousArea } from "./messages";
import ChallengeName from "./ChallengeName";
import EditParticipantsButton from "./EditParticipants/Button";
import EditPlan from "./UpdatePlan/Button";
import DeleteChallenge from "./DeleteChallenge";

const Configs = () => {
  return (
    <Wrapper cardTitle={configurations} backTo="/challenges">
      <div className="configurations">
        <ChallengeName />
        <EditParticipantsButton />
        <EditPlan />
        <h3 className="configurations__title">{dangerousArea}</h3>
        <DeleteChallenge />
      </div>
    </Wrapper>
  );
};

export default withChallenge(Configs);
