import React from "react";
import { isNativeIOS } from "utils";
import AppleAuthButton from "./IOS";

const AppleButton = (props) => {
  if (isNativeIOS) return <AppleAuthButton {...props} />;

  return null;
};

export default AppleButton;
