import React, { useCallback, useMemo } from "react";
import Header from "components/App/Header";
import { bookById, subtitle, title } from "../messages";
import { getInitialReading } from "components/PlanChecklistV2/ReplanModal/utils";
import { loading } from "components/SocialLoginButtons/messages";
import { ProgressBarLoading, ProgressBar } from "atoms";

const PlanHeader = ({ error, planv2, isLoading, additionalComponent }) => {
  const subtitle = isLoading ? (
    loading
  ) : error ? (
    "Ooops... :("
  ) : (
    <GetSubtitle plan={planv2} />
  );

  const read = useMemo(() => planv2.filter((item) => item.read), [planv2]);

  const percentage = useMemo(
    () => (read.length / planv2.length) * 100,
    [read, planv2]
  );

  return (
    <Header
      title={title}
      subtitle={subtitle}
      color="white"
      additionalComponent={
        isLoading ? (
          <ProgressBarLoading />
        ) : (
          <ProgressBar percentage={parseFloat(percentage.toFixed(2))} />
        )
      }
    />
  );
};

const GetSubtitle = ({ plan }) => {
  const initialReading = getInitialReading(plan);

  const getStartBookName = useCallback(() => {
    return initialReading?.readingStart?.book
      ? bookById(initialReading.readingStart.book)
      : null;
  }, [initialReading]);

  const getStartBookChapter = useCallback(() => {
    return initialReading?.readingStart?.chapter;
  }, [initialReading]);

  const getEndBookChapter = useCallback(() => {
    return plan[plan.length - 1]?.readingEnd?.chapter;
  }, [plan]);

  const getEndBookName = useCallback(() => {
    return bookById(plan[plan.length - 1]?.readingEnd?.book);
  }, [plan]);

  const startBook = getStartBookName();
  const startBookChapter = getStartBookChapter();
  const endBook = getEndBookName();
  const endBookChapter = getEndBookChapter();

  return subtitle(startBook, startBookChapter, endBook, endBookChapter);
};

export default PlanHeader;
