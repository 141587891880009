export function storeUser({ user, token }) {
  return {
    type: "STORE_USER",
    user,
    token,
  };
}

export function storeUserPicture(picture) {
  return {
    type: "STORE_USER_PICTURE",
    picture,
  };
}

export function handleLogout() {
  return {
    type: "LOGOUT",
  };
}
