import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  editChallengeName,
  enterChallengeName,
  enterLargerChallengeName,
} from "./messages";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { useModal } from "contexts/Modal/useModal";
import { save } from "../Questions/messages";
import { Input } from "components/Form";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { sendErrorEmail } from "utils";
import { loading } from "components/SocialLoginButtons/messages";
import Button from "components/Button";
import { useSnackbarNotification } from "components/SnackbarNotifications";

const UpdateChallengeName = () => {
  const { closeModal } = useModal();
  const { request: challenge, updateChallengeTitle } = useChallenge();
  const [challengeTitle, setChallengeTitle] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();

  useEffect(() => {
    setError(null);
  }, [challengeTitle]);

  const onFirstButtonClick = useCallback(async () => {
    if (!challengeTitle) return setError(enterChallengeName);
    if (challengeTitle.length < 2) return setError(enterLargerChallengeName);

    setIsLoading(true);

    try {
      const {
        data: { newTitle },
      } = await challenge.put("name/update", { challengeTitle });

      updateChallengeTitle(newTitle);
    } catch (err) {
      sendErrorEmail(err, "on updating challenge name");
      openGeneralErrorSnackbar();
      closeModal();
    } finally {
      closeModal();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeTitle]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onFirstButtonClick();
      }
    },
    [onFirstButtonClick]
  );

  return (
    <Fragment>
      <h3>{editChallengeName}</h3>

      <div className="form mb16 mt16">
        <Input
          placeholder={editChallengeName}
          onChange={({ target: { value } }) => setChallengeTitle(value)}
          error={error}
          autoFocus={true}
          onKeyDown={onKeyDown}
        />
      </div>
      <Button
        onClick={onFirstButtonClick}
        className="full-width"
        disabled={isLoading}
      >
        {isLoading ? loading : save}
      </Button>
    </Fragment>
  );
};

const ChallengeName = () => {
  const { createModal } = useModal();

  const onClick = useCallback(() => {
    createModal({
      children: <UpdateChallengeName />,
    });
  }, [createModal]);

  return (
    <div className="configurations__item" onClick={onClick}>
      <p>{editChallengeName}</p>
      <ArrowRight />
    </div>
  );
};

export default ChallengeName;
