import classNames from "classnames";
import Button from "components/Button";
import { Input, TextArea } from "components/Form";
import { send, sending } from "pages/Authentication/messages";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { sendEmail, sendErrorEmail } from "utils/sendEmail";
import {
  didntFind,
  emailPlaceholder,
  howWeGetInTouch,
  sendUsAMessage,
  sent,
  writeHere,
} from "../messages";

import { saySomething } from "../messages";

export const REQUIRED = {
  required: {
    value: true,
    message: saySomething,
  },
};

const emailSubject = "Creating Challlenge - Bible Journey option not found";
const emailMessage = (message, contact) =>
  `message: ${message}\n contact:${contact}`;

const OptionNotFound = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (messageSent) {
      setIsLoading(false);
      setTimeout(() => setMessageSent(false), 4000);
    }
  }, [messageSent]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    reset();

    try {
      await sendEmail(emailSubject, emailMessage(data.message, data.contact));
      setTimeout(() => {
        setMessageSent(true);
      }, 5000);
    } catch (err) {
      setIsLoading(false);
      sendErrorEmail(err, `on requesting plan details ${data}`);
    }
  };

  return (
    <div className="didnt-find">
      <p className="didnt-find__title">
        <b>{didntFind}</b>
      </p>
      <p className="didnt-find__subtitle">{sendUsAMessage}</p>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__row full-width">
          <TextArea
            name="message"
            placeholder={writeHere}
            {...register("message", REQUIRED)}
            error={errors?.message?.message}
          />
        </div>
        <div className="form__row full-width">
          <Input
            label={howWeGetInTouch}
            name="contact"
            type="text"
            {...register("contact")}
            placeholder={emailPlaceholder}
          />
        </div>
        <div className="form__row">
          <Button
            size="medium"
            className={classNames("button--primary ", {
              isLoading,
              fadeOut: messageSent,
            })}
            variation="square-rounded"
            theme="primary"
            disabled={isLoading}
            greyOnDisabled={false}
          >
            {messageSent ? sent : isLoading ? sending : send}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OptionNotFound;
