import React, { createContext, memo, useEffect, useState } from "react";
import { findCurrentVersion } from "organisms/BibleReading/utils";
import { get } from "idb-keyval";
import DownloadBible from "organisms/BibleReading/DownloadBible";
import { biblesArray } from "organisms/BibleReading/biblesArray";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { weHadToChangeTheVersion } from "./messages";

const getOption = async (version) => {
  const offline = !!(await get(`bible-${version.id}-downloaded`));

  return {
    id: version.id,
    label: version.name,
    value: version.abbr,
    origin: version.origin,
    endpoint: version.endpoint,
    offline,
    icon: version.downloadAvailable ? (
      <DownloadBible version={version} offline={offline} />
    ) : null,
  };
};

const selectOptions = async () => {
  return await Promise.all(
    biblesArray.map(async (languages) => {
      return {
        title: languages.language,
        options: await Promise.all(
          languages.versions.map((version) => {
            return getOption(version);
          })
        ),
      };
    })
  );
};

export const BibleContext = createContext();

export const BibleProvider = ({ bibleVersion, chooseVersion, children }) => {
  const [isReading, setIsReading] = useState(false);
  const [currentVersion, setCurrentVersion] = useState({});
  const [hasDefinedVersion, setHasDefinedVersion] = useState(false);
  const [versions, setVersions] = useState([]);
  const [hadToChangeVersion, setHadToChangeVersion] = useState(false);

  const { openCustomSnackbar } = useSnackbarNotification();

  useEffect(() => {
    const version = findCurrentVersion(bibleVersion);

    get(`bible-${version.id}-downloaded`).then((offline) => {
      setCurrentVersion({ ...version, offline });
      setHasDefinedVersion(true);
    });
  }, [bibleVersion]);

  useEffect(() => {
    if (isReading && hadToChangeVersion) {
      openCustomSnackbar({
        message: weHadToChangeTheVersion,
        variant: "success",
      });
      setHadToChangeVersion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hadToChangeVersion, isReading]);

  useEffect(() => {
    if (
      hasDefinedVersion &&
      !currentVersion.offline &&
      !navigator.onLine &&
      !!versions.length
    ) {
      const version = versions[0].options[0];
      chooseVersion({ abbr: version.value, id: version.id });
      setCurrentVersion(version);
      setHadToChangeVersion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion.offline, hasDefinedVersion, versions]);

  useEffect(() => {
    if (hasDefinedVersion) {
      selectOptions().then((versions) => {
        if (
          (!navigator.onLine && !currentVersion.offline) ||
          !navigator.onLine
        ) {
          let offline = [];

          versions.forEach((language) => {
            if (language.options.some((option) => option.offline)) {
              offline.push({
                title: language.title,
                options: language.options.filter((option) => option.offline),
              });
            }
          });

          return setVersions(offline);
        }

        setHasDefinedVersion(false);
        setVersions(versions);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDefinedVersion]);

  return (
    <BibleContext.Provider
      value={{
        currentVersion,
        isReading,
        versions,
        setIsReading,
      }}
    >
      {children}
    </BibleContext.Provider>
  );
};

export default memo(BibleProvider);
