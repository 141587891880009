/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Input } from "components/Form";
import Button from "components/Button";
import { storeUser } from "store/actions/user";
import { EMAIL_REQUIRED, PASSWORD_REQUIRED } from "../validationSchema";
import { useAuth } from "../../../contexts/AuthContext";
import { loggingIn, loginMsg } from "./messages";
import { email, password } from "../messages";

const Form = () => {
  const { formLogin, isLoading, register, handleSubmit, errors } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(formLogin)} className="form">
        <div className="form__row full-width">
          <Input
            label={email}
            name="email"
            type="email"
            placeholder="billy@graham.com"
            {...register("email", EMAIL_REQUIRED)}
            error={errors?.email?.message}
          />
        </div>
        <div className="form__row full-width">
          <Input
            label={password}
            name="password"
            type="password"
            placeholder="*********"
            {...register("password", PASSWORD_REQUIRED)}
            error={errors?.password?.message}
            showForgotPasswordLink={true}
          />
        </div>
        <div className="form__row">
          <Button
            size="medium"
            className="button--success  mt8"
            variation="square-rounded"
            theme="primary"
            disabled={isLoading}
          >
            {isLoading ? loggingIn : loginMsg}
          </Button>
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveUser: (payload) => dispatch(storeUser(payload)),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Form));
