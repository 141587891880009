import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "store";
import { PersistGate } from "redux-persist/integration/react";
import AppWrapper from "components/AppWrapper";
import GlobalContexts from "../GlobalContexts";
import { routesData } from "./data";
import { BottomBar } from "molecules/BottomBar";
import { Notifications } from "components/Notifications";
import GlobalComponents from "GlobalComponents";
import PageNotFound from "pages/PageNotFound";
import AppUrlListener from "atoms/AppUrlListener";

const Routing = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <GlobalContexts>
            <AppWrapper>
              <GlobalComponents>
                <AppUrlListener />
                <Switch>
                  {routesData.map((item, key) => (
                    <Route key={key} {...item} />
                  ))}
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </GlobalComponents>

              <BottomBar />
              <Notifications />
            </AppWrapper>
          </GlobalContexts>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default Routing;
