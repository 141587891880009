export const menu = {
  menu: {
    menu: "Menu",
    challenges: "Desafios",
    subscription: "Assinatura",
    cancelSubscription: "Cancelar assinatura",
    editPlan: "Editar plano",
    downloadPlanPdf: "Baixar plano em PDF",
    downloadingPlanPdf: "Baixando...",
    shareBibleJourney: "Compartilhe o Bible Journey",
    giveFeedback: "Dê feedback",
    donate: "Doar",
    logout: "Sair",
    editReminder: "Editar lembrete",
    configureReminder: "Configurar lembrete",
    yourPlan: "Seu plano pessoal",
    challengeInvite: "Convite para desafio:",
    createAChallenge: "Crie um desafio",
    areYouSure: "Tem certeza?",
    willLostProgress:
      "Você perderá acesso a todos os desafios que já criou e os participantes também. *",
    yesCancel: "Sim, cancelar a assinatura",
    changedMyMind: "Mudei de ideia",
    unsubscribing: "Cancelando...",
    successfullyUnsubscribed: "Cancelado com sucesso :)",
    premiumAccount: "Conta Premium",
    language: "Idioma",
    reminder: "Lembrete",
    theme: "Tema",
    dark: "Escuro",
    light: "Claro",
    toCancel:
      "Para cancelar a assinatura, vá em Configurações > Clique no seu nome > Clique em Assinaturas",
    toCancelAndroid:
      "Para cancelar a assinatura, vá em no Google Play > Clique no ícone do perfil. > Pagamentos & assinaturas. > Assinaturas.",
    processStarted: "Processo iniciado",
    dangerArea: "Área de perigo",
    weWillGetInTouch:
      "Entraremos em contato para lhe informar o andamento da exclusão da sua conta. Se estiver certo da sua escolha, confirme:",
    payAttention:
      "Fique atento à sua caixa de entrada. Em breve mandaremos um e-mail para",
    yesImSure: "Sim, tenho certeza.",
    askAccountRemoval: "Pedir exclusão de conta",
  },
};
