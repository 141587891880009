import { i18n } from "translate/i18n";

export const inviteReceived = i18n.t("messages.challenge.inviteReceived");

export const inviteReceivedDescription = ({ name, challengeName }) =>
  i18n.t("messages.challenge.inviteReceivedDescription", {
    name,
    challengeName,
  });

export const seeLater = i18n.t(`messages.challenge.seeLater`);
export const accept = i18n.t(`messages.challenge.accept`);
export const decline = i18n.t(`messages.challenge.decline`);
export const notAllow = i18n.t(`messages.challenge.notAllow`);
