import React from "react";
import { isIOSdevice, isNativePlatform } from "utils/devices";
import IOSFacebookLoginButton from "./IOS";
import WebFacebookLoginButton from "./Web";

const FacebookLoginButton = (props) => {

  if (isIOSdevice && isNativePlatform)
    return <IOSFacebookLoginButton {...props} />;

  return <WebFacebookLoginButton {...props} />;
};

export default FacebookLoginButton;
