import React, { Fragment, useEffect, useRef, useState } from "react";

import { usePlan } from "../Context";

import classNames from "classnames";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import { FormFeedback } from ".";
import { getItemMaxHeightStyle } from "components/Accordion/getItemMaxHeightStyle";
import DatesInputs from "../components/DateInputs";

const DefaultForm = () => {
  const [contentStyle, setContentStyle] = useState({});
  const contentRef = useRef(null);

  const { openMoreOptions, formError, blockedWeekDays, setBlockedWeekDays } =
    usePlan();

  useEffect(() => {
    setTimeout(() => {
      setContentStyle(
        getItemMaxHeightStyle(!openMoreOptions, contentRef.current)
      );
    }, 100);
  }, [openMoreOptions, formError, contentRef]);

  return (
    <Fragment>
      <div
        ref={contentRef}
        style={contentStyle}
        className={classNames("default-form", { openMoreOptions })}
      >
        <ReadingInputs />
        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />
        <DatesInputs />
        <FormFeedback />
      </div>
    </Fragment>
  );
};

export default DefaultForm;
