import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { dayTranslate, buildReadingDate } from "./messages";
import { ReadButton } from "components/ReadButton";
import { buildReadingInterval } from "utils/reading";
import { usePlan } from "components/FormPlan/Context";
import { api } from "utils";
import { useChallenge } from "contexts/Challenge/useChallenge";
import useUserPoints from "contexts/Challenge/UserPoints/useUserPoints";
import { usePlanProgress } from "contexts/Challenge/PlanProgress";
import { ReactComponent as Checkbox } from "assets/images/checkbox.svg";
import { ReactComponent as CheckboxChecked } from "assets/images/checkbox-checked.svg";

const ChecklistItem = ({ day, checked, planId }) => {
  const { date, readingDetails, _id } = day;
  const weekDay = moment(date).day();
  const dayNumber = moment(date).date();
  const [isChecked, setIsChecked] = useState(checked);
  const readingInterval = buildReadingInterval(day);
  const readingDate = buildReadingDate(weekDay, dayTranslate, dayNumber);
  const { plan, isLoading, isFetchingPlan } = usePlan();
  const { challengeHeaders, rankingId, isOwner, currentChallenge } =
    useChallenge();
  const { showPointsText } = useUserPoints();
  const { increaseProgress, decreaseProgress } = usePlanProgress();

  useEffect(() => {
    const found = plan.find((item) => item._id === day._id);
    const shouldToggle =
      found && found?.read !== isChecked && !isLoading && !isFetchingPlan;
    shouldToggle && setIsChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  const headers = useMemo(() => {
    return {
      headers: {
        ranking_id: rankingId,
        day_id: _id,
        plan_id: planId,
        ...challengeHeaders.headers,
      },
    };
  }, [_id, challengeHeaders.headers, planId, rankingId]);

  const handleCheck = useCallback(
    (_id, isChecked) => {
      day.read = isChecked;

      if (isChecked) {
        api.put("/challenge/plan/reading/complete", {}, headers);
      } else {
        api.put("/challenge/plan/reading/undo", {}, headers);
      }
    },
    [day, headers]
  );

  const shouldShowPointsText = useMemo(() => {
    return !isOwner && !currentChallenge.includeLeaders;
  }, [currentChallenge.includeLeaders, isOwner]);

  const onClick = useCallback(() => {
    if (!isChecked) {
      shouldShowPointsText && showPointsText({ points: 1 });
      increaseProgress();
    } else {
      shouldShowPointsText && showPointsText({ points: -1 });
      decreaseProgress();
    }
    setIsChecked(!isChecked);
    handleCheck(_id, !isChecked, headers);
  }, [
    isChecked,
    handleCheck,
    _id,
    headers,
    shouldShowPointsText,
    showPointsText,
    increaseProgress,
    decreaseProgress,
  ]);

  const onFinishReading = useCallback(() => {
    if (!isChecked) {
      shouldShowPointsText && showPointsText({ points: 1 });
      setIsChecked(true);
      increaseProgress();
      handleCheck(_id, true, headers);
    }
  }, [
    isChecked,
    shouldShowPointsText,
    showPointsText,
    increaseProgress,
    handleCheck,
    _id,
    headers,
  ]);

  return (
    <div
      className={classNames("checklist__item noClickHighlight", {
        checked: isChecked,
      })}
    >
      <div className="checklist__item-content" onClick={onClick}>
        <i className="checkbox">
          {isChecked ? <CheckboxChecked /> : <Checkbox />}
        </i>
        <article>
          <p>{readingInterval}</p>
          <span>{readingDate}</span>
        </article>
      </div>

      <ReadButton
        onFinishReading={onFinishReading}
        day={day}
        readingDetails={readingDetails}
      />
    </div>
  );
};

export default ChecklistItem;
