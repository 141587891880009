import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

const ChapterNameLoading = ({ focusMode }) => {
  return (
    <Fragment>
      <div
        className="bible-reading__chapter-name-loading"
        style={{
          padding: focusMode ? "8px 16px" : "32px 16px 24px 16px",
        }}
      >
        <Skeleton
          className="skeleton"
          height={focusMode ? 16 : 22}
          width={250}
        />
      </div>
    </Fragment>
  );
};

export default ChapterNameLoading;
