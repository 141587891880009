import { i18n } from "../../translate/i18n";

export const title = i18n.t("titles.appHeader.title");

export const subtitle = (
  startBook,
  startBookChapter,
  endBook,
  endBookChapter
) =>
  i18n.t("titles.appHeader.subtitle", {
    startReading: `${startBook} ${startBookChapter}`,
    endReading: `${endBook} ${endBookChapter}`,
  });

export const bookByIndex = (index) =>
  i18n.t(`messages.bibleBooks.${index}.name`);

export const bookById = (index) => i18n.t(`messages.bibleBooks.${index}.name`);
