import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";

import { useChallenge } from "contexts/Challenge/useChallenge";
import { useUserPoints } from "../UserPoints";
import { useUser } from "contexts/User";
import { usePlanProgress } from "../PlanProgress";

export const ChallengeRankingContext = createContext();

export const RankingProvider = ({ children }) => {
  const [viewGeneralRanking, setViewGeneralRanking] = useState(true);
  const [viewReadingRanking, setViewReadingRanking] = useState(false);
  const [viewQuestionsRanking, setViewQuestionsRanking] = useState(false);
  const [podiumIsLoaded, setPodiumIsLoaded] = useState();
  const { isLoadingPoints } = useUserPoints();
  const { email: userEmail, name } = useUser();
  const { viewingChallenge } = useChallenge();

  useEffect(() => {
    if (!viewingChallenge) setPodiumIsLoaded(false);
  }, [viewingChallenge]);

  const { totalPoints, questionPoints } = useUserPoints();

  const { currentChallenge, isOwner } = useChallenge();

  const { noReaders, readersRanking } = useChallengePlan();

  const { progress: readingProgress } = usePlanProgress();

  const noParticipants = useMemo(
    () => currentChallenge?.participants?.length === 0,
    [currentChallenge]
  );

  const removeLeadersIfNeeded = useCallback(
    (arr) => {
      const { includeLeaders } = currentChallenge;

      return arr.filter((position) => {
        const isOwner = position.user._id === currentChallenge.owner;
        if (isOwner && !includeLeaders) return false;
        return true;
      });
    },
    [currentChallenge]
  );

  const pushMissingParticipants = useCallback(
    (readers) => {
      currentChallenge?.ranking?.forEach((position) => {
        if (!readers.length) return;

        const isOnBothRankings = readers.find((reader) => {
          return reader.user.email === position.user.email;
        });

        if (!isOnBothRankings) {
          readers.push({
            user: position.user,
            conclusionPercentage: 0,
            position: readersRanking.length + 1,
          });
        }
      });
    },
    [currentChallenge?.ranking, readersRanking.length]
  );

  const orderQuestionsRanking = useCallback(() => {
    const questionsRanking = currentChallenge?.ranking?.map(
      (position, index) => {
        const isLoggedUser = position.user.email === userEmail;
        if (isLoggedUser && questionPoints > 0) {
          return {
            ...position,
            questionPoints,
          };
        }

        return position;
      }
    );

    const sortedQuestionsRanking = questionsRanking?.sort((a, b) => {
      return b.questionPoints - a.questionPoints;
    });

    return sortedQuestionsRanking;
  }, [currentChallenge?.ranking, questionPoints, userEmail]);

  const orderTotalRanking = useCallback(() => {
    const ranking = currentChallenge?.ranking?.map((position, index) => {
      const isLoggedUser = position.user.email === userEmail;
      if (isLoggedUser && totalPoints > 0) {
        return {
          ...position,
          totalPoints,
        };
      }
      return { ...position, totalPoints: position.points };
    });

    const sortedChallengeRanking = ranking?.sort((a, b) => {
      return b.totalPoints - a.totalPoints;
    });

    return sortedChallengeRanking;
  }, [currentChallenge?.ranking, totalPoints, userEmail]);

  const addMissingUserIfNeeded = useCallback(
    (readersRanking) => {
      const userIsOnRanking = readersRanking.some((reader) => {
        return reader.user.email === userEmail;
      }, []);

      if (!userIsOnRanking && !isOwner) {
        readersRanking.push({
          user: {
            email: userEmail,
            name,
          },
          conclusionPercentage: readingProgress,
        });
      }
    },
    [name, readingProgress, userEmail, isOwner]
  );

  const orderReadersRanking = useCallback(() => {
    addMissingUserIfNeeded(readersRanking);

    const ranking = readersRanking.map((position, index) => {
      const isLoggedUser = position.user.email === userEmail;
      if (isLoggedUser && readingProgress > 0) {
        return {
          ...position,
          conclusionPercentage: readingProgress,
        };
      }
      return { ...position };
    });

    const sortedReadersRanking = ranking?.sort((a, b) => {
      return b.conclusionPercentage - a.conclusionPercentage;
    });

    return sortedReadersRanking;
  }, [addMissingUserIfNeeded, readersRanking, readingProgress, userEmail]);

  const createNoReadersRanking = useCallback(() => {
    return currentChallenge?.ranking?.map((position) => {
      return { ...position, conclusionPercentage: 0 };
    });
  }, [currentChallenge?.ranking]);

  const sortedChallengeRanking = useMemo(() => {
    if (isLoadingPoints && !isOwner) return [];

    if (viewQuestionsRanking) {
      const ranking = orderQuestionsRanking();

      return removeLeadersIfNeeded(ranking);
    }

    if (viewReadingRanking && noReaders) {
      const ranking = createNoReadersRanking();
      return removeLeadersIfNeeded(ranking);
    }

    if (viewReadingRanking) {
      const readers = orderReadersRanking();
      pushMissingParticipants(readers);

      return removeLeadersIfNeeded(readers);
    }

    const ranking = orderTotalRanking();

    return removeLeadersIfNeeded(ranking);
  }, [
    isLoadingPoints,
    isOwner,
    viewQuestionsRanking,
    viewReadingRanking,
    noReaders,
    orderTotalRanking,
    removeLeadersIfNeeded,
    orderQuestionsRanking,
    createNoReadersRanking,
    orderReadersRanking,
    pushMissingParticipants,
  ]);

  const podium = useMemo(() => {
    if (!sortedChallengeRanking) return [];

    const podium = sortedChallengeRanking.slice(0, 3);

    return podium;
  }, [sortedChallengeRanking]);

  const outOfRanking = useMemo(() => {
    if (!sortedChallengeRanking) return [];

    return sortedChallengeRanking.slice(3);
  }, [sortedChallengeRanking]);

  return (
    <ChallengeRankingContext.Provider
      value={{
        podium,
        outOfRanking,
        noParticipants,
        viewGeneralRanking,
        viewReadingRanking,
        viewQuestionsRanking,
        podiumIsLoaded,
        setPodiumIsLoaded,
        setViewGeneralRanking,
        setViewReadingRanking,
        setViewQuestionsRanking,
      }}
    >
      {children}
    </ChallengeRankingContext.Provider>
  );
};

export default memo(RankingProvider);
