import React, { memo } from "react";
import { ReactComponent as IconWeek } from "assets/images/week-icon.svg";
import { buildWeekDaysLabel } from "./messages";
import {
  sundayLetter,
  mondayLetter,
  tuesdayLetter,
  wednesdayLetter,
  thursdayLetter,
  fridayLetter,
  saturdayLetter,
} from "./messages";
import classNames from "classnames";

const weekArray = [
  sundayLetter,
  mondayLetter,
  tuesdayLetter,
  wednesdayLetter,
  thursdayLetter,
  fridayLetter,
  saturdayLetter,
];

const WeekDaysSelector = ({ blockedWeekDays, onChange }) => {
  const onClick = (index) => {
    const array = [...blockedWeekDays];
    const isOnList = array.some((i) => i === index);

    if (blockedWeekDays.length === 6 && !isOnList) return;

    if (isOnList) {
      const newDays = array.filter((item) => item !== index);
      return onChange(newDays);
    }

    array.push(index);

    onChange(array);
  };

  return (
    <div className="week-days-selector">
      <div className="week-days-selector__header">
        <IconWeek /> <p>{buildWeekDaysLabel(blockedWeekDays)}</p>
      </div>
      <div className="week-days-selector__days">
        {weekArray.map((day, index) => (
          <div
            key={index}
            onClick={() => onClick(index)}
            className={classNames("week-days-selector__day", {
              active: !blockedWeekDays.some((day) => day === index),
            })}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(WeekDaysSelector);
