import React, { createContext, memo, useState } from "react";
import classNames from "classnames";
import { points, point } from "./messages";
import { useEffect } from "react";
import { useUser } from "contexts/User";
import { useChallenge } from "../useChallenge";

export const UserPointsContext = createContext();

const UserPointsProvider = ({ children }) => {
  const [activeMessage, setActiveMessage] = useState(false);
  const [leaveMessage, setLeaveMessage] = useState(false);
  const [quantityToShow, setQuantityToShow] = useState(0);
  const [isIncreasing, setIsIncreasing] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isLoadingPoints, setIsLoadingPoints] = useState(true);
  const [pointsToIncrease, setPointsToIncrease] = useState(0);
  const [questionPointsToIncrease, setQuestionPointsToIncrease] = useState(0);
  const [questionPoints, setQuestionPoints] = useState(0);

  const { currentChallenge, isLoadingChallenge } = useChallenge();

  useEffect(() => {
    if (questionPointsToIncrease !== 0) {
      setQuestionPoints(questionPoints + questionPointsToIncrease);

      setQuestionPointsToIncrease(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionPointsToIncrease]);

  useEffect(() => {
    if (pointsToIncrease !== 0) {
      setTotalPoints(totalPoints + pointsToIncrease);

      setPointsToIncrease(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsToIncrease]);

  const { email: userEmail } = useUser();

  useEffect(() => {
    const rankingUser = currentChallenge?.ranking?.find(
      (item) => item?.user?.email === userEmail
    );

    if (rankingUser && !isLoadingChallenge) {
      setTotalPoints(rankingUser.points);
      setQuestionPoints(rankingUser.questionPoints);
      setIsLoadingPoints(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail, currentChallenge, isLoadingChallenge]);

  const showPointsText = ({ points, questionPoint } = {}) => {
    setIsIncreasing(points > 0);
    setQuantityToShow(points);
    setActiveMessage(true);
    if (questionPoint) {
      setQuestionPointsToIncrease(points);
    }
    setPointsToIncrease(points);

    setTimeout(() => {
      setActiveMessage(false);
      setLeaveMessage(true);

      setTimeout(() => {
        setLeaveMessage(false);
      }, 1000);
    }, 1000);
  };

  return (
    <UserPointsContext.Provider
      value={{
        totalPoints,
        isLoadingPoints,
        questionPoints,
        showPointsText,
        setTotalPoints,
      }}
    >
      <div
        className={classNames("points-message", {
          active: activeMessage,
          leave: leaveMessage,
          isIncreasing,
          isDecreasing: !isIncreasing,
        })}
      >
        {isIncreasing && "+"}
        {quantityToShow} {quantityToShow > 1 ? `${points}!` : point}
        {isIncreasing ? " 🎉" : " 😢"}
      </div>
      {children}
    </UserPointsContext.Provider>
  );
};

export default memo(UserPointsProvider);
