import React, { Fragment, memo, useEffect, useMemo } from "react";
import { useModal } from "contexts/Modal/useModal";
import Modal from "components/Modal";
import { useHistory } from "react-router";
import { UpdateChecker } from "atoms";

const buildExtraProps = (extraData) => {
  return !!Object.keys(extraData)?.length ? extraData : {};
};

const GlobalComponents = ({ children }) => {
  const {
    waitingList,
    waitingListStatus,
    normalListProps,
    shouldUpdateActiveModalProps,
  } = useModal();

  const history = useHistory();

  const extraModalProp = useMemo(
    () => buildExtraProps(shouldUpdateActiveModalProps),
    [shouldUpdateActiveModalProps]
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        history.push(event.data.notification.click_action);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalProps = useMemo(() => {
    if (Object.keys(normalListProps).length === 1) return { active: false };
    return normalListProps;
  }, [normalListProps]);

  return (
    <Fragment>
      {waitingList.map((modal, index) => (
        <Modal
          key={modal.id}
          {...modal}
          active={waitingListStatus[index]?.active}
        />
      ))}
      <Modal {...modalProps} {...extraModalProp} />
      <UpdateChecker />
      {children}
    </Fragment>
  );
};

export default memo(GlobalComponents);
