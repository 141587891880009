import React, { Fragment, useCallback, useEffect, useState } from "react";
import withChallenge from "HOCs/withChallenge";
import Wrapper from "../Wrapper";
import {
  editParticipants,
  action,
  notJoinedYet,
  remove,
  addParticipants,
  wantToRemove,
  yesRemoveThisPerson,
  inCaseYouChangeYourMind,
  resendInvitation,
  invitationSent,
} from "../messages";
import { name } from "../../Ranking/messages";
import { ReactComponent as XIcon } from "assets/icons/icon-x.svg";
import Button from "components/Button";
import { useModal } from "contexts/Modal/useModal";
import { areYouSure } from "../../Questions/messages";
import { changedMyMind } from "pages/Menu/messages";
import AddParticipants from "./AddParticipants";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import classNames from "classnames";
import Skeleton from "./Skeleton";
import { useUser } from "contexts/User";
import { loading } from "components/SocialLoginButtons/messages";

const Participant = ({ user, requestFetchParticipants }) => {
  const { createModal, closeModal } = useModal();
  const { request: challenge, currentChallenge } = useChallenge();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [inviteWasSent, setInviteWasSent] = useState(false);

  const { name: userName } = useUser();

  const onRemoveClick = useCallback(async () => {
    try {
      await challenge.delete(`participant/delete/${user._id}`);

      requestFetchParticipants();
    } catch (error) {
      sendErrorEmail(error, "creating challenge:createChallenge");
      openGeneralErrorSnackbar();
    } finally {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._id]);

  const onSendInvite = useCallback(async () => {
    try {
      setIsSendingInvite(true);

      await challenge.post("invite/resend", {
        participants: [user.email],
        ownerName: userName,
        challengeName: currentChallenge.title,
      });

      setInviteWasSent(true);
    } catch (error) {
      sendErrorEmail(error, "creating challenge:createChallenge");
      openGeneralErrorSnackbar();
    } finally {
      setIsSendingInvite(false);
    }
  }, [
    challenge,
    currentChallenge.title,
    openGeneralErrorSnackbar,
    user.email,
    userName,
  ]);

  const openDeleteParticipant = useCallback(() => {
    if (!user.accepted) return null;

    createModal({
      title: areYouSure,
      subtitle: wantToRemove,
      firstButtonText: changedMyMind,
      secondButtonText: yesRemoveThisPerson,
      disclaimer: inCaseYouChangeYourMind,
      secondButtonTheme: "danger",
      firstButtonTheme: "success",
      onFirstButtonClick: closeModal,
      onSecondButtonClick: onRemoveClick,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, createModal, user]);

  return (
    <div className="table__item">
      <div className="table__item-name">{user.name || user.email}</div>
      <div className="table__item-action" onClick={openDeleteParticipant}>
        {!user.accepted ? (
          <span
            className={classNames("table__item-action-link", {
              notInitial: isSendingInvite || inviteWasSent,
            })}
            onClick={onSendInvite}
          >
            {isSendingInvite
              ? loading
              : inviteWasSent
              ? invitationSent
              : resendInvitation}
          </span>
        ) : (
          <Fragment>
            <p>{remove}</p>
            <XIcon />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const EditParticipantsPage = () => {
  const { createModal } = useModal();
  const { request: challenge, isLoadingChallenge } = useChallenge();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [participants, setParticipants] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  const fetchParticipants = useCallback(async () => {
    try {
      setIsLoading(true);

      let {
        data: { participants, invitedUsers },
      } = await challenge.get("participants");

      setParticipants(
        participants.map((participant) => ({
          ...participant,
          accepted: true,
        }))
      );

      setInvitedUsers(
        invitedUsers?.map(({ user }) => ({ ...user, accepted: false }))
      );
    } catch (error) {
      sendErrorEmail(error, "creating challenge:createChallenge");
      openGeneralErrorSnackbar();
    } finally {
      setIsLoading(false);
      setShouldFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge]);

  useEffect(() => {
    (!isLoadingChallenge || shouldFetch) && fetchParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingChallenge, shouldFetch]);

  const requestFetchParticipants = useCallback(() => {
    setShouldFetch(true);
  }, []);

  const onAddParticipantsClick = useCallback(() => {
    createModal({
      children: (
        <Fragment>
          <h3>{addParticipants}</h3>

          <div className="mt16">
            <AddParticipants
              requestFetchParticipants={requestFetchParticipants}
            />
          </div>
        </Fragment>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper cardTitle={editParticipants}>
      <div className="table table--participants">
        <div className="table__header">
          <p>{name}</p>
          <p className="table__header-points">{action}</p>
        </div>

        <div className="table__body">
          {isLoading ? (
            <Skeleton className="skeleton" />
          ) : (
            <Fragment>
              {participants.map((user, index) => (
                <Participant
                  key={index}
                  user={user}
                  participants={participants}
                  requestFetchParticipants={requestFetchParticipants}
                />
              ))}
              {!!invitedUsers.length && (
                <p className="table__middle-title">{notJoinedYet}:</p>
              )}
              {invitedUsers.map((user, index) => (
                <Participant
                  key={index}
                  user={user}
                  participants={participants}
                  requestFetchParticipants={requestFetchParticipants}
                />
              ))}
              <div className="table__item--button">
                <Button
                  size="medium"
                  className="button--success  mt8"
                  variation="square-rounded"
                  theme="primary"
                  onClick={onAddParticipantsClick}
                >
                  {addParticipants}
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default withChallenge(EditParticipantsPage);
