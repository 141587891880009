import { useContext } from "react";
import { QuestionContext } from "./Component";

const useQuestion = () => {
  const context = useContext(QuestionContext);
  if (!context)
    throw new Error("useQuestion must be used within a QuestionProvider");

  return context;
};

export default useQuestion;
