import React, { createContext, useEffect, useState } from "react";
import Modal from "components/Modal";
import { Input, TextArea } from "components/Form";

import { send, sending } from "pages/Authentication/messages";
import Button from "components/Button";
import { sendErrorEmail, sendErrorReportingEmail } from "utils/sendEmail";
import classNames from "classnames";
import {
  emailPlaceholder,
  sent,
  wantUsToGetInTouch,
} from "components/FormPlan/messages";
import { reportError, tellUs, whatHappened } from "./messages";
import { useCallback } from "react";

export const ReportErrorContext = createContext();

const ReportErrorProvider = ({ children }) => {
  const [isModalActive, setModalIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");

  const closeModal = () => setModalIsActive(false);

  useEffect(() => {
    messageSent && setTimeout(() => setModalIsActive(false), 1000);
  }, [messageSent]);

  const onSend = useCallback(async () => {
    setIsLoading(true);

    try {
      await sendErrorReportingEmail(message, contact);
      setTimeout(() => {
        setMessageSent(true);
        setIsLoading(false);
        setTimeout(() => setMessageSent(false), 3000);
      }, 1000);
    } catch (err) {
      setIsLoading(false);
      sendErrorEmail(err, `on trying to report error`);
    }
  }, [message, contact]);

  const onMessageChange = useCallback(({ target: { value } }) => {
    setMessage(value);
  }, []);

  const onContactChange = useCallback(({ target: { value } }) => {
    setContact(value);
  }, []);

  const onKeyDown = useCallback(
    ({ key }) => {
      key === "Enter" && onSend();
    },
    [onSend]
  );

  const openReportErrorModal = () => setModalIsActive(true);

  return (
    <ReportErrorContext.Provider value={{ openReportErrorModal }}>
      <Modal
        active={isModalActive}
        secondButtonText={send}
        onSecondButtonClick={() => {}}
        onOverlayClick={closeModal}
      >
        <div className="form">
          <h3 className="mb16">{reportError}</h3>

          <div className="form__row full-width mt16">
            <TextArea
              label={whatHappened}
              fullWidth={true}
              placeholder={tellUs}
              onChange={onMessageChange}
            />
          </div>
          <div className="form__row full-width">
            <Input
              label={wantUsToGetInTouch}
              name="contact"
              type="text"
              placeholder={emailPlaceholder}
              onChange={onContactChange}
              onKeyDown={onKeyDown}
            />
          </div>
          <div className="form__row full-width">
            <Button
              size="medium"
              className={classNames("", {
                isLoading,
                fadeOut: messageSent,
              })}
              variation="square-rounded"
              fullWidth={true}
              theme="primary"
              disabled={isLoading}
              onClick={onSend}
            >
              {messageSent ? sent : isLoading ? sending : send}
            </Button>
          </div>
        </div>
      </Modal>
      {children}
    </ReportErrorContext.Provider>
  );
};

export default ReportErrorProvider;
