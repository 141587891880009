import React, { memo } from "react";
import { isNativeIOS } from "utils";

const GeneralPrivacyPolicy = () => {
  return (
    <section
      className="section "
      style={{ background: "var(--app-background)", color: "var(--white)" }}
    >
      <div className="container">
        <article className="article-content">
          <h1 className="bold">Privacy Policy</h1>
          <p>
            Your privacy is important to us. It is Bible Journey policy respect
            your privacy in relation to any information about you that we can
            collect on the {isNativeIOS ? "app" : "website"}{" "}
            <a href="http://bibleplan.online">Bible Journey</a>, and other sites
            that we own and operate.
          </p>
          <p>
            We only ask for personal information when we really need it them to
            provide you with a service. We do it by fair means and law, with
            your knowledge and consent. We also inform why we are collecting it
            and how it will be used.
          </p>
          <p>
            We only retain collected information for as long as necessary to
            provide the requested service. When we store data, we protect within
            commercially acceptable means to avoid loss and theft, as well as
            access, disclosure, copying, use or modification not authorized.
          </p>
          <p>
            We do not share personally identifiable information publicly. or
            with third parties, except as required by law.
          </p>
          <p>
            Our {isNativeIOS ? "app" : "website"} may have links to external
            websites that are not operated for us. Please be aware that we have
            no control over the content and practices of these sites and we
            cannot accept responsibility for their respective{" "}
            <a
              href="https://politicaprivacidade.com"
              target="_BLANK"
              rel="noopener noreferrer"
            >
              privacy policies
            </a>
            .
          </p>
          <p>
            You are free to refuse our request for information. personal
            information, with the understanding that we may not be able to
            provide some of the desired services.
          </p>
          <p>
            Your continued use of our {isNativeIOS ? "app" : "website"} will be
            considered acceptance of our practices around privacy and personal
            information. if you have any questions about how we handle user data
            and personal information, please contact us.
          </p>
          <h2>Bible Journey Cookie Policy</h2> <h3>What are cookies?</h3>
          <p>
            As is common practice on almost all professional websites, this
            {isNativeIOS ? "app" : "website"} uses cookies, which are small
            files downloaded to your computer, to improve your experience. This
            page describes what information they collect, how we use it, and why
            sometimes we need to store these cookies. We will also share how you
            can prevent these cookies from being stored, however, this can
            downgrade or 'break' certain elements of the
            {isNativeIOS ? "app" : "website"} functionality.
          </p>
          <h3>How do we use cookies?</h3>
          <p>
            We use cookies for a variety of reasons, detailed below.
            Unfortunately, in most cases, there are no default options for the
            sector to disable cookies without completely disabling the
            functionality and features they add to this site. IT'S recommended
            that you leave all cookies if you are not sure whether whether or
            not you need them if they are used to provide a service that you
            use.
          </p>
          <h3>Disable cookies</h3>
          <p>
            You can prevent cookies from being set by adjusting the your browser
            settings (see browser Help for know how to do it). Please be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will generally
            result in deactivation of certain functionality and features of this{" "}
            {isNativeIOS ? "app" : "website"} . Therefore, it is recommended
            that you do not disable cookies.
          </p>
          <h3>Cookies We Set</h3>
          <ul>
            <li>
              Account-related cookies
              <br />
              <br /> If you create an account with us, we will use cookies for
              the management of the application process and general
              administration. These cookies will generally be deleted when you
              leave the system, however, in some cases, they may remain later to
              remember your site preferences when you exit.
              <br />
              <br />
            </li>
            <li>
              Log-related cookies in
              <br />
              <br /> We use cookies when you are logged in, so that we can
              remember this action. This saves you from having to do login
              whenever you visit a new page. These cookies are normally removed
              or cleared when you log out to ensure that you can only access
              resources and areas restricted when logging in.
              <br />
              <br />
            </li>
            <li>
              Cookies related to email newsletters
              <br />
              <br /> This site offers newsletter subscription services
              newsletter or email and cookies may be used to remember whether
              you are already registered and whether to show certain
              notifications only valid for subscribed / non-subscribed users
              enrolled.
              <br />
              <br />
            </li>
            <li>
              Orders processing related cookies
              <br />
              <br /> This site offers e-commerce facilities or payment and some
              cookies are essential to ensure that your order is remembered
              between pages so that we can process it properly.
              <br />
              <br />
            </li>
            <li>
              Cookies related to surveys
              <br />
              <br /> Periodically, we offer surveys and questionnaires to
              provide interesting information, useful tools or to understand our
              user base more accurately. These surveys may use cookies to
              remember who has already participated in a survey or to provide
              accurate results after changing pages.
              <br />
              <br />
            </li>
            <li>
              Form-related cookies
              <br />
              <br /> When you submit data through a form like the found on the
              contact pages or in the registration forms. comments, cookies can
              be set to remember the User details for future correspondence.
              <br />
              <br />
            </li>
            <li>
              Site Preference Cookies
              <br />
              <br /> To provide you with a great experience on this site, we
              provide the functionality to set your preferences for how that{" "}
              {isNativeIOS ? "app" : "website"} runs when you use it. to
              remember your preferences, we need to set cookies so that these
              information can be called up whenever you interact with a page is
              affected by your preferences.
              <br />
            </li>
          </ul>
          <h3>Third Party Cookies</h3>
          <p>
            In some special cases, we also use cookies provided by trusted third
            parties. The following section details which cookies from third
            parties you can find through this {isNativeIOS ? "app" : "website"}{" "}
            .
          </p>
          <ul>
            <li>
              This {isNativeIOS ? "app" : "website"} uses Google Analytics,
              which is one of the most widespread and trusted analytics on the
              web to help us understand how you use the site and how we can
              improve your experience. These cookies can track things like how
              long you spend on the site and the pages you visit, so that we can
              keep producing compelling content.
            </li>
          </ul>
          <p>
            For more information about Google Analytics cookies, see the
            official Google Analytics page.
          </p>
          <ul>
            <li>
              Third-party analytics are used to track and measure usage of this
              site, so that we can continue to produce content attractive. These
              cookies can track things like the time you passes on the{" "}
              {isNativeIOS ? "app" : "website"} or the pages visited, which
              helps us to understand how we can improve the site for you.
            </li>
            <li>
              We periodically test new features and make changes subtle in the
              way the site presents itself. when we are still testing new
              features, these cookies may be used to ensure you receive a
              consistent experience while is on the site, while we understand
              what optimizations our users appreciate most.
            </li>
            <li>
              As we sell products, it is important that we understand the
              statistics on how many visitors to our site actually buy and so
              this is the kind of data that these Cookies will track. This is
              important to you as it means that we can make accurate business
              predictions that allow us to analyze our advertising and product
              costs to ensure the best possible price.
            </li>
            <li>
              The Google AdSense service we use to serve advertising uses a
              DoubleClick cookie to serve more relevant ads on across the web
              and limit the number of times a particular ad is displayed to you.
              <br /> For more information about Google AdSense, see the Official
              AdSense Privacy FAQs.
            </li>
            <li>
              We use advertisements to offset operating costs of this site and
              provide funding for future developments. The behavioral
              advertising cookies used by this {isNativeIOS ? "app" : "website"}
              are designed to ensure that you deliver the most relevant whenever
              possible, anonymously tracking their interests and featuring
              similar things that may be of interest to you interest.
            </li>
            <li>
              Various partners advertise on our behalf and cookies from
              Affiliate tracking simply allows us to see if our customers
              accessed the site through one of our websites partners, so that we
              can properly credit them, and when applicable, allow our affiliate
              partners to offer any promotion that may provide you to make a
              purchase.
            </li>
          </ul>
          <h3>User Commitment</h3>
          <p>
            The user undertakes to make proper use of the contents and
            information that Bible Journey offers on the{" "}
            {isNativeIOS ? "app" : "website"} and with enunciative, but not
            limiting:
          </p>
          <ul>
            <li>
              A) Not to engage in activities that are illegal or contrary to the
              good faith and public order;
            </li>
            <li>
              B) Not to disseminate propaganda or content of a racist nature,
              xenophobic, or online bookmakers (eg:
              <a href="https://ondeapostar.pt/review/betano/">Betano</a>), games
              of chance, any type of illegal pornography, apology for terrorism
              or against human rights;
            </li>
            <li>
              C) Do not cause damage to physical (hardware) and logical systems
              (software) from Bible Journey, its suppliers or third parties, to
              introduce or spread computer viruses or any other hardware or
              software systems that are capable of causing damage previously
              mentioned.
            </li>
          </ul>
          <h3>Data Usage</h3>
          <p>
            When you create an account with your email address on Bible Journey,
            as part of of the process of creating your unique identification, we
            collect the personal information you give us such as: name, email
            and password. When you access our {isNativeIOS ? "app" : "website"}{" "}
            , we also receive automatically your computer's internet protocol,
            address IP address in order to obtain information that helps us
            learn about your browser and operating system. This information is
            saved safely in our database.
          </p>
          <p>
            When you create a Bible Journey account from the login solution of
            facebook, as part of the identification creation process unique to
            you and to personalize your experience, we collect the following
            information: name, e-mail, photo and the Id of your user in the
            Facebook.This information is safely saved in our database give data.
          </p>
          <p>
            When you create a Bible Journey account from the login solution from
            Google as part of the unique identification process yours and to
            customize your experience, we collect the following information:
            name, email, photo and your user's Google ID. This information is
            safely saved in our database.
          </p>
          <p>
            Email Marketing will only be carried out if you allow it. In these
            emails you will be able to receive news about Bible Journey, new
            products and other updates.
          </p>
          <h3>How to delete your data</h3>
          <p>
            To delete your data stored in the Bible Journey database, send an
            email to thebibleplan@gmail.com.
          </p>
          <h3>More information</h3>
          <p>
            Let's hope it's clarified and, as mentioned earlier, if there's
            something you're not sure you need or not, it is generally safer to
            leave cookies enabled in case you interact with one of the features
            you use on our {isNativeIOS ? "app" : "website"} .
          </p>
          <h1 className="bold">Terms of Use</h1>
          <p>
            When accessing the {isNativeIOS ? "app" : "website"}{" "}
            <a href="http://bibleplan.online">Bible Journey</a>, agree to abide
            by these terms of service, all laws and applicable regulations and
            agree that you are responsible for compliance with all applicable
            local laws. If you do not agree to any of these terms, you are
            prohibited from using or accessing this site. The materials
            contained on this site are protected by the applicable copyright and
            trademark laws.
          </p>
          <h2>1. License Usage</h2>
          <p>
            Permission is granted to temporarily download a copy of the
            materials (information or software) on the Bible Journey{" "}
            {isNativeIOS ? "app" : "website"} , for personal, non-commercial
            transitory viewing. This is the concession of a license, not a
            transfer of title, and under this license, you cannot:&nbsp;
          </p>
          <ol>
            <li>modify or copy the materials;&nbsp; </li>
            <li>
              use the materials for any commercial purpose or to public display
              (commercial or non-commercial);&nbsp;
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on
              the Bible Journey {isNativeIOS ? "app" : "website"} ;&nbsp;
            </li>
            <li>
              remove any copyright or other notations from property of
              materials; or&nbsp;
            </li>
            <li>
              transfer the materials to someone else or 'mirror' the materials
              on any other server.
            </li>
          </ol>
          <p>
            This license will automatically terminate if you violate any of
            these restrictions and may be terminated by Bible Journey at any
            time. When you finish viewing these materials or after expiration of
            this license, you must delete all downloaded materials in your
            possession, whether in electronic or printed format.
          </p>
          <h2>2. Disclaimer</h2>
          <ol>
            <li>
              The materials on the Bible Journey{" "}
              {isNativeIOS ? "app" : "website"} are provided 'as is'. Bible
              Journey makes no warranties, express or implied, and for this
              medium, disclaims and disclaims all other warranties, including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose or
              non-infringement of property intellectual property or other
              infringement of rights.
            </li>
            <li>
              Furthermore, Bible Journey does not guarantee or make any
              representations concerning accuracy, likely results, or
              reliability of use of the materials on your{" "}
              {isNativeIOS ? "app" : "website"} or otherwise related to these
              materials or on websites linked to this{" "}
              {isNativeIOS ? "app" : "website"} .
            </li>
          </ol>
          <h2>3. Limitations</h2>
          <p>
            In no event will Bible Journey or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit or due to business interruption) arising from the use or
            inability to use the materials in Bible Journey, even if Bible
            Journey or an authorized Bible Journey representative has been
            notified orally or in writing of the possibility of such damages. As
            some jurisdictions do not allow limitations on warranties implied,
            or limitations of liability for consequential damages or
            incidentally, these limitations may not apply to you.
          </p>
          <h2>4. Precision of materials</h2>
          <p>
            Materials displayed on the Bible Journey{" "}
            {isNativeIOS ? "app" : "website"} may include errors technical,
            typographic or photographic. Bible Journey does not guarantee that
            any material on its {isNativeIOS ? "app" : "website"} is accurate,
            complete or current. Bible Journey may make changes to the materials
            contained on its {isNativeIOS ? "app" : "website"} at any time
            without notice. However, Bible Journey is not undertakes to update
            the materials.
          </p>
          <h2>5. Links</h2>
          <p>
            Bible Journey has not reviewed all sites linked to its site and does
            not is responsible for the content of any linked site. The inclusion
            of any link does not imply endorsement by Bible Journey of the site.
            The use of any linked site is at your own risk and user risk.
          </p>
          <p /> <h3>Changes</h3>
          <p>
            Bible Journey may revise these {isNativeIOS ? "app" : "website"}{" "}
            terms of service at any time. time, without prior notice. By using
            this site, you agree to be linked to the current version of these
            terms of service.
          </p>
          <h3>Applicable law</h3>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of Bible Journey and you irrevocably submit
            to the jurisdiction exclusive jurisdiction of the courts in that
            state or locality.
          </p>
          <p>
            This privacy policy and terms of use are effective to from{" "}
            <strong>August</strong>/<strong>2021</strong>.
          </p>
        </article>
      </div>
    </section>
  );
};

export default memo(GeneralPrivacyPolicy);
