import React, { memo, useCallback, useEffect, useMemo } from "react";
import MenuCard from "molecules/MenuCard";
import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import {
  challengeInvite,
  challenges as challengesMsg,
  createAChallenge,
} from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useHistory } from "react-router-dom";
import { useChallengeInvites } from "contexts/Challenge/ChallengeInvites/useChallengeInvites";
import { useUser } from "contexts/User";
import { useModal } from "contexts/Modal/useModal";
import { subscribeOrBuy } from "pages/Challenges/messages";

const Challenges = () => {
  const { challenges, fetchChallenges } = useChallenge();
  const { challengeInvites, openInviteModal } = useChallengeInvites();
  const history = useHistory();
  const { premium, isLogged } = useUser();
  const { createModal, closeModal } = useModal();

  useEffect(() => {
    !Object.keys(challenges).length && isLogged && fetchChallenges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenges, isLogged]);

  const onCreateNewChallengeClick = useCallback(() => {
    const exceededLimitOfChallenges =
      challenges?.owner?.length >= premium.amountPurchases;

    if (
      (premium.type === "one_time" && exceededLimitOfChallenges) ||
      premium.notLoaded
    ) {
      createModal({
        subtitle: subscribeOrBuy,
        firstButtonText: "Ok",
        onFirstButtonClick: closeModal,
      });

      return history.push("/challenges/presentation#start-now");
    }

    history.push("/challenges/new/name");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenges, premium]);

  const challengesList = useMemo(() => {
    const list = [];
    challenges?.owner?.forEach(({ title, participants, _id }) =>
      list?.push({
        title,
        description: `${participants.length} participants`,
        onClick: () => history.push(`challenge/${_id}/ranking`),
        pointer: true,
        Component: <ArrowRight />,
      })
    );

    challenges?.participant?.forEach(({ title, participants, _id }) =>
      list?.push({
        title,
        description: `${participants?.length} participants`,
        onClick: () => history.push(`challenge/${_id}/ranking`),
        pointer: true,
        Component: <ArrowRight />,
      })
    );

    challengeInvites?.forEach((challenge) =>
      list?.push({
        title: challengeInvite,
        description: challenge.challenge.title,
        onClick: () => openInviteModal(challenge),
        variant: "info",
        pointer: true,
        Component: <ArrowRight />,
      })
    );

    return list;
  }, [
    challengeInvites,
    challenges?.owner,
    challenges?.participant,
    history,
    openInviteModal,
  ]);

  const menu = useMemo(
    () => ({
      header: {
        title: challengesMsg,
        Icon: <FlagIcon className="path-stroke-white" width="12" />,
      },
      items: [
        ...challengesList,
        {
          title: createAChallenge,
          variant: "success",
          className: "uppercase cursor-pointer",
          onClick: onCreateNewChallengeClick,
          Component: <ArrowRight />,
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [challengesList, history]
  );

  return <MenuCard header={menu.header} items={menu.items} />;
};

export default memo(Challenges);
