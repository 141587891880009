import { bibleBooks } from "./bibleBooks";
import { buttons } from "./buttons";
import { menu } from "./menu";
import { months } from "./months";
import { planCreation } from "./planCreation";
import { week } from "./week";

const messages = {
  pl: {
    translations: {
      titles: {
        askDonationsModal: "Pomóż nam robić więcej",
        donateOptionsModal: "Dziękuję!",
        appHeader: {
          title: "Twój biblijny plan",
          subtitle: "Od {{startReading}} do {{endReading}}",
        },
        delayedReadingModal: "Ups! Opóźniłeś swoje czytanie.",
        planCreation: {
          title: "Czytaj Biblię",
          subtitle:
            "Ustaw daty rozpoczęcia i zakończenia czytania i stwórz swój spersonalizowany plan!",
        },
        createdPlan: {
          title: "Plan utworzony!",
          subtitle:
            "Zacznij czytać i nie martw się, jeśli ominiesz dzień. Plan zostanie przestawiony dla Ciebie!",
        },
        editPlan: {
          title: "Edytuj swój plan",
          subtitle:
            "Ustaw interwał dni, w których zaczniesz i zakończysz czytanie oraz uaktualnisz swój plan :)",
        },
        askConfirmationModal: {
          title: "Jesteś pewien?",
          subtitle:
            "Twój obecny postęp zostanie utracony, a plan zostanie wykonany od początku!",
        },
        biblePicker: {
          book: "Księga",
          chapter: "Rozdział",
        },
      },
      messages: {
        bibleReading: {
          read: "Czytaj",
        },

        planChecklist: {
          day: "dzień",
        },
        editPlan: {
          formChecklistTitle: "Edytuj teraz:",
        },
        delayedReadingModal: {
          subtitle:
            "Ale nie martw się, możemy przestawić Twój plan od miejsca, w którym przerwałeś :)",
        },
        askDonationsModal: {
          paragraph1:
            "Bible Journey to narzędzie przeznaczone do błogosławieństwa.",
          paragraph2:
            "Rejestrując się jako comiesięczny darczyńca, pomagasz nam rozwijać się i docierać do większej liczby osób.",
        },
        donateOptionsModal: {
          subtitle: "Wybierz typ wsparcia, aby kontynuować.",
        },

        shareBibleJourney: {
          mobileText: "Cześć! To jest link do pobrania BibleJourneyu :)",
          nonMobileText: "Cześć! To jest link prowadzący do BibleJourneyu :)",
        },
        footer: { madeBy: "Stworzone przez" },
        ...planCreation,
        ...week,
        ...months,
        ...bibleBooks,
        ...menu,
      },
      ...buttons,
    },
  },
};

export { messages };
