import React from "react";
import { Link } from "react-router-dom";

import backArrow from "assets/images/left-arrow--white-2.svg";

const BackArrowNavbar = ({ backTo }) => (
  <nav className="navbar">
    <div className="navbar__inside only-arrow">
      <Link className="navbar__back" to={backTo}>
        <img
          className="arrow"
          src={backArrow}
          alt="Back to the previous page"
        />
      </Link>

      <span></span>
    </div>
  </nav>
);

export default BackArrowNavbar;
