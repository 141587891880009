/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Input } from "components/Form";
import Button from "components/Button";
import { useAuth } from "contexts/AuthContext";
import { EMAIL_SCHEMA, NAME_SCHEMA, PASSWORD_SCHEMA } from "./validationSchema";

import {
  onRegistering,
  policyTerms,
  registering,
  registerMsgCaptalized,
  email,
  password,
  name,
} from "./messages";

const FormRegister = () => {
  const { formRegister, isLoading, handleSubmit, register, errors } = useAuth();

  return (
    <>
      <form onSubmit={handleSubmit(formRegister)} className="form">
        <div className="form__row full-width">
          <Input
            label={name}
            name="name"
            type="text"
            placeholder="Billy Graham"
            {...register("name", NAME_SCHEMA)}
            error={errors?.name?.message}
          />
        </div>
        <div className="form__row full-width">
          <Input
            label={email}
            name="email"
            type="email"
            placeholder="billy@graham.com"
            {...register("email", EMAIL_SCHEMA)}
            error={errors?.email?.message}
          />
        </div>
        <div className="form__row full-width">
          <Input
            label={password}
            name="password"
            type="password"
            placeholder="*********"
            {...register("password", PASSWORD_SCHEMA)}
            error={errors?.password?.message}
            usePasswordTypeToggle={true}
            showForgotPasswordLink={false}
          />
        </div>
        <div className="form__row">
          <span className="font-size-0">
            {onRegistering}{" "}
            <Link to="/politica-de-privacidade">{policyTerms}</Link>
          </span>
        </div>
        <div className="form__row">
          <Button
            size="medium"
            className="button--success  mt8"
            variation="square-rounded"
            theme="primary"
            disabled={isLoading}
          >
            {isLoading ? registering : registerMsgCaptalized}
          </Button>
        </div>
      </form>
    </>
  );
};

export default memo(FormRegister);
