export const months = {
  months: {
    0: "January",
    january: "January",
    1: "February",
    february: "February",
    2: "March",
    march: "March",
    3: "April",
    april: "April",
    4: "May",
    may: "May",
    5: "June",
    june: "June",
    6: "July",
    july: "July",
    7: "August",
    august: "August",
    8: "September",
    september: "September",
    9: "October",
    october: "October",
    10: "November",
    november: "November",
    11: "December",
    december: "December",
  },
};
