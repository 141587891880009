import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";

const HeaderLoading = () => {
  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "0 auto",
        padding: "40px 16px 0 16px",
        textAlign: "center",
        height: "123px",
      }}
    >
      <Skeleton
        className="skeleton"
        width={200}
        height={30}
        style={{ margin: "0 auto" }}
      />
    </div>
  );
};

export default memo(HeaderLoading);
