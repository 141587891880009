import React, { useState } from "react";
import { ReactComponent as AppleLogo } from "assets/icons/apple.svg";
import { useAuth } from "contexts/AuthContext";
import Button from "components/Button";
import { loading } from "../messages";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { baseURL, sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";

const options = {
  clientId: baseURL,
  redirectURI: window.location.origin,
  scopes: "email name",
  state: "12345",
  nonce: "nonce",
};

const AppleAuthButton = ({ text }) => {
  const { handleAppleLogin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();

  const onButtonClick = async () => {
    setIsLoading(true);

    SignInWithApple.authorize(options)
      .then((result) => {
        const user = {
          email: result.response.email,
          appleId: result.response.user,
          name: `${result.response.givenName} ${result.response.familyName}`,
          identityToken: result.response.identityToken,
        };

        handleAppleLogin(user, (status) => setIsLoading(status));
      })
      .catch((error) => {
        setIsLoading(false);
        sendErrorEmail(error, "Apple Auth Error");
        openGeneralErrorSnackbar();
      });
  };

  return (
    <Button
      size="medium"
      className="social-button apple font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <AppleLogo />

      {isLoading ? loading : text}
    </Button>
  );
};

export default AppleAuthButton;
