import React, { useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import isMobile from "ismobilejs";
import { SplashScreen } from "@capacitor/splash-screen";
import IOSStatusBarHandler from "components/IOSStatusBarHandler";
import { isIOSdevice } from "utils/devices";

const AppWrapper = ({ children }) => {
  useEffect(() => {
    const isIOS = isMobile().apple.device;

    if (isIOS && Capacitor.isNativePlatform()) {
      SplashScreen.hide();
    }
  }, []);

  return (
    <>
      {isIOSdevice && Capacitor.isNativePlatform() && <IOSStatusBarHandler />}
      {children}
    </>
  );
};

export default AppWrapper;
