import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import MenuCard from "molecules/MenuCard";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import {
  cancelSubscription,
  areYouSure,
  willLostProgress,
  changedMyMind,
  yesCancel,
  unsubscribing,
  successfullyUnsubscribed,
  premiumAccount,
  toCancel,
  toCancelAndroid,
} from "../messages";
import api from "utils/api";
import { useHeaders } from "hooks";
import { useModal } from "contexts/Modal/useModal";
import { subscribeNow } from "pages/Challenges/Presentation/components/messages";
import { useHistory } from "react-router";
import { useUser } from "contexts/User";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { isNativeIOS } from "utils";

const Subscription = () => {
  const [subscriptionId, setSubscriptionId] = useState("");

  const headers = useHeaders();
  const { createModal, closeModal, updateActiveModalProps } = useModal();
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(false);
  const [shouldCancelSubscription, setShouldCancelSubscription] =
    useState(false);
  const [premiumTitle, setPremiumTitle] = useState("");
  const [premiumType, setPremiumType] = useState("");
  const history = useHistory();
  const isSubscription = useMemo(
    () => premiumType === "subscription",
    [premiumType]
  );
  const { deleteAllChallenges } = useChallenge();
  const { premium } = useUser();

  useEffect(() => {
    if (shouldCancelSubscription) {
      deleteAllChallenges();

      updateActiveModalProps({
        firstButtonText: null,
        secondButtonDisabled: true,
        secondButtonText: unsubscribing,
      });

      headers.headers.subscription_id = subscriptionId;

      api.delete(`/premium`, headers).then(() => {
        setSubscriptionCanceled(true);

        updateActiveModalProps({
          title: successfullyUnsubscribed,
          subtitle: null,
          secondButtonText: null,
          firstButtonText: null,
        });

        setTimeout(() => {
          closeModal();
        }, 2000);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCancelSubscription]);

  useEffect(() => {
    setSubscriptionId(premium?.subscriptionId);
    setPremiumTitle(premium?.title);
    setPremiumType(premium?.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premium]);

  const onCancelSubscription = useCallback(async () => {
    if (isNativeIOS) {
      return createModal({
        title: areYouSure,
        subtitle: toCancel,
        firstButtonText: "Ok",
        onFirstButtonClick: closeModal,
      });
    }

    if (premium.origin === "android") {
      return createModal({
        title: areYouSure,
        subtitle: toCancelAndroid,
        firstButtonText: "Ok",
        onFirstButtonClick: closeModal,
      });
    }
    return createModal({
      title: areYouSure,
      subtitle: willLostProgress,
      firstButtonText: changedMyMind,
      secondButtonText: yesCancel,
      onFirstButtonClick: closeModal,
      onSecondButtonClick: async () => {
        setShouldCancelSubscription(true);
      },
    });
  }, [premium?.origin, createModal, closeModal]);

  const onSubscribeNow = useCallback(() => {
    history.push("/challenges/presentation#start-now");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menu = useMemo(
    () => {
      return {
        header: {
          title: premiumAccount,
        },
        items: [
          {
            description: premiumTitle,
          },
          {
            description: isSubscription ? cancelSubscription : subscribeNow,
            variant: isSubscription ? "danger" : "success",
            onClick: isSubscription ? onCancelSubscription : onSubscribeNow,
            pointer: true,
            Component: <ArrowRight />,
          },
        ],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [premiumTitle]
  );

  if (subscriptionCanceled || !premiumType || premium?.notPremium) return null;

  return <MenuCard {...menu} />;
};

export default memo(Subscription);
