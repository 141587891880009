import React, { createContext, memo, useEffect, useRef, useState } from "react";

export const GlobalScrollContext = createContext();

export const GlobalScrollProvider = ({ children }) => {
  const [isOnTop, setIsOnTop] = useState(true);
  const [isOnBottom, setIsOnBottom] = useState(false);
  const [isOnIOSSafeTop, setIsOnIOSSafeTop] = useState(true);
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  let lastScrollTop = useRef(0);
  let isNowDown = useRef();
  let isOnTopRef = useRef();
  let isOnBottomRef = useRef();
  let isOnIOSSafeTopRef = useRef();

  const onScroll = () => {
    const safeAreaTopPx = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--safe-area-top");

    const safeAreaTop = parseInt(safeAreaTopPx.replace("px", "")) / 4;

    const spaceTop = window.pageYOffset;

    const isDown = spaceTop > lastScrollTop.current;

    if (isDown !== isNowDown.current) {
      setIsScrollingDown(isDown);
    }

    isNowDown.current = isDown;
    lastScrollTop.current = spaceTop <= 0 ? 0 : spaceTop;

    const isOnBottom =
      window.scrollY + window.innerHeight >= document.body.offsetHeight;
    const isOnTop = window.scrollY <= safeAreaTop;
    const isOnIOSSafeTop = window.scrollY <= safeAreaTop;

    if (isOnBottom !== isOnBottomRef.current) setIsOnBottom(isOnBottom);

    if (isOnTop !== isOnTopRef.current) setIsOnTop(isOnTop);

    if (isOnIOSSafeTop !== isOnIOSSafeTopRef.current)
      setIsOnIOSSafeTop(isOnIOSSafeTop);

    isOnBottomRef.current = isOnBottom;
    isOnTopRef.current = isOnTop;
    isOnIOSSafeTopRef.current = isOnIOSSafeTop;
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    return () => document.removeEventListener("scroll", onScroll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalScrollContext.Provider
      value={{
        isScrollingDown,
        isOnBottom,
        isOnTop,
        isOnIOSSafeTop,
        setIsOnTop,
      }}
    >
      {children}
    </GlobalScrollContext.Provider>
  );
};

export default memo(GlobalScrollProvider);
