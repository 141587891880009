import React, { Fragment, memo, useEffect, useMemo } from "react";
import userIcon from "assets/icons/user.svg";
import classNames from "classnames";
import { padLeadingZeros } from "utils";
import { useChallenge } from "contexts/Challenge/useChallenge";
import useChallengeRanking from "contexts/Challenge/Ranking/useChallengeRanking";
import { isNativeIOS } from "utils";

const Podium = () => {
  const { podium, podiumIsLoaded, setPodiumIsLoaded } = useChallengeRanking();

  const { challengeIsLoaded } = useChallenge();

  const firstPosition = useMemo(() => podium?.[0], [podium]);
  const secondPosition = useMemo(() => podium?.[1], [podium]);
  const thirdPosition = useMemo(() => podium?.[2], [podium]);

  const noThirdPosition = useMemo(() => !thirdPosition, [thirdPosition]);
  const noSecondPosition = useMemo(() => !secondPosition, [secondPosition]);
  const noFirstPosition = useMemo(() => !firstPosition, [firstPosition]);

  useEffect(() => {
    let timeoutId = null;
    if (challengeIsLoaded) {
      timeoutId = setTimeout(() => {
        setPodiumIsLoaded(true);
      }, 4000);
    }

    return () => {
      clearTimeout(timeoutId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podiumIsLoaded]);

  return (
    <div
      className={classNames("podium", {
        podiumIsLoaded,
        isNativeIOS,
      })}
    >
      <div className="podium__inside">
        <PodiumColumn
          position={secondPosition}
          empty={noSecondPosition}
          medal="silver"
          positionNumber={2}
        />

        <PodiumColumn
          position={firstPosition}
          empty={noFirstPosition}
          medal="gold"
          positionNumber={1}
        />

        <PodiumColumn
          position={thirdPosition}
          empty={noThirdPosition}
          medal="bronze"
          positionNumber={3}
        />
      </div>
    </div>
  );
};

const PodiumColumn = memo(({ position, empty, medal, positionNumber }) => {
  const { viewReadingRanking, viewQuestionsRanking } = useChallengeRanking();

  const points = useMemo(() => {
    if (empty) return null;

    const pointsQuestion = padLeadingZeros(position.questionPoints, 3);
    const pointsTotal = padLeadingZeros(position.totalPoints, 3);
    const progressReading = position.conclusionPercentage + "%";

    if (viewQuestionsRanking) return pointsQuestion;

    if (viewReadingRanking) return progressReading;

    return pointsTotal;
  }, [
    empty,
    position?.conclusionPercentage,
    position?.questionPoints,
    position?.totalPoints,
    viewQuestionsRanking,
    viewReadingRanking,
  ]);

  return (
    <div className={`podium-column ${medal}`}>
      <div className="podium-column__header">
        {!empty && (
          <Fragment>
            <div
              className={classNames("podium__pic", {
                "no-pic": !position.user.picture,
              })}
            >
              <span
                style={{
                  backgroundImage: `url(${position.user.picture || userIcon})`,
                }}
              />
            </div>
            <span className="podium-column__name">
              {position.user.name.split(" ")[0]}
            </span>
          </Fragment>
        )}
      </div>
      <div className="podium-column__metal ">
        <div className="podium-column__metal-content">
          <div className="podium-column__points">{points}</div>
          <div className="podium-column__position">{positionNumber}</div>
        </div>
      </div>
    </div>
  );
});

export default memo(Podium);
