export const months = {
  months: {
    0: "Januari",
    january: "Januari",
    1: "Februari",
    february: "Februari",
    2: "Maret",
    march: "Maret",
    3: "April",
    april: "April",
    4: "Mei",
    may: "Mei",
    5: "Juni",
    june: "Juni",
    6: "Juli",
    july: "Juli",
    7: "Agustus",
    august: "Agustus",
    8: "September",
    september: "September",
    9: "Oktober",
    october: "Oktober",
    10: "November",
    november: "November",
    11: "Desember",
    december: "Desember",
  },
};
