import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import MenuCard from "molecules/MenuCard";
import { languageMsg } from "../messages";
import {
  IS_PT_LANGUAGE,
  IS_EN_LANGUAGE,
  IS_ID_LANGUAGE,
  IS_PL_LANGUAGE,
} from "utils";

import { i18n } from "translate/i18n";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { Select } from "molecules";

const Selected = ({ onClick, selected }) => {
  return (
    <span className="language-selector__selected" onClick={onClick}>
      {selected}
    </span>
  );
};

const languagesOptions = [
  { label: "Português", value: "pt", id: "pt" },
  { label: "English", value: "en", id: "en" },
  { label: "bahasa Indonesia", value: "id", id: "id" },
  { label: "Polski", value: "pl", id: "pl" },
];

const LanguageSelector = () => {
  const [language, setLanguage] = useState();
  const [showSelect, setShowSelect] = useState(false);

  const selectedLanguage = useMemo(() => {
    if (IS_PT_LANGUAGE) return { label: "Português", value: "pt", id: "pt" };
    if (IS_EN_LANGUAGE) return { label: "English", value: "en", id: "en" };
    if (IS_ID_LANGUAGE)
      return { label: "bahasa Indonesia", value: "id", id: "id" };
    if (IS_PL_LANGUAGE) return { label: "Polski", value: "pl", id: "pl" };
  }, []);

  useEffect(() => {
    languagesOptions.forEach((item) => {
      if (item.value === selectedLanguage.value) {
        setLanguage(item.label);
      }
    });
  }, [selectedLanguage]);

  const onSelectOption = (selected) => {
    i18n.changeLanguage(selected.value);

    window.location.reload();
  };

  const toggleSelect = useCallback(() => {
    setShowSelect(!showSelect);
  }, [showSelect]);

  const menu = useMemo(
    () => ({
      header: {
        title: languageMsg,
      },
      items: [
        {
          description: (
            <div className="language-selector">
              <Select
                onSelectOption={onSelectOption}
                items={languagesOptions}
                selected={selectedLanguage}
                toLeft={true}
                fullWidth={true}
                RenderSelected={
                  <Selected onClick={toggleSelect} selected={language} />
                }
              />
            </div>
          ),
          Component: <ArrowRight />,
        },
      ],
    }),
    [language, selectedLanguage, toggleSelect]
  );

  return <MenuCard header={menu.header} items={menu.items} />;
};

export default memo(LanguageSelector);
