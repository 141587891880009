import React, { memo } from "react";
import Plan from "./Plan";
import Ranking from "./Ranking";
import Questions from "./Questions";
import Configs from "./Configs";
import { useChallenge } from "contexts/Challenge/useChallenge";
import classNames from "classnames";

const ChallengeButtons = () => {
  const { isOwner } = useChallenge();
  return (
    <div className={classNames("bottom-bar__challenge-buttons", { isOwner })}>
      <Ranking />
      <Plan />
      <Questions />
      {isOwner && <Configs />}
    </div>
  );
};

export default memo(ChallengeButtons);
