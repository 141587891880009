import React, { useState, Fragment } from "react";
import Picker from "./Picker.js";

const BiblePicker = ({
  bibleReference,
  bookIndex,
  chapterNumber,
  onChange,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleShowPicker = () => setShowPicker(true);

  const onChangePicker = (newBibleReference, bookIndex, chapterNumber) => {
    onChange({
      bibleReference: newBibleReference,
      bookIndex: Number(bookIndex),
      chapter: Number(chapterNumber),
    });
  };

  return (
    <Fragment>
      <input
        readOnly
        onClick={handleShowPicker}
        type="text"
        book-index={bookIndex}
        chapter-number={chapterNumber}
        className="input with-pointer jsBiblePickerInput"
        value={bibleReference}
        placeholder="selecione"
      />
      {showPicker && <Picker onChangePicker={onChangePicker} />}
    </Fragment>
  );
};

export default BiblePicker;
