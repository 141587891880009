import React, { memo } from "react";
import useChallengeRanking from "contexts/Challenge/Ranking/useChallengeRanking";
import { points } from "../messages";
import { readingProgress } from "../../Plan/messages";
import { participantsMsg } from "pages/Challenges/messages";

const RankingHeader = () => {
  const { outOfRanking, viewReadingRanking } = useChallengeRanking();

  if (outOfRanking.length === 0) return null;

  return (
    <div className="table__header">
      <p></p>
      <p>{participantsMsg}</p>
      <p className="table__header-points">
        {viewReadingRanking ? readingProgress : points}
      </p>
    </div>
  );
};

export default memo(RankingHeader);
