import React, { useCallback } from "react";
import Button from "components/Button";
import DatePicker from "components/DatePicker";
import { endDateLabel, startDateLabel } from "./components/FormPlan/messages";
import { next, setTheDates } from "./messages";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";
import { useHistory, Redirect } from "react-router-dom";

const NewChallengeDates = () => {
  const {
    challengeName,
    setTrackDelays,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    blockedWeekDays,
  } = useNewChallenge();
  const history = useHistory();
  const onBeforeTodayInitialDate = useCallback(() => {
    setTrackDelays(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAfterTodayInitialDate = useCallback(() => {
    setTrackDelays(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onButtonClick = useCallback(() => {
    history.push("/challenges/new/reading");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!challengeName) {
    return <Redirect to="/challenges/new/name" />;
  }

  return (
    <div className="new-challenge">
      <div className="new-challenge__form new-challenge__form--dates">
        <h1 className="new-challenge__title">{setTheDates}</h1>
        <div className="new-challenge__dates">
          <div className="input-wrap">
            <label htmlFor="" className="input-label">
              {startDateLabel}
            </label>

            <DatePicker
              blockedWeekDays={blockedWeekDays}
              onAfterTodayInitialDate={onAfterTodayInitialDate}
              onBeforeTodayInitialDate={onBeforeTodayInitialDate}
              date={startDate}
              onDateChange={setStartDate}
            />
          </div>
          <div className="input-wrap">
            <label htmlFor="" className="input-label">
              {endDateLabel}
            </label>

            <DatePicker
              blockedWeekDays={blockedWeekDays}
              date={endDate}
              onDateChange={setEndDate}
            />
          </div>
        </div>

        <div className="new-challenge__controls">
          <Button
            size="medium"
            className="button--success "
            variation="square-rounded"
            theme="primary"
            type="submit"
            onClick={onButtonClick}
          >
            {next}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewChallengeDates;
