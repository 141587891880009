import { i18n } from "translate/i18n";

export const loginTitle = i18n.t("messages.authentication.loginTitle");
export const loggingIn = i18n.t("messages.authentication.loggingIn");
export const loginMsg = i18n.t("messages.authentication.loginMsg");
export const registerMsg = i18n.t("messages.authentication.registerMsg");
export const loginWithFacebook = i18n.t(
  "messages.authentication.loginWithFacebook"
);
export const loginWithGoogle = i18n.t(
  "messages.authentication.loginWithGoogle"
);
export const loginWithApple = i18n.t("messages.authentication.loginWithApple");
