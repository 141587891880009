import { memo, useCallback, useEffect } from "react";

import { useModal } from "contexts/Modal/useModal";
import {
  allow,
  allowNotifications,
  allowNotificationsText,
} from "contexts/Challenge/Announcement/messages";
import { useNotifications } from "components/Notifications/Context";
import { notAllow } from "contexts/Challenge/ChallengeInvites/messages";
import { permissionDeniedSubtitle } from "components/Notifications/Modals/messages";
import { loading } from "components/SocialLoginButtons/messages";

const NotificationsHandler = () => {
  const {
    handleNotificationsPermissions,
    handleSaveSubscription,
    checkPermissions,
  } = useNotifications();

  const { createModal, closeModal, updateActiveModalProps } = useModal();

  const createAskPermissionsModal = useCallback(
    ({ onSecondButtonClick }) => {
      createModal({
        title: allowNotifications,
        subtitle: allowNotificationsText,
        firstButtonText: notAllow,
        secondButtonText: allow,
        onFirstButtonClick: closeModal,
        onSecondButtonClick,
      });
    },
    [createModal, closeModal]
  );

  const createPermissionsDeniedModal = useCallback(() => {
    createModal({
      title: ":(",
      subtitle: permissionDeniedSubtitle,
      onSecondButtonClick: closeModal,
      secondButtonText: "Ok",
    });
  }, [createModal, closeModal]);

  useEffect(() => {
    checkPermissions({
      onNotDefined: () => {
        createAskPermissionsModal({
          onSecondButtonClick: () => {
            updateActiveModalProps({
              secondButtonText: loading,
              secondButtonDisabled: true,
            });
            handleNotificationsPermissions({
              onGranted: () => handleSaveSubscription({ then: closeModal }),
              onDenied: createPermissionsDeniedModal,
            });
          },
        });
      },
      onGranted: () => handleSaveSubscription(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default memo(NotificationsHandler);
