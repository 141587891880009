import { i18n } from "translate/i18n";

export const questionsMsg = i18n.t("messages.challenge.questions");
export const question = i18n.t("messages.challenge.question");
export const back = i18n.t("messages.challenge.back");
export const cancel = i18n.t("messages.challenge.cancel");
export const launchQuestion = i18n.t("messages.challenge.launchQuestion");
export const submit = i18n.t("messages.challenge.submit");
export const save = i18n.t("messages.challenge.save");
export const alternativesMsg = i18n.t("messages.challenge.alternatives");
export const correctAnswer = i18n.t("messages.challenge.correctAnswer");
export const wrongAnswer = i18n.t("messages.challenge.wrongAnswer");
export const addQuestion = i18n.t("messages.challenge.addQuestion");
export const editQuestion = i18n.t("messages.challenge.editQuestion");
export const selectAnswer = i18n.t("messages.challenge.selectAnswer");
export const addOption = i18n.t("messages.challenge.addOption");
export const option = i18n.t("messages.challenge.option");
export const deleteMsg = i18n.t("messages.challenge.delete");
export const areYouSureMessage = i18n.t("messages.challenge.areYouSureMessage");
export const areYouSure = i18n.t("messages.challenge.areYouSure");
export const yesDeleteQuestion = i18n.t("messages.challenge.yesDeleteQuestion");
export const notPossibleToUndo = i18n.t("messages.challenge.notPossibleToUndo");
export const yesDeleteChallenge = i18n.t(
  "messages.challenge.yesDeleteChallenge"
);
export const done = i18n.t("messages.challenge.done");
export const questionLaunched = i18n.t("messages.challenge.questionLaunched");
export const questionLaunchedMessage = i18n.t(
  "messages.challenge.questionLaunchedMessage"
);
export const questionsPointsWorth = i18n.t(
  "messages.challenge.questionsPointsWorth"
);
export const writeYourQuestion = i18n.t("messages.challenge.writeYourQuestion");
export const provideAnStatement = i18n.t(
  "messages.challenge.provideAnStatement"
);
export const selectAnAnswer = i18n.t("messages.challenge.selectAnAnswer");
export const failedListingQuestions = i18n.t(
  "messages.challenge.failedListingQuestions"
);
export const noQuestionsYet = i18n.t("messages.challenge.noQuestionsYet");
export const questionDeleted = i18n.t("messages.challenge.questionDeleted");
export const questionUpdated = i18n.t("messages.challenge.questionUpdated");
export const questionAlreadyAnswered = i18n.t(
  "messages.challenge.questionAlreadyAnswered"
);
