import React, { memo, useMemo } from "react";
import { questions } from "../../messages";
import { ReactComponent as QuestionIcon } from "assets/icons/question.svg";
import { NavLink } from "react-router-dom";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

const Questions = () => {
  const { viewingChallenge } = useChallenge();

  const history = useHistory();

  const isOnQuestionsPage = useMemo(() => {
    const list = ["challenge", "question"];
    const isInSomeQuestionPage = !list.some(
      (name) => !history.location.pathname.includes(name)
    );

    return isInSomeQuestionPage;
  }, [history.location.pathname]);

  return (
    <NavLink
      to={`/challenge/${viewingChallenge}/questions`}
      className={classNames("bottom-bar__icon bottom-bar__icon", {
        active: isOnQuestionsPage,
      })}
    >
      <div className="bottom-bar__icon-icon">
        <QuestionIcon className="bottom-bar__icon-svg--fill" />
      </div>
      <div className="bottom-bar__icon-text">{questions}</div>
    </NavLink>
  );
};

export default memo(Questions);
