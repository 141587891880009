import { i18n } from "translate/i18n";

export const createAccount = i18n.t("messages.authentication.createAccount");
export const onRegistering = i18n.t("messages.authentication.onRegistering");
export const policyTerms = i18n.t("messages.authentication.policyTerms");
export const registering = i18n.t("messages.authentication.registering");
export const registerMsgCaptalized = i18n.t(
  "messages.authentication.registerMsgCaptalized"
);
export const logYourself = i18n.t("messages.authentication.logYourself");
export const registerWithFacebook = i18n.t(
  "messages.authentication.registerWithFacebook"
);
export const registerWithGoogle = i18n.t(
  "messages.authentication.registerWithGoogle"
);
export const registerWithApple = i18n.t(
  "messages.authentication.registerWithApple"
);
export const finishWithFacebook = i18n.t(
  "messages.authentication.finishWithFacebook"
);
export const finishWithGoogle = i18n.t(
  "messages.authentication.finishWithGoogle"
);
export const finishWithApple = i18n.t(
  "messages.authentication.finishWithApple"
);
export const finishRegistration = i18n.t(
  "messages.authentication.finishRegistration"
);
