import api from "./api";

export const sendErrorEmail = async (error, where) => {
  return api.post("/service/send-mail", {
    subject: "Error on Bible Journey",
    message: `Erro: 
      language: ${localStorage.i18nextLng}
      ${window.navigator.userAgent}
      ${error}

      Where: ${where}

      Host: ${window.location.href}

      localStorage: 

      ${localStorage["persist:root"]}
      `,
  });
};

export const sendErrorReportingEmail = async (message, contact) => {
  return api.post("/service/send-mail", {
    subject: "Bible Journey Error reporting",
    message: `
    message: ${message}
    contact: ${contact}
      language: ${localStorage.i18nextLng}
      ${window.navigator.userAgent}

      Host: ${window.location.href}

      localStorage: 

      ${localStorage["persist:root"]}
      `,
  });
};

export const sendFeedbackEmail = async (message, contact) => {
  return api.post("/service/send-mail", {
    subject: "Bible Journey Feedback",
    message: `
    message: ${message}
    contact: ${contact}
      language: ${localStorage.i18nextLng}
      ${window.navigator.userAgent}

      Host: ${window.location.href}

      localStorage: 

      ${localStorage["persist:root"]}
      `,
  });
};

export const sendEmail = async (subject, message) => {
  try {
    api.post("/service/send-mail", {
      subject,
      message,
    });
    return true;
  } catch (error) {
    return false;
  }
};
