import React, { memo } from "react";
import { plan } from "../../messages";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { NavLink } from "react-router-dom";
import { useChallenge } from "contexts/Challenge/useChallenge";

const Plan = () => {
  const { viewingChallenge } = useChallenge();

  return (
    <NavLink
      to={`/challenge/${viewingChallenge}/plan`}
      className="bottom-bar__icon bottom-bar__icon noClickHighlight"
    >
      <div className="bottom-bar__icon-icon">
        <BookIcon className="bottom-bar__icon-svg bottom-bar__icon-svg--stroke" />
      </div>
      <div className="bottom-bar__icon-text">{plan}</div>
    </NavLink>
  );
};

export default memo(Plan);
