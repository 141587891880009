export const week = {
  week: {
    everyWeekDay: "Every week day",
    weekDays: "Week days",
    sunday: { name: "Sunday", abbreviation: "Sun", letter: "S" },
    monday: { name: "Monday", abbreviation: "Mon", letter: "M" },
    tuesday: { name: "Tuesday", abbreviation: "Tue", letter: "T" },
    wednesday: { name: "Wednesday", abbreviation: "Wed", letter: "W" },
    thursday: { name: "Thursday", abbreviation: "Thu", letter: "T" },
    friday: { name: "Friday", abbreviation: "Fri", letter: "F" },
    saturday: { name: "Saturday", abbreviation: "Sat", letter: "S" },
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
};
