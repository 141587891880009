import React, { useEffect } from "react";
import App from "components/App";
import Header from "components/App/Header";
import Skeleton from "react-loading-skeleton";
import RegisterCard from "pages/Authentication/Register/RegisterCard";
import { createAccount, logYourself, or } from "components/AuthBox/messages";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { useJoinChallenge } from "hooks";
import { isNativeIOS, isInstalled } from "utils";
import withChallengeName from "HOCs/withChallengeName";
import MainCard from "components/MainCard";

const InviteRegister = ({ challengeName }) => {
  const { idChallenge } = useParams();
  const { setChallengeToJoin, isLogged, user } = useAuth();

  const joinChallenge = useJoinChallenge();

  useEffect(() => {
    isLogged
      ? joinChallenge({ challengeToJoin: idChallenge, userToken: user.token })
      : setChallengeToJoin(idChallenge);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idChallenge]);

  useEffect(() => {
    if (!isInstalled) {
      const url = isNativeIOS
        ? "capacitor://localhost"
        : "intent://bibleplan.online/#Intent;scheme=https;package=xyz.appmaker.ybhmxs;end";
      window.location.replace(url);
    }
  }, []);

  return (
    <App>
      <Header>
        <p>Você foi convidado para o desafio</p>
        <p className="bold fz7 mt8 mb8">
          {challengeName ? (
            challengeName
          ) : (
            <Skeleton className="skeleton" height={21} width="80%" />
          )}
        </p>
        <p>Faça login ou crie uma conta para aceitar o convite.</p>
      </Header>

      <MainCard title={createAccount} centerTitle showBrandFooter>
        <RegisterCard />
        <div className="text-centered mt24">
          <span>
            {or}{" "}
            <Link to={`/c/invite/${idChallenge}/login`}>{logYourself}</Link>
          </span>
        </div>
      </MainCard>
    </App>
  );
};

export default withChallengeName(InviteRegister);
