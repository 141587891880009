import isMobile from "ismobilejs";
import { Capacitor } from "@capacitor/core";

export const isIOSdevice = isMobile().apple.device;
export const isIphoneXFamily =
  isIOSdevice && window.screen.height >= 812 && window.screen.width <= 476;

export const isMobileDevice = isMobile().any;
export const isNativePlatform = Capacitor.isNativePlatform();
export const isMac = navigator.platform.indexOf("Mac") > -1;
export const isIOSNativePlatform = isIOSdevice && isNativePlatform;
export const isNativeIOS = isIOSdevice && isNativePlatform;
