import React from "react";
import { isIOSdevice, isNativePlatform } from "utils/devices";
import IOSGoogleButton from "./IOS";
import WebGoogleButton from "./Web";

const GoogleButton = (props) => {
  if (isIOSdevice && isNativePlatform) return <IOSGoogleButton {...props} />;

  return <WebGoogleButton {...props} />;
};

export default GoogleButton;
