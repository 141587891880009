import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import { useModal } from "contexts/Modal/useModal";
import { useAuth } from "contexts/AuthContext";
import {
  registerPlanUpdate,
  syncDayWithBD,
} from "components/PlanChecklistV2/ChecklistItem/utils";
import { getApiAbbreviation } from "./messages";
import ChaptersControls from "./ChaptersControls";
import FinishReadingButton from "./FinishReadingButton";
import Verses from "./Verses";
import BibleHeader from "./BibleHeader";
import Copyright from "./Copyright";
import { useLocalScroll } from "hooks/useScroll";
import { sendErrorEmail, firstLetterCapitalize } from "utils";
import axios from "axios";
import { urls } from "./biblesArray";
import { useBible } from "contexts/Bible";
import FailedToLoadChapter from "./FailedToLoadChapter";
import { get } from "idb-keyval";

const BibleReading = ({
  bibleReadingInterval,
  planId,
  toggleReadStatus,
  readingDetails,
  onFinishReading = () => {},
  chooseVersion,
}) => {
  const { setIsReading, currentVersion } = useBible();

  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const { isOnScrollTop, isOnScrollBottom, onScroll } = useLocalScroll();
  const [shouldFetchChapter, setShouldFetchChapter] = useState(true);
  const [readingIndex, setReadingIndex] = useState(0);
  const [chapterTitle, setChapterTitle] = useState("");
  const [verses, setVerses] = useState([]);
  const bibleBodyRef = useRef(null);
  const copyrightRef = useRef(null);
  const { isLogged, getHeaders } = useAuth();
  const headers = getHeaders();
  const [isFinishingReading, setIsFinishingReading] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [copyright, setCopyright] = useState(null);
  const [failedToFetchChapter, setFailedToFetchChapter] = useState(false);

  useEffect(() => {
    setIsReading(true);

    return () => {
      setIsReading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (currentVersion?.origin === "aBibliaDigital") {
        throw new Error("no Copyright");
      }

      axios
        .get(
          `${urls.bibleBrain.base}/api/bibles/${currentVersion.id}/copyright${urls.bibleBrain.append}`
        )
        .then((response) => {
          setCopyright(response.data[0].copyright.copyright);
        })
        .catch(() => {
          setCopyright(null);
        });
    } catch (error) {
      setCopyright(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion]);

  const scrollToTop = useCallback(() => {
    bibleBodyRef?.current?.scrollTo(0, 0);
  }, [bibleBodyRef]);

  useEffect(() => {
    if (shouldFetchChapter) {
      scrollToTop();
      fetchBible({ version: currentVersion });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchChapter]);

  useEffect(() => {
    const scrollElement = bibleBodyRef.current ? bibleBodyRef.current : null;
    if (scrollElement) {
      let lastScrollTop = 0;
      let isNowDown;

      // todo: refactor this, putting the function outside the useEffect and removing the listener

      scrollElement.addEventListener(
        "scroll",
        () => {
          const st = scrollElement.pageYOffset || scrollElement.scrollTop;
          const isDown = st > lastScrollTop;

          if (isDown !== isNowDown) {
            setIsScrollingDown(isDown);
          }
          isNowDown = isDown;
          lastScrollTop = st <= 0 ? 0 : st;
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bibleBodyRef]);

  const normalizeResponse = useCallback(({ data, origin }) => {
    if (origin === "bibleBrain") {
      return {
        book: data.data?.[0]?.book_name_alt,
        chapter: data.data?.[0].chapter,
        verses: data.data.map((verse) => ({
          text: verse.verse_text,
          number: verse.verse_start,
        })),
      };
    }

    if (origin === "aBibliaDigital") {
      return {
        book: data?.book?.name,
        chapter: data?.chapter?.number,
        verses: data.verses,
      };
    }
  }, []);

  const getOfflineBible = useCallback(
    async (version) => {
      setIsLoading(true);
      setFailedToFetchChapter(false);

      try {
        const chapter = readingDetails[readingIndex].chapter;

        const offlineBible = await get(`bible-${version.id}`);

        const offlineChapter = offlineBible.find(
          (offline) =>
            offline.id.toLowerCase() === readingDetails[readingIndex].book &&
            offline.chapter === chapter
        );

        const bookName = firstLetterCapitalize(offlineChapter.book);

        setChapterTitle(`${bookName} ${offlineChapter.chapter}`);
        setVerses(offlineChapter.verses);
      } catch (err) {
        sendErrorEmail(
          err,
          `on fetching offline bible chapter, version: ${JSON.stringify(
            version
          )} ${JSON.stringify(readingDetails)}`
        );
        setFailedToFetchChapter(true);
        setVerses([]);
        setChapterTitle(null);
      } finally {
        setIsLoading(false);
        setShouldFetchChapter(null);
      }
    },
    [readingDetails, readingIndex]
  );

  const fetchBible = useCallback(
    async ({ version } = {}) => {
      if (version.offline) {
        return await getOfflineBible(version);
      }

      setIsLoading(true);
      setFailedToFetchChapter(false);

      const api = version.origin;
      const endpoint = version.endpoint;
      const bibleId = version.id;

      const book = getApiAbbreviation(readingDetails, readingIndex, api);
      const chapter = readingDetails[readingIndex].chapter;

      try {
        const response = await endpoint({
          bibleId,
          book,
          chapter,
        });

        const normalizedChapter = normalizeResponse({
          data: response.data,
          origin: api,
        });
        const bookName = firstLetterCapitalize(normalizedChapter.book);
        setChapterTitle(`${bookName} ${normalizedChapter.chapter}`);
        setVerses(normalizedChapter.verses);
      } catch (err) {
        sendErrorEmail(
          err,
          `on fetching bible chapter ${book} ${chapter} ${JSON.stringify(
            version
          )}
          ${JSON.stringify(readingDetails)}
          ${JSON.stringify({ api, endpoint, bibleId })}
          `
        );
        setFailedToFetchChapter(true);
        setVerses([]);
        setChapterTitle(null);
      } finally {
        setIsLoading(false);
        setShouldFetchChapter(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [getOfflineBible, normalizeResponse, readingDetails, readingIndex]
  );

  const onChooseVersion = useCallback(
    (version) => {
      chooseVersion({ abbr: version.value, id: version.id });
      scrollToTop();

      if (version.offline) return getOfflineBible(version);

      fetchBible({ version });
    },
    [chooseVersion, fetchBible, getOfflineBible, scrollToTop]
  );

  const goToNextChapter = useCallback(() => {
    setReadingIndex(readingIndex + 1);
    setShouldFetchChapter(true);
  }, [readingIndex]);

  const goToPrevChapter = useCallback(() => {
    setReadingIndex(readingIndex - 1);
    setShouldFetchChapter(true);
  }, [readingIndex]);

  const handleFinishReading = useCallback(() => {
    toggleReadStatus(bibleReadingInterval._id, true);

    onFinishReading();

    isLogged &&
      syncDayWithBD(bibleReadingInterval._id, true, headers).then(() => {
        registerPlanUpdate(planId, headers);
        setIsFinishingReading(false);
      });

    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bibleReadingInterval._id, headers, isLogged, planId]);

  return (
    <div className="bible-reading">
      <div
        className={classNames("bible-reading__head", {
          focusMode: isScrollingDown && !failedToFetchChapter,
        })}
      >
        <BibleHeader
          isLoading={isLoading}
          focusMode={isScrollingDown && !failedToFetchChapter}
          chapterTitle={chapterTitle}
          currentVersion={currentVersion}
          onChooseVersion={onChooseVersion}
        />
      </div>
      <div
        className={classNames("bible-reading__body", {
          isOnScrollTop,
          isLoading,
          isScrollingUp: !isScrollingDown,
        })}
        onScroll={onScroll}
        ref={bibleBodyRef}
      >
        <FailedToLoadChapter active={failedToFetchChapter} />
        <Verses isLoading={isLoading} verses={verses} />

        <FinishReadingButton
          readingIndex={readingIndex}
          readingDetails={readingDetails}
          isFinishingReading={isFinishingReading}
          handleFinishReading={handleFinishReading}
          shouldFetchChapter={shouldFetchChapter}
        />

        <Copyright
          isLoading={isLoading}
          ref={copyrightRef}
          currentVersion={currentVersion}
          copyright={copyright}
        />
      </div>

      <ChaptersControls
        copyrightElement={copyrightRef.current}
        isOnLastPage={readingIndex === readingDetails.length - 1}
        readingIndex={readingIndex}
        isLoading={isLoading}
        isOnScrollBottom={isOnScrollBottom && !isLoading}
        goToNextChapter={goToNextChapter}
        goToPrevChapter={goToPrevChapter}
        readingDetails={readingDetails}
        isScrollingDown={isScrollingDown}
      />
    </div>
  );
};

export default memo(BibleReading);
