import { i18n } from "translate/i18n";

export const updatePictureMsg = i18n.t("messages.updatePicture");
export const mobileText = i18n.t("messages.shareBibleJourney.mobileText");
export const menu = i18n.t("messages.menu.menu");
export const challenges = i18n.t("messages.menu.challenges");
export const subscription = i18n.t("messages.menu.subscription");
export const cancelSubscription = i18n.t("messages.menu.cancelSubscription");
export const nonMobileText = i18n.t("messages.shareBibleJourney.nonMobileText");
export const editPlan = i18n.t("messages.menu.editPlan");
export const shareBibleJourney = i18n.t("messages.menu.shareBibleJourney");
export const giveFeedback = i18n.t("messages.menu.giveFeedback");
export const logout = i18n.t("messages.menu.logout");
export const donate = i18n.t("messages.menu.donate");
export const madeBy = i18n.t("messages.footer.madeBy");
export const configureReminder = i18n.t("messages.menu.configureReminder");
export const editReminder = i18n.t("messages.menu.editReminder");
export const yourPlan = i18n.t("messages.menu.yourPlan");
export const login = i18n.t("messages.authentication.login");
export const challengeInvite = i18n.t("messages.menu.challengeInvite");
export const createAChallenge = i18n.t("messages.menu.createAChallenge");

export const reportError = i18n.t("messages.reportError.reportError");
export const tellUs = i18n.t("messages.reportError.tellUs");

export const downloadPlanPdf = i18n.t("messages.menu.downloadPlanPdf");
export const downloadingPlanPdf = i18n.t("messages.menu.downloadingPlanPdf");

export const areYouSure = i18n.t("messages.menu.areYouSure");
export const willLostProgress = i18n.t("messages.menu.willLostProgress");
export const toCancel = i18n.t("messages.menu.toCancel");
export const toCancelAndroid = i18n.t("messages.menu.toCancelAndroid");
export const yesCancel = i18n.t("messages.menu.yesCancel");
export const changedMyMind = i18n.t("messages.menu.changedMyMind");

export const unsubscribing = i18n.t("messages.menu.unsubscribing");
export const successfullyUnsubscribed = i18n.t(
  "messages.menu.successfullyUnsubscribed"
);
export const premiumAccount = i18n.t("messages.menu.premiumAccount");
export const languageMsg = i18n.t("messages.menu.language");
export const reminderMsg = i18n.t("messages.menu.reminder");
export const theme = i18n.t("messages.menu.theme");
export const dark = i18n.t("messages.menu.dark");
export const light = i18n.t("messages.menu.light");

export const processStarted = i18n.t("messages.menu.processStarted");
export const dangerArea = i18n.t("messages.menu.dangerArea");
export const weWillGetInTouch = i18n.t("messages.menu.weWillGetInTouch");
export const payAttention = i18n.t("messages.menu.payAttention");
export const yesImSure = i18n.t("messages.menu.yesImSure");
export const askAccountRemoval = i18n.t("messages.menu.askAccountRemoval");
