import React, { useCallback } from "react";
import { Input } from "components/Form";
import { challengeNameLabel, challengeNamePlaceholder, next } from "./messages";
import { NAME_SCHEMA } from "./validationSchema";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const NewChallengeName = () => {
  const { setChallengeName, challengeName } = useNewChallenge();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const onSubmit = useCallback(({ name }) => {
    setChallengeName(name);

    history.push({
      pathname: `/challenges/new/dates`,
      search: `?name=${name}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="new-challenge">
      <form
        className="new-challenge__form form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="new-challenge__title">{challengeNameLabel}</h1>
        <Input
          type="text"
          name="name"
          placeholder={challengeNamePlaceholder}
          {...register("name", NAME_SCHEMA)}
          error={errors?.name?.message}
          autoComplete={false}
          defaultValue={challengeName}
          autoFocus={true}
        />

        <div className="new-challenge__controls">
          <Button
            size="medium"
            className="button--success "
            variation="square-rounded"
            theme="primary"
            type="submit"
          >
            {next}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewChallengeName;
