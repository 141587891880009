import React, { memo } from "react";
import { ReactComponent as Sun } from "assets/icons/sun.svg";
import { ReactComponent as Moon } from "assets/icons/moon.svg";
import { useTheme } from "contexts/Theme/useTheme";

const ThemeSwitcher = ({ size = 1 }) => {
  const [currentTheme, toggleTheme] = useTheme();

  return (
    <div
      className={`theme-switcher ${currentTheme} size-${size}`}
      onClick={toggleTheme}
    >
      <Moon className="theme-switcher__dark-icon" />
      <Sun className="theme-switcher__light-icon" />
    </div>
  );
};

export default memo(ThemeSwitcher);
