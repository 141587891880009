import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import {
  somethingWentWrongSubtitle,
  somethingWentWrongTitle,
} from "./messages";
import { useNotifications } from "../Context";

const SomethingWentWrong = () => {
  const [openModal, setOpenModal] = useState(false);
  const { somethingWentWrong, closeSomethingWentWrongModal } =
    useNotifications();

  useEffect(() => {
    if (somethingWentWrong) setOpenModal(somethingWentWrong);
    else setOpenModal(false);
  }, [somethingWentWrong]);

  return (
    <Modal
      title={somethingWentWrongTitle}
      subtitle={somethingWentWrongSubtitle}
      active={openModal}
      onSecondButtonClick={closeSomethingWentWrongModal}
      onOverlayClick={closeSomethingWentWrongModal}
      secondButtonText={"Ok"}
    />
  );
};

export default SomethingWentWrong;
