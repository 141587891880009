export const months = {
  months: {
    0: "Janeiro",
    january: "Janeiro",
    1: "Fevereiro",
    february: "Fevereiro",
    2: "Março",
    march: "Março",
    3: "Abril",
    april: "Abril",
    4: "Maio",
    may: "Maio",
    5: "Junho",
    june: "Junho",
    6: "Julho",
    july: "Julho",
    7: "Agosto",
    august: "Agosto",
    8: "Setembro",
    september: "Setembro",
    9: "Outubro",
    october: "Outubro",
    10: "Novembro",
    november: "Novembro",
    11: "Dezembro",
    december: "Dezembro",
  },
};
