import React, { memo } from "react";
import { ReactComponent as HomeIcon } from "assets/icons/icon-home.svg";
import { home } from "../messages";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <NavLink to="/plan" exact={true} className={"bottom-bar__icon"}>
      <div className="bottom-bar__icon-icon">
        <HomeIcon className="bottom-bar__icon-svg--fill" />
      </div>
      <div className="bottom-bar__icon-text">{home}</div>
    </NavLink>
  );
};

export default memo(Home);
