import classNames from "classnames";
import React, { useMemo } from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";

const NewChallengeStepper = () => {
  const history = useHistory();

  const isOnNamePage = useMemo(
    () => history.location.pathname === "/challenges/new/name",
    [history.location.pathname]
  );
  const isOnDatesPage = useMemo(
    () => history.location.pathname === "/challenges/new/dates",
    [history.location.pathname]
  );
  const isOnReadingPage = useMemo(
    () => history.location.pathname === "/challenges/new/reading",
    [history.location.pathname]
  );
  const isOnParticipantsPage = useMemo(
    () => history.location.pathname === "/challenges/new/participants",
    [history.location.pathname]
  );

  return (
    <div className="new-challenge-stepper">
      <NavLink
        to="/challenges/new/name"
        className={classNames("new-challenge-stepper__step", {
          active:
            isOnNamePage ||
            isOnDatesPage ||
            isOnReadingPage ||
            isOnParticipantsPage,
        })}
      />
      <NavLink
        to="/challenges/new/dates"
        className={classNames("new-challenge-stepper__step", {
          active: isOnDatesPage || isOnReadingPage || isOnParticipantsPage,
        })}
      />
      <NavLink
        to="/challenges/new/reading"
        className={classNames("new-challenge-stepper__step", {
          active: isOnReadingPage || isOnParticipantsPage,
        })}
      />
    </div>
  );
};

export default withRouter(NewChallengeStepper);
