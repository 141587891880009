export const challenge = {
  challenge: {
    general: "Geral",
    reading: "Leitura",
    title: "Desafios de leitura chegaram! 🎉",
    subtitle:
      "Agora você pode criar um desafio para o seu grupo pra eles ficaram motivados em ler a Bíblia! 😀",
    firstButton: "Fechar",
    secondButton: "Show! Começar agora!",
    inviteReceived: "Você recebeu um convite 🎉",
    inviteReceivedDescription:
      "{{name}} te convidou para o desafio de leitura <b>{{challengeName}}</b>",
    seeLater: "Ver depois",
    accept: "Aceitar",
    notPossibleToUndo: "Esta ação não pode ser desfeita!",
    yesDeleteChallenge: "Sim, excluir este desafio",
    challengeCreated: "Desafio criado!",
    nowLastStep: "Agora vamos para o último passo",
    challengeNameLabel: "Qual será o nome do seu desafio?",
    readingChallenge: "Qual será o desafio de leitura?",
    challengeNamePlaceholder: "Desafio show",
    pleaseInformName: "Por favor, informe um nome",
    jump: "Pular",
    next: "Avançar",
    setTheDates: "Defina as datas 📆",
    addTheParticipants: "Adicione os integrantes",
    addTheParticipantsPlaceholder: "Escreva o e-mail dos integrantes",
    creatingChallenge: "Criando o desafio...",
    atLeastTwoEmails: "Por favor, adicione pelo menos dois e-mails",

    challenges: "Desafios",
    youAreLeader: "Você é líder:",
    youAreParticipant: "Você é participante:",
    createNewChallenge: "Crie um novo desafio",
    sendInvite: "Enviar convite",
    challengeLaunched: "Desafio lançado!",
    emailSent:
      "Um e-mail foi enviado para cada um dos integrantes. Conforme eles entrarem e começarem a ler, o Ranking vai se mover!",

    ranking: "Ranking",
    errorOnFetchingChallenges:
      "Não conseguimos carregar seus desafios agora. Por favor, tente novamente depois.",
    errorOnFetchingChallenge:
      "Não conseguimos carregar seu desafio agora. Por favor, tente novamente depois.",
    participants: "participantes",
    name: "Nome",
    points: "Pontos",
    point: "Ponto",
    notAcceptedYet: "Nenhum participante aceitou o convite ainda",
    checkEmail:
      "Peça para eles baixarem o app ou checarem a caixa de entrada de e-mails. Você também pode reenviar o convite na página de configurações.",
    decline: "Rejeitar",
    notAllow: "Não permitir",
    allowNotifications: "Permitir notificações?",
    allowNotificationsText:
      "Assim você receberá um alerta sempre que o líder do desafio postar uma pergunta surpresa 🎁",
    later: "Depois",
    allow: "Permitir notificações",
    biblicalPlan: "Plano bíblico",
    noPlanCreated: "Não foi criado um plano bíblico ainda",
    addPlan: "Adicionar plano",
    readingProgress: "Progresso da Leitura",
    createNow: "Criar agora",
    questions: "Perguntas",
    question: "Pergunta",
    back: "Voltar",
    submit: "Responder",
    save: "Salvar",
    alternatives: "Alternativas",
    correctAnswer: "Resposta correta",
    wrongAnswer: "Resposta errada :(",
    addQuestion: "Adicionar pergunta",
    editQuestion: "Editar pergunta",
    selectAnswer: "Selecionar resposta",
    addOption: "Adicionar opção",
    option: "Opção",
    delete: "Deletar",
    cancel: "Cancelar",
    areYouSureMessage:
      "As perguntas já respondidas pelos participantes não terão seus pontos removidos! 😬",
    areYouSure: "Tem certeza?",
    yesDeleteQuestion: "Sim, deletar",
    done: "Pronto",
    questionLaunched: "Pergunta lançada",
    launchQuestion: "Lançar pergunta",
    questionLaunchedMessage:
      "Pergunta lançada! Os participantes receberão uma notificação alertando sobre a pergunta 🏅😀",
    questionsPointsWorth: "Quantos pontos esta pergunta vale?",
    writeYourQuestion: "Escreva aqui sua pergunta",
    provideAnStatement: "Escreva uma pergunta",
    selectAnAnswer: "Selecione uma resposta",
    failedListingQuestions:
      "Não conseguimos listar as perguntas agora 😢. Tente novamente depois.",
    noQuestionsYet: "Nenhuma pergunta lançada ainda",
    questionDeleted: "Pergunta removida",
    questionUpdated: "Pergunta atualizada",
    questionAlreadyAnswered: "Questão já respondida",
    configurations: "Configurações",
    editChallengeName: "Editar nome do desafio",
    editParticipants: "Editar participantes",
    editPlan: "Editar plano",
    deleteChallenge: "Deletar desafio",
    dangerousArea: "Área de perigo",
    enterChallengeName: "Digite o nome do desafio",
    challengeDeleted: "Desafio deletado",
    enterLargerChallengeName: "Digite um nome maior",
    wantToRemove: "Quer mesmo remover esta pessoa do desafio?",
    yesRemoveThisPerson: "Sim, remover esta pessoa",
    inCaseYouChangeYourMind:
      "Caso mude de ideia, ela poderá ser adicionada novamente.",
    action: "Ação",
    remove: "Remover",
    notJoinedYet: "Ainda não ingressaram",
    addParticipants: "Adicionar participantes",
    add: "Adicionar",
    thePlanWillChange: "O plano vai mudar para todo mundo! *",
    yesUpdateIt: "Sim, quero mudar o plano",
    planUpdateDisclaimer:
      "os pontos ganhos pelos participantes com as leituras não serão removidos.",
    subscribeOrBuy: "Inscreva-se ou compre um novo desafio para poder cria-lo",
    resendInvitation: "Reenviar convite",
    invitationSent: "Convite enviado",
  },
};
