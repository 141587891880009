import React, { useCallback } from "react";
import { next, readingChallenge } from "./messages";
import { useHistory, Redirect } from "react-router-dom";
import Button from "components/Button";
import { FormPlan } from "./components/FormPlan";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";
import { loading } from "components/SocialLoginButtons/messages";

const NewChallengeReading = () => {
  const {
    challengeName,
    createChallenge,
    createPlan,
    setOpenMoreOptions,
    isLoading,
  } = useNewChallenge();

  const history = useHistory();

  const onButtonClick = useCallback(() => {
    setOpenMoreOptions(false);
    createPlan((plan) => {
      createChallenge(plan);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPlan, history]);

  if (!challengeName) {
    return <Redirect to="/challenges/new/name" />;
  }

  return (
    <div className="new-challenge">
      <div className="new-challenge__form form">
        <h1 className="new-challenge__title">{readingChallenge}</h1>

        <FormPlan />
        <div className="new-challenge__controls">
          {/* postponed: */}
          {/* <Link
            to="/challenges/new/participants"
            className="new-challenge__controls-jump"
          >
            {jump}
          </Link> */}
          <Button
            size="medium"
            className="button--success "
            variation="square-rounded"
            theme="primary"
            type="submit"
            disabled={isLoading}
            onClick={onButtonClick}
          >
            {isLoading ? loading : next}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewChallengeReading;
