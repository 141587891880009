import React, { Fragment, memo } from "react";
import Skeleton from "react-loading-skeleton";

const RankingLoading = () => {
  return (
    <Fragment>
      <div className="table__item" style={{ height: 72.5, width: "100%" }}>
        <div className="table__item-content">
          <Skeleton className="skeleton" width={20} height={20} />
          <Skeleton
            className="skeleton"
            width={40}
            height={40}
            style={{
              borderRadius: "50%",
            }}
          />
          <Skeleton className="skeleton" width={100} height={18} />
        </div>
        <Skeleton className="skeleton" width={27} height={20} />
      </div>
      <div className="table__item" style={{ height: 72.5, width: "100%" }}>
        <div className="table__item-content">
          <Skeleton className="skeleton" width={20} height={20} />
          <Skeleton
            className="skeleton"
            width={40}
            height={40}
            style={{
              borderRadius: "50%",
            }}
          />
          <Skeleton className="skeleton" width={100} height={18} />
        </div>
        <Skeleton className="skeleton" width={27} height={20} />
      </div>
      <div className="table__item" style={{ height: 72.5, width: "100%" }}>
        <div className="table__item-content">
          <Skeleton className="skeleton" width={20} height={20} />
          <Skeleton
            className="skeleton"
            width={40}
            height={40}
            style={{
              borderRadius: "50%",
            }}
          />
          <Skeleton className="skeleton" width={100} height={18} />
        </div>
        <Skeleton className="skeleton" width={27} height={20} />
      </div>
      <div className="table__item" style={{ height: 72.5, width: "100%" }}>
        <div className="table__item-content">
          <Skeleton className="skeleton" width={20} height={20} />
          <Skeleton
            className="skeleton"
            width={40}
            height={40}
            style={{
              borderRadius: "50%",
            }}
          />
          <Skeleton className="skeleton" width={100} height={18} />
        </div>
        <Skeleton className="skeleton" width={27} height={20} />
      </div>
      <div className="table__item" style={{ height: 72.5, width: "100%" }}>
        <div className="table__item-content">
          <Skeleton className="skeleton" width={20} height={20} />
          <Skeleton
            className="skeleton"
            width={40}
            height={40}
            style={{
              borderRadius: "50%",
            }}
          />
          <Skeleton className="skeleton" width={100} height={18} />
        </div>
        <Skeleton className="skeleton" width={27} height={20} />
      </div>
      <div className="table__item" style={{ height: 72.5, width: "100%" }}>
        <div className="table__item-content">
          <Skeleton className="skeleton" width={20} height={20} />
          <Skeleton
            className="skeleton"
            width={40}
            height={40}
            style={{
              borderRadius: "50%",
            }}
          />
          <Skeleton className="skeleton" width={100} height={18} />
        </div>
        <Skeleton className="skeleton" width={27} height={20} />
      </div>
    </Fragment>
  );
};

export default memo(RankingLoading);
