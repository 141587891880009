export const bibleBooks = {
  bibleBooks: {
    0: {
      name: "Księga Rodzaju",
      abbreviation: "Rdz",
      testament: "Stary Testament",
    },
    genesis: {
      name: "Księga Rodzaju",
      abbreviation: "Rdz",
      testament: "Stary Testament",
    },
    1: {
      name: "Księga Wyjścia",
      abbreviation: "Wj",
      testament: "Stary Testament",
    },
    exodus: {
      name: "Księga Wyjścia",
      abbreviation: "Wj",
      testament: "Stary Testament",
    },
    2: {
      name: "Księga Kapłańska",
      abbreviation: "Kpł",
      testament: "Stary Testament",
    },
    leviticus: {
      name: "Księga Kapłańska",
      abbreviation: "Kpł",
      testament: "Stary Testament",
    },
    3: {
      name: "Księga Liczb",
      abbreviation: "Lb",
      testament: "Stary Testament",
    },
    numbers: {
      name: "Księga Liczb",
      abbreviation: "Lb",
      testament: "Stary Testament",
    },
    4: {
      name: "Księga Powtórzonego Prawa",
      abbreviation: "Pwt",
      testament: "Stary Testament",
    },
    deuteronomy: {
      name: "Księga Powtórzonego Prawa",
      abbreviation: "Pwt",
      testament: "Stary Testament",
    },
    5: {
      name: "Księga Jozuego",
      abbreviation: "Joz",
      testament: "Stary Testament",
    },
    joshua: {
      name: "Księga Jozuego",
      abbreviation: "Joz",
      testament: "Stary Testament",
    },
    6: {
      name: "Księga Sędziów",
      abbreviation: "Sdz",
      testament: "Stary Testament",
    },
    judges: {
      name: "Księga Sędziów",
      abbreviation: "Sdz",
      testament: "Stary Testament",
    },
    7: {
      name: "Księga Rut",
      abbreviation: "Rtth",
      testament: "Stary Testament",
    },
    ruth: {
      name: "Księga Rut",
      abbreviation: "Rtth",
      testament: "Stary Testament",
    },
    8: {
      name: "1 Księga Samuela",
      abbreviation: "1 Sm",
      testament: "Stary Testament",
    },
    samuel1: {
      name: "1 Księga Samuela",
      abbreviation: "1 Sm",
      testament: "Stary Testament",
    },
    9: {
      name: "2 Księga Samuela",
      abbreviation: "2 Sm",
      testament: "Stary Testament",
    },
    samuel2: {
      name: "2 Księga Samuela",
      abbreviation: "2 Sm",
      testament: "Stary Testament",
    },
    10: {
      name: "1 Księga Królewska",
      abbreviation: "1 Krl",
      testament: "Stary Testament",
    },
    kings1: {
      name: "1 Księga Królewska",
      abbreviation: "1 Krl",
      testament: "Stary Testament",
    },
    11: {
      name: "2 Księga Królewska",
      abbreviation: "2 Krl",
      testament: "Stary Testament",
    },
    kings2: {
      name: "2 Księga Królewska",
      abbreviation: "2 Krl",
      testament: "Stary Testament",
    },
    12: {
      name: "1 Księga Kronik",
      abbreviation: "1 Krn",
      testament: "Stary Testament",
    },
    chronicles1: {
      name: "1 Księga Kronik",
      abbreviation: "1 Krn",
      testament: "Stary Testament",
    },
    13: {
      name: "2 Księga Kronik",
      abbreviation: "2 Krn",
      testament: "Stary Testament",
    },
    chronicles2: {
      name: "2 Księga Kronik",
      abbreviation: "2 Krn",
      testament: "Stary Testament",
    },
    14: {
      name: "Księga Ezdrasza",
      abbreviation: "Ezd",
      testament: "Stary Testament",
    },
    ezra: {
      name: "Księga Ezdrasza",
      abbreviation: "Ezd",
      testament: "Stary Testament",
    },
    15: {
      name: "Księga Nehemiasza",
      abbreviation: "Ne",
      testament: "Stary Testament",
    },
    nehemiah: {
      name: "Księga Nehemiasza",
      abbreviation: "Ne",
      testament: "Stary Testament",
    },
    16: {
      name: "Księga Estery",
      abbreviation: "Est",
      testament: "Stary Testament",
    },
    esther: {
      name: "Księga Estery",
      abbreviation: "Est",
      testament: "Stary Testament",
    },
    17: {
      name: "Księga Hioba",
      abbreviation: "Hi",
      testament: "Stary Testament",
    },
    job: {
      name: "Księga Hioba",
      abbreviation: "Hi",
      testament: "Stary Testament",
    },
    18: {
      name: "Księga Psalmów",
      abbreviation: "Ps",
      testament: "Stary Testament",
    },
    psalms: {
      name: "Księga Psalmów",
      abbreviation: "Ps",
      testament: "Stary Testament",
    },
    19: {
      name: "Księga Przysłów",
      abbreviation: "Prz",
      testament: "Stary Testament",
    },
    proverbs: {
      name: "Księga Przysłów",
      abbreviation: "Prz",
      testament: "Stary Testament",
    },
    20: {
      name: "Księga Koheleta",
      abbreviation: "Koh",
      testament: "Stary Testament",
    },
    ecclesiastes: {
      name: "Księga Koheleta",
      abbreviation: "Koh",
      testament: "Stary Testament",
    },
    21: {
      name: "Pieśń nad pieśniami",
      abbreviation: "PnP",
      testament: "Stary Testament",
    },
    solomonsongof: {
      name: "Pieśń nad pieśniami",
      abbreviation: "PnP",
      testament: "Stary Testament",
    },
    22: {
      name: "Księga Izajasza",
      abbreviation: "Iz",
      testament: "Stary Testament",
    },
    isaiah: {
      name: "Księga Izajasza",
      abbreviation: "Iz",
      testament: "Stary Testament",
    },
    23: {
      name: "Księga Jeremiasza",
      abbreviation: "Jr",
      testament: "Stary Testament",
    },
    jeremiah: {
      name: "Księga Jeremiasza",
      abbreviation: "Jr",
      testament: "Stary Testament",
    },
    24: {
      name: "Lamentacje Jeremiasza",
      abbreviation: "Lm",
      testament: "Stary Testament",
    },
    lamentations: {
      name: "Lamentacje Jeremiasza",
      abbreviation: "Lm",
      testament: "Stary Testament",
    },
    25: {
      name: "Księga Ezechiela",
      abbreviation: "Ez",
      testament: "Stary Testament",
    },
    ezekiel: {
      name: "Księga Ezechiela",
      abbreviation: "Ez",
      testament: "Stary Testament",
    },
    26: {
      name: "Księga Daniela",
      abbreviation: "Dn",
      testament: "Stary Testament",
    },
    daniel: {
      name: "Księga Daniela",
      abbreviation: "Dn",
      testament: "Stary Testament",
    },
    27: {
      name: "Księga Ozeasza",
      abbreviation: "Oz",
      testament: "Stary Testament",
    },
    hosea: {
      name: "Księga Ozeasza",
      abbreviation: "Oz",
      testament: "Stary Testament",
    },
    28: {
      name: "Księga Joela",
      abbreviation: "Jl",
      testament: "Stary Testament",
    },
    joel: {
      name: "Księga Joela",
      abbreviation: "Jl",
      testament: "Stary Testament",
    },
    29: {
      name: "Księga Amosa",
      abbreviation: "Am",
      testament: "Stary Testament",
    },
    amos: {
      name: "Księga Amosa",
      abbreviation: "Am",
      testament: "Stary Testament",
    },
    30: {
      name: "Księga Abdiasza",
      abbreviation: "Ab",
      testament: "Stary Testament",
    },
    obadiah: {
      name: "Księga Abdiasza",
      abbreviation: "Ab",
      testament: "Stary Testament",
    },
    31: {
      name: "Księga Jonasza",
      abbreviation: "Jon",
      testament: "Stary Testament",
    },
    jonah: {
      name: "Księga Jonasza",
      abbreviation: "Jon",
      testament: "Stary Testament",
    },
    32: {
      name: "Księga Micheasza",
      abbreviation: "Mi",
      testament: "Stary Testament",
    },
    micah: {
      name: "Księga Micheasza",
      abbreviation: "Mi",
      testament: "Stary Testament",
    },
    33: {
      name: "Księga Nahuma",
      abbreviation: "Na",
      testament: "Stary Testament",
    },
    nahum: {
      name: "Księga Nahuma",
      abbreviation: "Na",
      testament: "Stary Testament",
    },
    34: {
      name: "Księga Habakuka",
      abbreviation: "Ha",
      testament: "Stary Testament",
    },
    habakkuk: {
      name: "Księga Habakuka",
      abbreviation: "Ha",
      testament: "Stary Testament",
    },
    35: {
      name: "Księga Sofoniasza",
      abbreviation: "So",
      testament: "Stary Testament",
    },
    zephaniah: {
      name: "Księga Sofoniasza",
      abbreviation: "So",
      testament: "Stary Testament",
    },
    36: {
      name: "Księga Aggeusza",
      abbreviation: "Ag",
      testament: "Stary Testament",
    },
    haggai: {
      name: "Księga Aggeusza",
      abbreviation: "Ag",
      testament: "Stary Testament",
    },
    37: {
      name: "Księga Zachariasza",
      abbreviation: "Za",
      testament: "Stary Testament",
    },
    zechariah: {
      name: "Księga Zachariasza",
      abbreviation: "Za",
      testament: "Stary Testament",
    },
    38: {
      name: "Księga Malachiasza",
      abbreviation: "Ml",
      testament: "Stary Testament",
    },
    malachi: {
      name: "Księga Malachiasza",
      abbreviation: "Ml",
      testament: "Stary Testament",
    },
    39: {
      name: "Ewangelia wg św. Mateusza",
      abbreviation: "Mt",
      testament: "Nowy Testament",
    },
    matthew: {
      name: "Ewangelia wg św. Mateusza",
      abbreviation: "Mt",
      testament: "Nowy Testament",
    },
    40: {
      name: "Ewangelia wg św. Marka",
      abbreviation: "Mk",
      testament: "Nowy Testament",
    },
    mark: {
      name: "Ewangelia wg św. Marka",
      abbreviation: "Mk",
      testament: "Nowy Testament",
    },
    41: {
      name: "Ewangelia wg św. Łukasza",
      abbreviation: "Łk",
      testament: "Nowy Testament",
    },
    luke: {
      name: "Ewangelia wg św. Łukasza",
      abbreviation: "Łk",
      testament: "Nowy Testament",
    },
    42: {
      name: "Ewangelia wg św. Jana",
      abbreviation: "J",
      testament: "Nowy Testament",
    },
    john: {
      name: "Ewangelia wg św. Jana",
      abbreviation: "J",
      testament: "Nowy Testament",
    },
    43: {
      name: "Dzieje Apostolskie",
      abbreviation: "Dz",
      testament: "Nowy Testament",
    },
    acts: {
      name: "Dzieje Apostolskie",
      abbreviation: "Dz",
      testament: "Nowy Testament",
    },
    44: {
      name: "List do Rzymian",
      abbreviation: "Rz",
      testament: "Nowy Testament",
    },
    romans: {
      name: "List do Rzymian",
      abbreviation: "Rz",
      testament: "Nowy Testament",
    },
    45: {
      name: "1 List do Koryntian",
      abbreviation: "1 Kor",
      testament: "Nowy Testament",
    },
    corinthians1: {
      name: "1 List do Koryntian",
      abbreviation: "1 Kor",
      testament: "Nowy Testament",
    },
    46: {
      name: "2 List do Koryntian",
      abbreviation: "2 Kor",
      testament: "Nowy Testament",
    },
    corinthians2: {
      name: "2 List do Koryntian",
      abbreviation: "2 Kor",
      testament: "Nowy Testament",
    },
    47: {
      name: "List do Galatów",
      abbreviation: "Ga",
      testament: "Nowy Testament",
    },
    galatians: {
      name: "List do Galatów",
      abbreviation: "Ga",
      testament: "Nowy Testament",
    },
    48: {
      name: "List do Efezjan",
      abbreviation: "Ef",
      testament: "Nowy Testament",
    },
    ephesians: {
      name: "List do Efezjan",
      abbreviation: "Ef",
      testament: "Nowy Testament",
    },
    49: {
      name: "List do Filipian",
      abbreviation: "Flp",
      testament: "Nowy Testament",
    },
    philippians: {
      name: "List do Filipian",
      abbreviation: "Flp",
      testament: "Nowy Testament",
    },
    50: {
      name: "List do Kolosan",
      abbreviation: "Kol",
      testament: "Nowy Testament",
    },
    colossians: {
      name: "List do Kolosan",
      abbreviation: "Kol",
      testament: "Nowy Testament",
    },
    51: {
      name: "1 List do Tesaloniczan",
      abbreviation: "1 Tes",
      testament: "Nowy Testament",
    },
    thessalonians1: {
      name: "1 List do Tesaloniczan",
      abbreviation: "1 Tes",
      testament: "Nowy Testament",
    },
    52: {
      name: "2 List do Tesaloniczan",
      abbreviation: "2 Tes",
      testament: "Nowy Testament",
    },
    thessalonians2: {
      name: "2 List do Tesaloniczan",
      abbreviation: "2 Tes",
      testament: "Nowy Testament",
    },
    53: {
      name: "1 List do Tymoteusza",
      abbreviation: "1 Tm",
      testament: "Nowy Testament",
    },
    timothy1: {
      name: "1 List do Tymoteusza",
      abbreviation: "1 Tm",
      testament: "Nowy Testament",
    },
    54: {
      name: "2 List do Tymoteusza",
      abbreviation: "2 Tm",
      testament: "Nowy Testament",
    },
    timothy2: {
      name: "2 List do Tymoteusza",
      abbreviation: "2 Tm",
      testament: "Nowy Testament",
    },
    55: {
      name: "List do Tytusa",
      abbreviation: "Tt",
      testament: "Nowy Testament",
    },
    titus: {
      name: "List do Tytusa",
      abbreviation: "Tt",
      testament: "Nowy Testament",
    },
    56: {
      name: "List do Filemona",
      abbreviation: "Flm",
      testament: "Nowy Testament",
    },
    philemon: {
      name: "List do Filemona",
      abbreviation: "Flm",
      testament: "Nowy Testament",
    },
    57: {
      name: "List do Hebrajczyków",
      abbreviation: "Hbr",
      testament: "Nowy Testament",
    },
    hebrews: {
      name: "List do Hebrajczyków",
      abbreviation: "Hbr",
      testament: "Nowy Testament",
    },
    58: {
      name: "List św. Jakuba",
      abbreviation: "Jk",
      testament: "Nowy Testament",
    },
    james: {
      name: "List św. Jakuba",
      abbreviation: "Jk",
      testament: "Nowy Testament",
    },
    59: {
      name: "1 List św. Piotra",
      abbreviation: "1 P",
      testament: "Nowy Testament",
    },
    peter1: {
      name: "1 List św. Piotra",
      abbreviation: "1 P",
      testament: "Nowy Testament",
    },
    60: {
      name: "2 List św. Piotra",
      abbreviation: "2 P",
      testament: "Nowy Testament",
    },
    peter2: {
      name: "2 List św. Piotra",
      abbreviation: "2 P",
      testament: "Nowy Testament",
    },
    61: {
      name: "1 List św. Jana",
      abbreviation: "1 J",
      testament: "Nowy Testament",
    },
    john1: {
      name: "1 List św. Jana",
      abbreviation: "1 J",
      testament: "Nowy Testament",
    },
    62: {
      name: "2 List św. Jana",
      abbreviation: "2 J",
      testament: "Nowy Testament",
    },
    john2: {
      name: "2 List św. Jana",
      abbreviation: "2 J",
      testament: "Nowy Testament",
    },
    63: {
      name: "3 List św. Jana",
      abbreviation: "3 J",
      testament: "Nowy Testament",
    },
    john3: {
      name: "3 List św. Jana",
      abbreviation: "3 J",
      testament: "Nowy Testament",
    },
    64: {
      name: "List św. Judy",
      abbreviation: "Jud",
      testament: "Nowy Testament",
    },
    jude: {
      name: "List św. Judy",
      abbreviation: "Jud",
      testament: "Nowy Testament",
    },
    65: {
      name: "Apokalipsa św. Jana",
      abbreviation: "Ap",
      testament: "Nowy Testament",
    },
    revelation: {
      name: "Apokalipsa św. Jana",
      abbreviation: "Ap",
      testament: "Nowy Testament",
    },
  },
};
