import { Container } from "atoms";
import React, { Fragment, memo, useEffect, useState } from "react";
import { sendErrorReportingEmail } from "utils/sendEmail";

const ErrorReporting = () => {
  const [reported, setReported] = useState(false);

  useEffect(() => {
    sendErrorReportingEmail(
      "via error reporting page",
      "via error reporting page"
    ).then(() => setReported(true));
  }, []);

  return (
    <div
      style={{
        color: "var(--white)",
        textAlign: "center",
        padding: "1rem",
        paddingTop: "3rem",
        fontSize: "2rem",
      }}
    >
      <Container>
        {reported ? (
          <Fragment>
            <svg
              width="90"
              height="71"
              viewBox="0 0 90 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M75.6656 2.26362C77.2036 0.795671 79.2502 -0.0158728 81.3736 0.000235284C83.4971 0.0163433 85.5312 0.858845 87.0468 2.34996C88.5624 3.84108 89.441 5.86418 89.4971 7.99237C89.5533 10.1206 88.7826 12.1874 87.3476 13.7567L43.7858 68.3762C43.0367 69.1851 42.1326 69.8342 41.1276 70.2848C40.1226 70.7354 39.0373 70.9782 37.9366 70.9986C36.8358 71.019 35.7423 70.8167 34.7214 70.4037C33.7004 69.9908 32.773 69.3757 31.9946 68.5951L3.10616 39.6326C2.30167 38.881 1.6564 37.9747 1.20886 36.9677C0.761322 35.9607 0.520673 34.8736 0.501274 33.7713C0.481876 32.6691 0.684126 31.5742 1.09596 30.552C1.50778 29.5298 2.12076 28.6012 2.89831 27.8216C3.67586 27.0421 4.60205 26.4275 5.62164 26.0147C6.64123 25.6018 7.73333 25.399 8.83278 25.4185C9.93223 25.4379 11.0165 25.6792 12.0209 26.1279C13.0254 26.5765 13.9294 27.2235 14.679 28.03L37.5408 50.9396L75.4582 2.50443C75.5265 2.42016 75.5994 2.33976 75.6765 2.26362H75.6656Z"
                fill="#009B72"
              />
            </svg>

            <p className="mt16">Error reported</p>
          </Fragment>
        ) : (
          "Reporting..."
        )}
      </Container>
    </div>
  );
};

export default memo(ErrorReporting);
