export const buttons = {
  buttons: {
    editPlan: {
      updatePlan: "Memperbaharui jadwal rencana bacaan Alkitab",
    },
    askConfirmationModal: {
      firstButtonText: "Tetap menggunakan jadwal yang sama",
      secondButtonText: "Mengatur kembali seluruh jadwal bacaan",
    },
    planCreation: {
      createPlan: "Membuat jadwal bacaan",
      createPlanLoading: "Sedang membuat jadwal bacaan...",
    },
    delayedReadingModal: {
      firstButton: "Tidak perlu",
      secondButton: "Mengatur kembali jadwal bacaan Alkitab Anda",
    },
    donateOptionsModal: {
      firstButton: " Satu kali saja donasikan",
      secondButton: "Donasikan secara rutin bulanan",
    },
    askDonationsModal: {
      firstButton: "Tolong Ingatkan saya nanti",
      secondButton: "Donasikan melalui Gmroad",
    },
    bibleReading: {
      finishReading: "Akhiri membaca",
      close: "Menutup",
    },
  },
};
