import React, { memo } from "react";
import App from "components/App";
import Header from "components/App/Header";

import { or, subtitle, title } from "../messages";
import { Navbar } from "components/Navbar";
import RegisterCard from "./RegisterCard";

import { createAccount, finishRegistration, logYourself } from "./messages";
import { useQuery } from "hooks";
import { Link } from "react-router-dom";
import MainCard from "components/MainCard";

const Register = () => {
  const finishRegister = useQuery("finishRegister");

  return (
    <App>
      <Navbar backTo="/login" onlyBackArrow={true} />
      <Header title={title} subtitle={subtitle} color="white" />

      <MainCard
        showBrandFooter
        centerTitle
        title={finishRegister ? finishRegistration : createAccount}
      >
        <RegisterCard />
        <div className="text-centered mt24">
          <span>
            {or} <Link to="/login">{logYourself}</Link>
          </span>
        </div>
      </MainCard>
    </App>
  );
};

export default memo(Register);
