import React, { memo, useCallback } from "react";
import ExceptionModal from "components/ExceptionModal";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const Exception = () => {
  const { showException, setShowException } = useNewChallenge();

  const onSecondButtonClick = useCallback(
    () => setShowException(false),
    [setShowException]
  );

  const props = { active: showException, onSecondButtonClick };

  return <ExceptionModal {...props} />;
};

export default memo(Exception);
