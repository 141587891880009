import UserProvider from "./Component";
import { connect } from "react-redux";
import { storeUser, storeUserPicture } from "store/actions/user";

const mapDispatchToProps = (dispatch) => ({
  saveUser: (payload) => dispatch(storeUser(payload)),
  saveUserPicture: (payload) => dispatch(storeUserPicture(payload)),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProvider);
