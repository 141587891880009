import React, { useMemo } from "react";
import classNames from "classnames";
import Challenges from "./Buttons/Challenges";
import ChallengeButtons from "./Buttons/Challenge/index.js";
import Home from "./Buttons/Home";
import Menu from "./Buttons/Menu";
import { withRouter } from "react-router-dom";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useHistory } from "react-router-dom";
import { isIphoneXFamily, isNativeIOS } from "utils";
import { useGlobalScroll } from "contexts/GlobalScroll";
import { useEffect } from "react";

const blockList = ["/challenges/presentation"];

const BottomBar = () => {
  const { viewingChallenge } = useChallenge();
  const history = useHistory();
  const { isOnBottom, isScrollingDown } = useGlobalScroll();

  useEffect(() => {
    if (isNativeIOS) {
      if (isScrollingDown || isOnBottom) {
        document.body.classList.add("isOnBottom");
      } else {
        document.body.classList.remove("isOnBottom");
      }
    }
  }, [isScrollingDown, isOnBottom]);

  const isOnBlockedPath = useMemo(
    () => blockList.some((path) => history.location.pathname === path),
    [history.location.pathname]
  );

  if (isOnBlockedPath) return null;

  return (
    <div
      className={classNames("bottom-bar ", {
        "bottom-bar--challenge": viewingChallenge,
        isIphone: isIphoneXFamily && !isOnBottom,
      })}
    >
      <div className="bottom-bar__inside">
        <Home />
        {viewingChallenge ? <ChallengeButtons /> : <Challenges />}
        <Menu />
      </div>
    </div>
  );
};

export default withRouter(BottomBar);
