import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as PlusIcon } from "assets/images/plus-icon.svg";
import { selectChaptersPerDay, moreOptions, selectQuantity } from "../messages";
import classNames from "classnames";
import Accordion from "components/Accordion";
import OptionNotFound from "./OptionNotFound";
import QuantityOfDays from "../Forms/QuantityOfDays";
import ChaptersPerDay from "../Forms/ChaptersPerDay";

import { getItemMaxHeightStyle } from "components/Accordion/getItemMaxHeightStyle";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";

import { planTypes } from "components/FormPlan/Context/planType";
import { useHistory } from "react-router-dom";

const MoreOptions = () => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const {
    openMoreOptions,
    setOpenMoreOptions,
    setPlanType,
    formError,
    setIsViewingForm,
  } = useChallengePlan();
  const contentRef = useRef(null);
  const [contentStyle, setContentStyle] = useState({});
  const history = useHistory();

  useEffect(() => {
    const isOnOtherOptionsPage = !accordionIsOpen && openMoreOptions;

    setIsViewingForm(!isOnOtherOptionsPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordionIsOpen, openMoreOptions]);

  useEffect(() => {
    setContentStyle(getItemMaxHeightStyle(openMoreOptions, contentRef.current));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMoreOptions, accordionIsOpen, formError]);

  useEffect(() => {
    !openMoreOptions && setPlanType(planTypes.default);
  }, [openMoreOptions, setPlanType]);

  const onClick = useCallback(() => {
    setOpenMoreOptions(!openMoreOptions);
    history.push({ search: `?planType=${planTypes.default}` });
  }, [history, openMoreOptions, setOpenMoreOptions]);

  const hideMoreOptions = useCallback((hide) => {
    setAccordionIsOpen(hide);
  }, []);

  const onSelectChaptersPerDay = () => {
    history.push({ search: `?planType=${planTypes.chaptersPerDay}` });
    setPlanType(planTypes.chaptersPerDay);
  };

  const onSelectQuantityOfDays = () => {
    history.push({ search: `?planType=${planTypes.quantityOfDays}` });
    setPlanType(planTypes.quantityOfDays);
  };

  const options = [
    {
      title: selectQuantity,
      Component: <QuantityOfDays />,
      onClick: onSelectQuantityOfDays,
    },
    {
      title: selectChaptersPerDay,
      Component: <ChaptersPerDay />,
      onClick: onSelectChaptersPerDay,
    },
  ];

  return (
    <Fragment>
      <div
        onClick={onClick}
        className={classNames("form__row more-options", {
          openMoreOptions,
          hidden: accordionIsOpen,
        })}
      >
        <PlusIcon /> <p>{moreOptions}</p>
      </div>
      <div
        ref={contentRef}
        className={classNames("more-options__content", {
          openMoreOptions,
          hideDidntFind: accordionIsOpen,
        })}
        style={contentStyle}
      >
        <Accordion
          reRender={formError}
          hideAccordion={hideMoreOptions}
          options={options}
        />

        <OptionNotFound />
      </div>
    </Fragment>
  );
};

export default memo(MoreOptions);
