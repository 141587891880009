import { connect } from "react-redux";

import { storePlan, trackDelays, updatePlan } from "store/actions/planv2";

import PlanProvider from "./Component";

const mapDispatchToProps = (dispatch) => ({
  storePlan: (
    planv2,
    planId,
    blockedWeekDays,
    planType,
    chaptersPerDay,
    quantityOfDays
  ) =>
    new Promise((resolve) => {
      dispatch(
        storePlan(
          planv2,
          planId,
          blockedWeekDays,
          planType,
          chaptersPerDay,
          quantityOfDays
        )
      );
      resolve();
    }),
  updatePlan: (
    planv2,
    blockedWeekDays,
    planType,
    chaptersPerDay,
    quantityOfDays
  ) =>
    new Promise((resolve) => {
      dispatch(
        updatePlan(
          planv2,
          blockedWeekDays,
          planType,
          chaptersPerDay,
          quantityOfDays
        )
      );
      resolve();
    }),
  trackDelays: (payload) => dispatch(trackDelays(payload)),
});

const mapStateToProps = ({
  planv2: {
    planId,
    updatePlan,
    planv2,
    lastModified,
    delays,
    planType,
    blockedWeekDays,
    chaptersPerDay,
    quantityOfDays,
    trackDelays: storedTrackDelays,
  },
}) => ({
  planId,
  updatePlan,
  lastModified,
  planv2,
  delays,
  planType,
  blockedWeekDays,
  chaptersPerDay,
  quantityOfDays,
  storedTrackDelays,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanProvider);
