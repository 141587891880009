import { i18n } from "translate/i18n";

export const createAccount = i18n.t("messages.authentication.createAccount");
export const onRegistering = i18n.t("messages.authentication.onRegistering");
export const policyTerms = i18n.t("messages.authentication.policyTerms");
export const registering = i18n.t("messages.authentication.registering");
export const registerMsgCaptalized = i18n.t(
  "messages.authentication.registerMsgCaptalized"
);
export const logYourself = i18n.t("messages.authentication.logYourself");
export const registerWithFacebook = i18n.t(
  "messages.authentication.registerWithFacebook"
);
export const registerWithGoogle = i18n.t(
  "messages.authentication.registerWithGoogle"
);
export const loginWithFacebook = i18n.t(
  "messages.authentication.loginWithFacebook"
);
export const loginWithGoogle = i18n.t(
  "messages.authentication.loginWithGoogle"
);
export const or = i18n.t("messages.authentication.or");
export const registerMsg = i18n.t("messages.authentication.registerMsg");
export const loggingIn = i18n.t("messages.authentication.loggingIn");
export const loginMsg = i18n.t("messages.authentication.loginMsg");
export const email = i18n.t("messages.authentication.email");
export const password = i18n.t("messages.authentication.password");
export const name = i18n.t("messages.authentication.name");
export const biggerName = i18n.t("messages.authentication.biggerName");
export const validEmail = i18n.t("messages.authentication.validEmail");
export const biggerPassword = i18n.t("messages.authentication.biggerPassword");
export const pleaseInform = i18n.t("messages.authentication.pleaseInform");
export const pleaseInformThe = i18n.t(
  "messages.authentication.pleaseInformThe"
);
