import Button from "components/Button";
import { ReactComponent as FacebookLogo } from "assets/images/icon-facebook.svg";

import React, { memo, useCallback, useEffect, useState } from "react";

import { sendErrorEmail } from "utils/sendEmail";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { storeUserPicture } from "store/actions/user";
import { useRequest } from "hooks";
import { useDispatch } from "react-redux";
import { loading } from "components/SocialLoginButtons/messages";
import { pullFacebookPic } from "../messages";

const WebFacebookUpdatePicture = ({ onImageChange }) => {
  const [facebookUser, setFacebookUser] = useState({});
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [isLoading, setIsLoading] = useState(false);

  const request = useRequest();
  const dispatch = useDispatch();

  useEffect(() => {
    if ("FB" in window && !!Object.keys(facebookUser).length) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.api(
            response.authResponse.userID,
            "GET",
            { fields: "id, email, name, picture" },
            (user) => setFacebookUser({ facebookId: user.id, ...user })
          );
        }
      });
    }
  }, [facebookUser]);

  const savePicture = useCallback(
    (user) => {
      setIsLoading(false);

      const picture = user.picture?.data?.url;

      dispatch(storeUserPicture(picture));
      onImageChange(picture);
      request.put("/user/picture", { picture });
    },
    [request, dispatch, onImageChange]
  );

  const onButtonClick = () => {
    const isConnected = !!facebookUser.email && facebookUser.facebookId;
    setIsLoading(true);

    if (isConnected) {
      savePicture(facebookUser);
    } else {
      try {
        window.FB.login(
          (response) => {
            if (response.status === "connected") {
              window.FB.api(
                response.authResponse.userID,
                "GET",
                { fields: "picture" },
                (user) => savePicture({ facebookId: user.id, ...user })
              );
            } else {
              setIsLoading(false);
              openGeneralErrorSnackbar();
              sendErrorEmail(JSON.stringify(response), "on facebook login");
            }
          },
          { scope: "public_profile,email" }
        );
      } catch (error) {
        setIsLoading(false);
        openGeneralErrorSnackbar();
        sendErrorEmail(JSON.stringify(error), "on facebook login");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Button
      size="medium"
      className="social-button facebook font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <FacebookLogo />
      {isLoading ? loading : pullFacebookPic}
    </Button>
  );
};

export default memo(WebFacebookUpdatePicture);
