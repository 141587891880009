import React, { memo, useCallback } from "react";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/images/arrow-left.svg";

const ChaptersControls = ({
  readingIndex,
  isLoading,
  goToNextChapter,
  goToPrevChapter,
  readingDetails,
  isOnScrollBottom,
  isOnLastPage,
  copyrightElement,
  isScrollingDown,
}) => {
  const copyrightElementHeight = copyrightElement
    ? copyrightElement.clientHeight
    : 0;

  const decideOnBottomValue = useCallback(() => {
    if (isScrollingDown && !isOnScrollBottom) {
      return 0;
    }

    if (isOnLastPage && isOnScrollBottom) {
      return copyrightElementHeight - 7;
    }

    if (isOnScrollBottom) {
      return 32;
    }

    return 0;
  }, [copyrightElementHeight, isOnLastPage, isOnScrollBottom, isScrollingDown]);

  return (
    <div
      className="bible-reading__controls"
      style={{ bottom: decideOnBottomValue() }}
    >
      {readingIndex > 0 && !isLoading ? (
        <div
          className="bible-reading__controls__button left"
          onClick={goToPrevChapter}
        >
          <span>
            <ArrowLeft />
          </span>
        </div>
      ) : (
        <span />
      )}

      {readingIndex < readingDetails.length - 1 && !isLoading ? (
        <div
          className="bible-reading__controls__button right"
          onClick={goToNextChapter}
        >
          <span>
            <ArrowRight />
          </span>
        </div>
      ) : (
        <span />
      )}
    </div>
  );
};

export default memo(ChaptersControls);
