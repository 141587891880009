import React from "react";

const SmallInput = React.forwardRef(
  (
    {
      type,
      placeholder,
      max,
      error,
      name,
      onBlur,
      onChange,
      label,
      defaultValue,
    },
    ref
  ) => {
    return (
      <div className="small-input">
        <div className="small-input__input-wrap">
          <input
            type={type}
            className={`small-input__input ${!!error && "error"} `}
            name={name}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            max={max}
            defaultValue={defaultValue}
            min={1}
          />
          {label && <label className="small-input__label">{label}</label>}
        </div>
        {!!error && <span className="small-input__error">{error}</span>}
      </div>
    );
  }
);

SmallInput.defaultProps = {
  label: "",
  type: "text",
  onBlur: () => {},
  onChange: () => {},
  defaultValue: "",
  max: null,
  placeholder: "",
  error: "",
  name: "",
};

export default SmallInput;
