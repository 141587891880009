import { i18n } from "translate/i18n";

export const title = i18n.t(`messages.challenge.title`);
export const subtitle = i18n.t(`messages.challenge.subtitle`);
export const firstButton = i18n.t(`messages.challenge.firstButton`);
export const secondButton = i18n.t(`messages.challenge.secondButton`);
export const allowNotifications = i18n.t(
  "messages.challenge.allowNotifications"
);
export const allowNotificationsText = i18n.t(
  "messages.challenge.allowNotificationsText"
);
export const later = i18n.t("messages.challenge.later");
export const allow = i18n.t("messages.challenge.allow");
