import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";
import { isNativeIOS } from "utils";

const AppUrlListener = () => {
  let history = useHistory();

  useEffect(() => {
    if (isNativeIOS) {
      App.addListener("appUrlOpen", (event) => {
        const slug = event.url.split(".online").pop();
        if (slug) {
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AppUrlListener;
