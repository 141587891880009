import React, { useEffect, useMemo } from "react";
import App from "components/App";
import MainCard from "components/MainCard";
import Header from "components/App/Header";
import ProgressBar from "atoms/ProgressBar";

import { subtitle, title } from "./messages";
import { PlanChecklistV2 } from "components/PlanChecklistV2";
import { Navbar } from "components/Navbar";
import { usePlan } from "components/FormPlan/Context";

const CreatedPlan = () => {
  const { setIsLoading, setIsFetchingPlan, plan } = usePlan();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(false);
    setIsFetchingPlan(false);
  }, [setIsLoading, setIsFetchingPlan]);

  const read = useMemo(() => plan.filter((item) => item.read), [plan]);

  const percentage = useMemo(
    () => (read.length / plan.length) * 100,
    [read, plan]
  );

  return (
    <App>
      <Navbar backTo="/new" />
      <Header
        title={title}
        subtitle={subtitle}
        color="white"
        additionalComponent={
          <ProgressBar percentage={parseFloat(percentage.toFixed(2))} />
        }
      />
      <MainCard containerOnly={true}>
        <PlanChecklistV2 />
      </MainCard>
    </App>
  );
};

export default CreatedPlan;
