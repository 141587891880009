import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right.svg";
import { getItemMaxHeightStyle } from "./getItemMaxHeightStyle";

const Accordion = ({ options, hideAccordion, reRender }) => {
  const [active, setActive] = useState(null);

  const toggle = (title) => {
    if (title === active) {
      hideAccordion(false);
      return setActive(null);
    }
    hideAccordion(true);
    setActive(title);
  };

  return (
    <div className="accordion">
      {options.map(({ title, Component, onClick }, key) => (
        <Option
          active={active === title}
          hidden={active !== title && !!active}
          key={key}
          title={title}
          Component={Component}
          toggle={toggle}
          onClick={onClick}
          reRender={reRender}
        />
      ))}
    </div>
  );
};

Accordion.defaultProps = {
  options: [],
  hideAccordion: () => {},
};

const Option = ({
  title,
  Component,
  onClick,
  active,
  hidden,
  toggle,
  reRender,
}) => {
  const [contentStyle, setContentStyle] = useState({});
  const contentRef = useRef(null);

  useEffect(() => {
    setContentStyle(
      getItemMaxHeightStyle(active && !hidden, contentRef.current)
    );
  }, [active, hidden, reRender]);

  return (
    <div className={classNames("accordion__item", { active, hidden })}>
      <article
        className="accordion__item-title"
        onClick={() => {
          toggle(title);
          onClick();
        }}
      >
        <ArrowRight />
        <p>{title}</p>
      </article>
      <div
        className="accordion__item-content"
        ref={contentRef}
        style={contentStyle}
      >
        {active && !hidden && Component}
      </div>
    </div>
  );
};

export default Accordion;
