import React, { memo, useEffect, useState } from "react";

const NumberInput = ({ onValueChange = () => {}, defaultValue = 3 }) => {
  const [value, setValue] = useState(defaultValue);

  const increment = () => {
    setValue(value + 1);
  };
  const decrement = () => {
    setValue(value - 1);
  };

  useEffect(() => {
    if (value < 1) {
      setValue(1);
    }
    onValueChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="number-input">
      <div className="number-input__container">
        <button onClick={decrement} className="number-input__control">
          -
        </button>
        <input
          className="number-input__input"
          type="number"
          defaultValue={defaultValue}
          value={value}
          min={1}
          max={100}
        />
        <button onClick={increment} className="number-input__control">
          +
        </button>
      </div>
    </div>
  );
};

export default memo(NumberInput);
