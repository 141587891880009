import React from "react";
import { i18n } from "translate/i18n";

export const news = i18n.t("messages.notifications.news");
export const newsSubtitle = i18n.t("messages.notifications.newsSubtitle");
export const remindMeLater = i18n.t("messages.notifications.remindMeLater");
export const configureNow = i18n.t("messages.notifications.configureNow");
export const permissionDeniedSubtitle = i18n.t(
  "messages.notifications.permissionDeniedSubtitle"
);
export const notificationConfigTitle = i18n.t(
  "messages.notifications.notificationConfigTitle"
);
export const save = i18n.t("messages.notifications.save");
export const alarmDefined = i18n.t("messages.notifications.alarmDefined");
export const alarmDefinedParagraph1 = ({ hour, minute }) => (
  <p>
    {i18n.t("messages.notifications.alarmDefinedParagraph1")}{" "}
    <b>
      {hour < 10 ? `0${hour}` : hour}:{minute < 10 ? `0${minute}` : minute}
    </b>
  </p>
);
export const alarmDefinedParagraph2 = i18n.t(
  "messages.notifications.alarmDefinedParagraph2"
);
export const close = i18n.t("messages.notifications.close");
export const reminderTitle = i18n.t("messages.notifications.reminderTitle");
export const reminderSubtitle = i18n.t(
  "messages.notifications.reminderSubtitle"
);
export const askToContinueTitle = i18n.t(
  "messages.notifications.askToContinueTitle"
);
export const stopReminders = i18n.t("messages.notifications.stopReminders");
export const somethingWentWrongTitle = i18n.t(
  "messages.notifications.somethingWentWrongTitle"
);
export const somethingWentWrongSubtitle = i18n.t(
  "messages.notifications.somethingWentWrongSubtitle"
);
