import React from "react";

import { startReadingLabel, endReadingLabel } from "../messages";

import BiblePicker from "components/BiblePicker";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const ReadingInputs = () => {
  const { startReading, setStartReading, endReading, setEndReading } =
    useNewChallenge();

  return (
    <div className="form__row form-plan__inputs">
      <div className="input-wrap">
        <label htmlFor="" className="input-label">
          {startReadingLabel}
        </label>
        <BiblePicker
          bookIndex={startReading.bookIndex}
          chapterNumber={startReading.chapter}
          bibleReference={startReading.bibleReference}
          onChange={setStartReading}
        />
      </div>
      <div className="input-wrap">
        <label htmlFor="" className="input-label">
          {endReadingLabel}
        </label>
        <BiblePicker
          bookIndex={endReading.bookIndex}
          chapterNumber={endReading.chapter}
          bibleReference={endReading.bibleReference}
          onChange={setEndReading}
        />
      </div>
    </div>
  );
};

export default ReadingInputs;
