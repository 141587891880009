import React, { useEffect } from "react";
import withChallenge from "HOCs/withChallenge";
import { question } from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import Wrapper from "../Wrapper";
import Owner from "./Owner";
import Participant from "./Participant";
import { useParams } from "react-router-dom";
import { useQuestion } from "contexts/Challenge/Question";

const ViewQuestion = () => {
  const { isOwner, request, isLoadingChallenge } = useChallenge();

  const {
    id,
    setAlternatives,
    setStatement,
    setPoints,
    setAnswer,
    setId,
    setIsLoadingQuestion,
    setIsAnswered,
    setShowAnswer,
    setSelectedAnswer,
  } = useQuestion();

  const { idQuestion } = useParams();

  useEffect(() => {
    if (!id && !isLoadingChallenge) {
      setIsLoadingQuestion(true);
      request
        .get(`${isOwner ? "owner" : "participant"}/question/${idQuestion}`)
        .then((response) => {
          const question = response.data.question;
          setAlternatives(question.alternatives);
          setStatement(question.statement);
          setPoints(question.points);
          setAnswer(question.answer);
          setId(question._id);
          setIsAnswered(!!question.answer);
          setShowAnswer(!!question.answer);
          setSelectedAnswer(isOwner ? question.answer : question.answerGiven);
        })
        .finally(() => setIsLoadingQuestion(false));
    }

    if (id && !isLoadingChallenge) {
      setIsLoadingQuestion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoadingChallenge]);

  return (
    <Wrapper cardTitle={question}>
      {isOwner ? <Owner /> : <Participant />}
    </Wrapper>
  );
};

export default withChallenge(ViewQuestion);
