const INICIAL_STATE = {
  plan: [],
  planArrayForPdf: [],
  built: false,
  trackDelays: true,
  readingDatesInterval: {
    startDate: "",
    endDate: "",
  },
  readingInterval: {
    start: {
      book: "",
      chapter: 0,
    },
    end: {
      book: "",
      chapter: 0,
    },
  },
};

export default function plan(state = INICIAL_STATE, action) {
  if (action.type === "STORE_PLAN") {
    return {
      ...state,
      plan: action.plan,
      built: true,
    };
  }

  if (action.type === "TRACK_DELAYS") {
    return {
      ...state,
      trackDelays: action.track,
    };
  }

  if (action.type === "STORE_PDF_ARRAY") {
    return {
      ...state,
      planArrayForPdf: action.planArrayForPdf,
    };
  }

  if (action.type === "STORE_READING_INTERVAL") {
    return {
      ...state,
      readingInterval: action.readingInterval,
    };
  }

  if (action.type === "STORE_READING_DATES_INTERVAL") {
    return {
      ...state,
      readingDatesInterval: action.readingDatesInterval,
    };
  }

  if (action.type === "RESET_PLAN_V1") {
    return {
      plan: [],
      planArrayForPdf: [],
      built: false,
      trackDelays: true,
      readingDatesInterval: {
        startDate: "",
        endDate: "",
      },
      readingInterval: {
        start: {
          book: "",
          chapter: 0,
        },
        end: {
          book: "",
          chapter: 0,
        },
      },
    };
  }

  if (action.type === "TOGGLE_READ_STATUS") {
    return {
      ...state,
      plan: state.plan.map((item, index) => {
        if (index === action.monthIndex) {
          return [
            state.plan[action.monthIndex][0],
            item[1].map((day, index) => {
              if (index === action.day) {
                return {
                  ...day,
                  read: day.read ? false : true,
                };
              }
              return day;
            }),
          ];
        }
        return item;
      }),
    };
  }

  return state;
}
