import moment from "moment";
import api from "utils/api";

export const registerPlanUpdate = async (planId, headers) =>
  await api.put(
    `/plan/register-update/${planId}`,
    { updatedAt: moment().toISOString() },
    headers
  );

export const syncDayWithBD = async (readingId, read, headers) =>
  api.put(
    `/day-reading/complete/${readingId}`,
    {
      read,
    },
    headers
  );
