import React, {
  useMemo,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Container } from "atoms";
import {
  challenges,
  createNewChallenge,
  youAreLeader,
  participantsMsg,
  youAreParticipant,
  subscribeOrBuy,
} from "./messages";
import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { Link, Redirect, useHistory } from "react-router-dom";
import Button from "components/Button";
import Skeleton from "./Skeleton";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useUser } from "contexts/User";
import { useModal } from "contexts/Modal/useModal";

const Challenges = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { fetchChallenges, challenges: challengesList } = useChallenge();
  const { isLogged, premium } = useUser();
  const { createModal, closeSpecificModal } = useModal();

  const onCreateNewChallengeClick = useCallback(() => {
    const exceededLimitOfChallenges =
      challengesList.owner.length >= premium.amountPurchases;

    if (
      (premium.type === "one_time" && exceededLimitOfChallenges) ||
      premium.notLoaded
    ) {
      createModal({
        id: subscribeOrBuy,
        subtitle: subscribeOrBuy,
        firstButtonText: "Ok",
        onFirstButtonClick: () => closeSpecificModal({ id: subscribeOrBuy }),
      });

      return history.push("/challenges/presentation#start-now");
    }

    history.push("/challenges/new/name");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengesList, premium]);

  useEffect(() => {
    if (isLogged) {
      setIsLoading(true);

      fetchChallenges(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasNoChallenges = useMemo(
    () =>
      !challengesList?.participant?.length && !challengesList?.owner?.length,
    [challengesList]
  );

  if ((!isLoading && hasNoChallenges && premium.notPremium) || !isLogged) {
    return <Redirect to="/challenges/presentation" />;
  }

  return (
    <div className="challenges">
      <Container>
        <div className="challenges__inside">
          <div className="challenges__title">
            <h1>{challenges}</h1>
            <FlagIcon className="path-stroke-white" width="28" height="28" />
          </div>
          {isLoading ? (
            <Skeleton className="skeleton" />
          ) : (
            <Fragment>
              <div className="challenges__list">
                {!!challengesList?.owner?.length && (
                  <div className="challenges__list-title">
                    <h2>{youAreLeader}</h2>
                  </div>
                )}
                {challengesList?.owner?.map(({ title, participants, _id }) => (
                  <ChallengesCard
                    key={_id}
                    title={title}
                    subtitle={`${participants.length} ${participantsMsg}`}
                    challengeId={_id}
                  />
                ))}

                <Button
                  size="medium"
                  className="button--success  icon-right"
                  variation="square-rounded"
                  theme="primary"
                  fullWidth={true}
                  onClick={onCreateNewChallengeClick}
                >
                  {createNewChallenge}
                  <FlagIcon className="path-stroke-white" />
                </Button>
              </div>
              <div className="challenges__list">
                {!!challengesList?.participant?.length && (
                  <div className="challenges__list-title">
                    <h2>{youAreParticipant}</h2>
                  </div>
                )}

                {challengesList?.participant?.map(
                  ({ title, participants, _id }) => (
                    <ChallengesCard
                      key={_id}
                      title={title}
                      subtitle={`${participants.length} ${participantsMsg}`}
                      challengeId={_id}
                    />
                  )
                )}
              </div>
            </Fragment>
          )}
        </div>
      </Container>
    </div>
  );
};

const ChallengesCard = ({ title, subtitle, challengeId }) => {
  return (
    <Link
      to={`challenge/${challengeId}/ranking`}
      className="challenges__list-item"
    >
      <div className="challenges__list-item-content">
        <div className="challenges__list-item-title">
          <h3>{title}</h3>
        </div>
        <div className="challenges__list-item-subtitle">
          <p>{subtitle}</p>
        </div>
      </div>
      <ArrowRight className="challenges__list-item-icon" />
    </Link>
  );
};

export default Challenges;
