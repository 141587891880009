import React, { memo, useMemo } from "react";
import MenuCard from "molecules/MenuCard";
import { dark, light, theme } from "../messages";
import { useTheme } from "contexts/Theme/useTheme";
import ThemeSwitcher from "atoms/ThemeSwitcher";

const Reminder = () => {
  const [currentTheme] = useTheme();

  const menu = useMemo(
    () => ({
      header: {
        title: theme,
      },
      items: [
        {
          description: currentTheme === "dark" ? dark : light,
          Component: <ThemeSwitcher />,
        },
      ],
    }),
    [currentTheme]
  );

  return <MenuCard header={menu.header} items={menu.items} />;
};

export default memo(Reminder);
