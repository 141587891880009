import { i18n } from "translate/i18n";

export const configurations = i18n.t("messages.challenge.configurations");
export const editChallengeName = i18n.t("messages.challenge.editChallengeName");
export const editParticipants = i18n.t("messages.challenge.editParticipants");
export const editPlan = i18n.t("messages.challenge.editPlan");
export const deleteChallenge = i18n.t("messages.challenge.deleteChallenge");
export const challengeDeleted = i18n.t("messages.challenge.challengeDeleted");
export const dangerousArea = i18n.t("messages.challenge.dangerousArea");
export const enterChallengeName = i18n.t(
  "messages.challenge.enterChallengeName"
);
export const enterLargerChallengeName = i18n.t(
  "messages.challenge.enterLargerChallengeName"
);
export const wantToRemove = i18n.t("messages.challenge.wantToRemove");
export const yesRemoveThisPerson = i18n.t(
  "messages.challenge.yesRemoveThisPerson"
);
export const inCaseYouChangeYourMind = i18n.t(
  "messages.challenge.inCaseYouChangeYourMind"
);
export const action = i18n.t("messages.challenge.action");
export const remove = i18n.t("messages.challenge.remove");
export const notJoinedYet = i18n.t("messages.challenge.notJoinedYet");
export const addParticipants = i18n.t("messages.challenge.addParticipants");
export const add = i18n.t("messages.challenge.add");
export const sendInvite = i18n.t("messages.challenge.sendInvite");

export const thePlanWillChange = i18n.t("messages.challenge.thePlanWillChange");
export const yesUpdateIt = i18n.t("messages.challenge.yesUpdateIt");
export const planUpdateDisclaimer = i18n.t(
  "messages.challenge.planUpdateDisclaimer"
);
export const resendInvitation = i18n.t("messages.challenge.resendInvitation");
export const invitationSent = i18n.t("messages.challenge.invitationSent");
