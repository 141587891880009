import React, { useEffect } from "react";
import { useChallenge } from "contexts/Challenge/useChallenge";

import { useParams } from "react-router";

const withChallenge = (Component) => {
  return (props) => {
    const { idChallenge } = useParams();
    const { setViewingChallenge } = useChallenge();

    useEffect(() => {
      setViewingChallenge(idChallenge);
    }, [idChallenge, setViewingChallenge]);

    return <Component {...props} idChallenge={idChallenge} />;
  };
};

export default withChallenge;
