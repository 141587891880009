import React, { Fragment, memo } from "react";
import Form from "./Form";
import { loginWithApple, loginWithFacebook, loginWithGoogle } from "./messages";
import { GoogleButton } from "components/SocialLoginButtons/Google";
import { FacebookLoginButton } from "components/SocialLoginButtons/Facebook";
import { AppleButton } from "components/SocialLoginButtons/Apple";

const LoginCard = () => (
  <Fragment className="main-card__body">
    <FacebookLoginButton text={loginWithFacebook} />
    <GoogleButton text={loginWithGoogle} />
    <AppleButton text={loginWithApple} />
    <hr style={{ margin: "32px 0" }} />
    <Form />
  </Fragment>
);

export default memo(LoginCard);
