import React, { memo } from "react";
import { ReactComponent as NoPlanImg } from "assets/images/no-plan.svg";
import {
  weCouldNotLoadChapter,
  thePossibleReasons,
  thePossibleReasons1,
  thePossibleReasons2,
  thePossibleReasons3,
  whatYouCanDo,
  whatYouCanDo1,
  whatYouCanDo2,
  whatYouCanDo3,
} from "./messages";

const FailedToLoadChapter = ({ active }) => {
  if (!active) return null;

  return (
    <div className="failed-to-load-chapter">
      <NoPlanImg />
      <article>
        <h3 className="failed-to-load-chapter__title-3">
          {weCouldNotLoadChapter}
        </h3>
        <h4 className="failed-to-load-chapter__title-4">
          {thePossibleReasons}
        </h4>
        <ul className="failed-to-load-chapter__ul">
          <li className="failed-to-load-chapter__li">{thePossibleReasons1}</li>
          <li className="failed-to-load-chapter__li">{thePossibleReasons2}</li>
          <li className="failed-to-load-chapter__li">{thePossibleReasons3}</li>
        </ul>
        <h4 className="failed-to-load-chapter__title-4">{whatYouCanDo}</h4>
        <ul className="failed-to-load-chapter__ul">
          <li className="failed-to-load-chapter__li">{whatYouCanDo1}</li>
          <li className="failed-to-load-chapter__li">{whatYouCanDo2}</li>
          <li className="failed-to-load-chapter__li">{whatYouCanDo3}</li>
        </ul>
      </article>
    </div>
  );
};

export default memo(FailedToLoadChapter);
