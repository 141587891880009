const activeContentStyle = ({ scrollHeight = 0 } = {}) => ({
  maxHeight: `${scrollHeight}px`,
});

const notActiveStyle = {
  maxHeight: 0,
};

export const getItemMaxHeightStyle = (active, ref) => {
  return active ? activeContentStyle(ref) : notActiveStyle;
};
