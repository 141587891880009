import { i18n } from "translate/i18n";

export const challengeNameLabel = i18n.t(
  "messages.challenge.challengeNameLabel"
);
export const challengeNamePlaceholder = i18n.t(
  "messages.challenge.challengeNamePlaceholder"
);
export const jump = i18n.t("messages.challenge.jump");
export const next = i18n.t("messages.challenge.next");
export const setTheDates = i18n.t("messages.challenge.setTheDates");
export const addTheParticipants = i18n.t(
  "messages.challenge.addTheParticipants"
);
export const addTheParticipantsPlaceholder = i18n.t(
  "messages.challenge.addTheParticipantsPlaceholder"
);
export const creatingChallenge = i18n.t("messages.challenge.creatingChallenge");
export const atLeastTwoEmails = i18n.t("messages.challenge.atLeastTwoEmails");
export const readingChallenge = i18n.t("messages.challenge.readingChallenge");
export const challengeCreated = i18n.t("messages.challenge.challengeCreated");
export const nowLastStep = i18n.t("messages.challenge.nowLastStep");
