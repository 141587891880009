import Button from "components/Button";
import React, { Fragment, memo } from "react";
import { finishReading } from "./messages";

const FinishReadingButton = ({
  readingIndex,
  readingDetails,
  isFinishingReading,
  handleFinishReading,
  shouldFetchChapter,
}) => {
  return (
    <Fragment>
      {readingIndex === readingDetails.length - 1 && !shouldFetchChapter && (
        <div className="bible-reading__finish-reading">
          <Button
            size="medium"
            className="button--success  mt8"
            variation="square-rounded"
            theme="primary"
            disabled={isFinishingReading}
            onClick={handleFinishReading}
          >
            {finishReading}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default memo(FinishReadingButton);
