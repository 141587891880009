import React, { createContext, useState, useContext, useCallback } from "react";
import SnackBar from "components/SnackBar";
import { Link, useHistory } from "react-router-dom";
import {
  register,
  accountNotFound,
  somethingWentWrong,
  emailRegistered,
  login,
  existingAccountUsing,
  invalidSolicitation,
  expiredSolicitation,
  askAgain,
  passwordChanged,
  accountConfirmed,
} from "./messages";

const SnackbarNotificationContext = createContext();

const errorConfig = { variant: "error", open: true };

const successConfig = { variant: "success", open: true };

const SnackbarNotificationsProvider = ({ children }) => {
  const [formFeedback, setFormFeedback] = useState({});

  const history = useHistory();

  const closeSnackbar = useCallback(() => {
    setFormFeedback({ ...formFeedback, open: false });

    setTimeout(() => {
      setFormFeedback({ open: false });
    }, 1000);
  }, [formFeedback]);

  const openGeneralErrorSnackbar = useCallback(
    () =>
      setFormFeedback({
        ...errorConfig,
        message: somethingWentWrong,
      }),
    []
  );

  const openCustomSnackbar = useCallback(
    ({ message, variant, Icon, closeAfterDelay = true, delay = 4000 } = {}) =>
      setFormFeedback({
        ...errorConfig,
        variant,
        message,
        Icon,
        closeAfterDelay,
        delay,
      }),
    []
  );

  const openAccountConfirmedSnackbar = useCallback(
    () =>
      setFormFeedback({
        ...successConfig,
        message: accountConfirmed,
      }),
    []
  );

  const showEmailNotFoundSnackbar = useCallback(
    ({ onLinkClick } = {}) => {
      const onClick = () => {
        closeSnackbar();
        onLinkClick ? onLinkClick() : history.push("/register");
      };

      setFormFeedback({
        ...errorConfig,
        closeAfterDelay: false,
        message: (
          <>
            {accountNotFound}{" "}
            <span className="color-white underline" onClick={onClick}>
              {register}
            </span>
          </>
        ),
      });
    },
    [closeSnackbar, history]
  );

  const showEmailRegisteredSnackbar = useCallback(
    ({ onLinkClick } = {}) => {
      const onClick = () => {
        closeSnackbar();
        onLinkClick ? onLinkClick() : history.push("/login");
      };

      setFormFeedback({
        ...errorConfig,
        message: (
          <>
            {emailRegistered}{" "}
            <span className="color-white underline" onClick={onClick}>
              {login}
            </span>
          </>
        ),
      });
    },
    [closeSnackbar, history]
  );

  const showEmailRegisteredWithSocialSnackbar = useCallback(
    (socialMedia) =>
      setFormFeedback({
        ...errorConfig,
        message: <>{existingAccountUsing(socialMedia)}</>,
      }),
    []
  );

  const showInvalidPasswordChangeRequest = useCallback(
    () =>
      setFormFeedback({
        ...errorConfig,
        message: invalidSolicitation,
      }),
    []
  );

  const showExpiredSolicitationSnackbar = useCallback(
    () =>
      setFormFeedback({
        ...errorConfig,
        message: (
          <>
            {expiredSolicitation}{" "}
            <Link
              className="color-white"
              to="/forgot-password"
              onClick={closeSnackbar}
            >
              {askAgain}
            </Link>
          </>
        ),
      }),
    [closeSnackbar]
  );

  const showSuccessfulPasswordChangeSnackbar = useCallback(
    () =>
      setFormFeedback({
        ...successConfig,
        closeAfterDelay: false,
        message: (
          <>
            {passwordChanged}{" "}
            <Link className="color-white" to="/login" onClick={closeSnackbar}>
              {login}
            </Link>
          </>
        ),
      }),
    [closeSnackbar]
  );

  return (
    <SnackbarNotificationContext.Provider
      value={{
        openGeneralErrorSnackbar,
        closeSnackbar,
        openAccountConfirmedSnackbar,
        showEmailNotFoundSnackbar,
        showEmailRegisteredSnackbar,
        showEmailRegisteredWithSocialSnackbar,
        showInvalidPasswordChangeRequest,
        showExpiredSolicitationSnackbar,
        showSuccessfulPasswordChangeSnackbar,
        openCustomSnackbar,
      }}
    >
      <SnackBar {...formFeedback} close={closeSnackbar} />

      {children}
    </SnackbarNotificationContext.Provider>
  );
};

export default SnackbarNotificationsProvider;

export const useSnackbarNotification = () => {
  const context = useContext(SnackbarNotificationContext);
  if (!context)
    throw new Error(
      "useSnackbarNotification must be used within a SnackbarNotificationsProvider"
    );

  return context;
};
