import React, { Fragment, useEffect, useRef, useState } from "react";

import Button from "components/Button";

import { createPlan, creatingPlan, updatePlan } from "./messages";

import { AskConfirmationModal, ExceptionModal } from "./Modals";

import { usePlan } from "./Context";
import { DefaultForm } from "./Forms";
import { MoreOptions } from "./components";
import classNames from "classnames";
import { getItemMaxHeightStyle } from "components/Accordion/getItemMaxHeightStyle";

const FormPlan = () => {
  const { setIsLoading } = usePlan();
  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ExceptionModal />
      <AskConfirmationModal />
      <div className="form form-plan">
        <DefaultForm />
        <MoreOptions />
        <DefaultButton />
      </div>
    </Fragment>
  );
};

const DefaultButton = () => {
  const {
    handlePlanCreation,
    isLoading,
    openMoreOptions,
    isEditing,
    allInputsAreValid,
  } = usePlan();

  const [contentStyle, setContentStyle] = useState({});
  const contentRef = useRef(null);

  useEffect(() => {
    setContentStyle(
      getItemMaxHeightStyle(!openMoreOptions, contentRef.current)
    );
  }, [openMoreOptions]);

  const onClick = () => {
    if (!allInputsAreValid()) return;
    handlePlanCreation();
  };

  return (
    <div
      ref={contentRef}
      style={contentStyle}
      className={classNames("default-button", { openMoreOptions })}
    >
      <Button onClick={onClick} theme="create-plan">
        {isLoading ? creatingPlan : isEditing ? updatePlan : createPlan}
      </Button>
    </div>
  );
};

export default FormPlan;
