import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingState = () => {
  return (
    <div className="plan-checklist">
      <div className="plan-checklist__header">
        <span></span>
        <Skeleton className="skeleton" height={26} width={150} />
        <span></span>
      </div>
      <div className="checklist">
        <CheckListItemLoading />
        <CheckListItemLoading />
        <CheckListItemLoading />
        <CheckListItemLoading />
        <CheckListItemLoading />
        <CheckListItemLoading />
      </div>
    </div>
  );
};

const CheckListItemLoading = () => (
  <div className="checklist__item">
    <div className="checklist__item-content">
      <Skeleton
        className="skeleton"
        height={16}
        width={16}
        style={{
          marginRight: "24px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Skeleton className="skeleton" height={25} width={150} />
        <Skeleton className="skeleton" height={18} width={130} />
      </div>
    </div>
    <div className="checklist__item__read-now">
      <Skeleton className="skeleton" height={20} width={43} />
    </div>
  </div>
);

export default LoadingState;
