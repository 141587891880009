import { combineReducers } from "redux";
import plan from "./plan";
import planv2 from "./planv2";
import donation from "./donation";
import bibleReading from "./bibleReading";
import user from "./user";
import loginUpdate from "./loginUpdate";
import notifications from "./notifications";
import challenge from "./challenge";

export default combineReducers({
  plan,
  planv2,
  donation,
  bibleReading,
  user,
  loginUpdate,
  notifications,
  challenge,
});
