import { i18n } from "translate/i18n";

export const startDateLabel = i18n.t("messages.planCreation.startDate");
export const endDateLabel = i18n.t("messages.planCreation.endDate");
export const startReadingLabel = i18n.t("messages.planCreation.startReading");
export const endReadingLabel = i18n.t("messages.planCreation.endReading");
export const genesis = i18n.t("messages.bibleBooks.genesis.abbreviation");
export const revelation = i18n.t("messages.bibleBooks.revelation.abbreviation");
export const createPlan = i18n.t("buttons.planCreation.createPlan");
export const creatingPlan = i18n.t("buttons.planCreation.createPlanLoading");
export const isCrossYearError = i18n.t(
  "messages.planCreation.feedbacks.isCrossYear"
);
export const startDateBiggerThenEndDateError = i18n.t(
  "messages.planCreation.feedbacks.startDateIsBiggerThenEndDate"
);

export const readingIntervalIsReverseError = i18n.t(
  "messages.planCreation.feedbacks.readingIntervalIsReverse"
);

export const updatePlan = i18n.t("buttons.editPlan.updatePlan");
export const moreOptions = i18n.t("messages.planCreation.moreOptions");
export const selectQuantity = i18n.t("messages.planCreation.selectQuantity");
export const selectChaptersPerDay = i18n.t(
  "messages.planCreation.selectChaptersPerDay"
);
export const chaptersPerDay = i18n.t("messages.planCreation.chaptersPerDay");
export const didntFind = i18n.t("messages.planCreation.didntFind");
export const sendUsAMessage = i18n.t("messages.planCreation.sendUsAMessage");
export const writeHere = i18n.t("messages.planCreation.writeHere");
export const howWeGetInTouch = i18n.t("messages.planCreation.howWeGetInTouch");
export const wantUsToGetInTouch = i18n.t(
  "messages.planCreation.wantUsToGetInTouch"
);
export const emailPlaceholder = i18n.t(
  "messages.planCreation.emailPlaceholder"
);
export const saySomething = i18n.t("messages.planCreation.saySomething");
export const sent = i18n.t("messages.planCreation.sent");
export const days = i18n.t("messages.planCreation.days");

export const errorOnFetchingPlan = i18n.t(
  "messages.planCreation.errorOnFetchingPlan"
);
