import React, { useEffect, memo, useRef, useMemo } from "react";
import Button from "../Button";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { isNativeIOS } from "utils";
import { useGlobalScroll } from "contexts/GlobalScroll";

const Modal = ({
  title,
  subtitle,
  onFirstButtonClick,
  onSecondButtonClick,
  firstButtonText,
  secondButtonText,
  active,
  onOverlayClick,
  text,
  secondButtonIcon,
  firstButtonIcon,
  children,
  size = "default",
  fullHeight = false,
  showTwoButtonsOptions = false,
  renderChildrenInsteadOfText = false,
  secondButtonDisabled = false,
  firstButtonDisabled = false,
  firstButtonTheme = "primary",
  secondButtonTheme = "success",
  thirdButtonText,
  thirdButtonDisabled = false,
  onThirdButtonClick,
  thirdButtonTheme,
  thirdButtonIcon,
  disclaimer = "",
  fullWindowHeight,
  modalNoPadding,
  fullScreenHeight,
  underButtons,
}) => {
  const windowRef = useRef();
  const contentRef = useRef();
  const { isOnIOSSafeTop } = useGlobalScroll();

  useEffect(() => {
    if (window.outerHeight < windowRef.current?.offsetHeight) {
      windowRef.current.style.height = "100%";
      contentRef.current.style.height = "100%";
    }
  }, [windowRef, contentRef, children]);

  useEffect(() => {
    // active
    //   ? (document.body.style.overflowY = "hidden")
    //   : (document.body.style.overflowY = "scroll");
    // return () => {
    //   document.body.style.overflowY = "scroll";
    // };
  }, [active]);

  const modalStyle = useMemo(() => {
    if (isOnIOSSafeTop && isNativeIOS && fullScreenHeight) {
      return {
        padding: 0,
        marginTop: "auto",
        height: `calc(${window.outerHeight}px - env(safe-area-inset-top))`,
      };
    }

    if (!isOnIOSSafeTop && isNativeIOS && fullScreenHeight) {
      return {
        padding: "env(safe-area-inset-top) 0 0 0",
      };
    }

    if (fullScreenHeight) {
      return {
        padding: 0,
      };
    }
  }, [fullScreenHeight, isOnIOSSafeTop]);

  return (
    <CSSTransition
      onClick={(event) => {
        (event.target.classList.contains("modal") ||
          event.target.classList.contains("modal__inside")) &&
          onOverlayClick &&
          onOverlayClick();
      }}
      className={classNames("modal", {
        modalNoPadding,
        isNativeIOS,
      })}
      classNames={{
        enter: "enter",
        enterDone: "enter",
        exit: "exit",
        exitDone: "exit",
      }}
      in={active}
      timeout={200}
      style={modalStyle}
    >
      <div>
        <div
          className={classNames("modal__overlay", { fullScreenHeight })}
        ></div>
        <div className="modal__inside">
          <div
            className={`modal__window modal__window--${size} ${
              fullHeight ? "modal__window--full-height" : ""
            }`}
            ref={windowRef}
            style={{
              ...(fullScreenHeight && { borderRadius: 0 }),
            }}
          >
            <div
              className={`modal__window-content ${
                fullWindowHeight ? "fullWindowHeight" : ""
              }`}
              ref={contentRef}
            >
              {children && !renderChildrenInsteadOfText ? (
                children
              ) : (
                <>
                  <h3>{title}</h3>
                  {subtitle && (
                    <article>
                      <p dangerouslySetInnerHTML={{ __html: subtitle }} />
                    </article>
                  )}
                  {renderChildrenInsteadOfText ? children : text}
                  <footer className="modal__footer">
                    {firstButtonText && (
                      <Button
                        onClick={onFirstButtonClick}
                        className="full-width"
                        theme={firstButtonTheme}
                        disabled={firstButtonDisabled}
                      >
                        {firstButtonIcon && firstButtonIcon}
                        {firstButtonText}
                      </Button>
                    )}
                    {secondButtonText && (
                      <Button
                        disabled={secondButtonDisabled}
                        onClick={onSecondButtonClick}
                        className="full-width"
                        theme={secondButtonTheme}
                      >
                        {secondButtonIcon && secondButtonIcon}
                        {secondButtonText}
                      </Button>
                    )}
                    {thirdButtonText && (
                      <Button
                        disabled={thirdButtonDisabled}
                        onClick={onThirdButtonClick}
                        className="full-width"
                        theme={thirdButtonTheme}
                      >
                        {thirdButtonIcon && thirdButtonIcon}
                        {thirdButtonText}
                      </Button>
                    )}
                  </footer>
                  {underButtons && (
                    <div className="modal__under-buttons">{underButtons}</div>
                  )}
                </>
              )}
            </div>
            {showTwoButtonsOptions && (
              <footer className="modal__footer-buttons">
                {firstButtonText && (
                  <Button
                    size="small"
                    onClick={onFirstButtonClick}
                    className="button--primary "
                  >
                    {firstButtonIcon && firstButtonIcon}
                    {firstButtonText}
                  </Button>
                )}
                {secondButtonText && (
                  <Button
                    size="small"
                    disabled={secondButtonDisabled}
                    onClick={onSecondButtonClick}
                    className="button--success"
                  >
                    {secondButtonIcon && secondButtonIcon}
                    {secondButtonText}
                  </Button>
                )}
              </footer>
            )}
            {disclaimer && (
              <div className="modal__disclaimer">
                <p>* {disclaimer}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default memo(Modal);
