import { biggerName, name, pleaseInformThe } from "components/AuthBox/messages";

const generalRequiredMessage = (name) => `${pleaseInformThe} ${name}`;

export const NAME_SCHEMA = {
  minLength: {
    value: 2,
    message: biggerName,
  },
  required: {
    value: true,
    message: generalRequiredMessage(name),
  },
};
