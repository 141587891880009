import React, { memo } from "react";
import { ReactComponent as NoPlanImg } from "assets/images/no-plan.svg";
import {
  weCouldNotFindThisPage,
  thePossibleReasons,
  thePossibleReasons1,
  thePossibleReasons2,
  thePossibleReasons3,
  whatYouCanDo,
  whatYouCanDo1,
} from "./messages";
import { Container } from "atoms";

const PageNotFound = () => {
  return (
    <div
      className="failed-to-load-chapter"
      style={{
        backgroundColor: "var(--main-card)",
        paddingBottom: "100px",
        minHeight: "100vh",
      }}
    >
      <Container>
        <NoPlanImg />
        <article>
          <h3 className="failed-to-load-chapter__title-3">
            {weCouldNotFindThisPage}
          </h3>
          <h4 className="failed-to-load-chapter__title-4">
            {thePossibleReasons}
          </h4>
          <ul className="failed-to-load-chapter__ul">
            <li className="failed-to-load-chapter__li">
              {thePossibleReasons1}
            </li>
            <li className="failed-to-load-chapter__li">
              {thePossibleReasons2}
            </li>
            <li className="failed-to-load-chapter__li">
              {thePossibleReasons3}
            </li>
          </ul>
          <h4 className="failed-to-load-chapter__title-4">{whatYouCanDo}</h4>
          <ul className="failed-to-load-chapter__ul">
            <li className="failed-to-load-chapter__li">{whatYouCanDo1}</li>
          </ul>
        </article>
      </Container>
    </div>
  );
};

export default memo(PageNotFound);
