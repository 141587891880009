import React, { useCallback } from "react";
import ExceptionModal from "components/ExceptionModal";
import { usePlan } from "../Context";

const Exception = () => {
  const { showException, setShowException } = usePlan();

  const onSecondButtonClick = useCallback(
    () => setShowException(false),
    [setShowException]
  );

  const props = { active: showException, onSecondButtonClick };

  return <ExceptionModal {...props} />;
};

export default Exception;
