import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  name: "",
  email: "",
  token: "",
  picture: "",
  hasConfirmedAccount: false,
};

const user = handleActions(
  {
    STORE_USER: (
      state,
      { user: { name, email, hasConfirmedAccount }, token }
    ) => ({
      ...state,
      name,
      email,
      token,
      hasConfirmedAccount,
    }),
    STORE_USER_PICTURE: (state, { picture }) => ({
      ...state,
      picture,
    }),
    LOGOUT: (state) => ({
      ...state,
      name: "",
      email: "",
      token: "",
      hasConfirmedAccount: "",
    }),
  },
  INITIAL_STATE
);

export default user;
