import { Navbar } from "components/Navbar";
import React, { memo, useCallback } from "react";
import { ReactComponent as HeaderImage } from "assets/images/challenge-lp-header-image.svg";
import { headerContent, headerTitle, moreAboutIt, startNow } from "./messages";
import Container from "./Container";
import { isDesktopViewport } from "utils/viewport";
import Button from "components/Button";
import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import { scrollIntoView } from "utils/scrollIntoView";

const Header = () => {
  const goToStartNow = useCallback(() => scrollIntoView("#start-now"), []);

  const goToHowItWorks = useCallback(() => scrollIntoView("#how-it-works"), []);

  return (
    <div className="challenge-lp__header">
      <Navbar onlyBackArrow={true} backTo={"/plan"} />
      <Container>
        <div className="challenge-lp__header-inside">
          <div className="challenge-lp__header-inside--desktop-left">
            <h1 className="challenge-lp__header-title">{headerTitle}</h1>
            <div className="challenge-lp__header-image--mobile">
              {!isDesktopViewport && <HeaderImage />}
            </div>
            <p className="challenge-lp__header-text">{headerContent}</p>
            <p className="challenge-lp__header-start-now">
              <Button
                size="medium"
                className="button--success  icon-right"
                variation="square-rounded"
                theme="primary"
                fullWidth={true}
                onClick={goToStartNow}
              >
                {startNow}
                <FlagIcon className="path-stroke-white" />
              </Button>
            </p>
            <p className="challenge-lp__header-more" onClick={goToHowItWorks}>
              {moreAboutIt}
            </p>
          </div>
          <div className="challenge-lp__header-image--desktop">
            {isDesktopViewport && <HeaderImage />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default memo(Header);
