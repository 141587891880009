import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";

const ChallengesSkeleton = () => {
  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          marginBottom: "32px",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={150}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={71}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={71}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={35}
          width="100%"
          style={{ borderRadius: "8px" }}
        />
      </div>
      <div
        style={{
          marginBottom: "32px",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={150}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={71}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
      </div>
    </div>
  );
};

export default memo(ChallengesSkeleton);
