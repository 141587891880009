import React, { memo, useCallback, useState } from "react";
import { ReactComponent as FacebookLogo } from "assets/images/icon-facebook.svg";
import Button from "components/Button";
import { pullFacebookPic } from "../messages";
import { sendErrorEmail } from "utils/sendEmail";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import axios from "axios";
import { useRequest } from "hooks";
import { useDispatch } from "react-redux";
import { storeUserPicture } from "store/actions/user";
import { loading } from "components/SocialLoginButtons/messages";

const IOSFacebookLoginButton = ({ onImageChange }) => {
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [isLoading, setIsLoading] = useState(false);

  const onError = useCallback(
    (error) => {
      setIsLoading(false);
      openGeneralErrorSnackbar();
      sendErrorEmail(error, "on IOS facebook login");
    },
    [openGeneralErrorSnackbar]
  );

  const request = useRequest();
  const dispatch = useDispatch();

  const startLogin = useCallback(
    (user) => {
      setIsLoading(false);

      const picture = user.picture?.data?.url;

      dispatch(storeUserPicture(picture));
      onImageChange(picture);
      request.put("/user/picture", { picture });
    },
    [dispatch, onImageChange, request]
  );

  const loadUserData = useCallback(
    async (token) => {
      const url = `https://graph.facebook.com/${token.userId}?fields=id,name,picture.width(100),email&access_token=${token.token}`;
      axios
        .get(url)
        .then((res) => {
          const user = {
            facebookId: res.data.id,
            ...res.data,
          };

          startLogin(user);
        })
        .catch((error) => {
          onError(error);
        });
    },
    [onError, startLogin]
  );

  const getCurrentToken = useCallback(async () => {
    const result = await FacebookLogin.getCurrentAccessToken();

    if (result.accessToken) {
      loadUserData(result.accessToken);
    } else {
      onError();
      // Not logged in.
    }
  }, [loadUserData, onError]);

  const handleLogin = useCallback(async () => {
    const FACEBOOK_PERMISSIONS = ["email"];
    const result = await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    });

    if (result.accessToken && result.accessToken.userId) {
      loadUserData(result.accessToken);
    } else if (result.accessToken && !result.accessToken.userId) {
      getCurrentToken();
    } else {
      onError();
      // Login failed
    }
  }, [getCurrentToken, loadUserData, onError]);

  const onButtonClick = useCallback(() => {
    setIsLoading(true);

    handleLogin();
  }, [handleLogin]);

  return (
    <Button
      size="medium"
      className="social-button facebook font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <FacebookLogo />
      {isLoading ? loading : pullFacebookPic}
    </Button>
  );
};

export default memo(IOSFacebookLoginButton);
