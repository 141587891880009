export const planCreation = {
  planCreation: {
    formTitle: "Mengatur sekarang:",
    startDate: "Kapan mulai",
    endDate: "Kapan selesai",
    startReading: "Bacaan mulai dari kitab: ",
    endReading: "Bacaan selasai di Kitab:",
    feedbacks: {
      isCrossYear: "Anda hanya bisa memilih tanggal dalam tahun yang sama",
      startDateIsBiggerThenEndDate:
        "Pastikan Tanggal mulai dan selesai dengan benar.",
      eadingIntervalIsReverse: "Pastikan Kitab mulai dan selesai dengan benar.",
    },
  },
};
