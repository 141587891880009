import React, { useCallback, useEffect, useState } from "react";

import withChallenge from "HOCs/withChallenge";
import {
  questionsPointsWorth,
  addOption,
  option,
  selectAnswer,
  writeYourQuestion,
  alternativesMsg,
  done,
  provideAnStatement,
  selectAnAnswer,
  editQuestion,
  deleteMsg,
  save,
  areYouSureMessage,
  yesDeleteQuestion,
  questionDeleted,
  questionUpdated,
} from "./messages";
import { Input, TextArea } from "components/Form";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as XIcon } from "assets/icons/icon-x.svg";
import { NumberInput } from "molecules";
import Button from "components/Button";
import classNames from "classnames";
import Wrapper from "./Wrapper";
import { useQuestion } from "contexts/Challenge/Question";
import { useModal } from "contexts/Modal/useModal";
import { areYouSure, changedMyMind } from "pages/Menu/messages";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { sendErrorEmail } from "utils";
import { loading } from "components/SocialLoginButtons/messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useHistory } from "react-router-dom";

const EditQuestion = () => {
  const data = useQuestion();
  const history = useHistory();

  const { request: challenge, questionsPage } = useChallenge();

  const [alternatives, setAlternatives] = useState(data.alternatives);
  const [isSelectingAnswer, setIsSelectingAnswer] = useState(false);
  const [answer, setAnswer] = useState(data.answer);
  const [statement, setStatement] = useState(data.statement);
  const [errors, setErrors] = useState({});
  const [points, setPoints] = useState(data.points);
  const { createModal, closeModal, updateActiveModalProps } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const { openGeneralErrorSnackbar, openCustomSnackbar } =
    useSnackbarNotification();

  useEffect(() => {
    errors.answer &&
      setTimeout(() => setErrors({ ...errors, answer: null }), 1000);
  }, [errors]);

  const onAlternativeChange = (newValue, index) => {
    const newAlternatives = [...alternatives];
    newAlternatives[index] = newValue;
    setAlternatives(newAlternatives);
  };

  const addAlternative = useCallback(() => {
    setAlternatives([...alternatives, `option ${alternatives.length + 1}`]);
  }, [alternatives]);

  const removeAlternative = useCallback(
    (toRemove) => {
      setAlternatives(
        alternatives.filter((alternative) => alternative !== toRemove)
      );
    },
    [alternatives]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        addAlternative();
      }
    },
    [addAlternative]
  );

  const onSelectAlternative = useCallback(
    (alternative) => {
      isSelectingAnswer && setAnswer(alternative);
    },
    [isSelectingAnswer]
  );

  const onRemoveAlternative = useCallback(
    (alternative) => {
      if (alternative === answer) setAnswer(null);
      removeAlternative(alternative);
    },
    [answer, removeAlternative]
  );

  const onStatementChange = useCallback(
    ({ target: { value } }) => {
      setStatement(value);
      setErrors({ ...errors, statement: null });
    },
    [errors]
  );

  const allFieldsAreValid = useCallback(() => {
    let errors = {};

    if (!answer) errors = { ...errors, answer: selectAnAnswer };

    if (!statement) errors = { ...errors, statement: provideAnStatement };

    setErrors(errors);

    if (!answer || !statement) return false;

    return true;
  }, [answer, statement]);

  const onSubmit = useCallback(async () => {
    if (!allFieldsAreValid()) return;

    try {
      setIsLoading(true);

      await challenge.put("questions/update", {
        statement,
        alternatives,
        answer,
        points,
        questionId: data.id,
      });

      history.push(questionsPage);

      openCustomSnackbar({
        variant: "success",
        message: questionUpdated,
      });
    } catch (error) {
      sendErrorEmail(error, "on adding question");
      openGeneralErrorSnackbar();
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allFieldsAreValid,
    alternatives,
    answer,
    challenge,
    points,
    statement,
    data,
  ]);

  const deleteQuestion = useCallback(() => {
    const handleDelete = async () => {
      try {
        setIsLoading(true);

        await challenge.delete(`questions/delete/${data.id}`);

        history.push(questionsPage);

        openCustomSnackbar({
          variant: "success",
          message: questionDeleted,
        });
      } catch (error) {
        sendErrorEmail(error, "on deleting question");
        openGeneralErrorSnackbar();
      } finally {
        closeModal();
        setIsLoading(false);
      }
    };

    createModal({
      title: areYouSure,
      subtitle: areYouSureMessage,
      firstButtonText: changedMyMind,
      secondButtonText: yesDeleteQuestion,
      onFirstButtonClick: closeModal,
      onSecondButtonClick: async () => {
        handleDelete();
        updateActiveModalProps({
          secondButtonText: loading,
          secondButtonDisabled: true,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge, closeModal, createModal, data._id, history, questionsPage]);

  return (
    <Wrapper cardTitle={editQuestion}>
      <div className="add-question form">
        <div className="add-question__title">
          <TextArea
            placeholder={writeYourQuestion}
            rows={2}
            onChange={onStatementChange}
            error={errors?.statement}
            autoFocus={true}
            defaultValue={data.statement}
          />
        </div>
        <div className="add-question__alternatives-title">
          {isSelectingAnswer ? selectAnswer : alternativesMsg}
        </div>
        <div className="add-question__alternatives">
          {alternatives?.map((alternative, index) => (
            <div
              className={classNames("add-question__alternative", {
                isSelectingAnswer,
                active: answer === alternative,
              })}
              key={index}
              onClick={() => onSelectAlternative(alternative)}
            >
              <div className={"add-question__alternative-input"}>
                {isSelectingAnswer ? (
                  <p>{alternative}</p>
                ) : (
                  <Input
                    defaultValue={`${alternative} ${
                      alternative === option ? index + 1 : ""
                    }`}
                    onChange={({ target: { value } }) =>
                      onAlternativeChange(value, index)
                    }
                    onKeyDown={onKeyDown}
                    autoFocus={
                      index === alternatives.length - 1 &&
                      alternatives.length > 1
                    }
                  />
                )}
              </div>
              {alternatives.length > 1 && !isSelectingAnswer && (
                <XIcon
                  width={16}
                  height={16}
                  onClick={() => onRemoveAlternative(alternative)}
                />
              )}
            </div>
          ))}
          {!isSelectingAnswer && (
            <div className={"add-question__alternatives-add"}>
              <button onClick={addAlternative}>{addOption}</button>
            </div>
          )}
        </div>
        {isSelectingAnswer ? (
          <div className="add-question__selecting-alternative">
            <Button
              size="adapted"
              className="button--primary "
              variation="square-rounded"
              theme="primary"
              type="submit"
              onClick={() => setIsSelectingAnswer(false)}
            >
              {done}
            </Button>
          </div>
        ) : (
          <div
            onClick={() => setIsSelectingAnswer(true)}
            className={classNames("add-question__select-alternative", {
              error: !!errors?.answer,
            })}
          >
            <CheckIcon />
            <p>{errors.answer ? errors.answer : selectAnswer}</p>
          </div>
        )}
        {!isSelectingAnswer && (
          <div className="add-question__points">
            <p>{questionsPointsWorth}</p>
            <NumberInput onValueChange={setPoints} defaultValue={points} />
          </div>
        )}
        {!isSelectingAnswer && (
          <div className="add-question__controls">
            <span
              className="add-question__controls-delete"
              onClick={deleteQuestion}
            >
              {deleteMsg}
            </span>

            <Button
              size="small"
              className="button--primary "
              variation="square-rounded"
              theme="primary"
              type="submit"
              disabled={isLoading}
              onClick={onSubmit}
            >
              {isLoading ? loading : save}
            </Button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default withChallenge(EditQuestion);
