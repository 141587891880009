import React from "react";
import { isIOSdevice, isNativePlatform } from "utils/devices";
import IOSFacebookUpdatePicture from "./IOS";
import WebFacebookUpdatePicture from "./Web";

const FacebookUpdatePicture = (props) => {
  if (isIOSdevice && isNativePlatform)
    return <IOSFacebookUpdatePicture {...props} />;

  return <WebFacebookUpdatePicture {...props} />;
};

export default FacebookUpdatePicture;
