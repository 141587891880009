import React, { useEffect } from "react";
import { FormPlan } from "components/FormPlan";
import App from "components/App";
import Header from "components/App/Header";
import { subtitle, title, formChecklistTitle } from "./messages";
import { Navbar } from "components/Navbar";
import { usePlan } from "components/FormPlan/Context";
import MainCard from "components/MainCard";

const EditPlan = () => {
  const { setIsEditing, setAskConfirmation } = usePlan();

  useEffect(() => {
    setIsEditing(true);
    setAskConfirmation(true);
  }, [setIsEditing, setAskConfirmation]);

  return (
    <App>
      <Navbar backTo="/plan" />
      <Header title={title} subtitle={subtitle} color="white" />
      <MainCard title={formChecklistTitle} showBrandFooter>
        <FormPlan />
      </MainCard>
    </App>
  );
};

export default EditPlan;
