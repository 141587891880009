import React from "react";

import { days } from "../messages";

import SmallInput from "components/Form/SmallInput";
import { FormFeedback } from ".";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const QuantityOfDays = () => {
  const {
    quantityOfDays,
    setQuantityOfDays,
    blockedWeekDays,
    setBlockedWeekDays,
  } = useNewChallenge();

  const onChange = ({ target: { value } }) => setQuantityOfDays(Number(value));

  return (
    <div className="quantity-of-days">
      <div className="quantity-of-days-form">
        <div className="form__row">
          <SmallInput
            type="number"
            defaultValue={quantityOfDays}
            name="days"
            onChange={onChange}
            label={days}
            max={1190}
          />
        </div>

        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />

        <ReadingInputs />
        <FormFeedback />
      </div>
    </div>
  );
};

export default QuantityOfDays;
