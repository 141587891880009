export const week = {
  week: {
    everyWeekDay: "Todos os dias da semana",
    weekDays: "Dias da semana",
    sunday: { name: "Domingo", abbreviation: "Dom", letter: "D" },
    monday: { name: "Segunda", abbreviation: "Seg", letter: "S" },
    tuesday: { name: "Terça-feira", abbreviation: "Ter", letter: "T" },
    wednesday: { name: "Quarta-feira", abbreviation: "Qua", letter: "Q" },
    thursday: { name: "Quinta-feira", abbreviation: "Qui", letter: "Q" },
    friday: { name: "Sexta-feira", abbreviation: "Sex", letter: "S" },
    saturday: { name: "Sábado", abbreviation: "Sab", letter: "S" },
    0: "Domingo",
    1: "Segunda",
    2: "Terça-feira",
    3: "Quarta-feira",
    4: "Quinta-feira",
    5: "Sexta-feira",
    6: "Sábado",
  },
};
