import { ReactComponent as NoPlanImg } from "assets/images/no-plan.svg";

import React, { Fragment } from "react";
import { biblicalPlan, noPlanCreated } from "./messages";

const NoPlan = () => {
  return (
    <Fragment>
      <div className="main-card__header">
        <div className="main-card__title">
          <h2 className=" text-centered">{biblicalPlan}</h2>
        </div>
      </div>
      <div className="main-card__body">
        <div className="table">
          <div className="no-plan">
            <p>{noPlanCreated}</p>
            <NoPlanImg />
            {/* {isOwner && (
              //postponed:
              <Button
                size="medium"
                className="button--success "
                variation="square-rounded"
                theme="primary"
                type="submit"
              >
                {createNow}
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NoPlan;
