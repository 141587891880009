import React, { memo, useCallback } from "react";

import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import Button from "components/Button";
import { challengeLaunched, startNow } from "./messages";
import Container from "./Container";
import { scrollIntoView } from "utils/scrollIntoView";

const ChallengeLaunched = () => {
  const goToStartNow = useCallback(() => scrollIntoView("#start-now"), []);

  return (
    <div className="challenge-launched">
      <Container>
        <div className="challenge-launched__container__title fade-up">
          {challengeLaunched}
        </div>
        <div className="challenge-launched__cta fade-up">
          <Button
            size="medium"
            className="button--success  icon-right"
            variation="square-rounded"
            theme="primary"
            onClick={goToStartNow}
            fullWidth={true}
          >
            {startNow}
            <FlagIcon className="path-stroke-white" />
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default memo(ChallengeLaunched);
