/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";

import BiblicalPlan from "utils/BiblicalPlan";
import { useHistory } from "react-router-dom";
import { ChecklistHeader } from "./ChecklistHeader";
import { ChecklistItem } from "./ChecklistItem";
import { findMonthToShow, scrollIntoFirstUnread } from "./utils";
import { ReplanModal } from "./ReplanModal";
import LoadingState from "./LoadingState";
import { usePlan } from "components/FormPlan/Context";
import Footer from "components/Footer";

const biblePlanUtil = new BiblicalPlan();

const checkIfPlanIsComplete = (plan) => !plan.some((day) => !day.read);

const PlanChecklistV2 = ({ planv2 }) => {
  const [activeMonth, setActiveMonth] = useState(0);
  const [planGroupedByMonth, setPlanGroupedByMonth] = useState([]);
  const [foundMonthToShow, setFoundMonthToShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastDayIndex, setLastDayIndex] = useState(0);
  const history = useHistory();
  const checklistRef = useRef(null);
  const {
    setPlanWasReset,
    planWasReset,
    isLoading: planIsLoading,
    isFetchingPlan,
  } = usePlan();

  const numberOfMonthsInPlan = useMemo(
    () => planv2.length && biblePlanUtil.getNumberOfMonthsInPlan(planv2),
    [planv2]
  );
  const planIsComplete = useMemo(() => checkIfPlanIsComplete(planv2), [planv2]);

  useEffect(() => {
    const planIsReadyToShow = !!planGroupedByMonth.length && !isFetchingPlan;
    setIsLoading(!planIsReadyToShow && !foundMonthToShow);
  }, [planGroupedByMonth, foundMonthToShow, isFetchingPlan, planWasReset]);

  useEffect(() => {
    if (foundMonthToShow && !isLoading)
      scrollIntoFirstUnread(checklistRef.current);
  }, [foundMonthToShow, isLoading]);

  const whenFindMonthToShow = useCallback((found) => {
    setActiveMonth(found);
    setFoundMonthToShow(true);
  }, []);

  const whenMonthToShowNotFound = useCallback(() => {
    if (numberOfMonthsInPlan === planGroupedByMonth.length) {
      setActiveMonth(planGroupedByMonth.length - 1);
      return setFoundMonthToShow(true);
    }

    incrementMonth();
  }, [planGroupedByMonth]);

  useEffect(() => {
    const loadedMonthsAreRead =
      !!planGroupedByMonth.length &&
      !planGroupedByMonth.some((month) => month.some((day) => !day.read));

    if (loadedMonthsAreRead && !planIsComplete && !isFetchingPlan) {
      loadMoreThreeMonths();
    } else if (
      !foundMonthToShow &&
      !!planGroupedByMonth.length &&
      !isFetchingPlan
    ) {
      findMonthToShow(
        planGroupedByMonth,
        whenFindMonthToShow,
        whenMonthToShowNotFound
      );
    }
  }, [planGroupedByMonth, isFetchingPlan]);

  const loadMoreThreeMonths = (
    { useCurrentMonths = true, startIndex } = {},
    callback = () => {}
  ) => {
    if (!planv2.length) return;

    if (planv2.length === 1) return setPlanGroupedByMonth([[planv2[0]]]);

    const result = biblePlanUtil.lazyLoadGroupedMonths({
      plan: planv2,
      startIndex: Number.isSafeInteger(startIndex) ? startIndex : lastDayIndex,
      saveStartIndex: (index) => setLastDayIndex(index),
    });
    const newGroup = useCurrentMonths ? [...planGroupedByMonth] : [];
    result.forEach((item) => newGroup.push(item));

    callback(newGroup);

    setPlanGroupedByMonth(newGroup);
  };

  const resetAndLoadMonths = () => {
    if (!planv2.length) return;

    const result = biblePlanUtil.lazyLoadGroupedMonths({
      plan: planv2,
      startIndex: 0,
      saveStartIndex: (index) => setLastDayIndex(index),
    });

    const newGroup = [];
    result.forEach((item) => newGroup.push(item));
    setPlanGroupedByMonth(newGroup);
  };

  useEffect(() => {
    if (planWasReset && !isFetchingPlan) {
      setFoundMonthToShow(false);
      setPlanWasReset(false);
      resetAndLoadMonths();
    }
  }, [planWasReset, isFetchingPlan]);

  useEffect(() => {
    const allMonthsLoaded =
      lastDayIndex === planv2.length - 1 && planv2.length !== 1;

    if (!allMonthsLoaded && !isFetchingPlan && !planIsLoading)
      loadMoreThreeMonths();
  }, [activeMonth, isFetchingPlan, isLoading, planIsLoading]);

  const incrementMonth = useCallback(() => {
    setActiveMonth(activeMonth + 1);
  }, [activeMonth, checklistRef?.current]);

  const decrementMonth = useCallback(() => {
    setActiveMonth(activeMonth - 1);
  }, [activeMonth]);

  if (isLoading) return <LoadingState />;

  if (!planv2.length) {
    history.push("/new");
    return null;
  }

  const onItemChecked = (itemId, isChecked) => {
    const monthsCopy = [...planGroupedByMonth];
    monthsCopy.map((month) => {
      return month.map((item) => {
        if (item._id === itemId) {
          item.read = isChecked;
          return item;
        }
        return item;
      });
    });
  };

  return (
    <>
      <ReplanModal />
      <section className="plan-checklist">
        <ChecklistHeader
          activeMonth={activeMonth}
          planGroupedByMonth={planGroupedByMonth}
          incrementMonth={incrementMonth}
          decrementMonth={decrementMonth}
        />
        <div className="plan-checklist__body">
          <ChecklistElement ref={checklistRef}>
            {planGroupedByMonth[activeMonth]?.map((day, index) => (
              <ChecklistItem
                activeMonth={activeMonth}
                day={day}
                checked={day.read}
                key={day.date + index}
                onChecked={onItemChecked}
              />
            ))}

            <Footer />
          </ChecklistElement>
        </div>
      </section>
    </>
  );
};

const ChecklistElement = React.forwardRef(({ children }, ref) => {
  const checklistRef = useRef(null);

  useEffect(() => {
    if (checklistRef.current) {
      setTimeout(() => {
        if (checklistRef.current) {
          checklistRef.current.style.maxHeight = `${
            window.innerHeight -
            checklistRef.current.getBoundingClientRect().top -
            63
          }px`;
        }
      }, 500);
    }
  }, [checklistRef.current]);

  return (
    <div
      className="checklist"
      onScroll={(e) => {
        if (e.target.scrollTop === 0) {
          return e.target.classList.remove("isScrolling");
        }
        e.target.classList.add("isScrolling");
      }}
      ref={(node) => {
        checklistRef.current = node;

        ref.current = node;
      }}
    >
      {children}
    </div>
  );
});

export default PlanChecklistV2;
