import { IS_PT_LANGUAGE } from "./language";
import { isNativeIOS } from "utils";

export const groupByType = (products) => {
  return products?.reduce((previous, current) => {
    previous[current.type] = previous[current.type] || [];
    previous[current.type].push(current);
    return previous;
  }, Object.create(null));
};

export const getProduct = (product) => {
  return IS_PT_LANGUAGE
    ? product?.filter((item) => item.currency === "brl")[0]
    : product?.filter((item) => item.currency === "usd")[0];
};

export const getAndroidProduct = (callback) => {
  if ("getDigitalGoodsService" in window) {
    window
      .getDigitalGoodsService("https://play.google.com/billing")
      .then((service) => {
        if (service) {
          service
            .getDetails(["bibleplan_subscription_0"])
            .then((itemDetails) => {
              callback({ ...itemDetails[0], digitalGoodsAPIAvailable: true });
            });
        }
      });
  }
};

export const getStripeProduct = (product) => {
  return IS_PT_LANGUAGE
    ? product?.filter((item) => item.currency === "brl")[0]
    : product?.filter((item) => item.currency === "usd")[0];
};

export const getProductTitle = (product) => {
  return IS_PT_LANGUAGE
    ? product?.product.metadata["BR-title"]
    : product?.product.metadata["EN-title"];
};

export const getLocalePrice = (unit) => {
  const locale = IS_PT_LANGUAGE ? "pt-BR" : "en-US";
  const currency = IS_PT_LANGUAGE ? "BRL" : "USD";

  const localePrice = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(unit / 100);

  return localePrice;
};

export const toPrice = ({ cents, currency }) => {
  const locale = currency === "brl" ? "pt-BR" : "en-US";

  if (!cents || !currency) return "";

  const localePrice = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(cents / 100);

  return localePrice;
};

export const getProductPrice = (product) => {
  if (isNativeIOS) return product?.price;

  // using stripe
  return product?.unit_amount && getLocalePrice(product?.unit_amount);
};

export const getIOSProductPrice = (product) => {
  return product?.price;
};

export const getAndroidProductPrice = (product) => {
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: product.price.currency,
  }).format(product.price.value);
};

export const getStripeProductPrice = (product) => {
  return product?.unit_amount && getLocalePrice(product?.unit_amount);
};
