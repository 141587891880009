import React, { createContext, useState, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  wrongPassword,
  emailNotFound,
  finishYourRegistration,
} from "./messages";
import { sendErrorEmail } from "utils/sendEmail";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { useModal } from "contexts/Modal/useModal";
import AuthBox from "components/AuthBox";
import { IS_PT_LANGUAGE, api } from "utils";
import { useJoinChallenge } from "hooks";

const LoginContext = createContext();

const AuthProvider = ({ children, saveUser, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [onLogged, setOnLogged] = useState(null);
  const isLogged = !!user.token;
  const [usingAuthBox, setUsingAuthBox] = useState(false);
  const [toggleAuthBox, setToggleAuthBox] = useState(null);
  const { createModal } = useModal();
  const [challengeToJoin, setChallengeToJoin] = useState(null);

  const {
    openGeneralErrorSnackbar,
    openAccountConfirmedSnackbar,
    showEmailNotFoundSnackbar,
    showEmailRegisteredSnackbar,
    showEmailRegisteredWithSocialSnackbar,
    showInvalidPasswordChangeRequest,
    showExpiredSolicitationSnackbar,
    showSuccessfulPasswordChangeSnackbar,
    openCustomSnackbar,
  } = useSnackbarNotification();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const history = useHistory();

  const joinChallenge = useJoinChallenge();

  const handleOnLogged = (userToken) => {
    if (challengeToJoin) {
      joinChallenge({ userToken, challengeToJoin });
      return setChallengeToJoin(null);
    }

    if (onLogged) {
      onLogged();
      return setOnLogged(null);
    }

    //so that the whole app is reloaded
    window.location.href = "/";
    // history.push("/plan");
  };

  const showWrongPasswordError = useCallback(
    () =>
      setError("password", {
        type: "manual",
        message: wrongPassword,
      }),
    [setError]
  );

  const showEmailNotFound = useCallback(
    () =>
      setError("email", {
        type: "manual",
        message: emailNotFound,
      }),
    [setError]
  );

  const removeLoading = useCallback(() => setIsLoading(false), []);

  const getHeaders = useCallback(() => {
    const bearerToken = user.token ? `Bearer ${user.token}` : "";
    return {
      headers: {
        authorization: bearerToken,
        "Content-Type": "application/json",
        language: IS_PT_LANGUAGE ? "PT" : "EN",
      },
    };
  }, [user]);

  const handleAccountConfirmation = async (token) => {
    setIsLoading(true);

    try {
      await api.post("/auth/confirm_email", { token }, getHeaders());
      history.push("/plan");
      openAccountConfirmedSnackbar();
      setIsLoading(false);
    } catch (err) {
      history.push("/plan");
      openGeneralErrorSnackbar();
      setIsLoading(false);
    }
  };

  const formRegister = async (data) => {
    try {
      setIsLoading(true);
      const response = await api.post("/auth/register", data, getHeaders());

      if (response.data.taken) {
        removeLoading();
        return showEmailRegisteredSnackbar({
          onLinkClick: usingAuthBox ? toggleAuthBox : null,
        });
      }

      if (response.data.usingFacebook) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Facebook");
      }

      if (response.data.usingGoogle) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Google");
      }

      if (response.data.user) {
        setIsSubmitSuccessful(true);
        saveUser(response.data);
        handleOnLogged(response.data.token);
      } else openGeneralErrorSnackbar();

      removeLoading();
    } catch (err) {
      removeLoading();
      openGeneralErrorSnackbar();
      sendErrorEmail(err, `on form register ${JSON.stringify(data)}`);
    }
  };

  const forgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const response = await api.post(
        "/auth/forgot_password",
        data,
        getHeaders()
      );

      if (response.data.notFound) {
        removeLoading();
        return showEmailNotFound();
      }

      if (response.data.usingFacebook) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Facebook");
      }

      if (response.data.usingGoogle) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Google");
      }

      if (response.data.success) {
        setIsSubmitSuccessful(true);
        return removeLoading();
      }

      return openGeneralErrorSnackbar();
    } catch (err) {
      removeLoading();
      openGeneralErrorSnackbar();
      sendErrorEmail(err, `on forgot password ${JSON.stringify(data)}`);
    }
  };

  const resetPassword = async (data) => {
    try {
      setIsLoading(true);
      const response = await api.post(
        "/auth/reset_password",
        data,
        getHeaders()
      );

      if (response.data.notFound) {
        removeLoading();
        return showEmailNotFound();
      }

      if (response.data.usingFacebook) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Facebook");
      }

      if (response.data.usingGoogle) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Google");
      }

      if (response.data.invalidToken) {
        removeLoading();
        return showInvalidPasswordChangeRequest();
      }

      if (response.data.tokenExpired) {
        removeLoading();
        return showExpiredSolicitationSnackbar();
      }

      if (response.data.success) {
        setIsSubmitSuccessful(true);
        removeLoading();
        return showSuccessfulPasswordChangeSnackbar();
      }

      return openGeneralErrorSnackbar();
    } catch (err) {
      removeLoading();
      openGeneralErrorSnackbar();
      sendErrorEmail(err, `on reset password ${JSON.stringify(data)}`);
    }
  };

  const formLogin = async (data) => {
    try {
      setIsLoading(true);
      const response = await api.post("/auth/authenticate", data, getHeaders());

      if (response.data.shouldFinishRegister) {
        removeLoading();
        openCustomSnackbar({
          variant: "success",
          message: finishYourRegistration,
        });
        return history.push("/register?finishRegister=true");
      }

      if (response.data.user) {
        setIsSubmitSuccessful(true);
        removeLoading();
        saveUser(response.data);
        return handleOnLogged(response.data.token);
      }

      if (response.data.notFound) {
        removeLoading();

        return showEmailNotFoundSnackbar({
          onLinkClick: usingAuthBox ? toggleAuthBox : null,
        });
      }

      if (response.data.usingFacebook) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Facebook");
      }

      if (response.data.usingGoogle) {
        removeLoading();
        return showEmailRegisteredWithSocialSnackbar("Google");
      }

      if (response.data.wrongPassword) {
        removeLoading();
        return showWrongPasswordError();
      }

      return openGeneralErrorSnackbar();
    } catch (err) {
      removeLoading();
      openGeneralErrorSnackbar();
      sendErrorEmail(err, `on form login`);
    }
  };

  const socialLogin = async (socialMedia, data, callback) => {
    try {
      const response = await api.post(
        `/auth/${socialMedia}`,
        data,
        getHeaders()
      );

      if (response.data.user) {
        setIsSubmitSuccessful(true);
        removeLoading();
        saveUser(response.data);
        callback(true);
        return handleOnLogged(response.data.token);
      }

      removeLoading();
      openGeneralErrorSnackbar();
    } catch (err) {
      removeLoading();
      callback(false);
      openGeneralErrorSnackbar();
      sendErrorEmail(
        err,
        `on social login social: ${socialMedia} data: ${JSON.stringify(data)}`
      );
    }
  };

  const openAuthModal = ({ title, onLogged, ...config }) => {
    if (onLogged) {
      setOnLogged(() => () => {
        onLogged();
        setUsingAuthBox(false);
      });
    }

    createModal({
      ...config,
      children: (
        <>
          <h4 className="mb24 font-primary fz7 bold">{title}</h4>
          <AuthBox />
        </>
      ),
    });
  };

  const handleFacebookLogin = (data, callback = () => {}) =>
    socialLogin("facebook", data, callback);

  const handleGoogleLogin = (data, callback = () => {}) =>
    socialLogin("google", data, callback);

  const handleAppleLogin = (data, callback = () => {}) =>
    socialLogin("apple", data, callback);

  return (
    <LoginContext.Provider
      value={{
        isLoading,
        handleSubmit,
        register,
        errors,
        handleFacebookLogin,
        handleGoogleLogin,
        handleAppleLogin,
        formLogin,
        formRegister,
        forgotPassword,
        isSubmitSuccessful,
        resetPassword,
        isLogged,
        getHeaders,
        reset,
        handleAccountConfirmation,
        openAuthModal,
        user,
        setUsingAuthBox,
        setToggleAuthBox,
        challengeToJoin,
        setChallengeToJoin,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(LoginContext);
  if (!context) throw new Error("useAuth must be used within a AuthProvider");
  return context;
};
