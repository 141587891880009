export const menu = {
  menu: {
    menu: "Menu",
    challenges: "Challenges",
    subscription: "Subscription",
    cancelSubscription: "Cancel subscription",
    downloadPlanPdf: "Download plan in PDF",
    downloadingPlanPdf: "Downloading...",
    editPlan: "Edit plan",
    shareBibleJourney: "Share the Bible Journey",
    giveFeedback: "Give feedback",
    donate: "Donate",
    logout: "Logout",
    editReminder: "Edit reminder",
    configureReminder: "Configure reminder",
    yourPlan: "Your personal plan",
    challengeInvite: "Challenge invite:",
    createAChallenge: "Create a challenge",
    areYouSure: "Are you sure?",
    willLostProgress:
      "You will lose access to all the challenges you've created and the participants too.",
    yesCancel: "Yes, cancel the subscription",
    changedMyMind: "Changed my mind",
    unsubscribing: "Unsubscribing...",
    successfullyUnsubscribed: "Successfully unsubscribed",
    premiumAccount: "Premium Account",
    language: "Language",
    reminder: "Reminder",
    theme: "Theme",
    dark: "Dark",
    light: "Light",
    toCancel:
      "For canceling the subscription, in your phone go to Settings app > Tap your name > Tap Subscriptions",
    toCancelAndroid:
      "For canceling the subscription, in your phone go to the Google Play app > At the top right, tap the profile icon. > Payments & subscriptions. > Subscriptions.",
    processStarted: "Process started",
    dangerArea: "Danger Area",
    weWillGetInTouch:
      "We'll contact you to let you know the progress of deleting your account. If you're sure of your choice, confirm:",
    payAttention: "Keep an eye on your inbox. We'll send an email to",
    yesImSure: "Yes, I'm sure.",
    askAccountRemoval: "Request account deletion",
  },
};
