import React from "react";

const TextArea = React.forwardRef(
  (
    {
      label,
      placeholder,
      error,
      name,
      onBlur,
      onChange,
      rows,
      defaultValue,
      autoFocus,
    },
    ref
  ) => {
    return (
      <div className="input-wrap  align-left full-width">
        <div className="input-wrap__header">
          <label htmlFor={name} className="input-label primary">
            {label}
          </label>
        </div>
        <div className="input-wrap__input">
          <textarea
            className={`input text-area full-width primary text-left ${
              !!error && "error"
            } `}
            defaultValue={defaultValue}
            name={name}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            rows={rows}
            autoFocus={autoFocus}
          />
        </div>
        {!!error && <span className="input-error">{error}</span>}
      </div>
    );
  }
);

TextArea.defaultProps = {
  label: "",
  onBlur: () => {},
  onChange: () => {},
  placeholder: "",
  error: "",
  name: "",
  rows: 4,
  autoFocus: false,
};

export default TextArea;
