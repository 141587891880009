import classNames from "classnames";
import React, { memo } from "react";

const MenuCard = ({ header, items }) => {
  return (
    <div className="menu__card">
      {header && (
        <div className="menu__card-title">
          <h2>{header.title}</h2>
          {header.Icon}
        </div>
      )}

      {items.map(
        (
          {
            title,
            className,
            pointer,
            description,
            variant,
            onClick,
            onDescriptionClick,
            Component,
          },
          index
        ) => {
          if (
            !title &&
            !className &&
            !description &&
            !variant &&
            !onClick &&
            !Component
          )
            return null;

          return (
            <div
              className={classNames("menu__card-item", {
                [`menu__card-item--${variant}`]: !!variant,
                [`${className}`]: !!className,
                "cursor-pointer": !!pointer,
              })}
              onClick={onClick}
              key={index}
            >
              <div className="menu__card-item-left">
                {title && (
                  <p className="menu__card-item-left__item menu__card-item-left-title">
                    {title}
                  </p>
                )}
                {description && (
                  <p
                    onClick={onDescriptionClick}
                    className="menu__card-item-left__item menu__card-item-left-description"
                  >
                    {description}
                  </p>
                )}
              </div>
              <div className="menu__card-item-right">{Component}</div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default memo(MenuCard);
