import React, { useCallback } from "react";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { editPlan } from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useHistory } from "react-router-dom";

const EditChallengePlan = () => {
  const { viewingChallenge } = useChallenge();

  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(`/challenge/${viewingChallenge}/configs/plan/edit`);
  }, [history, viewingChallenge]);

  return (
    <div className="configurations__item" onClick={onClick}>
      <p>{editPlan}</p>
      <ArrowRight />
    </div>
  );
};

export default EditChallengePlan;
