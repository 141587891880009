import { useAuth } from "contexts/AuthContext";
import { api } from "utils";

const useRequest = () => {
  const { getHeaders } = useAuth();

  const headers = getHeaders();

  return {
    get: async (path) => api.get(`${path}`, headers),
    post: async (path, data) => api.post(`${path}`, data, headers),
    put: async (path, data) => api.put(`${path}`, data, headers),
    delete: async (path) => api.delete(`${path}`, headers),
  };
};

export default useRequest;
