export function startReadBible(readingInterval) {
  return {
    type: "START_READ_BIBLE",
    readingInterval,
  };
}

export function closeModal() {
  return {
    type: "CLOSE_MODAL",
  };
}

export function chooseVersion({ abbr, id }) {
  return {
    type: "CHOOSE_VERSION",
    abbr,
    id,
  };
}

export function storeCurrentReading(month, readingListIndex) {
  return {
    type: "STORE_CURRENT_READING",
    month,
    readingListIndex,
  };
}
