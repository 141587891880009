import { memo, useCallback, useState } from "react";

import userIcon from "assets/icons/user.svg";
import Button from "components/Button";
import { close } from "components/Notifications/Modals/messages";
import { useModal } from "contexts/Modal/useModal";
import { useUser } from "contexts/User";
import {
  UpdatePictureFacebook,
  UpdatePictureGoogle,
} from "molecules/UpdatePicture";

import classNames from "classnames";
import { sendEmail } from "utils";
import {
  areYouSure,
  askAccountRemoval,
  dangerArea,
  payAttention,
  processStarted,
  updatePictureMsg,
  weWillGetInTouch,
  yesImSure,
} from "../messages";

const ModalUserArea = () => {
  const { closeModal } = useModal();
  const { name, email, picture } = useUser();
  const [askedAccountRemoval, setAskedAccountRemoval] = useState(false);
  const [accountRemovalStarted, setAccountRemovalStarted] = useState(false);
  const [updatePicture, setUpdatePicture] = useState(null);

  const onAskAccountRemoval = () => {
    setAskedAccountRemoval(true);
  };

  const handleAccountRemoval = () => {
    sendEmail(
      "Solicitação de exclusão de conta",
      `nome: ${name} \n email: ${email}`
    );
    setAccountRemovalStarted(true);
    setAskedAccountRemoval(false);

    setTimeout(() => {
      closeModal();
    }, 4000);
  };

  return (
    <div className="menu-edit-user">
      <div className="menu__user menu__user--modal">
        <div
          className={classNames("menu__user-pic", {
            "no-pic": !picture && !updatePicture,
          })}
          style={{
            backgroundImage: `url(${updatePicture || picture || userIcon})`,
          }}
        />

        <div className="menu__user-info">
          <p className="menu__user-name">{name}</p>
          <p className="menu__user-email">{email}</p>
        </div>
      </div>

      <div className="menu-edit-user__sections">
        <div className="menu-edit-user__section">
          <h4 className="menu-edit-user__section-title">{updatePictureMsg}</h4>
          <div className="menu-edit-user__buttons">
            <UpdatePictureFacebook
              onImageChange={(image) => setUpdatePicture(image)}
            />
            <UpdatePictureGoogle
              onImageChange={(image) => setUpdatePicture(image)}
            />
          </div>
        </div>

        <div className="menu-edit-user__section">
          <h4 className="menu-edit-user__section-title">
            {askedAccountRemoval
              ? areYouSure
              : accountRemovalStarted
              ? processStarted
              : dangerArea}
          </h4>
          {askedAccountRemoval && (
            <p className="menu-edit-user__section-text">{weWillGetInTouch}</p>
          )}
          {accountRemovalStarted && (
            <p className="menu-edit-user__section-text">
              {payAttention} {email}.
            </p>
          )}

          {askedAccountRemoval ? (
            <Button
              size="medium"
              className="font-regular"
              variation="square-rounded"
              theme="success"
              fullWidth
              onClick={handleAccountRemoval}
            >
              {yesImSure}
            </Button>
          ) : accountRemovalStarted ? null : (
            <Button
              size="medium"
              className="font-regular"
              variation="square-rounded"
              theme="danger"
              fullWidth
              onClick={onAskAccountRemoval}
            >
              {askAccountRemoval}
            </Button>
          )}
        </div>
      </div>

      <div className="menu-edit-user__bottom">
        <span onClick={closeModal}>{close}</span>
      </div>
    </div>
  );
};

const UserArea = () => {
  const { name, email, picture, isLogged } = useUser();
  const { createModal } = useModal();

  const onClick = useCallback(() => {
    createModal({
      children: <ModalUserArea />,
    });
  }, [createModal]);

  if (!isLogged) return null;

  return (
    <div className="menu__user" onClick={onClick}>
      <div
        className={classNames("menu__user-pic", {
          "no-pic": !picture,
        })}
        style={{ backgroundImage: `url(${picture || userIcon})` }}
      />

      <div className="menu__user-info">
        <p className="menu__user-name">{name}</p>
        <p className="menu__user-email">{email}</p>
      </div>
    </div>
  );
};

export default memo(UserArea);
