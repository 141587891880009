export const challenge = {
  challenge: {
    general: "General",
    reading: "Reading",
    title: "Bible Reading Challenges 🎉",
    subtitle:
      "Now you can create a challenge for your group, so they can get motivation to read the Bible 😀",
    firstButton: "Dismiss",
    secondButton: "Create a challenge now",
    inviteReceived: "You received an invite 🎉",
    inviteReceivedDescription:
      "{{name}} has invited you to participate the Bible Reading Challenge <b>{{challengeName}}</b>",
    notPossibleToUndo: "This action cannot be undone!",
    yesDeleteChallenge: "Yes, delete this challenge",
    seeLater: "See later",
    accept: "Accept",
    challengeCreated: "Challenge created!",
    nowLastStep: "Now just one last step",
    challengeNameLabel: "What will be the name of your challenge?",
    readingChallenge: "What will be the reading challenge?",
    challengeNamePlaceholder: "The Best Challenge Ever",
    jump: "Jump",
    next: "Next",
    setTheDates: "Set the dates 📆",
    addTheParticipants: "Add the participants",
    addTheParticipantsPlaceholder: "Write participants' Email",
    creatingChallenge: "Creating the challenge...",
    atLeastTwoEmails: "Please enter at least two emails",
    challenges: "Challenges",
    youAreLeader: "You are the leader:",
    youAreParticipant: "You are a participant:",
    createNewChallenge: "Create a new challenge",
    challengeLaunched: "Challenge launched!",
    emailSent:
      "An email was sent to each of the members. As they log in and start reading, the Ranking will move!",
    ranking: "Ranking",
    sendInvite: "Send invite",
    errorOnFetchingChallenges:
      "We couldn't retrieve your challenges. Please try again later.",
    errorOnFetchingChallenge:
      "We couldn't retrieve your challenge. Please try again later.",
    participants: "participants",
    name: "Name",
    points: "Points",
    point: "Point",
    notAcceptedYet: "No participant has accepted the invitation yet.",
    checkEmail:
      "Tell them to download the app or check the invite on their emails. You can also resend the invite on the settings page.",
    decline: "Decline",
    allowNotifications: "Allow notifications?",
    allowNotificationsText:
      "This way you will get an alert whenever the challenge leader posts a surprise question 🎁",
    later: "Later",
    allow: "Allow notifications",
    notAllow: "Not allow",
    biblicalPlan: "Biblical Plan",
    noPlanCreated: "A biblical plan hasn't been created yet",
    addPlan: "Add plan",
    readingProgress: "Reading Progress",
    createNow: "Create now",
    questions: "Questions",
    question: "Question",
    back: "Back",
    submit: "Submit",
    save: "Save",
    alternatives: "Alternatives",
    correctAnswer: "Correct answer",
    wrongAnswer: "Wrong answer",
    addQuestion: "Add question",
    editQuestion: "Edit question",
    selectAnswer: "Select answer",
    addOption: "Add option",
    option: "Option",
    delete: "Delete",
    cancel: "Cancel",
    launchQuestion: "Submit question",
    areYouSureMessage:
      "Questions already answered by participants will not have their points removed! 😬",
    areYouSure: "Are you sure?",
    yesDeleteQuestion: "Yes, delete this question",
    done: "Done",
    questionLaunched: "Question launched!",
    questionLaunchedMessage:
      "Question Released! Participants will receive a notification alerting them to the question 🏅😀",
    questionsPointsWorth: "How many points is this question worth?",
    writeYourQuestion: "Write your question",
    provideAnStatement: "Provide an statement",
    selectAnAnswer: "Select an answer",
    failedListingQuestions:
      "We couldn't list questions now 😢. Try again later.",
    noQuestionsYet: "There are no questions yet.",
    questionDeleted: "Question deleted",
    questionUpdated: "Question updated",
    questionAlreadyAnswered: "Question already answered",
    configurations: "Configurations",
    editChallengeName: "Edit challenge name",
    editParticipants: "Edit participants",
    editPlan: "Edit plan",
    deleteChallenge: "Delete challenge",
    challengeDeleted: "Challenge deleted",
    dangerousArea: "Dangerous area",
    enterChallengeName: "Please enter a challenge title",
    enterLargerChallengeName: "Please enter a larger challenge title",
    wantToRemove:
      "Do you really want to remove this person from the challenge?",
    yesRemoveThisPerson: "Yes, remove this person",
    inCaseYouChangeYourMind:
      "If you change your mind, this person can be added again.",
    action: "Action",
    remove: "Remove",
    notJoinedYet: "Not joined yet",
    addParticipants: "Add participants",
    add: "Add",
    thePlanWillChange: "The plan will reset for everybody! *",
    yesUpdateIt: "Yes, update the plan",
    planUpdateDisclaimer:
      "Points earned by participants from readings will not be removed.",
    subscribeOrBuy: "Subscribe or buy a new challenge to be able to create it",
    resendInvitation: "Resend invitation",
    invitationSent: "Invitation sent",
  },
};
