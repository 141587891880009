import Header from "components/App/Header";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useQuery } from "hooks";
import React, { Fragment, memo } from "react";
import { challengeLaunched, emailSent } from "./messages";

const RankingPageHeader = () => {
  const [isNewChallenge] = useQuery(["isNewChallenge", "isNewParticipant"]);
  const { currentChallenge } = useChallenge();

  return (
    <Fragment>
      {isNewChallenge ? (
        <Header title={challengeLaunched} subtitle={emailSent} color="white" />
      ) : (
        <Header centered title={currentChallenge.title} color="white" />
      )}
    </Fragment>
  );
};

export default memo(RankingPageHeader);
