import { useSnackbarNotification } from "components/SnackbarNotifications";
import { useAuth } from "contexts/AuthContext";
import { useEffect } from "react";
import { sendErrorEmail } from "utils/sendEmail";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5O3iQUH-UdceQkEFVepcyCdbpLwtoVtE",
  authDomain: "bibleplan-push.firebaseapp.com",
  projectId: "bibleplan-push",
  storageBucket: "bibleplan-push.appspot.com",
  messagingSenderId: "214140120098",
  appId: "1:214140120098:web:80b861460158199f886e7b",
  measurementId: "G-3VZNNYH77H",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const WebGoogleButton = ({ text }) => {
  const { handleGoogleLogin } = useAuth();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();

  function handleCredentialResponse(response) {
    const idToken = response.credential;
    const credential = GoogleAuthProvider.credential(idToken);

    signInWithCredential(auth, credential)
      .then((response) => {
        console.log({
          name: response?.user?.displayName,
          email: response?.user?.email,
          picture: response?.user?.photoURL,
          googleId: response?.user?.providerData?.[0]?.uid,
        });

        handleGoogleLogin(
          {
            name: response?.user?.displayName,
            email: response?.user?.email,
            picture: response?.user?.photoURL,
            googleId: response?.user?.providerData?.[0]?.uid,
          },
          () => {}
        );
      })
      .catch((error) => {
        console.log(error);

        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The credential that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        const errorInfos = {
          errorCode,
          errorMessage,
          email,
          credential,
        };

        openGeneralErrorSnackbar();
        sendErrorEmail(JSON.stringify(errorInfos), `on google login`);
      });
  }

  useEffect(() => {
    const init = () => {
      // pass the callback function created in step 3
      window.google.accounts.id.initialize({
        client_id:
          "311418506011-dfjggo1q0nbs32vk9qp4m705vmu7io5f.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });

      // initiate a pop up to login
      window.google.accounts.id.prompt();

      // render login button
      // use the element ID created in step 2
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButton"),
        { theme: "outline", size: "large", width: "365px" }
      );
    };

    if (window.google) {
      init();
    } else {
      window.onload = function () {
        init();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="googleSignInButton" />;
};

export default WebGoogleButton;
