import { useContext } from "react";
import { ChallengeInvitesContext } from "./Component";

export const useChallengeInvites = () => {
  const context = useContext(ChallengeInvitesContext);
  if (!context)
    throw new Error(
      "useChallengeInvites must be used within a ChallengeInvitesProvider"
    );

  return context;
};
