import React, { memo, useEffect } from "react";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { appUpdated, newUpdateAvailable, update } from "./messages";

const broadcastAvailable = "BroadcastChannel" in window;
const channel = broadcastAvailable
  ? new BroadcastChannel("service-worker-update-check")
  : null;

const UpdateButton = () => {
  return (
    <div
      className="update-button"
      onClick={() => {
        localStorage.setItem("updated", "true");
        window.location.reload();
      }}
    >
      {update}
    </div>
  );
};

const UpdateChecker = () => {
  const { openCustomSnackbar } = useSnackbarNotification();

  useEffect(() => {
    if (localStorage.getItem("updated") === "true") {
      openCustomSnackbar({
        message: appUpdated,
        variant: "success",
        delay: 6000,
      });

      localStorage.removeItem("updated");
    }

    if (broadcastAvailable) {
      channel.onmessage = (message) => {
        if (message.data.promptToReload) {
          openCustomSnackbar({
            message: newUpdateAvailable,
            variant: "success",
            Icon: <UpdateButton />,
            delay: 8000,
          });
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default memo(UpdateChecker);
