export const bibleBooks = {
  bibleBooks: {
    0: {
      name: "Genesis",
      abbreviation: "Gen",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "gn",
        bibleBrain: "GEN",
      },
    },
    genesis: {
      name: "Genesis",
      abbreviation: "Gen",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "gn",
        bibleBrain: "GEN",
      },
    },
    1: {
      name: "Exodus",
      abbreviation: "Ex",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ex",
        bibleBrain: "EXO",
      },
    },
    exodus: {
      name: "Exodus",
      abbreviation: "Ex",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ex",
        bibleBrain: "EXO",
      },
    },
    2: {
      name: "Leviticus",
      abbreviation: "Lev",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "lv",
        bibleBrain: "LEV",
      },
    },
    leviticus: {
      name: "Leviticus",
      abbreviation: "Lev",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "lv",
        bibleBrain: "LEV",
      },
    },
    3: {
      name: "Numbers",
      abbreviation: "Num",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "nm",
        bibleBrain: "NUM",
      },
    },
    numbers: {
      name: "Numbers",
      abbreviation: "Num",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "nm",
        bibleBrain: "NUM",
      },
    },
    4: {
      name: "Deuteronomy",
      abbreviation: "Deut",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "dt",
        bibleBrain: "DEU",
      },
    },
    deuteronomy: {
      name: "Deuteronomy",
      abbreviation: "Deut",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "dt",
        bibleBrain: "DEU",
      },
    },
    5: {
      name: "Joshua",
      abbreviation: "Josh",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "js",
        bibleBrain: "JOS",
      },
    },
    joshua: {
      name: "Joshua",
      abbreviation: "Josh",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "js",
        bibleBrain: "JOS",
      },
    },
    6: {
      name: "Judges",
      abbreviation: "Judg",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jud",
        bibleBrain: "JDG",
      },
    },
    judges: {
      name: "Judges",
      abbreviation: "Judg",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jud",
        bibleBrain: "JDG",
      },
    },
    7: {
      name: "Ruth",
      abbreviation: "Ruth",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "rt",
        bibleBrain: "RUT",
      },
    },
    ruth: {
      name: "Ruth",
      abbreviation: "Ruth",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "rt",
        bibleBrain: "RUT",
      },
    },
    8: {
      name: "1 Samuel",
      abbreviation: "1Sam",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "1sm",
        bibleBrain: "1SA",
      },
    },
    samuel1: {
      name: "1 Samuel",
      abbreviation: "1Sam",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "1sm",
        bibleBrain: "1SA",
      },
    },
    9: {
      name: "2 Samuel",
      abbreviation: "2Sam",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "2sm",
        bibleBrain: "2SA",
      },
    },
    samuel2: {
      name: "2 Samuel",
      abbreviation: "2Sam",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "2sm",
        bibleBrain: "2SA",
      },
    },
    10: {
      name: "1 Kings",
      abbreviation: "1Kings",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "1kgs",
        bibleBrain: "1KI",
      },
    },
    kings1: {
      name: "1 Kings",
      abbreviation: "1Kings",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "1kgs",
        bibleBrain: "1KI",
      },
    },
    11: {
      name: "2 Kings",
      abbreviation: "2Kings",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "2kgs",
        bibleBrain: "2KI",
      },
    },
    kings2: {
      name: "2 Kings",
      abbreviation: "2Kings",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "2kgs",
        bibleBrain: "2KI",
      },
    },
    12: {
      name: "1 Chronicles",
      abbreviation: "1Chron",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "1ch",
        bibleBrain: "1CH",
      },
    },
    chronicles1: {
      name: "1 Chronicles",
      abbreviation: "1Chron",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "1ch",
        bibleBrain: "1CH",
      },
    },
    13: {
      name: "2 Chronicles",
      abbreviation: "2Chron",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "2ch",
        bibleBrain: "2CH",
      },
    },
    chronicles2: {
      name: "2 Chronicles",
      abbreviation: "2Chron",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "2ch",
        bibleBrain: "2CH",
      },
    },
    14: {
      name: "Ezra",
      abbreviation: "Ezra",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ezr",
        bibleBrain: "EZR",
      },
    },
    ezra: {
      name: "Ezra",
      abbreviation: "Ezra",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ezr",
        bibleBrain: "EZR",
      },
    },
    15: {
      name: "Nehemiah",
      abbreviation: "Neh",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ne",
        bibleBrain: "NEH",
      },
    },
    nehemiah: {
      name: "Nehemiah",
      abbreviation: "Neh",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ne",
        bibleBrain: "NEH",
      },
    },
    16: {
      name: "Esther",
      abbreviation: "Est",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "et",
        bibleBrain: "EST",
      },
    },
    esther: {
      name: "Esther",
      abbreviation: "Est",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "et",
        bibleBrain: "EST",
      },
    },
    17: {
      name: "Job",
      abbreviation: "Job",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "job",
        bibleBrain: "JOB",
      },
    },
    job: {
      name: "Job",
      abbreviation: "Job",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "job",
        bibleBrain: "JOB",
      },
    },
    18: {
      name: "Psalms",
      abbreviation: "Ps",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ps",
        bibleBrain: "PSA",
      },
    },
    psalms: {
      name: "Psalms",
      abbreviation: "Ps",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ps",
        bibleBrain: "PSA",
      },
    },
    19: {
      name: "Proverbs",
      abbreviation: "Prov",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "prv",
        bibleBrain: "PRO",
      },
    },
    proverbs: {
      name: "Proverbs",
      abbreviation: "Prov",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "prv",
        bibleBrain: "PRO",
      },
    },
    20: {
      name: "Ecclesiastes",
      abbreviation: "Eccles",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ec",
        bibleBrain: "ECC",
      },
    },
    ecclesiastes: {
      name: "Ecclesiastes",
      abbreviation: "Eccles",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ec",
        bibleBrain: "ECC",
      },
    },
    21: {
      name: "Song of Solomon",
      abbreviation: "Song",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "so",
        bibleBrain: "SNG",
      },
    },
    solomonsongof: {
      name: "Song of Solomon",
      abbreviation: "Song",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "so",
        bibleBrain: "SNG",
      },
    },
    22: {
      name: "Isaiah",
      abbreviation: "Isa",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "is",
        bibleBrain: "ISA",
      },
    },
    isaiah: {
      name: "Isaiah",
      abbreviation: "Isa",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "is",
        bibleBrain: "ISA",
      },
    },
    23: {
      name: "Jeremiah",
      abbreviation: "Jer",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jr",
        bibleBrain: "JER",
      },
    },
    jeremiah: {
      name: "Jeremiah",
      abbreviation: "Jer",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jr",
        bibleBrain: "JER",
      },
    },
    24: {
      name: "Lamentations",
      abbreviation: "Lam",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "lm",
        bibleBrain: "LAM",
      },
    },
    lamentations: {
      name: "Lamentations",
      abbreviation: "Lam",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "lm",
        bibleBrain: "LAM",
      },
    },
    25: {
      name: "Ezekiel",
      abbreviation: "Ezek",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ez",
        bibleBrain: "EZK",
      },
    },
    ezekiel: {
      name: "Ezekiel",
      abbreviation: "Ezek",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ez",
        bibleBrain: "EZK",
      },
    },
    26: {
      name: "Daniel",
      abbreviation: "Dan",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "dn",
        bibleBrain: "DAN",
      },
    },
    daniel: {
      name: "Daniel",
      abbreviation: "Dan",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "dn",
        bibleBrain: "DAN",
      },
    },
    27: {
      name: "Hosea",
      abbreviation: "Hos",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ho",
        bibleBrain: "HOS",
      },
    },
    hosea: {
      name: "Hosea",
      abbreviation: "Hos",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ho",
        bibleBrain: "HOS",
      },
    },
    28: {
      name: "Joel",
      abbreviation: "Joel",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jl",
        bibleBrain: "JOL",
      },
    },
    joel: {
      name: "Joel",
      abbreviation: "Joel",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jl",
        bibleBrain: "JOL",
      },
    },
    29: {
      name: "Amos",
      abbreviation: "Amos",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "am",
        bibleBrain: "AMO",
      },
    },
    amos: {
      name: "Amos",
      abbreviation: "Amos",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "am",
        bibleBrain: "AMO",
      },
    },
    30: {
      name: "Obadiah",
      abbreviation: "Obad",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ob",
        bibleBrain: "OBA",
      },
    },
    obadiah: {
      name: "Obadiah",
      abbreviation: "Obad",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ob",
        bibleBrain: "OBA",
      },
    },
    31: {
      name: "Jonah",
      abbreviation: "Jonah",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jn",
        bibleBrain: "JON",
      },
    },
    jonah: {
      name: "Jonah",
      abbreviation: "Jonah",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "jn",
        bibleBrain: "JON",
      },
    },
    32: {
      name: "Micah",
      abbreviation: "Mic",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "mi",
        bibleBrain: "MIC",
      },
    },
    micah: {
      name: "Micah",
      abbreviation: "Mic",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "mi",
        bibleBrain: "MIC",
      },
    },
    33: {
      name: "Nahum",
      abbreviation: "Nah",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "na",
        bibleBrain: "NAM",
      },
    },
    nahum: {
      name: "Nahum",
      abbreviation: "Nah",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "na",
        bibleBrain: "NAM",
      },
    },
    34: {
      name: "Habakkuk",
      abbreviation: "Hab",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "hk",
        bibleBrain: "HAB",
      },
    },
    habakkuk: {
      name: "Habakkuk",
      abbreviation: "Hab",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "hk",
        bibleBrain: "HAB",
      },
    },
    35: {
      name: "Zephaniah",
      abbreviation: "Zeph",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "zp",
        bibleBrain: "ZEP",
      },
    },
    zephaniah: {
      name: "Zephaniah",
      abbreviation: "Zeph",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "zp",
        bibleBrain: "ZEP",
      },
    },
    36: {
      name: "Haggai",
      abbreviation: "Hag",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "hg",
        bibleBrain: "HAG",
      },
    },
    haggai: {
      name: "Haggai",
      abbreviation: "Hag",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "hg",
        bibleBrain: "HAG",
      },
    },
    37: {
      name: "Zechariah",
      abbreviation: "Zech",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "zc",
        bibleBrain: "ZEC",
      },
    },
    zechariah: {
      name: "Zechariah",
      abbreviation: "Zech",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "zc",
        bibleBrain: "ZEC",
      },
    },
    38: {
      name: "Malachi",
      abbreviation: "Mal",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ml",
        bibleBrain: "MAL",
      },
    },
    malachi: {
      name: "Malachi",
      abbreviation: "Mal",
      testament: "Old testament",
      apiAbbreviation: {
        aBibliaDigital: "ml",
        bibleBrain: "MAL",
      },
    },
    39: {
      name: "Matthew",
      abbreviation: "Matt",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "mt",
        bibleBrain: "MAT",
      },
    },
    matthew: {
      name: "Matthew",
      abbreviation: "Matt",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "mt",
        bibleBrain: "MAT",
      },
    },
    40: {
      name: "Mark",
      abbreviation: "Mark",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "mk",
        bibleBrain: "MRK",
      },
    },
    mark: {
      name: "Mark",
      abbreviation: "Mark",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "mk",
        bibleBrain: "MRK",
      },
    },
    41: {
      name: "Luke",
      abbreviation: "Luke",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "lk",
        bibleBrain: "LUK",
      },
    },
    luke: {
      name: "Luke",
      abbreviation: "Luke",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "lk",
        bibleBrain: "LUK",
      },
    },
    42: {
      name: "John",
      abbreviation: "John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "jo",
        bibleBrain: "JHN",
      },
    },
    john: {
      name: "John",
      abbreviation: "John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "jo",
        bibleBrain: "JHN",
      },
    },
    43: {
      name: "Acts",
      abbreviation: "Acts",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "act",
        bibleBrain: "ACT",
      },
    },
    acts: {
      name: "Acts",
      abbreviation: "Acts",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "act",
        bibleBrain: "ACT",
      },
    },
    44: {
      name: "Romans",
      abbreviation: "Rom",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "rm",
        bibleBrain: "ROM",
      },
    },
    romans: {
      name: "Romans",
      abbreviation: "Rom",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "rm",
        bibleBrain: "ROM",
      },
    },
    45: {
      name: "1 Corinthians",
      abbreviation: "1Cor",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1co",
        bibleBrain: "1CO",
      },
    },
    corinthians1: {
      name: "1 Corinthians",
      abbreviation: "1Cor",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1co",
        bibleBrain: "1CO",
      },
    },
    46: {
      name: "2 Corinthians",
      abbreviation: "2Cor",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2co",
        bibleBrain: "2CO",
      },
    },
    corinthians2: {
      name: "2 Corinthians",
      abbreviation: "2Cor",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2co",
        bibleBrain: "2CO",
      },
    },
    47: {
      name: "Galatians",
      abbreviation: "Gal",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "gl",
        bibleBrain: "GAL",
      },
    },
    galatians: {
      name: "Galatians",
      abbreviation: "Gal",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "gl",
        bibleBrain: "GAL",
      },
    },
    48: {
      name: "Ephesians",
      abbreviation: "Eph",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "eph",
        bibleBrain: "EPH",
      },
    },
    ephesians: {
      name: "Ephesians",
      abbreviation: "Eph",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "eph",
        bibleBrain: "EPH",
      },
    },
    49: {
      name: "Philippians",
      abbreviation: "Phil",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "ph",
        bibleBrain: "PHP",
      },
    },
    philippians: {
      name: "Philippians",
      abbreviation: "Phil",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "ph",
        bibleBrain: "PHP",
      },
    },
    50: {
      name: "Colossians",
      abbreviation: "Col",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "cl",
        bibleBrain: "COL",
      },
    },
    colossians: {
      name: "Colossians",
      abbreviation: "Col",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "cl",
        bibleBrain: "COL",
      },
    },
    51: {
      name: "1 Thessalonians",
      abbreviation: "1Thess",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1ts",
        bibleBrain: "1TH",
      },
    },
    thessalonians1: {
      name: "1 Thessalonians",
      abbreviation: "1Thess",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1ts",
        bibleBrain: "1TH",
      },
    },
    52: {
      name: "2 Thessalonians",
      abbreviation: "2Thess",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2ts",
        bibleBrain: "2TH",
      },
    },
    thessalonians2: {
      name: "2 Thessalonians",
      abbreviation: "2Thess",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2ts",
        bibleBrain: "2TH",
      },
    },
    53: {
      name: "1 Timothy",
      abbreviation: "1Tim",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1tm",
        bibleBrain: "1TI",
      },
    },
    timothy1: {
      name: "1 Timothy",
      abbreviation: "1Tim",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1tm",
        bibleBrain: "1TI",
      },
    },
    54: {
      name: "2 Timothy",
      abbreviation: "2Tim",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2tm",
        bibleBrain: "2TI",
      },
    },
    timothy2: {
      name: "2 Timothy",
      abbreviation: "2Tim",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2tm",
        bibleBrain: "2TI",
      },
    },
    55: {
      name: "Titus",
      abbreviation: "Titus",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "tt",
        bibleBrain: "TIT",
      },
    },
    titus: {
      name: "Titus",
      abbreviation: "Titus",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "tt",
        bibleBrain: "TIT",
      },
    },
    56: {
      name: "Philemon",
      abbreviation: "Philem",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "phm",
        bibleBrain: "PHM",
      },
    },
    philemon: {
      name: "Philemon",
      abbreviation: "Philem",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "phm",
        bibleBrain: "PHM",
      },
    },
    57: {
      name: "Hebrews",
      abbreviation: "Heb",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "hb",
        bibleBrain: "HEB",
      },
    },
    hebrews: {
      name: "Hebrews",
      abbreviation: "Heb",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "hb",
        bibleBrain: "HEB",
      },
    },
    58: {
      name: "James",
      abbreviation: "James",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "jm",
        bibleBrain: "JAS",
      },
    },
    james: {
      name: "James",
      abbreviation: "James",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "jm",
        bibleBrain: "JAS",
      },
    },
    59: {
      name: "1 Peter",
      abbreviation: "1Pet",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1pe",
        bibleBrain: "1PE",
      },
    },
    peter1: {
      name: "1 Peter",
      abbreviation: "1Pet",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1pe",
        bibleBrain: "1PE",
      },
    },
    60: {
      name: "2 Peter",
      abbreviation: "2Pet",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2pe",
        bibleBrain: "2PE",
      },
    },
    peter2: {
      name: "2 Peter",
      abbreviation: "2Pet",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2pe",
        bibleBrain: "2PE",
      },
    },
    61: {
      name: "1 John",
      abbreviation: "1John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1jo",
        bibleBrain: "1JN",
      },
    },
    john1: {
      name: "1 John",
      abbreviation: "1John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "1jo",
        bibleBrain: "1JN",
      },
    },
    62: {
      name: "2 John",
      abbreviation: "2John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2jo",
        bibleBrain: "2JN",
      },
    },
    john2: {
      name: "2 John",
      abbreviation: "2John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "2jo",
        bibleBrain: "2JN",
      },
    },
    63: {
      name: "3 John",
      abbreviation: "3John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "3jo",
        bibleBrain: "3JN",
      },
    },
    john3: {
      name: "3 John",
      abbreviation: "3John",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "3jo",
        bibleBrain: "3JN",
      },
    },
    64: {
      name: "Jude",
      abbreviation: "Jude",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "jd",
        bibleBrain: "JUD",
      },
    },
    jude: {
      name: "Jude",
      abbreviation: "Jude",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "jd",
        bibleBrain: "JUD",
      },
    },
    65: {
      name: "Revelation",
      abbreviation: "Rev",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "re",
        bibleBrain: "REV",
      },
    },
    revelation: {
      name: "Revelation",
      abbreviation: "Rev",
      testament: "New Testament",
      apiAbbreviation: {
        aBibliaDigital: "re",
        bibleBrain: "REV",
      },
    },
  },
};
