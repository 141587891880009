import { menu } from "./menu";
import { bibleBooks } from "./bibleBooks";
import { buttons } from "./buttons";
import { months } from "./months";
import { week } from "./week";
import { planCreation } from "./planCreation";

const messages = {
  id: {
    translations: {
      titles: {
        askDonationsModal:
          "Umpan Balik Anda sangat menolong kami untuk meningkatkan aplikasi ini!  ",
        donateOptionsModal: "Terima Kasih!",
        appHeader: {
          title: "Jadwal Bacaan Alkitab Anda",
          subtitle: "Mulai {{startReading}} Selesai {{endReading}}",
        },
        delayedReadingModal:
          "Anda sedang sibuk-sibuk ya? Bacaan Anda terlambat! ",
        planCreation: {
          title: "Ayo Baca Alkitab! ",
          subtitle:
            "Anda sendiri bisa membuat jadwal bacaan Alkitab yang paling cocok bagi Anda! Tetapkan kitab dan tanggal untuk mulai dan selesai Jadwal Bacaan Alkitab Anda!",
        },
        createdPlan: {
          title: "Anda berhasil membuat jadwal bacaan Anda!",
          subtitle:
            "Mulailah membaca dan jangan khawatir kalau ada bacaan yang terlewatkan! Anda masih bisa mengatur kembali jadwal bacaan Anda!",
        },
        editPlan: {
          title: "Mengatur kembali jadwal bacaan Alkitab Anda",
          subtitle:
            "Susun kembali tanggal titik awal dan titik akhir untuk memperbaharui jadwal bacaan Alkitab Anda!",
        },
        askConfirmationModal: {
          title: "Apakah Anda Yakin akan perubahan jadwal bacaan Anda?",
          subtitle:
            "Jadwal bacaan yang lama akan terganti dengan jadwal yang baru!",
        },
        biblePicker: {
          book: "Book",
          chapter: "Bab",
        },
      },
      messages: {
        bibleReading: {
          read: "Baca baca",
        },

        planChecklist: {
          day: "hari",
        },
        editPlan: {
          formChecklistTitle: "Susun kembali sekarang:",
        },
        delayedReadingModal: {
          subtitle:
            "Tenang saja! Jadwal Bacaan Alkitab Anda bisa diatur kembali dari titik yang Anda berhenti! ",
        },
        askDonationsModal: {
          paragraph1:
            "Bible Journey adalah aplikasi yang diciptakan untuk menjadi berkat untuk semua orang.",
          paragraph2:
            "Dukungan dana Anda sangat penting untuk meningkatkan aplikasi ini dan menjangkau lebih banyak orang! Ayo segera daftarkan diri untuk menjadi donatur kami secara rutin bulanan.",
        },
        donateOptionsModal: {
          subtitle:
            "Pililah jenis dukungan dana yang paling sesuai bagi Anda untuk melanjutkan.",
        },
        menu: {
          downloadPlanPdf: "Jadwal Bacaan Alkitab(JaBa dalam bentuk PDF",
          downloadingPlanPdf: "Mengunduh dalam bentuk PDF",
          editPlan: "Mengunduh...",
          shareBibleJourney: "Silahkan bagikan Bible Journey",
          giveFeedback: "Memberikan umpan balik Anda!",
          donate: "Donasi",
        },
        shareBibleJourney: {
          mobileText:
            "Halo! Bible Journey tersedia di link ini untuk diunduh :)",
          nonMobileText: "Halo! Bible Journey tersedia di link ini :)",
        },
        footer: { madeBy: "Diciptakan oleh" },
        ...planCreation,
        ...week,
        ...months,
        ...bibleBooks,
        ...menu,
      },
      ...buttons,
    },
  },
};

export { messages };
