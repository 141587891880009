import React, {
  Fragment,
  useState,
  memo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import classNames from "classnames";

const Select = ({
  onSelectOption,
  selectedPrefix,
  selected,
  items = [],
  RenderSelected,
  toLeft,
  fullWidth,
  OfflineState,
}) => {
  const [active, setActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selected);
  const selectRef = useRef(null);
  const selectItemsRef = useRef(null);

  const onSelect = useCallback(
    (item) => {
      onSelectOption(item);
      setSelectedItem(item);
      setActive(false);
    },
    [onSelectOption]
  );

  const verifyOutsideClick = useCallback((event) => {
    if (!selectItemsRef.current?.contains(event.target)) {
      setActive(false);
    }
  }, []);

  useEffect(() => {
    if (active) {
      document.addEventListener("click", verifyOutsideClick);
    } else {
      document.removeEventListener("click", verifyOutsideClick);
    }
    return () => {
      document.removeEventListener("click", verifyOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  if (!items.length && OfflineState) {
    return (
      <div className={classNames("select", { toLeft, active })} ref={selectRef}>
        <div className="select__items" ref={selectItemsRef}>
          <div className="select__item">{OfflineState}</div>
        </div>
        <div
          className={classNames("select__control", {
            "full-width": fullWidth,
            noStyle: !!RenderSelected,
          })}
        >
          <div
            className={classNames("select__control-button", {})}
            onClick={() => setActive(!active)}
          >
            {RenderSelected ? (
              RenderSelected
            ) : (
              <div className="select__control-button-text">
                {selectedPrefix} {selectedItem.value}
              </div>
            )}

            <div className="select__control-button-icon">
              <ArrowDown />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames("select", { toLeft, active })} ref={selectRef}>
      <div className="select__items" ref={selectItemsRef}>
        {items.map((item, index) => {
          if (item.title) {
            return (
              <Fragment key={index}>
                <div className="select__item-title">{item.title}</div>
                {item.options.map((option, index) => (
                  <Fragment key={index}>
                    <div
                      key={index}
                      className={classNames("select__item", {
                        active: selectedItem.id === option.id,
                      })}
                      onClick={() => onSelect(option)}
                    >
                      <span className="select__item-label">{option.label}</span>
                      {option.icon ? option.icon : null}
                    </div>
                  </Fragment>
                ))}
              </Fragment>
            );
          } else {
            return (
              <div
                key={index}
                className={classNames("select__item", {
                  active: selectedItem.id === item.id,
                })}
                onClick={() => onSelect(item)}
              >
                <div className="select__item-label">{item.label}</div>
              </div>
            );
          }
        })}
      </div>

      <div
        className={classNames("select__control", {
          "full-width": fullWidth,
          noStyle: !!RenderSelected,
        })}
      >
        <div
          className={classNames("select__control-button", {})}
          onClick={() => setActive(!active)}
        >
          {RenderSelected ? (
            RenderSelected
          ) : (
            <div className="select__control-button-text">
              {selectedPrefix} {selectedItem.value}
            </div>
          )}

          <div className="select__control-button-icon">
            <ArrowDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Select);
