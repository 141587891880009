import React, { useCallback, useMemo, useState } from "react";
import {
  alternativesMsg,
  back,
  submit,
  correctAnswer,
  wrongAnswer,
  questionAlreadyAnswered,
} from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useQuestion } from "contexts/Challenge/Question";
import Button from "components/Button";
import { useUserPoints } from "contexts/Challenge/UserPoints";
import { loading } from "components/SocialLoginButtons/messages";
import { sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import Loader from "./Loader";

const Participant = () => {
  const { viewingChallenge, request: challenge, rankingId } = useChallenge();
  const {
    id: questionId,
    alternatives,
    statement,
    answer,
    setAnswer,
    points,
    isLoadingQuestion,
    isAnswered,
    setIsAnswered,
    showAnswer,
    setShowAnswer,
    selectedAnswer,
    setSelectedAnswer,
  } = useQuestion();
  const [isLoading, setIsLoading] = useState(false);
  const { showPointsText } = useUserPoints();

  const { openGeneralErrorSnackbar, openCustomSnackbar } =
    useSnackbarNotification();

  const backTo = useMemo(() => {
    return `/challenge/${viewingChallenge}/questions`;
  }, [viewingChallenge]);

  const onQuestionClick = useCallback(
    (alternative) => {
      !showAnswer && setSelectedAnswer(alternative);
    },
    [setSelectedAnswer, showAnswer]
  );

  const onAnswerClick = useCallback(async () => {
    if (isAnswered) {
      setShowAnswer(true);

      return openCustomSnackbar({
        variant: "error",
        message: questionAlreadyAnswered,
      });
    }

    try {
      setIsLoading(true);

      const {
        data: { answerIsCorrect, answer, alreadyAnswered },
      } = await challenge.post("question/answer", {
        questionId,
        userAnswer: selectedAnswer,
        rankingId,
      });

      if (alreadyAnswered) {
        setIsLoading(false);
        openCustomSnackbar({
          variant: "error",
          message: questionAlreadyAnswered,
        });

        return;
      }

      setAnswer(answer);

      if (answerIsCorrect) {
        showPointsText({ points, questionPoint: true });
      }
    } catch (err) {
      sendErrorEmail(err, "on answering question");
      openGeneralErrorSnackbar();
    } finally {
      setIsLoading(false);
      setShowAnswer(true);
      setIsAnswered(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, questionId, rankingId, selectedAnswer]);

  const buttonTheme = useMemo(() => {
    return showAnswer
      ? showAnswer && selectedAnswer === answer
        ? "success"
        : "danger"
      : "primary";
  }, [answer, selectedAnswer, showAnswer]);

  const buttonText = useMemo(() => {
    if (showAnswer && selectedAnswer === answer) {
      return correctAnswer;
    }

    if (showAnswer && selectedAnswer !== answer) {
      return wrongAnswer;
    }

    return submit;
  }, [answer, selectedAnswer, showAnswer]);

  if (isLoadingQuestion) return <Loader />;

  return (
    <div className="view-question form">
      <div className="view-question__title">
        <p>{statement}</p>
      </div>
      <div className="view-question__alternatives-title">{alternativesMsg}</div>
      <div className="view-question__alternatives">
        {alternatives?.map((alternative, index) => (
          <div
            className={classNames("view-question__alternative notOwner", {
              isSelected: selectedAnswer === alternative,
              isCorrect: answer === alternative && showAnswer,
              isWrong: answer !== alternative && showAnswer,
            })}
            key={index}
            onClick={() => onQuestionClick(alternative)}
          >
            <p>{alternative}</p>
          </div>
        ))}
      </div>

      <div className="view-question__controls">
        <Link to={backTo} className="new-challenge__controls-jump ">
          {back}
        </Link>
        <Button
          size="medium"
          className="color-white"
          variation="square-rounded"
          theme={buttonTheme}
          type="submit"
          disabled={!selectedAnswer || isLoading}
          onClick={onAnswerClick}
        >
          {isLoading ? loading : buttonText}
        </Button>
      </div>
    </div>
  );
};

export default Participant;
