import React, { memo, useState, useCallback } from "react";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import loading from "assets/images/loading.gif";
import { api, sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { set } from "idb-keyval";

const DownloadBible = ({ version, offline }) => {
  const [isDownloaded, setIsDownloaded] = useState(offline);
  const [isLoading, setIsLoading] = useState(false);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();

  const onDownload = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      setIsLoading(true);
      try {
        const response = await api.get(`/bible/${version.id}`);

        set(`bible-${version.id}`, response.data.bible);
        set(`bible-${version.id}-downloaded`, true);
        setIsDownloaded(true);
      } catch (error) {
        openGeneralErrorSnackbar();
        sendErrorEmail(
          error,
          `on Downloading Bible ${JSON.stringify(version)}`
        );
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [version]
  );

  if (isLoading) {
    return (
      <span className="select__item-icon">
        <img src={loading} alt="loading" />
      </span>
    );
  }

  if (isDownloaded) {
    return (
      <span className="select__item-icon">
        <CheckIcon className="check" />
      </span>
    );
  }
  return (
    <span className="select__item-icon" onClick={onDownload}>
      <DownloadIcon className="download" />
    </span>
  );
};

export default memo(DownloadBible);
