import BibleArray from "./BibleData.js";
import DateHandler from "./DateHandler.js";

export default class Bible {
  constructor() {
    this._bibleArray = new BibleArray();
    this._date = new DateHandler();
  }

  getBibleArray() {
    return this._bibleArray;
  }

  getBibleTotalChapters() {
    return this._totalChapters;
  }

  getBookAmountOfChapters(index) {
    return this.getBibleArray().books[index].chapters;
  }

  getBookIndexByBookAbbreviation(bookAbbreviation) {
    let found = null;

    this._bibleArray.books.forEach((item, index) => {
      if (item.bookAbbreviation === bookAbbreviation) {
        found = index;
      }
    });

    return found;
  }

  getBookNameByAbbreviation(abbreviation) {
    let found = this._bibleArray.books.find(
      (item) => item.bookAbbreviation === abbreviation
    );
    // console.log(abbreviation, found);

    return found ? found.name : "";
  }

  getChaptersInRange(rangeInterval) {
    const chapters = this._bibleArray.books;
    const startBookIndex = Number.isSafeInteger(rangeInterval.startBookIndex)
      ? rangeInterval.startBookIndex
      : this.getBookIndexByBookAbbreviation(rangeInterval.startBook);
    const endBookIndex = Number.isSafeInteger(rangeInterval.endBookIndex)
      ? rangeInterval.endBookIndex
      : this.getBookIndexByBookAbbreviation(rangeInterval.endBook);
    const startReadingChapter = rangeInterval.startChapter;
    const endReadingChapter = rangeInterval.endChapter;

    let books = [];
    chapters.forEach((book, index) => {
      if (index >= startBookIndex && index <= endBookIndex) {
        if (index === endBookIndex && index === startBookIndex) {
          return books.push({
            ...book,
            chapters: endReadingChapter - startReadingChapter + 1,
          });
        }

        if (index === endBookIndex) {
          return books.push({
            ...book,
            chapters: endReadingChapter,
          });
        }

        if (index === startBookIndex) {
          return books.push({
            ...book,
            chapters: book.chapters - startReadingChapter + 1,
          });
        }

        books.push(book);
      }
    });

    const totalChapters = books.reduce(
      (accumulator, currentValue) => accumulator + currentValue.chapters,
      0
    );

    return totalChapters;
  }

  normalizeReadingRange(readingRange) {
    const endBookIndex = Number.isSafeInteger(
      readingRange.readingEnd?.endBookIndex
    )
      ? readingRange.readingEnd?.endBookIndex
      : readingRange.endBookIndex;
    const endChapter = Number.isSafeInteger(readingRange.readingEnd?.endChapter)
      ? readingRange.readingEnd?.endChapter
      : readingRange.endChapter;
    const startBookIndex = Number.isSafeInteger(
      readingRange.readingStart?.startBookIndex
    )
      ? readingRange.readingStart?.startBookIndex
      : readingRange.startBookIndex;
    const startChapter = Number.isSafeInteger(
      readingRange.readingStart?.startChapter
    )
      ? readingRange.readingStart?.startChapter
      : readingRange.startChapter;

    return {
      endBookIndex,
      endChapter,
      startBookIndex,
      startChapter,
    };
  }

  createArrayByReadingRange(readingRange) {
    readingRange = this.normalizeReadingRange(readingRange);

    const chaptersAmount = this.getChaptersInRange(readingRange);
    const startBookIndex = Number.isSafeInteger(readingRange.startBookIndex)
      ? readingRange.startBookIndex
      : this.getBookIndexByBookAbbreviation(readingRange.startBook);

    let readingRangeArray = [];

    let i = 1;
    let chapter = readingRange.startChapter;
    let bookIndex = startBookIndex;

    for (; i <= chaptersAmount; i++, chapter++) {
      readingRangeArray.push({
        book: this._bibleArray.books[bookIndex]?.id,
        chapters: this._bibleArray.books[bookIndex]?.chapters,
        abbreviation: this._bibleArray.books[bookIndex]?.bookAbbreviation,
        name: this._bibleArray.books[bookIndex]?.name,
        chapter,
        bookIndex,
      });

      const inTheEndOfTheChapter =
        this._bibleArray.books[bookIndex]?.chapters === chapter;

      if (inTheEndOfTheChapter) {
        bookIndex++;
        chapter = 0;
      }
    }
    return readingRangeArray;
  }
}
