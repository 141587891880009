import { memo } from "react";
import Skeleton from "react-loading-skeleton";

const ProgressBarLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        marginTop: 16,
        flexDirection: "column",
        height: 35.5,
      }}
    >
      <Skeleton
        className="skeleton"
        style={{
          height: "18px",
          marginBottom: "4px",
          width: "110px",
        }}
      />
      <Skeleton
        className="skeleton"
        style={{
          height: "8px",
        }}
      />
    </div>
  );
};

export default memo(ProgressBarLoading);
