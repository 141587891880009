import React, { useState } from "react";

import { ReactComponent as GoogleLogo } from "assets/images/icon-google.svg";
import Button from "components/Button";
import { pullGooglePic } from "../messages";
import "@codetrix-studio/capacitor-google-auth";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

import { sendErrorEmail } from "utils/sendEmail";
import { useSnackbarNotification } from "components/SnackbarNotifications";

import { useRequest } from "hooks";
import { useDispatch } from "react-redux";
import { storeUserPicture } from "store/actions/user";
import { loading } from "components/SocialLoginButtons/messages";

const IOSGoogleButton = ({ onImageChange = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();

  const request = useRequest();
  const dispatch = useDispatch();

  const onButtonClick = async () => {
    setIsLoading(true);

    const googleUser = await GoogleAuth.signIn(null);

    const user = {
      name: googleUser.name,
      email: googleUser.email,
      picture: googleUser.imageUrl,
      googleId: googleUser.id,
    };

    setIsLoading(true);

    dispatch(storeUserPicture(user.picture));
    onImageChange(user.picture);
    try {
      await request.put("/user/picture", { picture: user.picture });
    } catch (err) {
      setIsLoading(false);
      openGeneralErrorSnackbar();
      sendErrorEmail(JSON.stringify(err), `on google pull image`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      size="medium"
      className="social-button google font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <GoogleLogo />

      {isLoading ? loading : pullGooglePic}
    </Button>
  );
};

export default IOSGoogleButton;
