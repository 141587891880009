import {
  IS_PT_LANGUAGE,
  IS_EN_LANGUAGE,
  IS_ID_LANGUAGE,
  IS_PL_LANGUAGE,
} from "utils";
import { biblesArray } from "./biblesArray";

export const findCurrentVersion = (bibleVersion) => {
  let current;

  if (!bibleVersion.abbr || !bibleVersion.id) {
    let language;
    if (IS_PT_LANGUAGE) {
      language = biblesArray.find((bible) => bible.language === "Português");
    }
    if (IS_EN_LANGUAGE) {
      language = biblesArray.find((bible) => bible.language === "English");
    }
    if (IS_ID_LANGUAGE) {
      language = biblesArray.find((bible) => bible.language === "Indonesia");
    }
    if (IS_PL_LANGUAGE) {
      language = biblesArray.find((bible) => bible.language === "Polsky");
    }

    return language.versions[0];
  }

  biblesArray.forEach((language) => {
    language.versions.find((version) => {
      if (version.id.toLowerCase() === bibleVersion.id.toLowerCase()) {
        current = version;
        return true;
      }
      return false;
    });
  });
  return current;
};
