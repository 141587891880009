import { i18n } from "translate/i18n";

export const biblicalPlan = i18n.t("messages.challenge.biblicalPlan");
export const noPlanCreated = i18n.t("messages.challenge.noPlanCreated");
export const addPlan = i18n.t("messages.challenge.addPlan");
export const readingProgress = i18n.t("messages.challenge.readingProgress");
export const name = i18n.t("messages.challenge.name");
export const createNow = i18n.t("messages.challenge.createNow");

export const delayedReadingModal = i18n.t("titles.delayedReadingModal");
export const subtitleDelayReading = i18n.t(
  "messages.delayedReadingModal.subtitle"
);
export const notNecessary = i18n.t("buttons.delayedReadingModal.firstButton");
export const resetPlan = i18n.t("buttons.delayedReadingModal.secondButton");
