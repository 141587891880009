import React, { useEffect } from "react";
import App from "components/App";
import Header from "components/App/Header";
import LoginCard from "pages/Authentication/Login/LoginCard";
import { or, registerMsg } from "components/AuthBox/messages";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import withChallengeName from "HOCs/withChallengeName";
import Skeleton from "react-loading-skeleton";
import { isInstalled, isNativeIOS } from "utils";
import { loginToJoin, youWereInvited } from "./messages";
import MainCard from "components/MainCard";
import { login } from "pages/Menu/messages";

const InviteLogin = ({ challengeName }) => {
  const { idChallenge } = useParams();
  const { setChallengeToJoin } = useAuth();

  useEffect(() => {
    setChallengeToJoin(idChallenge);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idChallenge]);

  useEffect(() => {
    if (!isInstalled) {
      const url = isNativeIOS
        ? "capacitor://localhost"
        : "intent://bibleplan.online/#Intent;scheme=https;package=xyz.appmaker.ybhmxs;end";

      window.location.replace(url);
    }
  }, []);

  return (
    <App>
      <Header>
        <p>{youWereInvited}</p>
        <p className="bold fz7 mt8 mb8">
          {challengeName ? (
            challengeName
          ) : (
            <Skeleton className="skeleton" height={21} width="80%" />
          )}
        </p>
        <p>{loginToJoin}</p>
      </Header>

      <MainCard title={login} centerTitle showBrandFooter>
        <LoginCard />
        <div className="text-centered mt24">
          <span>
            {or} <Link to={`/c/invite/${idChallenge}`}>{registerMsg}</Link>
          </span>
        </div>
      </MainCard>
    </App>
  );
};

export default withChallengeName(InviteLogin);
