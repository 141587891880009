import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  keepClosed: false,
};

const loginUpdate = handleActions(
  {
    KEEP_MODAL_CLOSED: (state) => ({
      ...state,
      keepClosed: true,
    }),
  },
  INITIAL_STATE
);

export default loginUpdate;
