import React from "react";

const GeneralPrivacyPolicy = () => {
  return (
    <section
      className="section "
      style={{ background: "var(--app-background)", color: "var(--white)" }}
    >
      <div className="container">
        <article className="article-content">
          <h1 className="bold">Política Privacidade</h1>
          <p>
            A sua privacidade é importante para nós. É política do Bible Journey
            respeitar a sua privacidade em relação a qualquer informação sua que
            possamos coletar no site{" "}
            <a href="http://bibleplan.online">Bible Journey</a>, e outros sites
            que possuímos e operamos.
          </p>
          <p>
            Solicitamos informações pessoais apenas quando realmente precisamos
            delas para lhe fornecer um serviço. Fazemo-lo por meios justos e
            legais, com o seu conhecimento e consentimento. Também informamos
            por que estamos coletando e como será usado.
          </p>
          <p>
            Apenas retemos as informações coletadas pelo tempo necessário para
            fornecer o serviço solicitado. Quando armazenamos dados, protegemos
            dentro de meios comercialmente aceitáveis ​​para evitar perdas e
            roubos, bem como acesso, divulgação, cópia, uso ou modificação não
            autorizados.
          </p>
          <p>
            Não compartilhamos informações de identificação pessoal publicamente
            ou com terceiros, exceto quando exigido por lei.
          </p>
          <p>
            O nosso site pode ter links para sites externos que não são operados
            por nós. Esteja ciente de que não temos controle sobre o conteúdo e
            práticas desses sites e não podemos aceitar responsabilidade por
            suas respectivas{" "}
            <a
              href="https://politicaprivacidade.com"
              target="_BLANK"
              rel="noopener noreferrer"
            >
              políticas de privacidade
            </a>
            .
          </p>
          <p>
            Você é livre para recusar a nossa solicitação de informações
            pessoais, entendendo que talvez não possamos fornecer alguns dos
            serviços desejados.
          </p>
          <p>
            O uso continuado de nosso site será considerado como aceitação de
            nossas práticas em torno de privacidade e informações pessoais. Se
            você tiver alguma dúvida sobre como lidamos com dados do usuário e
            informações pessoais, entre em contacto connosco.
          </p>
          <h2>Política de Cookies Bible Journey</h2> <h3>O que são cookies?</h3>
          <p>
            Como é prática comum em quase todos os sites profissionais, este
            site usa cookies, que são pequenos arquivos baixados no seu
            computador, para melhorar sua experiência. Esta página descreve
            quais informações eles coletam, como as usamos e por que às vezes
            precisamos armazenar esses cookies. Também compartilharemos como
            você pode impedir que esses cookies sejam armazenados, no entanto,
            isso pode fazer o downgrade ou 'quebrar' certos elementos da
            funcionalidade do site.
          </p>
          <h3>Como usamos os cookies?</h3>
          <p>
            Utilizamos cookies por vários motivos, detalhados abaixo.
            Infelizmente, na maioria dos casos, não existem opções padrão do
            setor para desativar os cookies sem desativar completamente a
            funcionalidade e os recursos que eles adicionam a este site. É
            recomendável que você deixe todos os cookies se não tiver certeza se
            precisa ou não deles, caso sejam usados ​​para fornecer um serviço
            que você usa.
          </p>
          <h3>Desativar cookies</h3>
          <p>
            Você pode impedir a configuração de cookies ajustando as
            configurações do seu navegador (consulte a Ajuda do navegador para
            saber como fazer isso). Esteja ciente de que a desativação de
            cookies afetará a funcionalidade deste e de muitos outros sites que
            você visita. A desativação de cookies geralmente resultará na
            desativação de determinadas funcionalidades e recursos deste site.
            Portanto, é recomendável que você não desative os cookies.
          </p>
          <h3>Cookies que definimos</h3>
          <ul>
            <li>
              Cookies relacionados à conta
              <br />
              <br /> Se você criar uma conta connosco, usaremos cookies para o
              gerenciamento do processo de inscrição e administração geral.
              Esses cookies geralmente serão excluídos quando você sair do
              sistema, porém, em alguns casos, eles poderão permanecer
              posteriormente para lembrar as preferências do seu site ao sair.
              <br />
              <br />
            </li>
            <li>
              Cookies relacionados ao login
              <br />
              <br /> Utilizamos cookies quando você está logado, para que
              possamos lembrar dessa ação. Isso evita que você precise fazer
              login sempre que visitar uma nova página. Esses cookies são
              normalmente removidos ou limpos quando você efetua logout para
              garantir que você possa acessar apenas a recursos e áreas
              restritas ao efetuar login.
              <br />
              <br />
            </li>
            <li>
              Cookies relacionados a boletins por e-mail
              <br />
              <br /> Este site oferece serviços de assinatura de boletim
              informativo ou e-mail e os cookies podem ser usados ​​para lembrar
              se você já está registrado e se deve mostrar determinadas
              notificações válidas apenas para usuários inscritos / não
              inscritos.
              <br />
              <br />
            </li>
            <li>
              Pedidos processando cookies relacionados
              <br />
              <br /> Este site oferece facilidades de comércio eletrônico ou
              pagamento e alguns cookies são essenciais para garantir que seu
              pedido seja lembrado entre as páginas, para que possamos
              processá-lo adequadamente.
              <br />
              <br />
            </li>
            <li>
              Cookies relacionados a pesquisas
              <br />
              <br /> Periodicamente, oferecemos pesquisas e questionários para
              fornecer informações interessantes, ferramentas úteis ou para
              entender nossa base de usuários com mais precisão. Essas pesquisas
              podem usar cookies para lembrar quem já participou numa pesquisa
              ou para fornecer resultados precisos após a alteração das páginas.
              <br />
              <br />
            </li>
            <li>
              Cookies relacionados a formulários
              <br />
              <br /> Quando você envia dados por meio de um formulário como os
              encontrados nas páginas de contacto ou nos formulários de
              comentários, os cookies podem ser configurados para lembrar os
              detalhes do usuário para correspondência futura.
              <br />
              <br />
            </li>
            <li>
              Cookies de preferências do site
              <br />
              <br /> Para proporcionar uma ótima experiência neste site,
              fornecemos a funcionalidade para definir suas preferências de como
              esse site é executado quando você o usa. Para lembrar suas
              preferências, precisamos definir cookies para que essas
              informações possam ser chamadas sempre que você interagir com uma
              página for afetada por suas preferências.
              <br />
            </li>
          </ul>
          <h3>Cookies de Terceiros</h3>
          <p>
            Em alguns casos especiais, também usamos cookies fornecidos por
            terceiros confiáveis. A seção a seguir detalha quais cookies de
            terceiros você pode encontrar através deste site.
          </p>
          <ul>
            <li>
              Este site usa o Google Analytics, que é uma das soluções de
              análise mais difundidas e confiáveis ​​da Web, para nos ajudar a
              entender como você usa o site e como podemos melhorar sua
              experiência. Esses cookies podem rastrear itens como quanto tempo
              você gasta no site e as páginas visitadas, para que possamos
              continuar produzindo conteúdo atraente.
            </li>
          </ul>
          <p>
            Para mais informações sobre cookies do Google Analytics, consulte a
            página oficial do Google Analytics.
          </p>
          <ul>
            <li>
              As análises de terceiros são usadas para rastrear e medir o uso
              deste site, para que possamos continuar produzindo conteúdo
              atrativo. Esses cookies podem rastrear itens como o tempo que você
              passa no site ou as páginas visitadas, o que nos ajuda a entender
              como podemos melhorar o site para você.
            </li>
            <li>
              Periodicamente, testamos novos recursos e fazemos alterações
              subtis na maneira como o site se apresenta. Quando ainda estamos
              testando novos recursos, esses cookies podem ser usados ​​para
              garantir que você receba uma experiência consistente enquanto
              estiver no site, enquanto entendemos quais otimizações os nossos
              usuários mais apreciam.
            </li>
            <li>
              À medida que vendemos produtos, é importante entendermos as
              estatísticas sobre quantos visitantes de nosso site realmente
              compram e, portanto, esse é o tipo de dados que esses cookies
              rastrearão. Isso é importante para você, pois significa que
              podemos fazer previsões de negócios com precisão que nos permitem
              analizar nossos custos de publicidade e produtos para garantir o
              melhor preço possível.
            </li>
            <li>
              O serviço Google AdSense que usamos para veicular publicidade usa
              um cookie DoubleClick para veicular anúncios mais relevantes em
              toda a Web e limitar o número de vezes que um determinado anúncio
              é exibido para você.
              <br /> Para mais informações sobre o Google AdSense, consulte as
              FAQs oficiais sobre privacidade do Google AdSense.
            </li>
            <li>
              Utilizamos anúncios para compensar os custos de funcionamento
              deste site e fornecer financiamento para futuros desenvolvimentos.
              Os cookies de publicidade comportamental usados ​​por este site
              foram projetados para garantir que você forneça os anúncios mais
              relevantes sempre que possível, rastreando anonimamente seus
              interesses e apresentando coisas semelhantes que possam ser do seu
              interesse.
            </li>
            <li>
              Vários parceiros anunciam em nosso nome e os cookies de
              rastreamento de afiliados simplesmente nos permitem ver se nossos
              clientes acessaram o site através de um dos sites de nossos
              parceiros, para que possamos creditá-los adequadamente e, quando
              aplicável, permitir que nossos parceiros afiliados ofereçam
              qualquer promoção que pode fornecê-lo para fazer uma compra.
            </li>
          </ul>
          <h3>Compromisso do Usuário</h3>
          <p>
            O usuário se compromete a fazer uso adequado dos conteúdos e da
            informação que o Bible Journey oferece no site e com caráter
            enunciativo, mas não limitativo:
          </p>
          <ul>
            <li>
              A) Não se envolver em atividades que sejam ilegais ou contrárias à
              boa fé a à ordem pública;
            </li>
            <li>
              B) Não difundir propaganda ou conteúdo de natureza racista,
              xenofóbica, ou casas de apostas online (ex.:
              <a href="https://ondeapostar.pt/review/betano/"> Betano</a>),
              jogos de sorte e azar, qualquer tipo de pornografia ilegal, de
              apologia ao terrorismo ou contra os direitos humanos;
            </li>
            <li>
              C) Não causar danos aos sistemas físicos (hardwares) e lógicos
              (softwares) do Bible Journey, de seus fornecedores ou terceiros,
              para introduzir ou disseminar vírus informáticos ou quaisquer
              outros sistemas de hardware ou software que sejam capazes de
              causar danos anteriormente mencionados.
            </li>
          </ul>
          <h3>Uso de dados</h3>
          <p>
            Quando você cria uma conta com seu e-mail no Bible Journey, como
            parte do processo de criação de identificação única sua, coletamos
            as informações pessoais que você nos dá tais como: nome, e-mail e
            senha. Quando você acessa nosso site, também recebemos
            automaticamente o protocolo de internet do seu computador, endereço
            de IP, a fim de obter informações que nos ajudam a aprender sobre
            seu navegador e sistema operacional. Estas informações ficam salvas
            com segurança em nossa base da dados.
          </p>
          <p>
            Quando você cria uma conta no Bible Journey a partir da solução de
            login do facebook, como parte do processo de criação de
            identificação única sua e para personalização de sua experiência,
            coletamos as seguintes informações: nome, e-mail, foto e o Id de seu
            usuário no Facebook.Estas informações ficam salvas com segurança em
            nossa base da dados.
          </p>
          <p>
            Quando você cria uma conta no Bible Journey a partir da solução de
            login do Google, como parte do processo de criação de identificação
            única sua e para personalização de sua experiência, coletamos as
            seguintes informações: nome, e-mail, foto e o Id de seu usuário no
            Google. Estas informações ficam salvas com segurança em nossa base
            da dados.
          </p>
          <p>
            Email Marketing será realizado apenas caso você permita. Nestes
            emails você poderá receber notícia sobre o Bible Journey, novos
            produtos e outras atualizações.
          </p>
          <h3>Como excluir seus dados</h3>
          <p>
            Para excluir seus dados armazenados na base de dados do Bible
            Journey, envie um e-mail para thebibleplan@gmail.com.
          </p>
          <h3>Mais informações</h3>
          <p>
            Esperemos que esteja esclarecido e, como mencionado anteriormente,
            se houver algo que você não tem certeza se precisa ou não,
            geralmente é mais seguro deixar os cookies ativados, caso interaja
            com um dos recursos que você usa em nosso site.
          </p>
          <h1 className="bold">Termos de uso</h1>
          <p>
            Ao acessar ao site{" "}
            <a href="http://bibleplan.online">Bible Journey</a>, concorda em
            cumprir estes termos de serviço, todas as leis e regulamentos
            aplicáveis ​​e concorda que é responsável pelo cumprimento de todas
            as leis locais aplicáveis. Se você não concordar com algum desses
            termos, está proibido de usar ou acessar este site. Os materiais
            contidos neste site são protegidos pelas leis de direitos autorais e
            marcas comerciais aplicáveis.
          </p>
          <h2>1. Uso de Licença</h2>
          <p>
            É concedida permissão para baixar temporariamente uma cópia dos
            materiais (informações ou software) no site Bible Journey , apenas
            para visualização transitória pessoal e não comercial. Esta é a
            concessão de uma licença, não uma transferência de título e, sob
            esta licença, você não pode:&nbsp;
          </p>
          <ol>
            <li>modificar ou copiar os materiais;&nbsp; </li>
            <li>
              usar os materiais para qualquer finalidade comercial ou para
              exibição pública (comercial ou não comercial);&nbsp;
            </li>
            <li>
              tentar descompilar ou fazer engenharia reversa de qualquer
              software contido no site Bible Journey;&nbsp;
            </li>
            <li>
              remover quaisquer direitos autorais ou outras notações de
              propriedade dos materiais; ou&nbsp;
            </li>
            <li>
              transferir os materiais para outra pessoa ou 'espelhe' os
              materiais em qualquer outro servidor.
            </li>
          </ol>
          <p>
            Esta licença será automaticamente rescindida se você violar alguma
            dessas restrições e poderá ser rescindida por Bible Journey a
            qualquer momento. Ao encerrar a visualização desses materiais ou
            após o término desta licença, você deve apagar todos os materiais
            baixados em sua posse, seja em formato eletrónico ou impresso.
          </p>
          <h2>2. Isenção de responsabilidade</h2>
          <ol>
            <li>
              Os materiais no site da Bible Journey são fornecidos 'como estão'.
              Bible Journey não oferece garantias, expressas ou implícitas, e,
              por este meio, isenta e nega todas as outras garantias, incluindo,
              sem limitação, garantias implícitas ou condições de
              comercialização, adequação a um fim específico ou não violação de
              propriedade intelectual ou outra violação de direitos.
            </li>
            <li>
              Além disso, o Bible Journey não garante ou faz qualquer
              representação relativa à precisão, aos resultados prováveis ​​ou à
              confiabilidade do uso dos materiais em seu site ou de outra forma
              relacionado a esses materiais ou em sites vinculados a este site.
            </li>
          </ol>
          <h2>3. Limitações</h2>
          <p>
            Em nenhum caso o Bible Journey ou seus fornecedores serão
            responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos
            por perda de dados ou lucro ou devido a interrupção dos negócios)
            decorrentes do uso ou da incapacidade de usar os materiais em Bible
            Journey, mesmo que Bible Journey ou um representante autorizado da
            Bible Journey tenha sido notificado oralmente ou por escrito da
            possibilidade de tais danos. Como algumas jurisdições não permitem
            limitações em garantias implícitas, ou limitações de
            responsabilidade por danos conseqüentes ou incidentais, essas
            limitações podem não se aplicar a você.
          </p>
          <h2>4. Precisão dos materiais</h2>
          <p>
            Os materiais exibidos no site da Bible Journey podem incluir erros
            técnicos, tipográficos ou fotográficos. Bible Journey não garante
            que qualquer material em seu site seja preciso, completo ou atual.
            Bible Journey pode fazer alterações nos materiais contidos em seu
            site a qualquer momento, sem aviso prévio. No entanto, Bible Journey
            não se compromete a atualizar os materiais.
          </p>
          <h2>5. Links</h2>
          <p>
            O Bible Journey não analisou todos os sites vinculados ao seu site e
            não é responsável pelo conteúdo de nenhum site vinculado. A inclusão
            de qualquer link não implica endosso por Bible Journey do site. O
            uso de qualquer site vinculado é por conta e risco do usuário.
          </p>
          <p /> <h3>Modificações</h3>
          <p>
            O Bible Journey pode revisar estes termos de serviço do site a
            qualquer momento, sem aviso prévio. Ao usar este site, você concorda
            em ficar vinculado à versão atual desses termos de serviço.
          </p>
          <h3>Lei aplicável</h3>
          <p>
            Estes termos e condições são regidos e interpretados de acordo com
            as leis do Bible Journey e você se submete irrevogavelmente à
            jurisdição exclusiva dos tribunais naquele estado ou localidade.
          </p>
          <p>
            Esta política de privacidade e os termos de uso são efetivos a
            partir de <strong>August</strong>/<strong>2021</strong>.
          </p>
        </article>
      </div>
    </section>
  );
};

export default GeneralPrivacyPolicy;
