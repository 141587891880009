import moment from "moment";

const initialDate = moment();

const INICIAL_STATE = {
  remindLater: false,
  remindLaterDate: [
    initialDate.year(),
    initialDate.month(),
    initialDate.date(),
  ],
  donated: false,
  donationOptionsModalIsOpen: false,
};

export default function donation(state = INICIAL_STATE, action) {
  if (action.type === "STORE_REMIND_LATER") {
    return {
      ...state,
      remindLater: true,
    };
  }

  if (action.type === "STORE_REMIND_LATER_DATE") {
    return {
      ...state,
      remindLaterDate: action.remindLaterDate,
    };
  }

  if (action.type === "STORE_DONATED") {
    return {
      ...state,
      donated: action.donated,
    };
  }

  if (action.type === "TOGGLE_DONATIONS_OPTIONS_MODAL") {
    return {
      ...state,
      donationOptionsModalIsOpen: action.donationOptionsModalIsOpen,
    };
  }

  return state;
}
