import React from "react";

import { creatingPlan, updatePlan, days, createPlan } from "../messages";

import { usePlan } from "../Context";

import Button from "components/Button";
import SmallInput from "components/Form/SmallInput";
import { FormFeedback } from ".";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import DatesInputs from "../components/DateInputs";

const QuantityOfDays = () => {
  const {
    isLoading,
    isEditing,
    quantityOfDays,
    setQuantityOfDays,
    createPlaByQuantityOfDays,
    allInputsAreValid,
    blockedWeekDays,
    setBlockedWeekDays,
  } = usePlan();

  const onChange = ({ target: { value } }) => setQuantityOfDays(Number(value));

  const onClick = () => {
    if (!allInputsAreValid({ validateDays: false })) return;
    createPlaByQuantityOfDays();
  };

  return (
    <div className="quantity-of-days">
      <div className="quantity-of-days-form">
        <div className="form__row">
          <SmallInput
            type="number"
            defaultValue={quantityOfDays}
            name="days"
            onChange={onChange}
            label={days}
            max={1190}
          />
        </div>
        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />

        <DatesInputs withEndDate={false} />

        <ReadingInputs />
        <FormFeedback />
        <Button onClick={onClick} className="button button--primary  ">
          {isLoading ? creatingPlan : isEditing ? updatePlan : createPlan}
        </Button>
      </div>
    </div>
  );
};

export default QuantityOfDays;
