import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingState = () => {
  return (
    <div>
      <Skeleton
        className="skeleton"
        height={38}
        width={"100%"}
        style={{
          marginBottom: "12px",
          borderRadius: "8px",
        }}
      />
      <Skeleton
        className="skeleton"
        height={38}
        width={"100%"}
        style={{
          marginBottom: "12px",
          borderRadius: "8px",
        }}
      />
      <Skeleton
        className="skeleton"
        height={38}
        width={"100%"}
        style={{
          marginBottom: "12px",
          borderRadius: "8px",
        }}
      />
      <Skeleton
        className="skeleton"
        height={38}
        width={"100%"}
        style={{
          marginBottom: "12px",
          borderRadius: "8px",
        }}
      />
    </div>
  );
};

export default LoadingState;
