import { i18n } from "translate/i18n";

export const weekDays = i18n.t(`messages.week.weekDays`);
export const sundayLetter = i18n.t(`messages.week.sunday.letter`);
export const mondayLetter = i18n.t(`messages.week.monday.letter`);
export const tuesdayLetter = i18n.t(`messages.week.tuesday.letter`);
export const wednesdayLetter = i18n.t(`messages.week.wednesday.letter`);
export const thursdayLetter = i18n.t(`messages.week.thursday.letter`);
export const fridayLetter = i18n.t(`messages.week.friday.letter`);
export const saturdayLetter = i18n.t(`messages.week.saturday.letter`);

export const buildWeekDaysLabel = (blockedWeekDays) => {
  if (blockedWeekDays.length === 0) return i18n.t(`messages.week.everyWeekDay`);

  let days = [0, 1, 2, 3, 4, 5, 6];

  days = days.filter(
    (weekDay) => !blockedWeekDays.some((blocked) => blocked === weekDay)
  );

  return days.map((day, index, array) => {
    const isLastDay = array.length - 1 === index;
    const weekAbbreviation = getWeekAbbreviationFromIndex(day);
    return `${weekAbbreviation}${isLastDay ? "" : ", "}`;
  });
};

export const getWeekAbbreviationFromIndex = (index) => {
  switch (index) {
    case 0:
      return i18n.t(`messages.week.sunday.abbreviation`);
    case 1:
      return i18n.t(`messages.week.monday.abbreviation`);
    case 2:
      return i18n.t(`messages.week.tuesday.abbreviation`);
    case 3:
      return i18n.t(`messages.week.wednesday.abbreviation`);
    case 4:
      return i18n.t(`messages.week.thursday.abbreviation`);
    case 5:
      return i18n.t(`messages.week.friday.abbreviation`);
    case 6:
      return i18n.t(`messages.week.saturday.abbreviation`);
    default:
      return "";
  }
};
