import React, { useState } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import aphroditeInterface from "react-with-styles-interface-aphrodite";
import DefaultTheme from "react-dates/lib/theme/DefaultTheme";

import { IS_PT_LANGUAGE, IS_ID_LANGUAGE, IS_PL_LANGUAGE } from "utils";

if (IS_PT_LANGUAGE) require("moment/locale/pt-br");
if (IS_ID_LANGUAGE) require("moment/locale/id");
if (IS_PL_LANGUAGE) require("moment/locale/pl");

let language = "en";

if (IS_PT_LANGUAGE) language = "pt-br";
if (IS_ID_LANGUAGE) language = "id";
if (IS_PL_LANGUAGE) language = "pl";

ThemedStyleSheet.registerInterface(aphroditeInterface);

ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      highlighted: {
        backgroundColor: "#6A2D56",
        backgroundColor_active: "#6A2D56",
        backgroundColor_hover: "#522242",
      },
      selected: {
        backgroundColor: "#6A2D56",
        backgroundColor_active: "#6A2D56",
        backgroundColor_hover: "#6A2D56",
        borderColor: "#6A2D56",
        borderColor_active: "#6A2D56",
        borderColor_hover: "#6A2D56",
        color: "#fff",
        color_active: "#fff",
        color_hover: "#fff",
      },
    },
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderRadius: 40,
        borderTop: "1px solid #AD9CA7",
        borderRight: "1px solid #AD9CA7",
        borderBottom: "1px solid #AD9CA7",
        borderLeft: "1px solid #AD9CA7",
        outlineFocused: 0,
        borderFocused: "1px solid #AD9CA7",
        borderTopFocused: "1px solid #AD9CA7",
        borderLeftFocused: "1px solid #AD9CA7",
        borderBottomFocused: "1px solid #AD9CA7",
        borderRightFocused: "1px solid #AD9CA7",
      },
      pickerInput: {
        ...DefaultTheme.reactDates.border.pickerInput,
        borderRadius: 30,
      },
    },
    spacing: {
      ...DefaultTheme.reactDates.spacing,
      displayTextPaddingTop: 14,
      displayTextPaddingBottom: 14,
      displayTextPaddingLeft: 20,
      displayTextPaddingRight: 20,
    },
    sizing: {
      ...DefaultTheme.reactDates.sizing,
      inputWidth: 123,
    },
    font: {
      ...DefaultTheme.reactDates.font,
      size: 12,
      input: {
        ...DefaultTheme.reactDates.font.input,
        size: 12,
        lineHeight: "14px",
        textAlign: "center",
      },
    },
  },
});

const DatePicker = ({
  onDateChange,
  onBeforeTodayInitialDate,
  onAfterTodayInitialDate,
  date,
  blockedWeekDays,
}) => {
  const [focused, setFocused] = useState(false);

  const whenDateChange = (newDate) => {
    onDateChange(newDate);

    const difference = newDate.diff(moment(), "days");
    const isBeforeToday = difference < 0;

    if (isBeforeToday && onBeforeTodayInitialDate) {
      onBeforeTodayInitialDate();
    }

    if (!isBeforeToday && onAfterTodayInitialDate) {
      onAfterTodayInitialDate();
    }
  };

  const onFocusChange = ({ focused }) => {
    setFocused(focused);
  };

  return (
    <SingleDatePicker
      id="date_input"
      date={date}
      focused={focused}
      isOutsideRange={() => false}
      onDateChange={whenDateChange}
      onFocusChange={onFocusChange}
      numberOfMonths={1}
      disableScroll={true}
      readOnly={true}
      withPortal={true}
      hideKeyboardShortcutsPanel={true}
      isDayBlocked={(day) =>
        blockedWeekDays.some((weekDay) => day.weekday() === weekDay)
      }
      renderMonthElement={({ month }) =>
        moment(month).locale(language).format("MMMM YYYY")
      }
    />
  );
};

DatePicker.defaultProps = {
  onDateChange: () => {},
  onBeforeTodayInitialDate: () => {},
  onAfterTodayInitialDate: () => {},
  date: moment(),
};

export default DatePicker;
