import { connect } from "react-redux";

import { storePlan, trackDelays, updatePlan } from "store/actions/planv2";

import FormPlan from "./Component";

const mapDispatchToProps = (dispatch) => ({
  storePlan: (planv2, planId) => dispatch(storePlan(planv2, planId)),
  updatePlan: (planv2) => dispatch(updatePlan(planv2)),
  trackDelays: (payload) => dispatch(trackDelays(payload)),
});

const mapStateToProps = ({ planv2: { planId } }) => ({ planId });

export default connect(mapStateToProps, mapDispatchToProps)(FormPlan);
