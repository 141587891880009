import React, { useEffect } from "react";

import App from "components/App";
import { Navbar } from "components/Navbar";
import withChallenge from "HOCs/withChallenge";
import { useChallenge } from "contexts/Challenge/useChallenge";
import ChallengePlan from "./ChallengePlan";
import NoPlan from "./NoPlan";
import LoadingState from "components/PlanChecklistV2/LoadingState";
import ChallengePlanHeader from "./ChallengePlanHeader";
import MainCard from "components/MainCard";

const Plan = () => {
  const { isLoadingChallenge, currentChallenge } = useChallenge();

  const noPlanYet = !currentChallenge.planTemplate;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <App>
      <Navbar backTo="/challenges" />
      <ChallengePlanHeader />
      <MainCard containerOnly>
        {isLoadingChallenge ? (
          <LoadingState />
        ) : noPlanYet ? (
          <NoPlan />
        ) : (
          <ChallengePlan />
        )}
      </MainCard>
    </App>
  );
};

export default withChallenge(Plan);
