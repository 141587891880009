import React, { memo, useState, useEffect, useCallback } from "react";
import { ReactComponent as IconClose } from "assets/images/icon-close.svg";

import PropTypes from "prop-types";
import classNames from "classnames";

const SnackBar = ({
  open,
  close,
  variant,
  message,
  closeAfterDelay,
  Icon,
  delay,
}) => {
  const [shouldOpen, setShouldOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);

  const handleCloseSnackbar = useCallback(() => {
    close();
    setShouldOpen(false);
    setShouldClose(true);

    setTimeout(() => {
      setShouldClose(false);
    }, delay);
  }, [close, delay]);

  const handleOpenSnackbar = useCallback(() => {
    setShouldOpen(true);
    setTimeout(() => closeAfterDelay && handleCloseSnackbar(), delay);
  }, [closeAfterDelay, delay, handleCloseSnackbar]);

  useEffect(() => {
    if (open) handleOpenSnackbar();
    if (!open && !!message) handleCloseSnackbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div
      className={classNames(`snack-bar ${variant} `, {
        open: shouldOpen,
        close: shouldClose,
      })}
    >
      <p>{message}</p>
      {Icon ? Icon : <IconClose onClick={handleCloseSnackbar} />}
    </div>
  );
};

SnackBar.defaultProps = {
  open: false,
  variant: "",
  message: "",
  closeAfterDelay: true,
  close: () => {},
  Icon: null,
  delay: 4000,
};

SnackBar.propTypes = {
  open: PropTypes.bool,
  variant: PropTypes.string,
  message: PropTypes.string,
  closeAfterDelay: PropTypes.bool,
  close: PropTypes.func,
  Icon: PropTypes.element,
  delay: PropTypes.number,
};

export default memo(SnackBar);
