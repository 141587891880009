export const buttons = {
  buttons: {
    bibleReading: {
      finishReading: "Zakończ czytanie",
      close: "Blisko",
    },
    editPlan: {
      updatePlan: "Aktualizuj plan",
    },
    askConfirmationModal: {
      firstButtonText: "Zachowaj aktualny plan",
      secondButtonText: "Odtwórz cały plan",
    },
    planCreation: {
      createPlan: "Utwórz plan",
      createPlanLoading: "Tworzenie planu...",
    },
    delayedReadingModal: {
      firstButton: "To nie jest konieczne",
      secondButton: "Odtwórz plan",
    },
    donateOptionsModal: {
      firstButton: "Jednorazowe wsparcie",
      secondButton: "Cykliczne wsparcie",
    },
    askDonationsModal: {
      firstButton: "Przypomnij mi później",
      secondButton: "Wspieraj przez Gumroad",
    },
  },
};
