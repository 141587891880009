export const bibleBooksAbbreviations = [
  {
    en: "Gen",
    id: "Kej",
    pl: "Rdz",
    pt: "Gn",
  },
  {
    en: "Ex",
    id: "Kel",
    pl: "Wj",
    pt: "Êx",
  },
  {
    en: "Lev",
    id: "Im",
    pl: "Kpł",
    pt: "Lv",
  },
  {
    en: "Num",
    id: "Bil",
    pl: "Lb",
    pt: "Nm",
  },
  {
    en: "Deut",
    id: "Ul",
    pl: "Pwt",
    pt: "Dt",
  },
  {
    en: "Josh",
    id: "Yos",
    pl: "Joz",
    pt: "Js",
  },
  {
    en: "Judg",
    id: "Hak",
    pl: "Sdz",
    pt: "Jz",
  },
  {
    en: "Ruth",
    id: "Rut",
    pl: "Rtth",
    pt: "Rt",
  },
  {
    en: "1Sam",
    id: "1Sam",
    pl: "1 Sm",
    pt: "1Sm",
  },
  {
    en: "2Sam",
    id: "2Sam",
    pl: "2 Sm",
    pt: "2Sm",
  },
  {
    en: "1Kings",
    id: "1Raj",
    pl: "1 Krl",
    pt: "1Rs",
  },
  {
    en: "2Kings",
    id: "2Raj",
    pl: "2 Krl",
    pt: "2Rs",
  },
  {
    en: "1Chron",
    id: "1Taw",
    pl: "1 Krn",
    pt: "1Cr",
  },
  {
    en: "2Chron",
    id: "2Taw",
    pl: "2 Krn",
    pt: "2Cr",
  },
  {
    en: "Ezra",
    id: "Ezr",
    pl: "Ezd",
    pt: "Ed",
  },
  {
    en: "Neh",
    id: "Neh",
    pl: "Ne",
    pt: "Ne",
  },
  {
    en: "Est",
    id: "Est",
    pl: "Est",
    pt: "Et",
  },
  {
    en: "Job",
    id: "Ayb",
    pl: "Hi",
    pt: "Jó",
  },
  {
    en: "Ps",
    id: "Mzm",
    pl: "Ps",
    pt: "Sl",
  },
  {
    en: "Prov",
    id: "Ams",
    pl: "Prz",
    pt: "Pv",
  },
  {
    en: "Eccles",
    id: "Pkh",
    pl: "Koh",
    pt: "Ec",
  },
  {
    en: "Song",
    id: "Kid",
    pl: "PnP",
    pt: "Ct",
  },
  {
    en: "Isa",
    id: "Yes",
    pl: "Iz",
    pt: "Is",
  },
  {
    en: "Jer",
    id: "Yer",
    pl: "Jr",
    pt: "Jr",
  },
  {
    en: "Lam",
    id: "Rat",
    pl: "Lm",
    pt: "Lm",
  },
  {
    en: "Ezek",
    id: "Yeh",
    pl: "Ez",
    pt: "Ez",
  },
  {
    en: "Dan",
    id: "Dan",
    pl: "Dn",
    pt: "Dn",
  },
  {
    en: "Hos",
    id: "Hos",
    pl: "Oz",
    pt: "Os",
  },
  {
    en: "Joel",
    id: "Yl",
    pl: "Jl",
    pt: "Jl",
  },
  {
    en: "Amos",
    id: "Am",
    pl: "Am",
    pt: "Am",
  },
  {
    en: "Obad",
    id: "Ob",
    pl: "Ab",
    pt: "Ob",
  },
  {
    en: "Jonah",
    id: "Yun",
    pl: "Jon",
    pt: "Jn",
  },
  {
    en: "Mic",
    id: "Mi",
    pl: "Mi",
    pt: "Mq",
  },
  {
    en: "Nah",
    id: "Nah",
    pl: "Na",
    pt: "Na",
  },
  {
    en: "Hab",
    id: "Hab",
    pl: "Ha",
    pt: "Hc",
  },
  {
    en: "Zeph",
    id: "Zef",
    pl: "So",
    pt: "Sf",
  },
  {
    en: "Hag",
    id: "Hag",
    pl: "Ag",
    pt: "Ag",
  },
  {
    en: "Zech",
    id: "Za",
    pl: "Za",
    pt: "Zc",
  },
  {
    en: "Mal",
    id: "Mal",
    pl: "Ml",
    pt: "Ml",
  },
  {
    en: "Matt",
    id: "Mat",
    pl: "Mt",
    pt: "Mt",
  },
  {
    en: "Mark",
    id: "Mrk",
    pl: "Mk",
    pt: "Mc",
  },
  {
    en: "Luke",
    id: "Luk",
    pl: "Łk",
    pt: "Lc",
  },
  {
    en: "John",
    id: "Yoh",
    pl: "J",
    pt: "Jo",
  },
  {
    en: "Acts",
    id: "Kis",
    pl: "Dz",
    pt: "At",
  },
  {
    en: "Rom",
    id: "Rm",
    pl: "Rz",
    pt: "Rm",
  },
  {
    en: "1Cor",
    id: "1Kor",
    pl: "1 Kor",
    pt: "1Co",
  },
  {
    en: "2Cor",
    id: "2Kor",
    pl: "2 Kor",
    pt: "2Co",
  },
  {
    en: "Gal",
    id: "Gal",
    pl: "Ga",
    pt: "Gl",
  },
  {
    en: "Eph",
    id: "Ef",
    pl: "Ef",
    pt: "Ef",
  },
  {
    en: "Phil",
    id: "Flp",
    pl: "Flp",
    pt: "Fp",
  },
  {
    en: "Col",
    id: "Kol",
    pl: "Kol",
    pt: "Cl",
  },
  {
    en: "1Thess",
    id: "1Tes",
    pl: "1 Tes",
    pt: "1Ts",
  },
  {
    en: "2Thess",
    id: "2Tes",
    pl: "2 Tes",
    pt: "2Ts",
  },
  {
    en: "1Tim",
    id: "1Tim",
    pl: "1 Tm",
    pt: "1Tm",
  },
  {
    en: "2Tim",
    id: "2Tim",
    pl: "2 Tm",
    pt: "2Tm",
  },
  {
    en: "Titus",
    id: "Tit",
    pl: "Tt",
    pt: "Tt",
  },
  {
    en: "Philem",
    id: "Flm",
    pl: "Flm",
    pt: "Fm",
  },
  {
    en: "Heb",
    id: "Ibr",
    pl: "Hbr",
    pt: "Hb",
  },
  {
    en: "James",
    id: "Yak",
    pl: "Jk",
    pt: "Tg",
  },
  {
    en: "1Pet",
    id: "1Ptr",
    pl: "1 P",
    pt: "1Pe",
  },
  {
    en: "2Pet",
    id: "2Ptr",
    pl: "2 P",
    pt: "2Pe",
  },
  {
    en: "1John",
    id: "1Yoh",
    pl: "1 J",
    pt: "1Jo",
  },
  {
    en: "2John",
    id: "2 Yoh",
    pl: "2 J",
    pt: "2Jo",
  },
  {
    en: "3John",
    id: "3 Yoh",
    pl: "3 J",
    pt: "3Jo",
  },
  {
    en: "Jude",
    id: "Yud",
    pl: "Jud",
    pt: "Jd",
  },
  {
    en: "Rev",
    id: "Why",
    pl: "Ap",
    pt: "Ap",
  },
];
