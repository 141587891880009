import Modal from "components/Modal";
import React from "react";
import {
  exceptionModalButton,
  exceptionModalSubtitle,
  exceptionModalTitle,
} from "./messages";

const ExceptionModal = ({ active, onSecondButtonClick }) => (
  <Modal
    active={active}
    title={exceptionModalTitle}
    subtitle={exceptionModalSubtitle}
    onSecondButtonClick={onSecondButtonClick}
    secondButtonText={exceptionModalButton}
  />
);

export default ExceptionModal;
