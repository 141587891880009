import { connect } from "react-redux";
import { setAnnouncementAsSeen } from "store/actions/challenge";
import ChallengeInvites from "./Component";

const mapDispatchToProps = (dispatch) => ({
  setAnnouncementAsSeen: () => dispatch(setAnnouncementAsSeen()),
});

const mapStateToProps = ({ challenge: { sawAnnouncement } }) => ({
  sawAnnouncement,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeInvites);
