import { authentication } from "./authentication";
import { bibleBooks } from "./bibleBooks";
import { buttons } from "./buttons";
import { menu } from "./menu";
import { months } from "./months";
import { noticeModals } from "./noticeModals";
import { notifications } from "./notifications";
import { challengeLP } from "./challengeLP";
import { week } from "./week";
import { planCreation } from "./planCreation";
import { exceptionModal } from "./exceptionModal";
import { reportError } from "./reportError";
import { challenge } from "./challenge";
import { bottomBar } from "./bottomBar";
import { darkTheme } from "./darkTheme";

const messages = {
  en: {
    translations: {
      titles: {
        askDonationsModal: "Help us to do more",
        donateOptionsModal: "Thank you!",
        appHeader: {
          title: "Your biblical plan",
          subtitle: "From {{startReading}} to {{endReading}}",
        },
        delayedReadingModal: "Oops! You delayed your reading.",
        planCreation: {
          title: "Read the Bible",
          subtitle:
            "Set start and end dates for your reading and create your personalized plan!",
        },
        createdPlan: {
          title: "Plan created!",
          subtitle:
            "Start your reading and don't worry when you miss a day. The plan will be reset for you!",
        },
        editPlan: {
          title: "Edit your plan",
          subtitle:
            "Set an interval of days, when you will start and end your reading and update your plan :)",
        },
        askConfirmationModal: {
          title: "Are you sure?",
          subtitle:
            "Your current progress will be lost and the plan will be made from scratch!",
        },
        biblePicker: {
          book: "Book",
          chapter: "Chapter",
        },
      },
      messages: {
        pullGooglePic: "Pull picture from Google",
        pullFacebookPic: "Pull picture from Facebook",
        updatePicture: "Update picture",
        youWereInvited: "You were invited to the challenge",
        loginToJoin: "Login or create an account to accept the invite.",
        switchToApp: "Use the app",
        stayOnBrowser: "Stay on the browser",
        premiumApproved: "Your premium subscription has been approved",
        accessPremium: "You can now access all premium features",
        createFirstChallenge: "Create your first challenge",
        progress: "Progress",
        noVersionsOffline: "No versions available offline",
        connectAndDownload: "Connect and download a version to read offline",
        weHadToChangeTheVersion:
          "We changed you to a offline Bible version because you lost your connection :)",
        weCouldNotLoadChapter: "We couldn't load this chapter.",
        thePossibleReasons: "Possible reasons:",
        thePossibleReasons1:
          "This chapter may not exist in this version of the Bible.",
        thePossibleReasons2:
          "We may be experiencing some temporary instability.",
        thePossibleReasons3: "You might be offline",
        whatYouCanDo: "What you can do:",
        whatYouCanDo1: "Try changing the Bible version.",
        whatYouCanDo2: "Download a version to read offline.",
        whatYouCanDo3: "Come back later and try again.",

        whatIsYourFeedback: "What is your feedback?",
        textCopied: "Text copied!",
        linkCopied: "Link copiado",
        copyLink: "Copiar link",
        throughLink: "Through invite link",
        inviteViaEmail: "Invite via email",
        shareLink: "Share link",
        hereIsTheLink: "Here is the link to join the challenge:",
        loading: "Loading...",
        bibleReading: {
          read: "Read",
        },
        planChecklist: {
          day: "day",
        },
        editPlan: {
          formChecklistTitle: "Edit now:",
        },
        delayedReadingModal: {
          subtitle:
            "But don't worry, we can adapt your plan from where you left off :)",
        },
        askDonationsModal: {
          paragraph1: "Bible Journey is designed to bless.",
          paragraph2:
            "By signing up as a monthly donor you help us to evolve and reach more people.",
        },
        donateOptionsModal: {
          subtitle: "Choose the type of donation to proceed.",
        },
        shareBibleJourney: {
          mobileText: "Hi! This is the link to download Bible Journey :)",
          nonMobileText: "Hi! This is the link to access Bible Journey :)",
        },
        footer: { madeBy: "Made by" },
        forgotPassword: "Forgot password?",
        newUpdateAvailable: "There's a new update available to the app",
        update: "Update",
        appUpdated: "App updated 🎉",
        muteReminder: "Mute delay reminder",
        pageNotExist:
          "This page doesn't exists yet. Try checking if there's an update available.",
        verifyUpdate:
          "Try to check if there's an update available, or, report an error through Menu.",
        weCouldNotFindPage: "Oops! We couldn't find this page",
        ...week,
        ...months,
        ...bibleBooks,
        ...authentication,
        ...menu,
        ...noticeModals,
        ...notifications,
        ...challengeLP,
        ...planCreation,
        ...exceptionModal,
        ...reportError,
        ...challenge,
        ...bottomBar,
        ...darkTheme,
      },
      ...buttons,
      links: {
        donateOptionsModal: {
          oneTimeDonation: "https://gum.co/bibleplan-one-time-donation",
          recurringDonation: "https://gum.co/bibleplan-recurring-donation",
        },
      },
    },
  },
};

export { messages };
