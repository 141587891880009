import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import NewChallengeName from "./Name";
import NewChallengeStepper from "./components/NewChallengeSteper";
import Container from "atoms/Container";
import NewChallengeDates from "./Dates";
import NewChallengeReading from "./Reading";
import { NewChallengeProvider } from "contexts/Challenge/NewChallenge";
import { useUser } from "contexts/User";
import { useQuery } from "hooks";

const exact = true;

export const routesData = [
  { path: "/challenges/new/name", exact, component: NewChallengeName },
  { path: "/challenges/new/dates", exact, component: NewChallengeDates },
  { path: "/challenges/new/reading", exact, component: NewChallengeReading },
];

const NewChallenge = () => {
  const { premium, isLogged } = useUser();
  const iosPurchase = useQuery("iosPurchase");

  if ((premium.notPremium && !iosPurchase) || !isLogged) {
    return <Redirect to="/challenges/presentation" />;
  }

  return (
    <Fragment>
      <Container maxWidth={400}>
        <NewChallengeStepper />
        <NewChallengeProvider>
          {routesData.map((item, key) => (
            <Route key={key} {...item} />
          ))}
        </NewChallengeProvider>
      </Container>
    </Fragment>
  );
};

export default NewChallenge;
