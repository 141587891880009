import React from "react";
import { read } from "./messages";
import { ReactComponent as ReadIcon } from "assets/images/read-icon.svg";
import { useModal } from "contexts/Modal/useModal";
import { BibleReading } from "organisms/BibleReading";

const ReadButton = ({
  day,
  planId,
  readingDetails,
  onFinishReading,
  toggleReadStatus,
}) => {
  const { createModal } = useModal();

  const onClick = () => {
    createModal({
      size: "medium",
      fullHeight: true,
      fullWindowHeight: true,
      modalNoPadding: true,
      fullScreenHeight: true,
      onOverlayClick: () => {},
      children: (
        <BibleReading
          bibleReadingInterval={day}
          toggleReadStatus={toggleReadStatus}
          planId={planId}
          readingDetails={readingDetails}
          onFinishReading={onFinishReading}
        />
      ),
    });
  };

  return (
    <div className="read-now-button" onClick={onClick}>
      <button className="checklist__item__read-now">
        {read}
        <ReadIcon />
      </button>
    </div>
  );
};

export default ReadButton;
