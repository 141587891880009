import React, { Fragment } from "react";

import { chaptersPerDay } from "../messages";

import SmallInput from "components/Form/SmallInput";
import { FormFeedback } from ".";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const ChaptersPerDay = () => {
  const {
    quantityOfChapters,
    setQuantityOfChapters,
    blockedWeekDays,
    setBlockedWeekDays,
  } = useNewChallenge();

  const onChange = ({ target: { value } }) =>
    setQuantityOfChapters(Number(value));

  return (
    <Fragment>
      <div className="chapters-per-day">
        <div className="form__row">
          <SmallInput
            type="number"
            defaultValue={quantityOfChapters}
            name="days"
            onChange={onChange}
            label={chaptersPerDay}
            max={1190}
          />
        </div>
        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />

        <ReadingInputs />

        <FormFeedback />
      </div>
    </Fragment>
  );
};

export default ChaptersPerDay;
