import { connect } from "react-redux";

import { chooseVersion, closeModal } from "store/actions/bibleReading";

import { toggleReadStatus } from "store/actions/planv2";

import ReadButton from "./Component";

const mapDispatchToProps = (dispatch) => ({
  chooseVersion: (payload) => dispatch(chooseVersion(payload)),
  closeModal: (payload) => dispatch(closeModal(payload)),
  toggleReadStatus: (id, status) => dispatch(toggleReadStatus(id, status)),
});

const mapStateToProps = ({
  bibleReading: { bibleVersion, currentReading },
  planv2: { planId },
}) => ({
  bibleVersion,
  currentReading,
  planId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadButton);
