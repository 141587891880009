import React, { Fragment, memo, useMemo } from "react";
import classNames from "classnames";
import { padLeadingZeros } from "utils";
import useChallengeRanking from "contexts/Challenge/Ranking/useChallengeRanking";
import userIcon from "assets/icons/user.svg";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";

const RankingTable = () => {
  const { noParticipants, outOfRanking } = useChallengeRanking();

  return (
    <Fragment>
      {!noParticipants && (
        <Fragment>
          {outOfRanking?.map((position, index) => (
            <Position position={position} placement={index + 4} key={index} />
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

const Position = ({ position, placement }) => {
  const { viewReadingRanking, viewQuestionsRanking } = useChallengeRanking();

  const { noReaders } = useChallengePlan();

  const picture = useMemo(
    () => position.user.picture || userIcon,
    [position.user.picture]
  );

  const points = useMemo(() => {
    if (viewReadingRanking && noReaders) return "0%";

    const pointsQuestion = padLeadingZeros(position.questionPoints, 3);
    const pointsTotal = padLeadingZeros(position.totalPoints, 3);
    const progressReading = position.conclusionPercentage + "%";

    if (viewQuestionsRanking) return pointsQuestion;

    if (viewReadingRanking) return progressReading;

    return pointsTotal;
  }, [
    viewReadingRanking,
    noReaders,
    position.questionPoints,
    position.totalPoints,
    position.conclusionPercentage,
    viewQuestionsRanking,
  ]);

  return (
    <div className="table__item">
      <div className="table__item-content">
        <div className="table__item-position">{placement}</div>
        <div
          className={classNames("table__item-pic", {
            "no-pic": !position.user.picture,
          })}
          style={{
            backgroundImage: `url(${picture})`,
          }}
        />
        <div className="table__item-name">
          {position.user.name || position.user.email}
        </div>
      </div>
      <div className="table__item-points">{points}</div>
    </div>
  );
};

export default memo(RankingTable);
