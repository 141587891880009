export const challengeLP = {
  challengeLP: {
    headerTitle: "Engaje seu grupo com a leitura da Bíblia!",
    headerContent:
      "Agora você pode criar um desafio para incentivar a leitura da palavra!",
    moreAboutIt: "Saiba mais 👇🏼",
    startNow: "Começar agora",
    howItWorks: "Como funciona",
    giveAName: "💡 Dê um nome ao desafio",
    useCreativity: "Use sua criatividade 😀",
    defineDates: "📆 Defina quando o desafio começa e termina",
    oneTwoMonths: "Um, dois, três meses ou mais. Você decide 🙂",
    createAPlan: "📝 Crie um plano de leitura",
    theWholeBible: "A Bíblia inteira ou só um livro. Fique à vontade 😊",
    addParticipants: "👥 Adicione os integrantes",
    asYouWant: "Quantas pessoas quiser 😀",
    challengeLaunched: "Pronto! O desafio está lançado 🚀",
    partOfRanking: "Todos participarão de um ranking",
    rewardOnRead:
      "Para cada leitura diária do plano completa, o participante ganha 1 ponto",
    surpriseQuestion:
      "Você pode postar perguntas surpresas para equilibrar o jogo, com o importante detalhe de poder escolher a quantidade de pontos que cada pergunta valerá 😱",
    seeNow: "Veja na prática",
    play: "Play",
    chooseYourPlan: "Comece agora",
    monthPrice: "R$ 20,00",
    month: "mês",
    monthPriceDesc:
      "Para poder criar quantos desafios quiser, sempre que precisar",
    subscribeNow: "Assinar agora",
    subscribeNowDisc:
      "Não se preocupe se não gostar, você poderá cancelar sua assinatura quando quiser :)",
    onePaymentPrice: "R$ 100,00",
    onePaymentPriceDesc:
      "Para comprar um único desafio, com a duração que você quiser",
    payNow: "Pagar agora",
    payNowDisc:
      "Se quiser criar um desafio novo será necessário uma nova compra",
    closeVideo: "Fechar",
    firstSignIn: "Primeiro, entre em sua conta",
    subscribeWithCard: "Inscreva-se com seu cartão",
    successMsg: "Sua assinatura foi realizada com sucesso!",
    yesProceed: "Sim, tenho certeza",
    haveAlreadySubscription: "Você já tem uma assinatura ativa",
    checkoutUnavailable:
      "Desculpe, o checkout está temporáriamente indisponível. Por favor, tente novamente mais tarde.",
    paymentSuccess: "Pagamento efetuado!",
    weWillRedirect:
      "Vamos te redirecionar para a experiência de criação de desafio",
    paymentProcessing:
      "Estamos processando seu pagamento. Volte novamente mais tarde.",
    unsuccessfulPayment:
      "Não pudemos processar seu pagamento. Por favor, tente novamente.",
    verifyingStatus: "Verificando status do pagamento...",
    purchaseFailed: "A compra falhou. Tente novamente.",
    pendingPurchase: "Sua compra está pendente",
    waitWhileWeValidate:
      "Por favor, aguarde por um e-mail de confirmação ou abra o Bible Journey depois enquanto a sua compra está sendo verificada.",
    thanksForInterest: "Obrigado pelo seu interesse!",
    choseBetweenEmailOrWhatsapp:
      "Agora escolha entre receber um e-mail com mais informações ou prosseguir para um chat com a gente no WhatsApp:",
    callMeOnWhatsapp: "Me chame no WhatsApp",
    emailMe: "Me mande um e-mail",
    hiIWantToKnowMoreAboutBibleJourney:
      "Olá, quero saber mais sobre o Desafio Bible Journey",
  },
};
