import { i18n } from "translate/i18n";

export const askConfirmationTitle = i18n.t("titles.askConfirmationModal.title");
export const askConfirmationSubtitle = i18n.t(
  "titles.askConfirmationModal.subtitle"
);
export const askConfirmationFirstButtonText = i18n.t(
  "buttons.askConfirmationModal.firstButtonText"
);
export const askConfirmationSecondButtonText = i18n.t(
  "buttons.askConfirmationModal.secondButtonText"
);
