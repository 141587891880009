import { useContext } from "react";
import { UserPointsContext } from "./Component";

const useUserPoints = () => {
  const context = useContext(UserPointsContext);
  if (!context)
    throw new Error("useUserPoints must be used within a UserPointsProvider");

  return context;
};

export default useUserPoints;
