/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from "react";
import { Input } from "components/Form";
import Button from "components/Button";
import { EMAIL_REQUIRED, PASSWORD_REQUIRED } from "./validationSchema";
import { useAuth } from "contexts/AuthContext";
import { email, password, loggingIn, loginMsg } from "./messages";

const FormLogin = () => {
  const { formLogin, isLoading, register, handleSubmit, errors } = useAuth();

  return (
    <>
      <form onSubmit={handleSubmit(formLogin)} className="form">
        <div className="form__row full-width">
          <Input
            label={email}
            name="email"
            type="email"
            placeholder="billy@graham.com"
            {...register("email", EMAIL_REQUIRED)}
            error={errors?.email?.message}
          />
        </div>
        <div className="form__row full-width">
          <Input
            label={password}
            name="password"
            type="password"
            placeholder="*********"
            {...register("password", PASSWORD_REQUIRED)}
            error={errors?.password?.message}
            showForgotPasswordLink={true}
          />
        </div>
        <div className="form__row">
          <Button
            size="medium"
            className="button--success  mt8"
            variation="square-rounded"
            theme="primary"
            disabled={isLoading}
          >
            {isLoading ? loggingIn : loginMsg}
          </Button>
        </div>
      </form>
    </>
  );
};

export default memo(FormLogin);
