import React, { memo, useMemo } from "react";
import MenuCard from "molecules/MenuCard";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { login, logout } from "../messages";

const LoginButton = () => {
  const { isLogged } = useAuth();

  const history = useHistory();

  const items = useMemo(
    () => [
      {
        description: isLogged ? logout : login,
        onClick: () =>
          isLogged ? history.push("/logout") : history.push("/login"),
        variant: isLogged ? "primary" : "success-rounded",
        pointer: true,
        Component: <ArrowRight />,
        className: "mt40",
      },
    ],
    [isLogged, history]
  );

  return <MenuCard items={items} />;
};

export default memo(LoginButton);
