import moment from "moment";
import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  subscriptionToken: null,
  hasDefinedReminder: false,
  askAgainOn: moment(),
  reminderCounter: 0,
  permissionDenied: false,
  showReminders: true,
  definedHour: 17,
  definedMinute: 0,
};

const notifications = handleActions(
  {
    REMINDER_DEFINED: (
      state,
      { subscriptionToken, definedHour, definedMinute, ...a }
    ) => ({
      ...state,
      subscriptionToken,
      hasDefinedReminder: true,
      definedHour,
      definedMinute,
    }),
    STORE_PERMISSION_DENIED: (state) => ({
      ...state,
      permissionDenied: true,
    }),
    STOP_REMINDERS: (state) => ({
      ...state,
      showReminders: false,
    }),
    REMIND_LATER: ({ reminderCounter, ...state }) => ({
      ...state,
      reminderCounter: ++reminderCounter,
      askAgainOn: moment().add(++reminderCounter, "days"),
    }),
    SAVE_SUBSCRIPTION: (state, { subscriptionToken }) => ({
      ...state,
      subscriptionToken,
    }),
  },
  INITIAL_STATE
);

export default notifications;
