import { messages as portugueseMessages } from "./pt";
import { messages as englishMessages } from "./en";
import { messages as indonesianMessages } from "./id";
import { messages as polishMessages } from "./pl";

const messages = {
  ...polishMessages,
  ...portugueseMessages,
  ...indonesianMessages,
  ...englishMessages,
};

export { messages };
