import React, { createContext, memo, useState } from "react";
import { useCallback } from "react";

export const PlanProgressContext = createContext();

const PlanProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [unit, setUnit] = useState(0);

  const increaseProgress = useCallback(() => {
    setProgress((prev) => parseFloat((prev + unit).toFixed(2)));
  }, [unit]);

  const decreaseProgress = useCallback(() => {
    setProgress((prev) => parseFloat((prev - unit).toFixed(2)));
  }, [unit]);

  return (
    <PlanProgressContext.Provider
      value={{
        unit,
        progress,
        setUnit,
        setProgress,
        increaseProgress,
        decreaseProgress,
      }}
    >
      {children}
    </PlanProgressContext.Provider>
  );
};

export default memo(PlanProgressProvider);
