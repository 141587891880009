import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import Button from "components/Button";
import { challenges } from "../messages";
import { ReactComponent as FlagIcon } from "assets/icons/flag.svg";
import { NavLink } from "react-router-dom";

const Challenges = () => {
  const history = useHistory();
  const active = history.location.pathname === "/challenges";

  return (
    <div className="bottom-bar__challenges">
      <NavLink to="/challenges" className="tdn">
        <Button
          size="medium"
          className={classNames("icon-right", {
            active,
          })}
          variation="square-rounded"
          theme="primary-outlined"
        >
          {challenges}

          <FlagIcon className="bottom-bar__challenge-icon" />
        </Button>
      </NavLink>
    </div>
  );
};

export default Challenges;
