import React, { memo } from "react";
import { ranking } from "../../messages";
import { ReactComponent as RankingIcon } from "assets/icons/ranking.svg";
import { NavLink } from "react-router-dom";
import { useChallenge } from "contexts/Challenge/useChallenge";

const Challenge = () => {
  const { viewingChallenge } = useChallenge();

  return (
    <NavLink
      to={`/challenge/${viewingChallenge}/ranking`}
      className="bottom-bar__icon"
    >
      <div className="bottom-bar__icon-icon">
        <RankingIcon className="bottom-bar__icon-svg--fill" />
      </div>
      <div className="bottom-bar__icon-text">{ranking}</div>
    </NavLink>
  );
};

export default memo(Challenge);
