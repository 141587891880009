import React from "react";
import { isIOSdevice, isNativePlatform } from "utils/devices";
import IOSGoogleUpdatePicture from "./IOS";

const GoogleButton = (props) => {
  if (isIOSdevice && isNativePlatform)
    return <IOSGoogleUpdatePicture {...props} />;

  return null;
};

export default GoogleButton;
