import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useModal } from "contexts/Modal/useModal";
import { useAuth } from "contexts/AuthContext";
import { useHistory, withRouter } from "react-router-dom";
import api from "utils/api";
import {
  accept,
  decline,
  inviteReceived,
  inviteReceivedDescription,
  seeLater,
} from "./messages";
import { useChallenge } from "../useChallenge";
export const ChallengeInvitesContext = createContext();

const ChallengeInvitesProvider = ({ children, setAnnouncementAsSeen }) => {
  const { getHeaders, isLogged } = useAuth();
  const { createModal, closeModal } = useModal();
  const history = useHistory();
  const [challengeInvites, setChallengeInvites] = useState([]);
  const [inviteWasAccepted, setInviteWasAccepted] = useState(null);
  const [inviteWasDeclined, setInviteWasDeclined] = useState(null);
  const { viewingChallenge } = useChallenge();

  const inviteLink = useMemo(() => {
    return `https://bibleplan.online/c/invite/${viewingChallenge}`;
  }, [viewingChallenge]);

  useEffect(() => {
    if (inviteWasAccepted) {
      setInviteWasAccepted(null);
      let challengeInvitesCopy = [...challengeInvites];

      challengeInvitesCopy = challengeInvitesCopy.filter(
        (invite) => invite._id !== inviteWasAccepted.invite_id
      );

      setChallengeInvites(challengeInvitesCopy);
    }
  }, [challengeInvites, inviteWasAccepted]);

  useEffect(() => {
    if (inviteWasDeclined) {
      setInviteWasDeclined(null);
      let challengeInvitesCopy = [...challengeInvites];

      challengeInvitesCopy = challengeInvitesCopy.filter(
        (invite) => invite._id !== inviteWasDeclined.invite_id
      );

      setChallengeInvites(challengeInvitesCopy);
    }
  }, [challengeInvites, inviteWasDeclined]);

  const isOnPlanPage = history.location.pathname === "/plan";
  const isOnMenuPage = history.location.pathname === "/menu";

  useEffect(() => {
    if (isLogged && challengeInvites.length && isOnPlanPage) {
      challengeInvites.forEach((challenge) => {
        setTimeout(() => {
          openInviteModal(challenge);
        }, 500);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, challengeInvites, isOnPlanPage]);

  useEffect(() => {
    isLogged &&
      api.get("/challenges/invites", getHeaders()).then((res) => {
        setChallengeInvites(res.data?.challengeInvites);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const acceptChallengeInvite = useCallback(
    async (challenge_id, invite_id) => {
      const headers = getHeaders();
      headers.headers.challenge_id = challenge_id;
      headers.headers.invite_id = invite_id;

      await api.put(`/challenge/invite/accept`, {}, headers).then((res) => {
        setInviteWasAccepted({ invite_id, challenge_id });
        history.push({
          pathname: `/challenge/${challenge_id}/ranking`,
          search: `?isNewParticipant=true`,
        });
      });
    },
    [getHeaders, history]
  );

  const declineChallengeInvite = useCallback(
    async (challenge_id, invite_id) => {
      const headers = getHeaders();
      headers.headers.challenge_id = challenge_id;
      headers.headers.invite_id = invite_id;

      await api.put(`/challenge/invite/decline`, {}, headers);

      setInviteWasDeclined({ invite_id, challenge_id });
    },
    [getHeaders]
  );

  const openInviteModal = useCallback(
    ({
      _id: invite_id,
      challenge: { owner, title: challengeName, _id: challenge_id },
    }) => {
      if (!isOnMenuPage && !isOnPlanPage) return null;

      createModal({
        title: inviteReceived,
        pagesSafelist: ["/menu", "/plan"],
        subtitle: inviteReceivedDescription({
          name: owner?.name,
          challengeName,
        }),
        onOverlayClick: closeModal,
        firstButtonText: seeLater,
        secondButtonText: decline,
        thirdButtonText: accept,
        firstButtonTheme: "primary",
        secondButtonTheme: "danger",
        thirdButtonTheme: "success",
        onFirstButtonClick: () => {
          closeModal();
          setAnnouncementAsSeen();
        },
        onSecondButtonClick: () => {
          declineChallengeInvite(challenge_id, invite_id);
          closeModal();
          setAnnouncementAsSeen();
        },
        onThirdButtonClick: () => {
          acceptChallengeInvite(challenge_id, invite_id);
          closeModal();
          setAnnouncementAsSeen();
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isOnMenuPage,
      isOnPlanPage,
      createModal,
      closeModal,
      declineChallengeInvite,
      acceptChallengeInvite,
    ]
  );

  return (
    <ChallengeInvitesContext.Provider
      value={{
        challengeInvites,
        openInviteModal,
        inviteLink,
      }}
    >
      {children}
    </ChallengeInvitesContext.Provider>
  );
};

export default withRouter(ChallengeInvitesProvider);
