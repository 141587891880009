export const authentication = {
  authentication: {
    accountConfirmed: "Conta confirmada 🤩",
    confirmingAccount: "Confirmando conta",
    somethingWentWrong: "Algo deu errado, por favor tente novamente",
    accountNotFound: "Conta não encontrada.",
    register: "Cadastre-se.",
    emailRegistered: "Email já registrado.",
    login: "Faça login",
    existingAccountUsing:
      "Uma conta associada a este e-mail foi criada usando o {{socialMedia}}. Por favor, use-o para logar.",
    invalidSolicitation: "Solicitação de reset de senha inválida.",
    expiredSolicitation: "Solicitação de reset de senha expirada.",
    askAgain: "Solicite novamente",
    passwordChanged: "Senha alterada com sucesso.",
    wrongPassword: "Senha incorreta",
    emailNotFound: "E-mail não encontrado.",

    forgotPasswordMessage: "Esqueceu a senha?",
    askEmailVerification: "Pronto! Por favor, verifique seu e-mail :)",
    typeYourEmail:
      "Sem problemas. Digite o seu e-mail e enviaremos um link de redefinição de senha para você:",

    loginTitle: "Login",
    loggingIn: "Entrando...",
    loginMsg: "Entrar",
    registerMsg: "cadastre-se",
    loginWithFacebook: "Continue com o Facebook",
    loginWithGoogle: "Continue com o Google",
    loginWithApple: "Continue com a Apple",
    createAccount: "Criar conta",
    onRegistering: "Ao registrar-se você concorda com os nossos",
    policyTerms: "termos de serviço e política de privacidade",
    registering: "Cadastrando...",
    registerMsgCaptalized: "Cadastre-se",
    logYourself: "faça login",
    registerWithFacebook: "Cadastre-se com o Facebook",
    registerWithGoogle: "Cadastre-se com o Google",
    registerWithApple: "Cadastre-se com o Apple",
    finishWithFacebook: "Conclua com o Facebook",
    finishWithGoogle: "Conclua com o Google",
    finishWithApple: "Conclua com a Apple",

    changePassword: "Alterar minha senha",
    informYourPassword:
      "Informe sua nova senha do Bible Journey. Se você ignorar este processo sua senha não será alterada.",

    subtitle:
      "Tendo uma conta no Bible Journey você garante acesso ao seu plano Bíblico em qualquer dispositivo :)",
    sending: "Enviando...",
    send: "Enviar",
    email: "E-mail",
    password: "Senha",
    or: "ou",
    name: "Nome",
    //  validation messages

    biggerName: "Por favor, informe um nome maior",
    validEmail: "Por favor, informe um e-mail válido",
    biggerPassword: "Por favor, insira uma senha maior",
    pleaseInform: "Por favor, informe seu",
    pleaseInformThe: "Por favor, informe o",
    finishRegistration: "Concluir cadastro",
    finishYourRegistration: "Conclua seu cadastro",
  },
};
