export const challengeLP = {
  challengeLP: {
    headerTitle: "Engage your group with the Bible!",
    headerContent:
      "Now you can create a challenge to encourage Word of God reading!",
    moreAboutIt: "More about it 👇🏼",
    startNow: "Start now",
    howItWorks: "How it works",
    giveAName: "💡 Give the challenge a name",
    useCreativity: "Use your creativity 😀",
    defineDates: "📆 Decide when the challenge starts and ends",
    oneTwoMonths: "One, two, three months or more. You decides 🙂",
    createAPlan: "📝 Create a reading plan",
    theWholeBible: "The whole bible or just a book. Feel free to choose 😊",
    addParticipants: "👥 Add the participants",
    asYouWant: "As many people as you want 😀",
    challengeLaunched: "The challenge is launched 🚀",
    partOfRanking: "Everyone will participate in a ranking",
    rewardOnRead:
      "For each daily complete reading of the plan, the participant earns 1 point",
    surpriseQuestion:
      "You can post surprise questions to balance the game, with the important detail of being able to choose the amount of points each question will be worth 😱",
    seeNow: "See it in action ",
    play: "Play",
    chooseYourPlan: "Start now",
    monthPrice: "$ 20,00",
    month: "month",
    monthPriceDesc:
      "To create as many challenges as you want, whenever you need",
    subscribeNow: "Subscribe now",
    subscribeNowDisc:
      "Don't worry if you don't like it, you can cancel your subscription whenever you want :)",
    onePaymentPrice: "$ 100,00",
    onePaymentPriceDesc: "To buy a single challenge, with the duration you set",
    payNow: "Pay now",
    payNowDisc:
      "If you want to create a new challenge, you will need a new purchase",
    closeVideo: "Close",
    firstSignIn: "First, login to your account:",
    subscribeWithCard: "Subscribe with your card",
    successMsg: "Your subscription was successful",
    yesProceed: "Yes, proceed",
    haveAlreadySubscription: "You have already an active subscription",
    checkoutUnavailable:
      "Sorry, checkout is temporarily unavailable. Please try again later.",
    paymentSuccess: "Payment successful",
    weWillRedirect:
      "We will redirect you to the Challenge Creation experience...",
    paymentProcessing: "Your payment is processing. Come back later.",
    unsuccessfulPayment: "Your payment was not successful, please try again.",
    verifyingStatus: "Verifying payment status...",
    purchaseFailed: "Purchase failed. Try again.",
    pendingPurchase: "Your purchase is pending",
    waitWhileWeValidate:
      "Please wait for a confirmation e-mail or open Bible Journey later while we validate your purchase.",
    thanksForInterest: "Thanks for your interest!",
    choseBetweenEmailOrWhatsapp:
      "Now choose between receiving an e-mail with more information or proceed to a chat with us on WhatsApp:",
    callMeOnWhatsapp: "Call me on WhatsApp",
    emailMe: "Email me",
    hiIWantToKnowMoreAboutBibleJourney:
      "Hi, I want to know more about the Bible Journey Challenge",
  },
};
