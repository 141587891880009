import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import BiblicalPlan from "utils/BiblicalPlan";
import { storePlan } from "store/actions/planv2";
import { sendErrorEmail } from "utils/sendEmail";
import { usePlan } from "components/FormPlan/Context";

const biblicalPlan = new BiblicalPlan();

const Index = ({ planv1, planv2, dispatch }) => {
  const hasOnlyPlanv1 = !!planv1.length && !planv2.length;
  const { isFetchingPlan } = usePlan();
  const hasPlan = !!planv2.length || (!planv2.length && isFetchingPlan);
  const history = useHistory();

  if (hasOnlyPlanv1) {
    try {
      const v1ConvertedToV2 = biblicalPlan.turnPlanV1IntoPlanV2(planv1);
      dispatch(storePlan(v1ConvertedToV2));
      history.replace("/plan");
    } catch (error) {
      history.replace("/new");
      sendErrorEmail(error, `the planv1 is: ${JSON.stringify(planv1)}`);
    }

    return null;
  }
  hasPlan ? history.replace("/plan") : history.replace("/new");

  return null;
};

export default connect(({ plan: { plan: planv1 }, planv2: { planv2 } }) => ({
  planv1,
  planv2,
}))(Index);
