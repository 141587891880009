import moment from "moment";
import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  planv2: [],
  trackDelays: true,
  planId: null,
  lastModified: null,
  delays: 0,
  blockedWeekDays: [],
  planType: 0,
  chaptersPerDay: 3,
  quantityOfDays: 90,
};

const planv2 = handleActions(
  {
    STORE_PLAN_V2: (
      state,
      {
        planv2,
        planId,
        blockedWeekDays,
        planType,
        chaptersPerDay,
        quantityOfDays,
      }
    ) => ({
      ...state,
      planv2,
      planId,
      lastModified: moment().toISOString(),
      delays: 0,
      blockedWeekDays,
      planType,
      chaptersPerDay,
      quantityOfDays,
    }),
    UPDATE_PLAN_V2: (state, { planv2, blockedWeekDays, planType }) => ({
      ...state,
      planv2,
      lastModified: moment().toISOString(),
      delays: state.delays + 1,
      blockedWeekDays,
      planType,
    }),
    TRACK_DELAYS_V2: (state, { trackDelays }) => ({
      ...state,
      trackDelays,
    }),
    TOGGLE_TRACK_DELAYS_V2: (state) => ({
      ...state,
      trackDelays: !state.trackDelays,
    }),
    STORE_LAST_MODIFIED: (state) => ({
      ...state,
      lastModified: moment().toISOString(),
    }),
    RESET_LOCAL_PLAN: () => ({
      planv2: [],
      trackDelays: true,
      planId: null,
      lastModified: null,
      delays: 0,
      blockedWeekDays: [],
      planType: 0,
      chaptersPerDay: 3,
      quantityOfDays: 90,
    }),
    STORE_PLAN_ID: (state, { planId }) => ({
      ...state,
      planId,
    }),
    TOGGLE_READ_STATUS_V2: (state, { id, status }) => {
      return {
        ...state,
        lastModified: moment().toISOString(),
        planv2: state.planv2.map((item) => {
          if (item._id === id) {
            item.read = status || !item.read;
            return item;
          }
          return item;
        }),
      };
    },
  },
  INITIAL_STATE
);

export default planv2;
