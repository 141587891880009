import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from "react";

import BiblicalPlan from "utils/BiblicalPlan";
import {
  findMonthToShow,
  scrollIntoFirstUnread,
} from "components/PlanChecklistV2/utils";
import LoadingState from "components/PlanChecklistV2/LoadingState";
import { ChecklistHeader } from "components/PlanChecklistV2/ChecklistHeader";
import ChecklistItem from "./ChecklistItem";
import { api, sendErrorEmail } from "utils";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import moment from "moment";
import { todayMorning } from "components/FormPlan/constants";
import { useModal } from "contexts/Modal/useModal";
import { delayedReadingModal } from "components/PlanChecklistV2/ReplanModal/messages";
import { notNecessary, resetPlan, subtitleDelayReading } from "./messages";
import { loading } from "components/SocialLoginButtons/messages";
import { replan, syncPlanWithDB } from "utils/plan";
import { useHeaders } from "hooks";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";
import Footer from "components/Footer";

const biblePlanUtil = new BiblicalPlan();

const checkIfPlanIsComplete = (plan) => !plan.some((day) => !day.read);

const ParticipantPlanView = () => {
  const {
    plan,
    setPlan,
    setIsLoadingPlan,
    planIsLoaded,
    setPlanIsLoaded,
    planId,
    setPlanId,
    activeMonth,
    setActiveMonth,
    planGroupedByMonth,
    setPlanGroupedByMonth,
    foundMonthToShow,
    setFoundMonthToShow,
    lastDayIndex,
    setLastDayIndex,
    planWasReset,
    setPlanWasReset,
    isFetchingPlan,
    setIsFetchingPlan,
    delays,
    setDelays,
    blockedWeekDays,
    planType,
    quantityOfDays,
    quantityOfChapters,
    isLoading,
    trackDelays,
    setIsLoading,
    setTrackDelays,
    setPlanType,
    setQuantityOfDays,
    setQuantityOfChapters,
    setBlockedWeekDays,
  } = useChallengePlan();

  const checklistRef = useRef(null);

  const { openGeneralErrorSnackbar } = useSnackbarNotification();

  const { challengeHeaders } = useChallenge();

  const headers = useHeaders();

  const { createModal, closeModal, updateActiveModalProps } = useModal();

  const numberOfMonthsInPlan = useMemo(
    () => plan.length && biblePlanUtil.getNumberOfMonthsInPlan(plan),
    [plan]
  );
  const planIsComplete = useMemo(() => checkIfPlanIsComplete(plan), [plan]);

  useEffect(() => {
    setIsLoadingPlan(isLoading || isFetchingPlan);
  }, [isFetchingPlan, isLoading, setIsLoadingPlan]);

  const isLate = useCallback(() => {
    const lastNonReadDateOBj = plan.find((day) => !day.read);

    if (!lastNonReadDateOBj) return;

    const lastNonReadDate = moment(lastNonReadDateOBj.date);

    return lastNonReadDate.isBefore(todayMorning());
  }, [plan]);

  const handleReplan = useCallback(() => {
    setIsLoading(true);

    try {
      replan({
        plan,
        blockedWeekDays,
        planType,
        quantityOfDays,
        quantityOfChapters,
      }).then((newPlan) => {
        syncPlanWithDB({
          readingCalendar: newPlan,
          updateDelays: true,
          id: planId,
          shouldTrackDelays: trackDelays,
          blockedWeekDays,
          quantityOfDays,
          quantityOfChapters,
          planType,
          delays,
          headers,
        }).then((response) => {
          setPlan(response.data.plan.readingCalendar);
          setPlanWasReset(true);
          setIsLoading(false);
          closeModal();
        });
      });
    } catch (error) {
      sendErrorEmail(error, "on resetting challenge plan");
      openGeneralErrorSnackbar();
      closeModal();
    }
  }, [
    blockedWeekDays,
    closeModal,
    delays,
    headers,
    openGeneralErrorSnackbar,
    plan,
    planId,
    planType,
    quantityOfChapters,
    quantityOfDays,
    setIsLoading,
    setPlan,
    setPlanWasReset,
    trackDelays,
  ]);

  useEffect(() => {
    if (isLate() && trackDelays) {
      createModal({
        title: delayedReadingModal,
        subtitle: subtitleDelayReading,
        onFirstButtonClick: closeModal,
        onSecondButtonClick: () => {
          updateActiveModalProps({
            secondButtonText: loading,
            secondButtonDisabled: true,
            firstButtonDisabled: true,
          });

          handleReplan();
        },
        firstButtonText: notNecessary,
        secondButtonText: resetPlan,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackDelays]);

  useEffect(() => {
    const fetchPlan = async () => {
      setIsFetchingPlan(true);
      try {
        await api
          .get("/challenge/plan", challengeHeaders)
          .then(({ data: { plan } }) => {
            setPlan(plan.readingCalendar);
            setPlanId(plan._id);
            setTrackDelays(plan.shouldTrackDelays);
            setPlanType(plan.type);
            setQuantityOfDays(plan.quantityOfDays);
            setQuantityOfChapters(plan.chaptersPerDay);
            setBlockedWeekDays(plan.blockedWeekDays);
            setDelays(plan.delays);
            setPlanIsLoaded(true);
          });
      } catch (err) {
        openGeneralErrorSnackbar();
      } finally {
        setIsFetchingPlan(false);
      }
    };

    !planIsLoaded && fetchPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const planIsReadyToShow = !!planGroupedByMonth.length && !isFetchingPlan;
    setIsLoading(!planIsReadyToShow && !foundMonthToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planGroupedByMonth, foundMonthToShow, isFetchingPlan, planWasReset]);

  useEffect(() => {
    if (foundMonthToShow && !isLoading)
      scrollIntoFirstUnread(checklistRef.current);
  }, [foundMonthToShow, isLoading]);

  const whenFindMonthToShow = useCallback(
    (found) => {
      setActiveMonth(found);
      setFoundMonthToShow(true);
    },
    [setActiveMonth, setFoundMonthToShow]
  );

  const whenMonthToShowNotFound = useCallback(() => {
    if (numberOfMonthsInPlan === planGroupedByMonth.length) {
      setActiveMonth(planGroupedByMonth.length - 1);
      return setFoundMonthToShow(true);
    }

    incrementMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planGroupedByMonth]);

  useEffect(() => {
    const loadedMonthsAreRead =
      !!planGroupedByMonth.length &&
      !planGroupedByMonth.some((month) => month.some((day) => !day.read));

    if (loadedMonthsAreRead && !planIsComplete && !isFetchingPlan) {
      loadMoreThreeMonths();
    } else if (
      !foundMonthToShow &&
      !!planGroupedByMonth.length &&
      !isFetchingPlan
    ) {
      findMonthToShow(
        planGroupedByMonth,
        whenFindMonthToShow,
        whenMonthToShowNotFound
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planGroupedByMonth, isFetchingPlan]);

  const loadMoreThreeMonths = (
    { useCurrentMonths = true, startIndex } = {},
    callback = () => {}
  ) => {
    if (!plan.length) return;

    if (plan.length === 1) return setPlanGroupedByMonth([[plan[0]]]);

    const result = biblePlanUtil.lazyLoadGroupedMonths({
      plan: plan,
      startIndex: Number.isSafeInteger(startIndex) ? startIndex : lastDayIndex,
      saveStartIndex: (index) => setLastDayIndex(index),
    });
    const newGroup = useCurrentMonths ? [...planGroupedByMonth] : [];
    result.forEach((item) => newGroup.push(item));

    callback(newGroup);

    setPlanGroupedByMonth(newGroup);
  };

  const resetAndLoadMonths = () => {
    if (!plan.length) return;

    const result = biblePlanUtil.lazyLoadGroupedMonths({
      plan: plan,
      startIndex: 0,
      saveStartIndex: (index) => setLastDayIndex(index),
    });

    const newGroup = [];
    result.forEach((item) => newGroup.push(item));
    setPlanGroupedByMonth(newGroup);
  };

  useEffect(() => {
    if (planWasReset && !isFetchingPlan) {
      setFoundMonthToShow(false);
      setPlanWasReset(false);
      resetAndLoadMonths();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planWasReset, isFetchingPlan]);

  useEffect(() => {
    const allMonthsLoaded =
      lastDayIndex === plan.length - 1 && plan.length !== 1;

    if (!allMonthsLoaded && !isFetchingPlan) loadMoreThreeMonths();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMonth, isFetchingPlan, isLoading]);

  const incrementMonth = () => setActiveMonth(activeMonth + 1);
  const decrementMonth = () => setActiveMonth(activeMonth - 1);

  if (isLoading) return <LoadingState />;

  return (
    <Fragment>
      <section className="plan-checklist">
        <ChecklistHeader
          activeMonth={activeMonth}
          planGroupedByMonth={planGroupedByMonth}
          incrementMonth={incrementMonth}
          decrementMonth={decrementMonth}
        />
        <div className="plan-checklist__body">
          <ChecklistElement ref={checklistRef}>
            {planGroupedByMonth[activeMonth]?.map((day, index) => (
              <ChecklistItem
                planId={planId}
                day={day}
                checked={day.read}
                key={day.date + index}
              />
            ))}
            <Footer />
          </ChecklistElement>
        </div>
      </section>
    </Fragment>
  );
};

const ChecklistElement = React.forwardRef(({ children }, ref) => {
  const checklistRef = useRef(null);

  useEffect(() => {
    if (checklistRef.current) {
      setTimeout(() => {
        if (checklistRef.current) {
          checklistRef.current.style.maxHeight = `${
            window.innerHeight -
            checklistRef.current.getBoundingClientRect().top -
            63
          }px`;
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistRef.current]);

  return (
    <div
      className="checklist"
      onScroll={(e) => {
        if (e.target.scrollTop === 0) {
          return e.target.classList.remove("isScrolling");
        }
        e.target.classList.add("isScrolling");
      }}
      ref={(node) => {
        checklistRef.current = node;

        ref.current = node;
      }}
    >
      {children}
    </div>
  );
});

export default ParticipantPlanView;
