import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";

const ParticipantsSkeleton = () => {
  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={190}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={27}
          width={80}
          style={{ marginBottom: "16px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={220}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={27}
          width={100}
          style={{ marginBottom: "16px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={150}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={27}
          width={110}
          style={{ marginBottom: "16px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={180}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={27}
          width={100}
          style={{ marginBottom: "16px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          className="skeleton"
          height={27}
          width={210}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={27}
          width={100}
          style={{ marginBottom: "16px" }}
        />
      </div>
    </div>
  );
};

export default memo(ParticipantsSkeleton);
