import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import { permissionDeniedSubtitle } from "./messages";
import { useNotifications } from "../Context";

const PermissionDenied = () => {
  const [openModal, setOpenModal] = useState(false);
  const { showPermissionDeniedModal } = useNotifications();

  useEffect(() => {
    setOpenModal(showPermissionDeniedModal);
  }, [showPermissionDeniedModal]);

  const closeModal = () => setOpenModal(false);

  return (
    <Modal
      title={":("}
      subtitle={permissionDeniedSubtitle}
      active={openModal}
      onSecondButtonClick={closeModal}
      secondButtonText={"Ok"}
    />
  );
};

export default PermissionDenied;
