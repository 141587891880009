import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import { api } from "utils";

const withChallengeName = (Component) => {
  return (props) => {
    const { idChallenge } = useParams();
    const [challengeName, setChallengeName] = useState("");

    useEffect(() => {
      api
        .get("challenge/name", { headers: { challenge_id: idChallenge } })
        .then((res) => {
          setChallengeName(res.data.challengeName);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} challengeName={challengeName} />;
  };
};

export default withChallengeName;
