export const bottomBar = {
  bottomBar: {
    home: "Início",
    createAChallenge: "Crie um desafio",
    menu: "Menu",
    challenges: "Desafios",
    ranking: "Ranking",
    plan: "Plano",
    questions: "Perguntas",
    configs: "Configs",
  },
};
