import React, { useEffect } from "react";

import Modal from "components/Modal";
import {
  delayedReadingModal,
  subtitle,
  firstButton,
  secondButton,
} from "./messages";
import ExceptionModal from "components/ExceptionModal";

import { usePlan } from "components/FormPlan/Context";
import { MuteReminder } from "molecules";

const ReplanModal = ({ trackDelays }) => {
  const {
    isLate,
    replan,
    askReplan,
    setAskReplan,
    showException,
    setShowException,
  } = usePlan();

  useEffect(() => {
    if (isLate() && trackDelays) setTimeout(() => setAskReplan(true), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ExceptionModal
        active={showException}
        onSecondButtonClick={() => setShowException(false)}
      />
      <Modal
        active={askReplan}
        title={delayedReadingModal}
        subtitle={subtitle}
        onFirstButtonClick={() => {
          setAskReplan(false);
        }}
        onOverlayClick={() => {
          setAskReplan(false);
        }}
        onSecondButtonClick={() => {
          replan();
          setAskReplan(false);
        }}
        firstButtonText={firstButton}
        secondButtonText={secondButton}
        underButtons={<MuteReminder />}
      />
    </>
  );
};

export default ReplanModal;
