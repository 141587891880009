import { useChallengePlan } from "contexts/Challenge/ChallengePlan";

import React from "react";

const FormFeedback = () => {
  const { formError } = useChallengePlan();

  if (formError) return <div className="form-feedback">{formError}</div>;

  return null;
};

export default FormFeedback;
