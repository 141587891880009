import React, { createContext, memo, useState } from "react";

export const QuestionContext = createContext();

export const QuestionProvider = ({ children }) => {
  const [id, setId] = useState(null);
  const [alternatives, setAlternatives] = useState(null);
  const [statement, setStatement] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [points, setPoints] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState();

  return (
    <QuestionContext.Provider
      value={{
        alternatives,
        statement,
        points,
        answer,
        id,
        isAnswered,
        isLoadingQuestion,
        showAnswer,
        selectedAnswer,
        setSelectedAnswer,
        setShowAnswer,
        setIsLoadingQuestion,
        setIsAnswered,
        setAlternatives,
        setStatement,
        setPoints,
        setAnswer,
        setId,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export default memo(QuestionProvider);
