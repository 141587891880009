import React, { memo } from "react";

const Copyright = React.forwardRef(({ isLoading, copyright }, ref) => {
  if (isLoading) return null;

  return (
    <div ref={ref} className="bible-reading__copyright">
      {copyright}
    </div>
  );
});

export default memo(Copyright);
