import { useState } from "react";

export const useLocalScroll = () => {
  const [isOnScrollTop, setIsOnScrollTop] = useState(1);
  const [isOnScrollBottomArea, setIsOnScrollBottomArea] = useState(0);
  const [isOnScrollBottom, setIsOnScrollBottom] = useState(0);
  const onScroll = (event) => {
    const element = event.target;
    const isWithoutScroll = element.scrollHeight === element.clientHeight;
    const isOnTop = element.scrollTop === 0;
    const isOnBottomArea =
      element.scrollTop + element.clientHeight >= element.scrollHeight - 100;
    const isOnScrollBottom =
      element.scrollTop + element.clientHeight >= element.scrollHeight;

    setIsOnScrollTop(isOnTop);
    setIsOnScrollBottomArea(isOnBottomArea);
    setIsOnScrollBottom(isOnScrollBottom && !isWithoutScroll);
  };
  return {
    isOnScrollTop,
    isOnScrollBottomArea,
    isOnScrollBottom,
    onScroll,
  };
};
