import React, { memo, useState } from "react";
import { ReactComponent as FacebookLogo } from "assets/images/icon-facebook.svg";
import Button from "components/Button";
import { useAuth } from "contexts/AuthContext";
import { loading } from "../messages";
import { sendErrorEmail } from "utils/sendEmail";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import axios from "axios";

const IOSFacebookLoginButton = ({ text }) => {
  const { handleFacebookLogin } = useAuth();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [isLoading, setIsLoading] = useState(false);

  const onError = (error) => {
    setIsLoading(false);
    openGeneralErrorSnackbar();
    sendErrorEmail(error, "on IOS facebook login");
  };

  const handleLogin = async () => {
    const FACEBOOK_PERMISSIONS = ["email"];
    const result = await FacebookLogin.login({
      permissions: FACEBOOK_PERMISSIONS,
    });

    if (result.accessToken && result.accessToken.userId) {
      loadUserData(result.accessToken);
    } else if (result.accessToken && !result.accessToken.userId) {
      getCurrentToken();
    } else {
      onError();
      // Login failed
    }
  };

  const getCurrentToken = async () => {
    const result = await FacebookLogin.getCurrentAccessToken();

    if (result.accessToken) {
      loadUserData(result.accessToken);
    } else {
      onError();
      // Not logged in.
    }
  };

  const loadUserData = async (token) => {
    const url = `https://graph.facebook.com/${token.userId}?fields=id,name,picture.width(720),birthday,email&access_token=${token.token}`;
    axios
      .get(url)
      .then((res) => {
        const user = {
          facebookId: res.data.id,
          ...res.data,
        };

        startLogin(user);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const startLogin = (user) => {
    handleFacebookLogin(
      {
        email: user.email,
        name: user.name,
        facebookId: user.facebookId,
        picture: user.picture?.data?.url,
      },
      (status) => setIsLoading(status)
    );
  };

  const onButtonClick = () => {
    setIsLoading(true);

    handleLogin();
  };

  return (
    <Button
      size="medium"
      className="social-button facebook font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <FacebookLogo />
      {isLoading ? loading : text}
    </Button>
  );
};

export default memo(IOSFacebookLoginButton);
