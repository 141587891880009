import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { dayTranslate, buildReadingDate } from "./messages";
import { ReadButton } from "components/ReadButton";
import { buildReadingInterval } from "utils/reading";
import { useAuth } from "contexts/AuthContext";
import { registerPlanUpdate, syncDayWithBD } from "./utils";
import { usePlan } from "components/FormPlan/Context";
import { ReactComponent as Checkbox } from "assets/images/checkbox.svg";
import { ReactComponent as CheckboxChecked } from "assets/images/checkbox-checked.svg";

const ChecklistItem = ({
  day,
  checked,
  toggleReadStatus,
  planId,
  onChecked,
}) => {
  const { date, readingDetails, _id } = day;
  const weekDay = moment(date).day();
  const dayNumber = moment(date).date();
  const { isLogged, getHeaders } = useAuth();
  const [isChecked, setIsChecked] = useState(checked);
  const headers = getHeaders();
  const readingInterval = buildReadingInterval(day);
  const readingDate = buildReadingDate(weekDay, dayTranslate, dayNumber);
  const { plan, isLoading, isFetchingPlan } = usePlan();

  useEffect(() => {
    const found = plan.find((item) => item._id === day._id);
    const shouldToggle =
      found && found?.read !== isChecked && !isLoading && !isFetchingPlan;
    shouldToggle && setIsChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  const onClick = () => {
    toggleReadStatus(_id);
    setIsChecked(!isChecked);
    onChecked(_id, !isChecked);

    isLogged &&
      syncDayWithBD(_id, !isChecked, headers).then(() =>
        registerPlanUpdate(planId, headers)
      );
  };

  return (
    <div
      className={classNames("checklist__item noClickHighlight", {
        checked: isChecked,
      })}
    >
      <div className="checklist__item-content" onClick={onClick}>
        <i className="checkbox">
          {isChecked ? <CheckboxChecked /> : <Checkbox />}
        </i>
        <article>
          <p>{readingInterval}</p>
          <span>{readingDate}</span>
        </article>
      </div>

      <ReadButton day={day} readingDetails={readingDetails} />
    </div>
  );
};

export default ChecklistItem;
