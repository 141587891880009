import React, { memo, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import useChallengeRanking from "contexts/Challenge/Ranking/useChallengeRanking";
import { general, questions, reading } from "../messages";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";
import { useChallenge } from "contexts/Challenge/useChallenge";

const RankingFilter = () => {
  const { rankingIsLoaded, loadReadersRanking } = useChallengePlan();
  const {
    viewGeneralRanking,
    setViewGeneralRanking,
    viewReadingRanking,
    setViewReadingRanking,
    viewQuestionsRanking,
    setViewQuestionsRanking,
  } = useChallengeRanking();
  const { viewingChallenge } = useChallenge();

  const viewingChallengeRef = useRef(viewingChallenge);

  const { challengeIsLoaded } = useChallenge();

  const switchedChallenge = useMemo(() => {
    return (
      viewingChallengeRef.current !== viewingChallenge && !!viewingChallenge
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingChallenge, viewingChallengeRef.current]);

  useEffect(() => {
    // if ((challengeIsLoaded && !rankingIsLoaded) || switchedChallenge) {
    if (switchedChallenge) {
      viewingChallengeRef.current = viewingChallenge;

      loadReadersRanking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeIsLoaded, rankingIsLoaded, switchedChallenge]);

  return (
    <div className="ranking-filter">
      <span
        className={classNames({ active: viewGeneralRanking })}
        onClick={() => {
          setViewQuestionsRanking(false);
          setViewReadingRanking(false);
          setViewGeneralRanking(true);
        }}
      >
        {general}
      </span>
      <span
        className={classNames({ active: viewQuestionsRanking })}
        onClick={() => {
          setViewReadingRanking(false);
          setViewGeneralRanking(false);
          setViewQuestionsRanking(true);
        }}
      >
        {questions}
      </span>
      <span
        className={classNames({ active: viewReadingRanking })}
        onClick={() => {
          setViewQuestionsRanking(false);
          setViewGeneralRanking(false);
          setViewReadingRanking(true);
        }}
      >
        {reading}
      </span>
    </div>
  );
};

export default memo(RankingFilter);
