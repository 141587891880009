import React, { memo } from "react";
import DatePicker from "components/DatePicker";
import { endDateLabel, startDateLabel } from "components/FormPlan/messages";
import { today, lastDayInYear } from "utils";

const DatesSelector = ({
  onBeforeTodayInitialDate,
  onAfterTodayInitialDate,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  blockedWeekDays,
}) => (
  <div className="form__row form-plan__inputs">
    <div className="input-wrap">
      <label htmlFor="" className="input-label">
        {startDateLabel}
      </label>
      <DatePicker
        blockedWeekDays={blockedWeekDays}
        onAfterTodayInitialDate={onAfterTodayInitialDate}
        onBeforeTodayInitialDate={onBeforeTodayInitialDate}
        date={startDate}
        onDateChange={setStartDate}
      />
    </div>
    <div className="input-wrap">
      <label htmlFor="" className="input-label">
        {endDateLabel}
      </label>

      <DatePicker
        blockedWeekDays={blockedWeekDays}
        date={endDate}
        onDateChange={setEndDate}
      />
    </div>
  </div>
);

DatesSelector.defaultProps = {
  onBeforeTodayInitialDate: () => {},
  onAfterTodayInitialDate: () => {},
  setStartDate: () => {},
  setEndDate: () => {},
  startDate: today,
  endDate: lastDayInYear,
};

export default memo(DatesSelector);
