import React, { Suspense, useEffect } from "react";
import { IS_PT_LANGUAGE } from "utils/language";
import ChallengeLaunched from "./components/ChallengeLaunched";
import Header from "./components/Header";

const SeeInAction = React.lazy(() => import("./components/SeeInAction"));
const Explanation = React.lazy(() => import("./components/Explanation"));
const Explanation2 = React.lazy(() => import("./components/Explanation2"));
const ChoosePlan = React.lazy(() => import("./components/ChoosePlan"));

const documentTitle = IS_PT_LANGUAGE
  ? "Desafio de leitura Bíblica - Bible Journey"
  : "Bible reading challenge - Bible Journey";

const description = IS_PT_LANGUAGE
  ? "Engaje seu grupo com a leitura da Bíblia por meio de um desafio de leitura da Bíblia"
  : "Engage your group with the Bible by creating a Bible reading challenge to them";

const ChallengePresentation = () => {
  useEffect(() => {
    document.title = documentTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="twitter:description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", description);
  }, []);

  return (
    <div className="challenge-lp">
      <Header />
      <Suspense fallback={<div />}>
        <Explanation />
        <ChallengeLaunched />
        <Explanation2 />
        <SeeInAction />
        <ChoosePlan />
      </Suspense>
    </div>
  );
};

export default ChallengePresentation;
