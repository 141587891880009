export const notifications = {
  notifications: {
    news: "We have updates!",
    newsSubtitle:
      "Now you can set a time for us to remind you to take your reading 🎉🔔",
    remindMeLater: "Remind me later",
    configureNow: "Configure now",
    permissionDeniedSubtitle:
      "You disabled notifications. But no problem! If you change your mind, just allow the notifications in your device's settings.",
    notificationConfigTitle: "Choose your time",
    save: "Save",
    alarmDefined: "Alarm is set 🎉🔔",
    alarmDefinedParagraph1: "You will receive a reminder every day at",
    alarmDefinedParagraph2:
      "If you want to change the time, go to the menu and click on ”edit reminder”. ",
    close: "Close",
    reminderTitle: "Set your reminder",
    reminderSubtitle: "Set a time for us to remind you to do your reading 🎉🔔",
    askToContinueTitle: "Do you still want us to remind?",
    stopReminders: "Don't remind me anymore",
    somethingWentWrongTitle: "Sorry :(",
    somethingWentWrongSubtitle:
      "Something went wrong setting your reminders. Please try again later.",
    push: {
      title: "Hey! Let's read the Bible? 😊",
      body: "Stay up to date with your plan 📖",
    },
  },
};
