import { isIOSdevice, isMac } from "utils/devices";

const DownloadRedirect = () => {
  if (isIOSdevice || isMac) {
    window.location.href =
      "https://apps.apple.com/br/app/bibleplan-plano-de-leitura/id1581039806?l=en";

    return null;
  }

  window.location.href =
    "https://play.google.com/store/apps/details?id=xyz.appmaker.ybhmxs";

  return null;
};

export default DownloadRedirect;
