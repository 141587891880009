import React, {
  createContext,
  useState,
  useCallback,
  memo,
  useEffect,
  useMemo,
} from "react";
import api from "utils/api";
import { loadStripe } from "@stripe/stripe-js";
import { useUser } from "contexts/User";
import { sendErrorEmail } from "utils/sendEmail";
import { useHistory, withRouter } from "react-router";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import iosStore from "cordova-plugin-purchase/www/store-ios";
import { isIOSdevice, isNativeIOS } from "utils";
import { useHeaders } from "hooks";
import { useModal } from "contexts/Modal/useModal";
import { weWillRedirect } from "pages/Challenges/Presentation/components/messages";
export const CheckoutContext = createContext();

const IOS_MONTHLY_KEY = "bibleplan_monthly_0";
const IOS_UNIQUE_PT_KEY = "bibleplan_unique_1";
const IOS_UNIQUE_EN_KEY = "bibleplan_unique_0";

// const iosStore = new InAppPurchase2();

export const CheckoutProvider = ({ children }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [prices, setPrices] = useState(null);
  const [iosProducts, setProducts] = useState(null);
  const [checkoutUnavailable, setCheckoutUnavailable] = useState(false);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const headers = useHeaders();
  const [isIOSPurchasing, setIOSPurchasing] = useState();
  const { premium, updatePremiumInfo, getSubscription, isLogged } = useUser();
  const { createModal, closeAllModals } = useModal();
  const [shouldSaveIOSPurchase, setShouldSaveIOSPurchase] = useState(null);

  useEffect(() => {
    if (shouldSaveIOSPurchase && isLogged) {
      try {
        api
          .post("/checkout/ios/save", shouldSaveIOSPurchase, headers)
          .then(() => {
            setShouldSaveIOSPurchase(null);

            updatePremiumInfo({
              type: shouldSaveIOSPurchase.type,
              title: shouldSaveIOSPurchase.product_title,
            });

            createModal({
              subtitle: weWillRedirect,
            });

            setTimeout(() => {
              closeAllModals();
              getSubscription();
              history.push("/challenges/new/name?iosPurchase=true");
            }, 3000);

            setIOSPurchasing(null);
          })
          .catch((err) => {
            console.log("error saving ios purchase", err);
            openGeneralErrorSnackbar();
            sendErrorEmail(err, "error saving ios purchase");
            setShouldSaveIOSPurchase(null);
            setIOSPurchasing(null);
          });
      } catch (err) {
        console.log("error saving ios purchase", err);
        openGeneralErrorSnackbar();
        sendErrorEmail(err, "error saving ios purchase");
        setShouldSaveIOSPurchase(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, shouldSaveIOSPurchase]);

  const history = useHistory();

  const isOnCheckoutPage = useMemo(
    () => history.location.pathname === "/challenges/presentation",
    [history.location.pathname]
  );

  useEffect(() => {
    if (isNativeIOS) {
      registerProducts();
      setupListeners();
      iosStore.ready(() => {
        setProducts(iosStore.products);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerProducts = useCallback(() => {
    iosStore.register({
      id: IOS_MONTHLY_KEY,
      type: iosStore.NON_CONSUMABLE,
    });
    iosStore.register({
      id: IOS_UNIQUE_PT_KEY,
      type: iosStore.CONSUMABLE,
    });
    iosStore.register({
      id: IOS_UNIQUE_EN_KEY,
      type: iosStore.CONSUMABLE,
    });
    iosStore.refresh();
  }, []);

  const onIOSPurchaseFinished = useCallback(async (product) => {
    const billingType =
      product.billingPeriodUnit === "Day" ? "one_time" : "subscription";
    const data = {
      type: billingType,
      product_title: product.title
        ? product.title
        : product.billingPeriodUnit === "Day"
        ? "One-time purchase"
        : "Subscription",
      amount_paid: parseFloat(
        product.price.replace(",", ".").replace("R$", "").replace("$", "") * 100
      ).toFixed(0),
      currency_paid: product.currency,
      transaction: product.transaction,
    };

    setShouldSaveIOSPurchase(data);
  }, []);

  const setupListeners = () => {
    console.log("setupListeners chamado");
    iosStore
      .when("product")
      .approved((product) => {
        const isSafePurchase = new URLSearchParams(window.location.search).get(
          "isSafePurchase"
        );

        console.log("foi aproved>>>", product);

        if (
          (product.id === IOS_MONTHLY_KEY ||
            product.id === IOS_UNIQUE_PT_KEY ||
            product.id === IOS_UNIQUE_EN_KEY) &&
          isSafePurchase
        ) {
          onIOSPurchaseFinished(product);
        }

        return product.verify();
      })
      .verified((product) => product.finish());

    iosStore.when("product").error((err) => {
      console.log("Purchase Error: ", err);
      sendErrorEmail(JSON.stringify(err), "iosStore.when.product.error");
      closeAllModals();
      openGeneralErrorSnackbar();
      setIOSPurchasing(null);
    });

    iosStore.when("product").cancelled(() => {
      console.log("purchase was cancelled");
      closeAllModals();
      setIOSPurchasing(null);
    });
  };

  const inAppIOSPurchase = (productId) => {
    iosStore.order(productId).then(
      async () => {
        setIOSPurchasing({
          oneTime: productId !== IOS_MONTHLY_KEY,
          subscription: productId === IOS_MONTHLY_KEY,
        });
      },
      (error) => {
        console.log("Purchase error:", error);
        setIOSPurchasing(null);

        openGeneralErrorSnackbar();
        sendErrorEmail(error, "on purchasing with IOS InAppPurchase2");
      }
    );
  };

  const createCustomer = useCallback(
    async (email) => {
      if (premium?.customerId) return setCustomer({ id: premium.customerId });

      try {
        const { data } = await api.post("/checkout/create-customer", { email });
        setCustomer(data.customer);
      } catch (err) {
        setCheckoutUnavailable(true);
        sendErrorEmail(err, "on contexts/Checkout: createCustomer");
        openGeneralErrorSnackbar();
      }
    },
    [openGeneralErrorSnackbar, premium.customerId]
  );

  const loadCheckoutConfig = useCallback(async () => {
    try {
      const { data } = await api.get("/checkout/config");
      const stripePromise = loadStripe(data.publishableKey);

      setStripePromise(stripePromise);
      setPrices(data.prices);
    } catch (err) {
      openGeneralErrorSnackbar();
      setCheckoutUnavailable(true);
      sendErrorEmail(err, "on contexts/Checkout:loadCheckoutConfig");
    }
  }, [openGeneralErrorSnackbar]);

  useEffect(() => {
    isOnCheckoutPage && !isIOSdevice && loadCheckoutConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnCheckoutPage]);

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      setClientSecret(clientSecret);
    }
  }, []);

  const createPaymentIntent = async (price_id) => {
    try {
      const response = await api.post("/checkout/create-payment-intent", {
        price_id,
      });

      if (!response.data.clientSecret) {
        setClientSecret(false);
      }

      setClientSecret(response.data.clientSecret);
    } catch (err) {
      sendErrorEmail(err, "on contexts/Checkout: creating payment intent");
      setClientSecret(false);
      setCheckoutUnavailable(true);
      openGeneralErrorSnackbar();
    }
  };

  const createSubscription = async (price_id) => {
    try {
      const {
        data: { subscriptionId, clientSecret },
      } = await api.post("/checkout/create-subscription", {
        priceId: price_id,
        customerId: customer.id,
      });

      setSubscriptionData({ subscriptionId, clientSecret });
    } catch (err) {
      sendErrorEmail(err, "on contexts/Checkout: createSubscription");
      setCheckoutUnavailable(true);
      openGeneralErrorSnackbar();
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        customer,
        stripePromise,
        createPaymentIntent,
        clientSecret,
        createSubscription,
        subscriptionData,
        prices,
        checkoutUnavailable,
        createCustomer,
        inAppIOSPurchase,
        iosProducts,
        isIOSPurchasing,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default withRouter(memo(CheckoutProvider));
