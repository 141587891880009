import { useContext } from "react";
import { ReportErrorContext } from "./Component";

const useReportError = () => {
  const context = useContext(ReportErrorContext);

  if (!context)
    throw new Error("useReportError must be used within a ReportErrorProvider");

  const { openReportErrorModal } = context;
  return { openReportErrorModal };
};

export default useReportError;
