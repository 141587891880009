import { i18n } from "translate/i18n";

export const challengeLaunched = i18n.t("messages.challenge.challengeLaunched");
export const emailSent = i18n.t("messages.challenge.emailSent");
export const ranking = i18n.t("messages.challenge.ranking");
export const name = i18n.t("messages.challenge.name");
export const points = i18n.t("messages.challenge.points");
export const general = i18n.t("messages.challenge.general");
export const questions = i18n.t("messages.challenge.questions");
export const reading = i18n.t("messages.challenge.reading");
