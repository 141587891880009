import React, { memo } from "react";
import TextLoading from "./TextLoading";

const Verses = ({ isLoading, verses }) => {
  return (
    <article className="bible-chapters">
      {isLoading ? (
        <TextLoading />
      ) : (
        <ul className="bible-chapter__verses">
          {verses?.map((verse, index) => (
            <li className="bible-chapter__verse" key={index}>
              <span className="bible-chapter__verse__number">
                {verse.number}
              </span>
              {verse.text}
            </li>
          ))}
        </ul>
      )}
    </article>
  );
};

export default memo(Verses);
