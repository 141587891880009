import React from "react";
import { addTheParticipants, challengeCreated, nowLastStep } from "./messages";
import App from "components/App";
import { Navbar } from "components/Navbar";
import Header from "components/App/Header";
import AddParticipants from "../Challenge/Configs/EditParticipants/AddParticipants";
import withChallenge from "HOCs/withChallenge";
import MainCard from "components/MainCard";

const NewChallengeParticipants = () => {
  return (
    <App>
      <Navbar backTo="/challenges" />
      <Header title={challengeCreated} subtitle={nowLastStep} color="white" />

      <MainCard title={addTheParticipants} centerTitle>
        <AddParticipants />
      </MainCard>
    </App>
  );
};

export default withChallenge(NewChallengeParticipants);
