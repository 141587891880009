import React, { Fragment, memo } from "react";
import Select from "molecules/Select";
import { connectAndDownload, noVersionsOffline, version } from "./messages";
import { useBible } from "contexts/Bible";
import { ReactComponent as OfflineIcon } from "assets/icons/offline.svg";
import classNames from "classnames";

const OfflineState = () => {
  return (
    <div className="offline-versions-state">
      <div className="offline-versions-state__text">
        <h4>{noVersionsOffline}</h4>
        <p>{connectAndDownload}</p>
      </div>
      <OfflineIcon />
    </div>
  );
};

const VersionsSelector = ({ isLoading, chooseVersion, currentVersion }) => {
  const { versions } = useBible();

  const onSelectOption = (version) => {
    chooseVersion(version);
  };

  if (isLoading) return null;

  return (
    <div
      className={classNames("bible-reading__versions-selector isBeforeText")}
    >
      <Select
        onSelectOption={onSelectOption}
        items={versions}
        selected={{ value: currentVersion.abbr, id: currentVersion.id }}
        selectedPrefix={
          <Fragment>
            <span className="pipe">|</span> {version}:{" "}
          </Fragment>
        }
        OfflineState={<OfflineState />}
      />
    </div>
  );
};

export default memo(VersionsSelector);
