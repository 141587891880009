export const notifications = {
  notifications: {
    news: "Novidade!",
    newsSubtitle:
      "Agora você pode definir um horário para te lembrarmos de fazer sua leitura 🎉🔔",
    remindMeLater: "Lembre-me depois",
    configureNow: "Configurar agora",
    permissionDeniedSubtitle:
      "Você não nos permitiu mostrar notificações. Mas sem problemas! Caso mude de ideia, basta adicionar a permissão nas configurações do seu aparelho.",
    notificationConfigTitle: "Escolha o seu horário",
    save: "Salvar",
    alarmDefined: "Horário definido 🎉🔔",
    alarmDefinedParagraph1: "Você receberá um lembrete todos os dias às",
    alarmDefinedParagraph2:
      "Caso queira mudar o horário, vá no menu e clique em ”editar lembrete”. ",
    close: "Fechar",
    reminderTitle: "Configure seu lembrete",
    reminderSubtitle:
      "Defina um horário para te lembrarmos de fazer sua leitura 🎉🔔",
    askToContinueTitle: "Ainda quer lembremos?",
    stopReminders: "Não me lembre mais",
    somethingWentWrongTitle: "Desculpa :(",
    somethingWentWrongSubtitle:
      "Algo deu errado ao configurar seus lembretes. Tente novamente mais tarde.",
    push: {
      title: "Oi! Vamos ler a Bíblia? 😊",
      body: "Fique em dia com o seu plano de leitura 📖",
    },
  },
};
