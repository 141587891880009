import React, { Fragment, memo } from "react";
import { Link } from "react-router-dom";

import backArrow from "assets/images/left-arrow--white-2.svg";
import { ReactComponent as BibleJourneyLogo } from "assets/images/logotipo.svg";

import BackArrowNavbar from "./BackArrowNavbar";
import classNames from "classnames";

const Navbar = ({ backTo, title, onlyBackArrow }) => {
  if (onlyBackArrow) return <BackArrowNavbar backTo={backTo} />;

  return (
    <nav className="navbar">
      <div className={classNames("navbar__inside", { "only-arrow": backTo })}>
        {backTo ? (
          <Fragment>
            <Link className="navbar__back" to={backTo}>
              <img className="arrow" src={backArrow} alt="back" />
            </Link>
            <span className="navbar__title">{title}</span>
            <span className="navbar__right" />
          </Fragment>
        ) : (
          <BibleJourneyLogo className="navbar__logo" />
        )}
      </div>
    </nav>
  );
};

export default memo(Navbar);
