export const findMonthToShow = (planMonth, onFoundMonth, onMonthNotFound) => {
  const found = planMonth.find((item, index) => {
    if (item.some((day) => day.read === false)) {
      onFoundMonth(index);
      return true;
    }
    return false;
  });
  if (!found) onMonthNotFound();
};

export const scrollIntoFirstUnread = (checklist) => {
  const noChecked = checklist?.querySelector(".checklist__item:not(.checked)");

  if (noChecked) {
    let scrollTop = noChecked.offsetTop - noChecked.offsetHeight;

    setTimeout(() => {
      checklist.scrollTop = scrollTop;
    }, 500);
  }
};
