import React, { memo } from "react";
import { useChallenge } from "contexts/Challenge/useChallenge";
import Header from "components/App/Header";
import { ProgressBar, ProgressBarLoading } from "atoms";
import { usePlanProgress } from "contexts/Challenge/PlanProgress";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";
import { useEffect } from "react";
import { loading } from "components/SocialLoginButtons/messages";

const ChallengePlanHeader = () => {
  const { currentChallenge } = useChallenge();
  const { isLoadingPlan } = useChallengePlan();
  const { progress, setProgress, setUnit } = usePlanProgress();

  const { plan } = useChallengePlan();

  useEffect(() => {
    if (!!plan.length) {
      const read = plan.filter((item) => item.read);
      const progress = (read.length / plan.length) * 100;

      setUnit(100 / plan.length);
      setProgress(parseFloat(progress.toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  return (
    <Header
      title={isLoadingPlan ? loading : currentChallenge.title}
      color="white"
      additionalComponent={
        isLoadingPlan ? (
          <ProgressBarLoading />
        ) : (
          <ProgressBar percentage={progress} />
        )
      }
    />
  );
};

export default memo(ChallengePlanHeader);
