import { i18n } from "translate/i18n";
import Bible from "utils/Bible";
import BiblicalPlan from "utils/BiblicalPlan";

const bible = new Bible();
const biblicalPlan = new BiblicalPlan();

export const bibleBook = (name, useAbbreviation) => {
  return i18n.t(
    `messages.bibleBooks.${name}.${useAbbreviation ? "abbreviation" : "name"}`
  );
};

export const buildReadingInterval = (day, useAbbreviation) => {
  const { readingStart, readingEnd, readingDetails } = day;

  const readingRangeArray = bible.createArrayByReadingRange(day);

  const shouldSplitReading = !biblicalPlan.readingArraysAreEqual(
    readingRangeArray,
    readingDetails
  );

  if (shouldSplitReading) {
    const { startReading, endReading } =
      biblicalPlan.createSplittedReadingInterval(
        readingRangeArray,
        readingDetails
      );

    return buildSplittedReadingInterval(startReading, endReading);
  }

  const endBook =
    readingEnd.book === readingStart.book
      ? ""
      : bibleBook(readingEnd.book, useAbbreviation);

  const start = `${bibleBook(readingStart.book, useAbbreviation)} ${
    readingStart.chapter
  }`;
  const end = `${endBook} ${readingEnd.chapter}`;

  if (
    readingStart.chapter === readingEnd.chapter &&
    readingStart.book === readingEnd.book
  )
    return `${start}`;

  return `${start} - ${end}`;
};

export const buildSplittedReadingInterval = (startReading, endReading) => {
  let slittedReadingInterval = "";
  startReading.forEach((reading, index, array) => {
    const shouldRenderUniqueRead =
      reading.book === endReading[index].book &&
      reading.chapter === endReading[index].chapter;

    const endBook =
      reading.book === endReading[index].book
        ? ""
        : bibleBook(endReading[index].book);

    const uniqueRead = `${bibleBook(reading.book)} ${reading.chapter}`;
    const multipleRead = `${bibleBook(reading.book)} ${
      reading.chapter
    } - ${endBook} ${endReading[index].chapter}`;

    const read = shouldRenderUniqueRead ? uniqueRead : multipleRead;

    const renderComma = index !== array.length - 1;
    const comma = renderComma ? ", " : "";

    slittedReadingInterval += `${read}${comma}`;
  });

  return slittedReadingInterval;
};
