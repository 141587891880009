import { i18n } from "translate/i18n";

export const existingAccountUsing = (socialMedia) =>
  i18n.t("messages.authentication.existingAccountUsing", {
    socialMedia,
  });

export const somethingWentWrong = i18n.t(
  "messages.authentication.somethingWentWrong"
);
export const accountNotFound = i18n.t(
  "messages.authentication.accountNotFound"
);
export const register = i18n.t("messages.authentication.register");
export const emailRegistered = i18n.t(
  "messages.authentication.emailRegistered"
);
export const login = i18n.t("messages.authentication.login");
export const invalidSolicitation = i18n.t(
  "messages.authentication.invalidSolicitation"
);
export const expiredSolicitation = i18n.t(
  "messages.authentication.expiredSolicitation"
);
export const askAgain = i18n.t("messages.authentication.askAgain");
export const passwordChanged = i18n.t(
  "messages.authentication.passwordChanged"
);
export const accountConfirmed = i18n.t(
  "messages.authentication.accountConfirmed"
);
