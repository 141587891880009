import React, { useEffect } from "react";

import App from "components/App";
import MainCard from "components/MainCard";
import { PlanChecklistV2 } from "components/PlanChecklistV2";

import { PlanHeader } from "./PlanHeader";
import { useHistory } from "react-router-dom";
import { Navbar } from "components/Navbar";
import { useAuth } from "contexts/AuthContext";
import { usePlan } from "components/FormPlan/Context";

const Plan = () => {
  const {
    formError,
    fetchPlan,
    hasPlanInLocalStorage,
    isFetchingPlan,
    setIsLoading,
    setIsFetchingPlan,
  } = usePlan();
  const history = useHistory();
  const { isLogged } = useAuth();

  useEffect(() => {
    if (isLogged) {
      fetchPlan();
    } else {
      setIsFetchingPlan(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.scrollingElement.scrollTop = 0;
  }, []);

  if (!isLogged && !hasPlanInLocalStorage) {
    history.push("/new");
    return null;
  }

  if (!isFetchingPlan && !hasPlanInLocalStorage) {
    history.push("/new");
    return null;
  }

  return (
    <App>
      <Navbar />
      <PlanHeader isLoading={isFetchingPlan} error={formError} />
      <MainCard error={formError} containerOnly={true}>
        <PlanChecklistV2 />
      </MainCard>
    </App>
  );
};

export default Plan;
