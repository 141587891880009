import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as PlusIcon } from "assets/images/plus-icon.svg";
import { selectChaptersPerDay, moreOptions, selectQuantity } from "../messages";
import { usePlan } from "../Context";
import classNames from "classnames";
import Accordion from "components/Accordion";
import DidntFind from "./DidntFind";
import QuantityOfDays from "../Forms/QuantityOfDays";
import ChaptersPerDay from "../Forms/ChaptersPerDay";
import { planTypes } from "../Context/planType";
import { getItemMaxHeightStyle } from "components/Accordion/getItemMaxHeightStyle";

const MoreOptions = () => {
  const [hidden, setHidden] = useState(false);
  const { openMoreOptions, setOpenMoreOptions, setPlanType } = usePlan();
  const contentRef = useRef(null);
  const { formError } = usePlan();
  const [contentStyle, setContentStyle] = useState({});

  useEffect(() => {
    setContentStyle(getItemMaxHeightStyle(openMoreOptions, contentRef.current));
  }, [openMoreOptions, hidden, formError]);

  useEffect(() => {
    !openMoreOptions && setPlanType(planTypes.default);
  }, [openMoreOptions, setPlanType]);

  const onClick = useCallback(
    () => setOpenMoreOptions(!openMoreOptions),
    [openMoreOptions, setOpenMoreOptions]
  );

  const hideMoreOptions = useCallback((hide) => {
    setHidden(hide);
  }, []);

  const onSelectChaptersPerDay = () => {
    setPlanType(planTypes.chaptersPerDay);
  };

  const onSelectQuantityOfDays = () => {
    setPlanType(planTypes.quantityOfDays);
  };

  const options = [
    {
      title: selectQuantity,
      Component: <QuantityOfDays />,
      onClick: onSelectQuantityOfDays,
    },
    {
      title: selectChaptersPerDay,
      Component: <ChaptersPerDay />,
      onClick: onSelectChaptersPerDay,
    },
  ];

  return (
    <Fragment>
      <div
        onClick={onClick}
        className={classNames("form__row more-options", {
          openMoreOptions,
          hidden,
        })}
      >
        <PlusIcon /> <p>{moreOptions}</p>
      </div>
      <div
        ref={contentRef}
        className={classNames("more-options__content", {
          openMoreOptions,
          hideDidntFind: hidden,
        })}
        style={contentStyle}
      >
        <Accordion
          reRender={formError}
          hideAccordion={hideMoreOptions}
          options={options}
        />

        <DidntFind />
      </div>
    </Fragment>
  );
};

export default MoreOptions;
