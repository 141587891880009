import { connect } from "react-redux";
import { resetPlanV1 } from "store/actions/plan";
import { resetLocalPlan } from "store/actions/planv2";
import { handleLogout } from "store/actions/user";

import Logout from "./Component";

const mapDispatchToProps = (dispatch) => ({
  handleLogout: (payload) => dispatch(handleLogout(payload)),
  resetPlanV1: (payload) => dispatch(resetPlanV1(payload)),
  resetLocalPlan: (payload) => dispatch(resetLocalPlan(payload)),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
