import React, { memo } from "react";
import { configs } from "../../messages";
import { ReactComponent as ConfigIcon } from "assets/icons/config.svg";
import { NavLink } from "react-router-dom";
import { useChallenge } from "contexts/Challenge/useChallenge";

const Configs = () => {
  const { viewingChallenge } = useChallenge();

  return (
    <NavLink
      to={`/challenge/${viewingChallenge}/configs`}
      className="bottom-bar__icon bottom-bar__icon noClickHighlight"
    >
      <div className="bottom-bar__icon-icon">
        <ConfigIcon className="bottom-bar__icon-svg--fill" />
      </div>
      <div className="bottom-bar__icon-text">{configs}</div>
    </NavLink>
  );
};

export default memo(Configs);
