import React, { memo, useRef, useState } from "react";
import { useEffect } from "react";
import { progress } from "./messages";

const ProgressBar = ({ percentage, showLabel, animateLabel }) => {
  const labelRef = useRef();
  const barRef = useRef();
  const [percentageShown, setPercentageShown] = useState(0);

  useEffect(() => {
    let interval = null;

    if (animateLabel && labelRef.current) {
      let counter = percentageShown;

      interval = setInterval(() => {
        if (labelRef?.current?.innerHTML) {
          labelRef.current.innerHTML = `${counter}%`;
        }

        if (barRef?.current?.style?.width) {
          barRef.current.style.width = `${counter}%`;
        }

        const isDecreasing = percentage < percentageShown;

        if (
          (isDecreasing && counter <= parseInt(percentage)) ||
          (!isDecreasing && counter >= parseInt(percentage))
        ) {
          clearInterval(interval);
          setPercentageShown(percentage);

          labelRef.current.innerHTML = `${percentage}%`;
          barRef.current.style.width = `${percentage}%`;
        }

        isDecreasing ? counter-- : counter++;
      }, 10);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  return (
    <div className="progress-bar">
      {showLabel && (
        <div className="progress-bar__label">
          {progress}: <span ref={labelRef}></span>
        </div>
      )}
      <div className="progress-bar__bar">
        <div className="progress-bar__bar-fill" ref={barRef}></div>
      </div>
      {/* <div className="progress-bar__dates">
        <div className="progress-bar__date-start">Mar 1</div>
        <div className="progress-bar__date-end">Dez 1</div>
      </div> */}
    </div>
  );
};

ProgressBar.defaultProps = {
  percentage: 0,
  showLabel: true,
  animateLabel: true,
};

export default memo(ProgressBar);
