import React from "react";
import { FormPlan } from "components/FormPlan";
import MainCard from "components/MainCard";
import App from "components/App";
import Header from "components/App/Header";

import { title, subtitle, createNow } from "./messages";

const NewPlan = () => {
  return (
    <App>
      <Header title={title} subtitle={subtitle} color="white" />
      <MainCard title={createNow}>
        <FormPlan />
      </MainCard>
    </App>
  );
};

export default NewPlan;
