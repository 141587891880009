import React, { memo, useEffect, useState } from "react";
import { ReactComponent as FacebookLogo } from "assets/images/icon-facebook.svg";
import Button from "components/Button";
import { useAuth } from "contexts/AuthContext";
import { loading } from "../messages";
import { sendErrorEmail } from "utils/sendEmail";
import { useSnackbarNotification } from "components/SnackbarNotifications";

const WebLoginButton = ({ text }) => {
  const [facebookUser, setFacebookUser] = useState({});
  const { handleFacebookLogin } = useAuth();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ("FB" in window && !!Object.keys(facebookUser).length) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.api(
            response.authResponse.userID,
            "GET",
            { fields: "id, email, name, picture" },
            (user) => setFacebookUser({ facebookId: user.id, ...user })
          );
        }
      });
    }
  }, [facebookUser]);

  const startLogin = (user) => {
    handleFacebookLogin(
      {
        email: user.email,
        name: user.name,
        facebookId: user.facebookId,
        picture: user.picture?.data?.url,
      },
      (status) => setIsLoading(status)
    );
  };

  const onButtonClick = () => {
    const isConnected = !!facebookUser.email && facebookUser.facebookId;
    setIsLoading(true);

    if (isConnected) {
      startLogin(facebookUser);
    } else {
      window.FB.login(
        (response) => {
          if (response.status === "connected") {
            window.FB.api(
              response.authResponse.userID,
              "GET",
              { fields: "id, email, name, picture" },
              (user) => startLogin({ facebookId: user.id, ...user })
            );
          } else {
            setIsLoading(false);
            openGeneralErrorSnackbar();
            sendErrorEmail(JSON.stringify(response), "on facebook login");
          }
        },
        { scope: "public_profile,email" }
      );
    }
  };

  return (
    <Button
      size="medium"
      className="social-button facebook font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <FacebookLogo />
      {isLoading ? loading : text}
    </Button>
  );
};

export default memo(WebLoginButton);
