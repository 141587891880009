import { useContext } from "react";
import { PlanProgressContext } from "./Component";

const usePlanProgress = () => {
  const context = useContext(PlanProgressContext);
  if (!context)
    throw new Error(
      "usePlanProgress must be used within a PlanProgressProvider"
    );

  return context;
};

export default usePlanProgress;
