export const months = {
  months: {
    0: "Styczeń",
    january: "Styczeń",
    1: "Luty",
    february: "Luty",
    2: "Marzec",
    march: "Marzec",
    3: "Kwiecień",
    april: "Kwiecień",
    4: "Maj",
    may: "Maj",
    5: "Czerwiec",
    june: "Czerwiec",
    6: "Lipiec",
    july: "Lipiec",
    7: "Sierpień",
    august: "Sierpień",
    8: "Wrzesień",
    september: "Wrzesień",
    9: "Październik",
    october: "Październik",
    10: "Listopad",
    november: "Listopad",
    11: "Grudzień",
    december: "Grudzień",
  },
};
