import React, { useCallback, useEffect, useState } from "react";

import withChallenge from "HOCs/withChallenge";
import { addQuestion, failedListingQuestions } from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import Button from "components/Button";
import { useHistory } from "react-router-dom";
import { useQuestion } from "contexts/Challenge/Question";
import { sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import NoQuestions from "./NoQuestions";
import Skeleton from "./Skeleton";
import { ReactComponent as CheckedIcon } from "assets/icons/check.svg";
import { ReactComponent as RedXIcon } from "assets/icons/x-red.svg";

const List = ({ idChallenge }) => {
  const {
    viewingChallenge,
    isOwner,
    request: challenge,
    isLoadingChallenge,
  } = useChallenge();
  const history = useHistory();
  const {
    setPoints,
    setId,
    setAlternatives,
    setStatement,
    setAnswer,
    setIsAnswered,
    setShowAnswer,
    setSelectedAnswer,
  } = useQuestion();

  const { openCustomSnackbar } = useSnackbarNotification();

  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchQuestions = useCallback(async () => {
    try {
      const path = isOwner ? "owner/questions" : "participant/questions";

      const {
        data: { questions },
      } = await challenge.get(path);
      setQuestions(questions);
    } catch (err) {
      sendErrorEmail(err, "on listing questions");
      openCustomSnackbar({ message: failedListingQuestions, variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [challenge, openCustomSnackbar, isOwner]);

  useEffect(() => {
    fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingChallenge]);

  const onQuestionClick = useCallback(
    (question) => {
      setId(question._id);
      setAlternatives(question.alternatives);
      setStatement(question.statement);
      setAnswer(isOwner ? question.answer : question.answer?.answer);
      setPoints(question.points);
      setIsAnswered(!!question.answer);
      setShowAnswer(!!question.answer);
      setSelectedAnswer(question.answer?.answerGiven);

      history.push(`/challenge/${viewingChallenge}/question/${question._id}`);
    },
    [
      setId,
      setAlternatives,
      setStatement,
      setAnswer,
      isOwner,
      setPoints,
      setIsAnswered,
      setShowAnswer,
      setSelectedAnswer,
      history,
      viewingChallenge,
    ]
  );

  if (isLoading) return <Skeleton className="skeleton" />;

  return (
    <div className="questions">
      <div className="questions__list">
        {questions.map((question, index) => {
          const isAnswered = !!question.answered;
          const correctlyAnswered =
            question?.answer?.answer === question?.answer?.answerGiven;

          return (
            <div
              className="question"
              key={index}
              onClick={() => onQuestionClick(question)}
            >
              <p className="question__text">{question.statement}</p>

              {isAnswered &&
                (correctlyAnswered ? (
                  <div className="question__feedback">
                    <div className="question__checked-points">
                      +{question.points}
                    </div>
                    <div className="question__checked success">
                      <CheckedIcon />
                    </div>
                  </div>
                ) : (
                  <div className="question__checked error">
                    <RedXIcon />
                  </div>
                ))}
            </div>
          );
        })}
        {!questions.length && <NoQuestions />}
      </div>
      {isOwner && (
        <div className="questions__add">
          <Button
            size="medium"
            className="button--success  mt8"
            variation="square-rounded"
            theme="primary"
            goTo={`/challenge/${viewingChallenge}/questions/add`}
          >
            {addQuestion}
          </Button>
        </div>
      )}
    </div>
  );
};

export default withChallenge(List);
