const { useLocation, useHistory } = require("react-router-dom");

const useQuery = (query) => {
  const history = useHistory();
  const { search } = useLocation();

  const removeQuery = (param) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    params.delete(param);

    const newUrl = url.pathname + "?" + params.toString();
    history.replace(newUrl);
  };

  const resetQueries = () => {
    const url = new URL(window.location.href);

    const newUrl = url.pathname;
    history.replace(newUrl);
  };

  const addQuery = (param, value, replace) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    params.set(param, value);

    const newUrl = url.pathname + "?" + params.toString();
    replace ? history.replace(newUrl) : history.push(newUrl);
  };

  if (!query) return { removeQuery, addQuery, resetQueries };

  if (Array.isArray(query))
    return query.map((item) => new URLSearchParams(search).get(item));

  return new URLSearchParams(search).get(query);
};

export default useQuery;
