import DateHandler from "./DateHandler.js";
import Bible from "./Bible.js";
import moment from "moment";
import { bookIndexByBookAbbreviation } from "./bookIndexByBookAbbreviation.js";

export default class BiblicalPlan {
  constructor() {
    this._dateHandler = new DateHandler();
    this._bible = new Bible();
    this._totalChapters = 1189;
    this._bibleStartReading = { chapter: 1, book: 0 };
    this._bibleEndReading = { chapter: 22, book: 65 };
    this._plan = [];
    this._readingsMade = [];
  }

  getCapsByDay() {
    const bibleChapters = this.getPlanTotalChapters();
    let daysLeft = this._dateHandler.getQuantityOfDaysOnCalendar();
    daysLeft = daysLeft < 1 ? 1 : daysLeft;

    return bibleChapters / daysLeft;
  }

  getNumberOfMonthsInPlan(plan) {
    let totalOfMonths = 1;
    let prevMonth = moment(plan[0].date).month();

    plan.forEach((day) => {
      const currentMonth = moment(day.date).month();
      if (prevMonth !== currentMonth) {
        totalOfMonths++;
        prevMonth = moment(day.date).month();
      }
    });

    return totalOfMonths;
  }

  lazyLoadGroupedMonths({ plan, startIndex, saveStartIndex } = {}) {
    const group = [];

    for (let index = startIndex; index <= plan.length - 1; index++) {
      const item = plan[index];
      const monthId = moment(item.date).month();
      const year = moment(item.date).year();

      let indexFound;
      let found = group.find((month, index) => {
        if (
          month?.some(
            (day) =>
              monthId === moment(day.date).month() &&
              year === moment(day.date).year()
          )
        ) {
          indexFound = index;
          return true;
        }
        return false;
      });

      if (found) {
        group[indexFound].push(item);
      } else {
        if (group.length % 3 === 0 && group.length !== 0) {
          saveStartIndex(index);
          return group;
        }

        group.push([]);

        group[group.length - 1].push(item);
      }
    }

    saveStartIndex(plan.length - 1);

    return group;
  }

  plansAreEqual(plan1, plan2) {
    const differentLength = plan1.length !== plan2.length;

    if (differentLength) return false;

    const differentReadStatus = plan1.some(
      (day, index) => day.read !== plan2[index].read
    );

    if (differentReadStatus) return false;

    const differentReadPlan = plan1.some(
      (day, index) =>
        day.readingStart.book !== plan2[index].readingStart.book ||
        day.readingStart.chapter !== plan2[index].readingStart.chapter ||
        day.readingEnd.book !== plan2[index].readingEnd.book ||
        day.readingEnd.chapter !== plan2[index].readingEnd.chapter
    );

    if (differentReadPlan) return false;

    const differentCalendar = plan1.some(
      (day, index) => moment(day.date).diff(plan2[index].date, "days") !== 0
    );

    if (differentCalendar) return false;

    return true;
  }

  groupPlanByMonth(plan, onEachMonthFound = () => {}) {
    const group = [];

    plan.forEach((item, _id) => {
      const monthId = moment(item.date).month();
      const year = moment(item.date).year();

      let indexFound;
      let found = group.find((month, index) => {
        if (
          month?.some(
            (day) =>
              monthId === moment(day.date).month() &&
              year === moment(day.date).year()
          )
        ) {
          indexFound = index;
          return true;
        }
        return false;
      });

      if (found) {
        onEachMonthFound(found);
        group[indexFound].push(item);
      } else {
        group.push([]);
        group[group.length - 1].push(item);
      }
    }, {});

    return group;
  }

  getTotalReadChapters() {
    const readinsMade = this.getReadingsMade();

    return readinsMade.length;
  }

  getPlanTotalChapters() {
    const chapters = this._bible.getBibleArray().books;
    const startBook = this._bibleStartReading.book;
    const endBook = this._bibleEndReading.book;
    const startReadingChapter = this._bibleStartReading.chapter;
    const endReadingChapter = this._bibleEndReading.chapter;

    let books = [];
    chapters.forEach((book, index) => {
      if (index >= startBook && index <= endBook) {
        if (index === endBook && index === startBook) {
          return books.push({
            ...book,
            chapters: endReadingChapter - startReadingChapter + 1,
          });
        }

        if (index === endBook) {
          return books.push({
            ...book,
            chapters: endReadingChapter,
          });
        }

        if (index === startBook) {
          return books.push({
            ...book,
            chapters: book.chapters - startReadingChapter + 1,
          });
        }

        books.push(book);
      }
    });

    const totalChapters = books.reduce(
      (accumulator, currentValue) => accumulator + currentValue.chapters,
      0
    );

    const alreadyRead = this.getTotalReadChapters();

    return totalChapters - alreadyRead;
  }

  setPlanTotalChapters(totalChapters) {
    this._totalChapters = totalChapters;
  }

  getBibleStartReading() {
    return this._bibleStartReading;
  }

  setBibleStartReading(book, chapter) {
    let bible = this._bible.getBibleArray().books;

    if (chapter > bible[book].chapters) {
      //uses the default attributes value
      return false;
    }

    if (book === undefined) {
      this._bibleStartReading = { book: 0, chapter: 1 };
    } else {
      this._bibleStartReading = { book: book, chapter: chapter };
    }
  }

  setBibleEndReading(book, chapter) {
    let bible = this._bible.getBibleArray().books;

    if (chapter > bible[book].chapters) {
      //uses the default attributes value
      return false;
    }

    if (book === undefined) {
      this._bibleEndReading = { book: 65, chapter: 22 };
    } else {
      this._bibleEndReading = { book: book, chapter: chapter };
    }
  }

  getBibleEndReading() {
    return this._bibleEndReading;
  }

  getPlan() {
    return this._plan;
  }

  setPlan(
    endBook,
    endChapter,
    startBook,
    startChapter,
    startBookIndex,
    endBookIndex,
    endBookId,
    startBookId,
    readingDetails
  ) {
    this._plan.push({
      readingDetails,
      start: {
        book: startBook,
        chapter: startChapter,
        index: startBookIndex,
        id: startBookId,
      },
      end: {
        book: endBook,
        chapter: endChapter,
        index: endBookIndex,
        id: endBookId,
      },
    });
  }

  clearPlan() {
    this._plan = [];
  }

  planIsIncomplete() {
    return this.getPlan().length <= this._dateHandler.getCalendar().length - 1;
  }

  splitPlanInMonths(plan) {
    return plan.reduce((accumulator, day) => {
      const month = moment(day.date).month();
      return Object.assign(accumulator, {
        [month]: (accumulator[month] || []).concat(day),
      });
    }, {});
  }

  turnPlanV1IntoPlanV2(planv1) {
    const { books } = this._bible.getBibleArray();

    const plan = [];
    planv1.forEach((month) => {
      month[1].forEach((day) => plan.push(day));
    });

    const planv2 = plan.map(
      (
        {
          day,
          endBook,
          endBookIndex,
          endChapter,
          monthIndex,
          startBook,
          startBookIndex,
          startChapter,
        },
        _id
      ) => {
        startBookIndex = Number.isSafeInteger(startBookIndex)
          ? startBookIndex
          : bookIndexByBookAbbreviation(startBook);
        endBookIndex = Number.isSafeInteger(endBookIndex)
          ? endBookIndex
          : bookIndexByBookAbbreviation(endBook);

        return {
          date: moment([moment().year(), monthIndex, day]).toISOString(),
          readingDetails: this._bible.createArrayByReadingRange({
            startBookIndex,
            endBookIndex,
            startChapter,
            endChapter,
          }),
          readingStart: {
            startBook,
            startBookIndex,
            startChapter,
            book: books[startBookIndex].id,
            chapter: startChapter,
          },
          readingEnd: {
            endBook,
            endBookIndex,
            endChapter,
            book: books[endBookIndex].id,
            chapter: endChapter,
          },
          _id,
        };
      }
    );

    return planv2;
  }

  buildPlan() {
    let start = this.getBibleStartReading();
    let end = this.getBibleEndReading();

    let intervaloDeDias = this._dateHandler.getQuantityOfDaysOnCalendar();
    intervaloDeDias = intervaloDeDias < 1 ? 1 : intervaloDeDias;

    let totalCapitulos = this.getPlanTotalChapters();

    var intervalReading = !!Math.trunc(this.getCapsByDay())
      ? Math.trunc(this.getCapsByDay())
      : 1;

    var sobraDeCapitulos =
      totalCapitulos < intervaloDeDias ? 0 : totalCapitulos % intervaloDeDias;

    var chapter = start.chapter;
    var book = start.book;
    var intervalCounter = 1;
    var startBook;
    var startBookId;
    var startChapter;
    let startBookIndex;

    const { books } = this._bible.getBibleArray();

    const readingsMade = this.getReadingsMade();

    let readingDetails = [];

    for (; chapter <= books[book]?.chapters; chapter++) {
      const readAlready = readingsMade.some(
        // eslint-disable-next-line no-loop-func
        (item) => item.book === books[book]?.id && item.chapter === chapter
      );

      !readAlready && readingDetails.push({ book: books[book]?.id, chapter });

      if (intervalCounter === 1 && !readAlready) {
        startBook = books[book].bookAbbreviation;
        startBookId = books[book].id;
        startChapter = chapter;
        startBookIndex = book;
      }

      if (
        !readAlready &&
        intervalCounter === intervalReading &&
        this.planIsIncomplete()
      ) {
        if (
          !!sobraDeCapitulos &&
          chapter <= books[book].chapters &&
          book <= end.book
        ) {
          chapter++;
          sobraDeCapitulos--;

          if (chapter > books[book].chapters) {
            if (book < 65) book++;
            chapter = 1;
          }
        }

        const endBook = books[book].bookAbbreviation;
        const endBookId = books[book].id;
        const endChapter = chapter;

        const reacordAlreadyExists = readingDetails.some(
          (record) => record.book === endBookId && record.chapter === endChapter
        );
        if (!reacordAlreadyExists)
          readingDetails.push({ book: endBookId, chapter: endChapter });

        this.setPlan(
          endBook,
          endChapter,
          startBook,
          startChapter,
          startBookIndex,
          book,
          endBookId,
          startBookId,
          readingDetails
        );

        readingDetails = [];
        intervalCounter = 0;
      }

      if (book === end.book && chapter === end.chapter) {
        return;
      }

      if (chapter === books[book].chapters) {
        if (book < 65) book++;
        chapter = 0;
      }

      if (!readAlready) intervalCounter++;
    }
  }

  buildPlanByChaptersPerDay(chaptersPerDay) {
    let start = this.getBibleStartReading();
    let end = this.getBibleEndReading();

    let readingDetails = [];

    const intervalArray = this._bible.createArrayByReadingRange({
      startBookIndex: start.book,
      endBookIndex: end.book,
      startChapter: start.chapter,
      endChapter: end.chapter,
    });

    let startBook = intervalArray[0].abbreviation;
    let startBookId = intervalArray[0].book;
    let startChapter = intervalArray[0].chapter;
    let startBookIndex = intervalArray[0].bookIndex;

    const readingsMade = this.getReadingsMade();

    let intervalHelper = 0;
    intervalArray.forEach(
      ({ book, abbreviation, chapter, bookIndex }, index, array) => {
        const readAlready = readingsMade.some(
          (item) => item.book === book && item.chapter === chapter
        );

        if (readAlready) return;

        readingDetails.push({ book, chapter });

        const intervalMatch = (intervalHelper + 1) % chaptersPerDay === 0;

        if (intervalMatch) {
          this.setPlan(
            abbreviation,
            chapter,
            startBook,
            startChapter,
            startBookIndex,
            bookIndex,
            book,
            startBookId,
            readingDetails
          );

          readingDetails = [];

          startBook = array[index + 1]?.abbreviation;
          startBookId = array[index + 1]?.book;
          startChapter = array[index + 1]?.chapter;
          startBookIndex = array[index + 1]?.bookIndex;
        }

        const isLastIteration = index === array.length - 1;

        if (isLastIteration && !intervalMatch) {
          this.setPlan(
            abbreviation,
            chapter,
            startBook,
            startChapter,
            startBookIndex,
            bookIndex,
            book,
            startBookId,
            readingDetails
          );
        }

        intervalHelper++;
      }
    );
  }

  buildPlanByQuantityOfDays(chaptersPerDay) {
    let start = this.getBibleStartReading();
    let end = this.getBibleEndReading();

    let readingDetails = [];

    const intervalArray = this._bible.createArrayByReadingRange({
      startBookIndex: start.book,
      endBookIndex: end.book,
      startChapter: start.chapter,
      endChapter: end.chapter,
    });

    let startBook = intervalArray[0].abbreviation;
    let startBookId = intervalArray[0].book;
    let startChapter = intervalArray[0].chapter;
    let startBookIndex = intervalArray[0].bookIndex;

    intervalArray.forEach(
      ({ book, abbreviation, chapter, bookIndex }, index, array) => {
        readingDetails.push({ book, chapter });

        const intervalMatch = (index + 1) % chaptersPerDay === 0;

        if (intervalMatch) {
          this.setPlan(
            abbreviation,
            chapter,
            startBook,
            startChapter,
            startBookIndex,
            bookIndex,
            book,
            startBookId,
            readingDetails
          );

          readingDetails = [];

          startBook = array[index + 1]?.abbreviation;
          startBookId = array[index + 1]?.book;
          startChapter = array[index + 1]?.chapter;
          startBookIndex = array[index + 1]?.bookIndex;
        }

        const isLastIteration = index === array.length - 1;

        if (isLastIteration && !intervalMatch) {
          this.setPlan(
            abbreviation,
            chapter,
            startBook,
            startChapter,
            startBookIndex,
            bookIndex,
            book,
            startBookId,
            readingDetails
          );
        }
      }
    );
  }

  createArrayOfReadingsMade(readArray) {
    const readingsMade = [];

    readArray.forEach((day) => {
      this._bible
        .createArrayByReadingRange(day)
        .forEach((item) => readingsMade.push(item));
    });

    return readingsMade;
  }

  createSplittedReadingInterval(completeReadingArray, currentReadingArray) {
    const startReading = [
      {
        book: currentReadingArray[0].book,
        chapter: currentReadingArray[0].chapter,
      },
    ];

    const endReading = [];

    currentReadingArray.forEach((day, _i, currentArray) => {
      const lastIndex = currentArray[currentArray.length - 1];
      const isOnLastIndex =
        lastIndex.book === day.book && lastIndex.chapter === day.chapter;

      if (isOnLastIndex) {
        return endReading.push(day);
      }

      completeReadingArray.forEach((current, index, completeArray) => {
        if (current.book === day.book && current.chapter === day.chapter) {
          const nextInCurrent = currentArray[_i + 1];
          const nextInComplete = completeArray[index + 1];

          if (
            nextInCurrent?.book !== nextInComplete?.book ||
            nextInCurrent?.chapter !== nextInComplete?.chapter
          ) {
            endReading.push(current);
            startReading.push(nextInCurrent);
          }
        }
      });
    });

    return {
      startReading,
      endReading,
    };
  }

  readingArraysAreEqual(completeReadingArray, currentReadingArray) {
    let truthy = true;

    completeReadingArray.forEach((firstDay) => {
      if (
        currentReadingArray.some(
          (secondDay) =>
            secondDay.book === firstDay.book &&
            secondDay.chapter === firstDay.chapter
        )
      )
        return true;
      truthy = false;
    });

    return truthy;
  }

  setReadingsMade(readingsMade) {
    this._readingsMade = readingsMade;
  }

  getReadingsMade() {
    return this._readingsMade;
  }

  buildPlanCalendarByChaptersPerDay(
    startDate,
    startReading,
    endReading,
    chaptersPerDay,
    alreadyRead = [],
    blockedWeekDays = []
  ) {
    const readingsMade = this.createArrayOfReadingsMade(alreadyRead);
    this.setReadingsMade(readingsMade);
    this.clearPlan();

    const startBookIndex = Number.isSafeInteger(startReading.bookIndex)
      ? startReading.bookIndex
      : startReading.startBookIndex;

    const endBookIndex = Number.isSafeInteger(endReading.bookIndex)
      ? endReading.bookIndex
      : endReading.endBookIndex;

    this.setBibleStartReading(startBookIndex, startReading.chapter);
    this.setBibleEndReading(endBookIndex, endReading.chapter);
    this.buildPlanByChaptersPerDay(chaptersPerDay);

    const plan = this.getPlan();
    let date = startDate.clone();

    const finalPlan = plan.map(({ start, end, readingDetails }, index) => {
      date = this._dateHandler.decideOnSafeDate(blockedWeekDays, date, index);

      return {
        readingDetails: readingDetails,
        date: date.toISOString(),
        readingStart: {
          startBook: start.book,
          startBookIndex: start.index,
          startChapter: start.chapter,
          book: start.id,
          chapter: start.chapter,
        },
        readingEnd: {
          endBook: end.book,
          endBookIndex: end.index,
          endChapter: end.chapter,
          book: end.id,
          chapter: end.chapter,
        },
        read: false,
        _id: index,
      };
    });

    return finalPlan;
  }

  buildPlanCalendar(
    startDate,
    endDate,
    startReading,
    endReading,
    alreadyRead = [],
    blockedWeekDays = []
  ) {
    const readingsMade = this.createArrayOfReadingsMade(alreadyRead);
    this.setReadingsMade(readingsMade);
    this.clearPlan();
    this._dateHandler.clearCalendar();

    this._dateHandler.setStartDate(startDate);
    this._dateHandler.setEndDate(endDate);

    if (this._dateHandler.isStartDateAheadOfEndDate()) {
      this._dateHandler.setEndDateAsOneDayAfterStartDate();
    }

    this._dateHandler.buildCalendar(blockedWeekDays);

    const startBookIndex = Number.isSafeInteger(startReading.bookIndex)
      ? startReading.bookIndex
      : startReading.startBookIndex;

    const endBookIndex = Number.isSafeInteger(endReading.bookIndex)
      ? endReading.bookIndex
      : endReading.endBookIndex;

    this.setBibleStartReading(startBookIndex, startReading.chapter);
    this.setBibleEndReading(endBookIndex, endReading.chapter);
    this.buildPlan();

    let finalPlan = [];
    let plan = this.getPlan();
    let calendar = this._dateHandler.getCalendar();

    for (var count = 0; count <= calendar.length - 1; count++) {
      if (plan[count] === undefined) continue;

      finalPlan.push({
        readingDetails: plan[count].readingDetails,
        date: calendar[count].date,
        readingStart: {
          startBook: plan[count].start.book,
          startBookIndex: plan[count].start.index,
          startChapter: plan[count].start.chapter,
          book: plan[count].start.id,
          chapter: plan[count].start.chapter,
        },
        readingEnd: {
          endBook: plan[count].end.book,
          endBookIndex: plan[count].end.index,
          endChapter: plan[count].end.chapter,
          book: plan[count].end.id,
          chapter: plan[count].end.chapter,
        },
        read: false,
        _id: count,
      });
    }

    return finalPlan;
  }

  buildPlanCalendarByQuantityOfDays(
    startDate,
    quantityOfDays,
    startReading,
    endReading,
    alreadyRead = [],
    blockedWeekDays = []
  ) {
    const readingsMade = this.createArrayOfReadingsMade(alreadyRead);
    this.setReadingsMade(readingsMade);
    this.clearPlan();
    this._dateHandler.clearCalendar();

    this._dateHandler.setStartDate(startDate);

    this._dateHandler.buildCalendarByQuantityOfDays(
      blockedWeekDays,
      quantityOfDays
    );

    const startBookIndex = Number.isSafeInteger(startReading.bookIndex)
      ? startReading.bookIndex
      : startReading.startBookIndex;

    const endBookIndex = Number.isSafeInteger(endReading.bookIndex)
      ? endReading.bookIndex
      : endReading.endBookIndex;

    this.setBibleStartReading(startBookIndex, startReading.chapter);
    this.setBibleEndReading(endBookIndex, endReading.chapter);
    this.buildPlan();

    let finalPlan = [];
    let plan = this.getPlan();
    let calendar = this._dateHandler.getCalendar();

    for (var count = 0; count <= calendar.length - 1; count++) {
      if (plan[count] === undefined) continue;

      finalPlan.push({
        readingDetails: plan[count].readingDetails,
        date: calendar[count].date,
        readingStart: {
          startBook: plan[count].start.book,
          startBookIndex: plan[count].start.index,
          startChapter: plan[count].start.chapter,
          book: plan[count].start.id,
          chapter: plan[count].start.chapter,
        },
        readingEnd: {
          endBook: plan[count].end.book,
          endBookIndex: plan[count].end.index,
          endChapter: plan[count].end.chapter,
          book: plan[count].end.id,
          chapter: plan[count].end.chapter,
        },
        read: false,
        _id: count,
      });
    }

    return finalPlan;
  }
}
