import moment from "moment";

import { i18n } from "../translate/i18n";

class DateHandler {
  constructor() {
    this._date = new Date();
    this._startDate = {
      day: this._date.getDate(),
      month: this._date.getMonth(),
      year: this._date.getFullYear(),
    };

    this._endDate = {
      day: 31,
      month: 11,
      year: this._date.getFullYear(),
    };

    this._calendar = [];
  }

  getActualMonth() {
    return this._date.getMonth();
  }

  getTodayDay() {
    return this._date.getDate();
  }

  getMonthDaysLeft(startDay, monthNumber) {
    let monthDays = this.getMonthAmountOfDays(monthNumber);
    return monthDays - startDay;
  }

  getMonthAmountOfDays(monthNumber) {
    let calendar = [
      31,
      this.getLeapFebruary(),
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];
    return calendar[monthNumber];
  }

  getActualYear() {
    return this._date.getFullYear();
  }

  getLeapFebruary() {
    return this.isLeapYear() ? 29 : 28;
  }

  isLeapYear() {
    let ano = this.getActualYear();

    if ((ano % 4 === 0 && ano % 100 !== 0) || ano % 400 === 0) {
      return true;
    } else {
      return false;
    }
  }

  getMonthNameByIndex(index) {
    let monthName = "";
    switch (index) {
      case 0:
        monthName = i18n.t("messages.months.january");
        break;
      case 1:
        monthName = i18n.t("messages.months.february");
        break;
      case 2:
        monthName = i18n.t("messages.months.march");
        break;
      case 3:
        monthName = i18n.t("messages.months.april");
        break;
      case 4:
        monthName = i18n.t("messages.months.may");
        break;
      case 5:
        monthName = i18n.t("messages.months.june");
        break;
      case 6:
        monthName = i18n.t("messages.months.july");
        break;
      case 7:
        monthName = i18n.t("messages.months.august");
        break;
      case 8:
        monthName = i18n.t("messages.months.september");
        break;
      case 9:
        monthName = i18n.t("messages.months.october");
        break;
      case 10:
        monthName = i18n.t("messages.months.november");
        break;
      case 11:
        monthName = i18n.t("messages.months.december");
        break;
      default:
        break;
    }
    return monthName;
  }

  getStartDate() {
    return this._startDate;
  }

  setStartDate(startDate) {
    if (startDate) this._startDate = startDate;
    else this._startDate = moment();
  }

  setEndDate(endDate) {
    if (endDate) {
      this._endDate = endDate;
    } else {
      const lastDayInYear = moment([moment().year(), 11, 31]);
      this._endDate = lastDayInYear;
    }
  }

  getEndDate() {
    return this._endDate;
  }

  isStartDateAheadOfEndDate() {
    return this.getDiffBetweenDates() < 0;
  }

  createArrayOfMonthsByInterval(startDate, endDate) {
    let currentMonth = startDate.clone();
    let endMonth = endDate.clone();

    const monthsArray = [currentMonth.month()];

    while (
      currentMonth.year() !== endMonth.year() ||
      currentMonth.month() !== endMonth.month()
    ) {
      currentMonth = moment(currentMonth).add(1, "months");
      monthsArray.push(currentMonth.month());
    }

    return monthsArray;
  }

  getNextSafeDate(blockedWeekDays, day) {
    // eslint-disable-next-line array-callback-return
    let safeDate = day;
    blockedWeekDays.forEach(() => {
      const isBlocked = this.isABlockedWeekDay(blockedWeekDays, day);
      if (isBlocked) safeDate = day.add(1, "days");
    });

    return safeDate;
  }

  isABlockedWeekDay(blockedWeekDays, day) {
    return blockedWeekDays.some(
      (blodckedWeekDay) => blodckedWeekDay === day.weekday()
    );
  }

  getDiffBetweenDates() {
    return this.getEndDate().diff(this.getStartDate(), "days");
  }

  getDiffBetweenDatesOld() {
    let startDay = this.getStartDate().date();
    let endMonth = this.getEndDate().month();
    let endDay = this.getEndDate().day();

    let daysLeft = 0;

    const monthsArray = this.createArrayOfMonthsByInterval(
      this.getStartDate(),
      this.getEndDate()
    );

    monthsArray.forEach((month) => {
      daysLeft += this.getMonthAmountOfDays(month);
    });

    return (
      daysLeft - startDay - (this.getMonthAmountOfDays(endMonth) - endDay) + 1
    );
  }

  getActualYearDaysLeft() {
    let month = this.getActualMonth();
    let todayDay = this.getTodayDay();

    for (var daysLeft = 0, a = month; a <= 11; a++) {
      daysLeft += this.getMonthAmountOfDays(a);
    }

    return daysLeft - todayDay;
  }

  getAmountOfDaysUntil(monthIndex) {
    let month = this.getActualMonth();
    let todayDay = this.getTodayDay();

    for (var daysLeft = 0, a = month; a <= monthIndex; a++) {
      daysLeft += this.getMonthAmountOfDays(a);
    }

    return daysLeft - (todayDay - 1);
  }

  getCalendar() {
    return this._calendar;
  }

  clearCalendar() {
    this._calendar = [];
  }

  setEndDateAsOneDayAfterStartDate() {
    const oneDayAfter = this.getStartDate().clone().add(1, "days");
    this.setEndDate(oneDayAfter);
  }

  decideOnSafeDate(blockedWeekDays, date, index, ignoreFirstDay) {
    const isFirstDay = index === 0 && !ignoreFirstDay;

    if (!isFirstDay) date = date.clone().add(1, "days");

    const isABlockedWeekDay = this.isABlockedWeekDay(blockedWeekDays, date);

    if (isFirstDay && isABlockedWeekDay)
      return this.getNextSafeDate(blockedWeekDays, date);
    if (!isFirstDay && isABlockedWeekDay)
      return this.getNextSafeDate(blockedWeekDays, date);

    return date;
  }

  getQuantityOfDaysOnCalendar() {
    return this._calendar.length;
  }

  toEarliest(momentDate) {
    const year = momentDate.year();
    const month = momentDate.month();
    const date = momentDate.date();

    const earliest = moment([year, month, date]).startOf("day");

    return earliest;
  }

  toLatest(momentDate) {
    const year = momentDate.year();
    const month = momentDate.month();
    const date = momentDate.date();

    const latest = moment([year, month, date]).endOf("day");

    return latest;
  }

  buildCalendar(blockedWeekDays) {
    const startDate = this.getStartDate();
    const endDate = this.toLatest(this.getEndDate());

    let currentDate = this.toEarliest(startDate.clone());
    const isFirsDay = 0;

    currentDate = this.decideOnSafeDate(
      blockedWeekDays,
      currentDate,
      isFirsDay
    );

    for (let counter = 0; endDate.isAfter(currentDate); counter++) {
      this._calendar.push({
        date: currentDate.toISOString(),
      });

      currentDate = this.decideOnSafeDate(
        blockedWeekDays,
        currentDate,
        counter,
        true
      );
    }
  }

  buildCalendarByQuantityOfDays(blockedWeekDays, quantityOfDays) {
    const startDate = this.getStartDate();

    let currentDate = startDate.clone();
    const isFirsDay = 0;

    currentDate = this.decideOnSafeDate(
      blockedWeekDays,
      currentDate,
      isFirsDay
    );

    for (let counter = 0; counter < quantityOfDays; counter++) {
      this._calendar.push({
        date: currentDate.toISOString(),
      });
      currentDate = this.decideOnSafeDate(
        blockedWeekDays,
        currentDate,
        counter,
        true
      );
    }
  }
}

export default DateHandler;
