import { authentication } from "./authentication";
import { bibleBooks } from "./bibleBooks";
import { buttons } from "./buttons";
import { menu } from "./menu";
import { months } from "./months";
import { noticeModals } from "./noticeModals";
import { notifications } from "./notifications";
import { challengeLP } from "./challengeLP";
import { week } from "./week";
import { planCreation } from "./planCreation";
import { exceptionModal } from "./exceptionModal";
import { reportError } from "./reportError";
import { challenge } from "./challenge";
import { bottomBar } from "./bottomBar";
import { darkTheme } from "./darkTheme";

const messages = {
  pt: {
    translations: {
      titles: {
        askDonationsModal: "Ajude-nos a fazer mais",
        donateOptionsModal: "Obrigado!",
        appHeader: {
          title: "Seu plano bíblico",
          subtitle: "De {{startReading}} até {{endReading}}",
        },
        delayedReadingModal: "Opa! Você atrasou sua leitura.",
        planCreation: {
          title: "Leia a Bíblia",
          subtitle:
            "Defina um novo intervalo de dias, início e termino da leitura. Pronto! :)",
        },
        createdPlan: {
          title: "Plano criado!",
          subtitle:
            "Inicie sua leitura e não se preocupe quando faltar um dia. O plano será refeito para você!",
        },
        editPlan: {
          title: "Edite seu plano",
          subtitle:
            "Defina um novo intervalo de dias, início e termino da leitura. Pronto! :)",
        },
        askConfirmationModal: {
          title: "Tem certeza?",
          subtitle:
            "O seu progresso atual será perdido e o plano será feito do zero!",
        },
        biblePicker: {
          book: "Livro",
          chapter: "Capítulo",
        },
      },
      messages: {
        pullGooglePic: "Usar a foto do Google",
        pullFacebookPic: "Usar a foto do Facebook",
        updatePicture: "Mudar foto",
        youWereInvited: "Você foi convidado para o desafio",
        loginToJoin: "Faça login ou crie uma conta para aceitar o convite.",
        switchToApp: "Usar o app",
        stayOnBrowser: "Continuar no Browser",
        premiumApproved: "Sua assinatura premium foi aprovada",
        accessPremium:
          "Você pode agora acessar todas as funcionalidades premium",
        createFirstChallenge: "Crie seu primeiro desafio",
        progress: "Progresso",
        noVersionsOffline: "Não há versões disponíveis offline",
        connectAndDownload:
          "Conecte-se à internet e baixe uma versão para ler offline.",
        weHadToChangeTheVersion:
          "Nós mudamos para uma versão offline da Bíblia porque você está sem internet :)",
        weCouldNotLoadChapter: "Não conseguimos carregar este capítulo.",
        thePossibleReasons: "As razões possíveis são:",
        thePossibleReasons1:
          "Talvez este capítulo não exista nesta versão da Bíblia.",
        thePossibleReasons2:
          "Podemos estar com alguma instabilidade temporária.",
        thePossibleReasons3: "Você pode estar offline",
        whatYouCanDo: "O que você pode fazer:",
        whatYouCanDo1: "Tente mudar de versão.",
        whatYouCanDo2: "Baixe uma versão para ler offline.",
        whatYouCanDo3: "Volte aqui depois e tente ler novamente.",
        textCopied: "Texto copiado!",
        linkCopied: "Link copiado",
        copyLink: "Copiar link",
        throughLink: "Por meio de um link",
        inviteViaEmail: "Convide por e-mail",
        shareLink: "Enviar link",
        hereIsTheLink: "Aqui está o link para participar do desafio:",
        loading: "Carregando...",
        muteReminder: "Não monitorar atrasos",
        bibleReading: {
          read: "Ler",
        },
        whatIsYourFeedback: "Qual é o seu feedback?",
        planChecklist: {
          day: "dia",
        },
        editPlan: {
          formChecklistTitle: "Edite agora:",
        },
        delayedReadingModal: {
          subtitle:
            "Mas não se preocupe, podemos adaptar seu plano a partir de onde você parou :)",
        },
        askDonationsModal: {
          paragraph1: "O Bible Journey é uma ferramenta criada para abençoar.",
          paragraph2:
            "Ao se inscrever como doador mensal você nos auxilia a evoluir e alcançar mais pessoas.",
        },
        donateOptionsModal: {
          subtitle: "Escolha o tipo de doação para prosseguir.",
        },

        shareBibleJourney: {
          mobileText: "Oi! Este é o link para baixar o Bible Journey :)",
          nonMobileText: "Oi! Este é o link para acessar o Bible Journey :)",
        },
        footer: {
          madeBy: "Feito por",
        },
        forgotPassword: "Esqueceu a senha?",
        newUpdateAvailable: "Uma nova atualização disponível para o app",
        update: "Atualizar",
        appUpdated: "App atualizado :)",
        pageNotExist:
          "Essa página não existe ainda. Tente chegar se há uma atualiação disponível.",
        verifyUpdate:
          "Tente verificar se existe uma atualização disponível. Se não houver, reporte-nos um erro através do Menu.",
        weCouldNotFindPage: "Oops! Não conseguimos encontrar esta página :(",
        ...week,
        ...months,
        ...bibleBooks,
        ...authentication,
        ...menu,
        ...noticeModals,
        ...notifications,
        ...challengeLP,
        ...planCreation,
        ...exceptionModal,
        ...reportError,
        ...challenge,
        ...bottomBar,
        ...darkTheme,
      },
      ...buttons,

      links: {
        donateOptionsModal: {
          oneTimeDonation:
            "https://gumroad.com/l/bibleplan-contribuicao-unica?locale=pt",
          recurringDonation:
            "https://gumroad.com/l/bibleplan-contribuicao-recorrente?locale=pt",
        },
      },
    },
  },
};

export { messages };
