import React, { useCallback } from "react";
import { challengeDeleted, deleteChallenge } from "./messages";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { useModal } from "contexts/Modal/useModal";
import {
  areYouSure,
  notPossibleToUndo,
  yesDeleteChallenge,
} from "../Questions/messages";
import { changedMyMind } from "pages/Menu/messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { loading } from "components/SocialLoginButtons/messages";
import { sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";

const DeleteChallenge = () => {
  const { createModal, closeModal, updateActiveModalProps } = useModal();
  const { request: challenge } = useChallenge();

  const { openCustomSnackbar, openGeneralErrorSnackbar } =
    useSnackbarNotification();

  const onSecondButtonClick = useCallback(async () => {
    try {
      updateActiveModalProps({
        secondButtonText: loading,
        secondButtonDisabled: true,
      });

      const {
        data: { success },
      } = await challenge.delete("delete");

      if (!success) throw new Error("Challenge delete failed");

      openCustomSnackbar({ variant: "success", message: challengeDeleted });
    } catch (err) {
      sendErrorEmail(err, "on deleting challenge");
      openGeneralErrorSnackbar();
      closeModal();
    } finally {
      closeModal({ thenGoTo: "/challenges" });
    }
  }, [
    challenge,
    closeModal,
    openCustomSnackbar,
    openGeneralErrorSnackbar,
    updateActiveModalProps,
  ]);

  const onClick = useCallback(() => {
    createModal({
      renderChildrenInsteadOfText: true,
      title: areYouSure,
      subtitle: notPossibleToUndo,
      firstButtonText: changedMyMind,
      firstButtonTheme: "success",
      secondButtonTheme: "danger",
      secondButtonText: yesDeleteChallenge,
      onFirstButtonClick: closeModal,
      onSecondButtonClick,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createModal]);

  return (
    <div className="configurations__item" onClick={onClick}>
      <p>{deleteChallenge}</p>
      <ArrowRight />
    </div>
  );
};

export default DeleteChallenge;
