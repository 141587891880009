export function storePlan(plan, planId, blockedWeekDays, planType) {
  return {
    type: "STORE_PLAN",
    plan,
    planId,
    blockedWeekDays,
    planType,
  };
}

export function storePDFArray(planArrayForPdf) {
  return {
    type: "STORE_PDF_ARRAY",
    planArrayForPdf,
  };
}

export function toggleReadStatus(monthIndex, day) {
  return {
    type: "TOGGLE_READ_STATUS",
    monthIndex,
    day,
  };
}

export function storeReadingInterval(readingInterval) {
  return {
    type: "STORE_READING_INTERVAL",
    readingInterval,
  };
}

export function storeReadingCalendarInterval(datesInterval) {
  return {
    type: "STORE_READING_DATES_INTERVAL",
    readingDatesInterval: datesInterval,
  };
}

export function trackDelays(track) {
  return {
    type: "TRACK_DELAYS",
    track,
  };
}

export function resetPlanV1() {
  return {
    type: "RESET_PLAN_V1",
  };
}
