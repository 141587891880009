import React from "react";

import App from "components/App";
import Header from "components/App/Header";
import { Navbar } from "components/Navbar";
import withChallenge from "HOCs/withChallenge";

import { questionsMsg } from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import List from "./List";
import Skeleton from "./Skeleton";
import MainCard from "components/MainCard";

const Questions = () => {
  const { currentChallenge, isLoadingChallenge } = useChallenge();

  return (
    <App>
      <Navbar backTo="/challenges" />
      <Header title={currentChallenge.title} color="white" />
      <MainCard title={questionsMsg} centerTitle showBrandFooter>
        {isLoadingChallenge ? <Skeleton className="skeleton" /> : <List />}
      </MainCard>
    </App>
  );
};

export default withChallenge(Questions);
