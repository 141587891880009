export const storePlan = (
  planv2,
  planId,
  blockedWeekDays,
  planType,
  chaptersPerDay,
  quantityOfDays
) => ({
  type: "STORE_PLAN_V2",
  planv2,
  planId,
  blockedWeekDays,
  planType,
  chaptersPerDay,
  quantityOfDays,
});

export const updatePlan = (
  planv2,
  blockedWeekDays,
  planType,
  chaptersPerDay,
  quantityOfDays
) => ({
  type: "UPDATE_PLAN_V2",
  planv2,
  blockedWeekDays,
  planType,
  chaptersPerDay,
  quantityOfDays,
});

export const toggleReadStatus = (id, status) => ({
  type: "TOGGLE_READ_STATUS_V2",
  id,
  status,
});

export const trackDelays = (trackDelays) => ({
  type: "TRACK_DELAYS_V2",
  trackDelays,
});

export const toggleTrackDelays = () => ({
  type: "TOGGLE_TRACK_DELAYS_V2",
});

export const storeLastModified = () => ({
  type: "STORE_LAST_MODIFIED",
});

export const resetLocalPlan = () => ({
  type: "RESET_LOCAL_PLAN",
});

export const storePlanId = (planId) => ({
  type: "STORE_PLAN_ID",
  planId,
});
