import React, { Fragment } from "react";

import {
  creatingPlan,
  updatePlan,
  createPlan,
  chaptersPerDay,
} from "../messages";

import { usePlan } from "../Context";

import Button from "components/Button";
import SmallInput from "components/Form/SmallInput";
import { FormFeedback } from ".";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import DatesInputs from "../components/DateInputs";

const ChaptersPerDay = () => {
  const {
    isLoading,
    isEditing,
    quantityOfChapters,
    setQuantityOfChapters,
    allInputsAreValid,
    createPlaByQuantityOfChapters,
    blockedWeekDays,
    setBlockedWeekDays,
  } = usePlan();

  const onChange = ({ target: { value } }) =>
    setQuantityOfChapters(Number(value));

  const onClick = () => {
    if (!allInputsAreValid({ validateDays: false })) return;
    createPlaByQuantityOfChapters();
  };

  return (
    <Fragment>
      <div className="chapters-per-day">
        <div className="form__row">
          <SmallInput
            type="number"
            defaultValue={quantityOfChapters}
            name="days"
            onChange={onChange}
            label={chaptersPerDay}
            max={1190}
          />
        </div>
        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />

        <DatesInputs withEndDate={false} />

        <ReadingInputs />

        <FormFeedback />
        <Button onClick={onClick} className="button button--primary  ">
          {isLoading ? creatingPlan : isEditing ? updatePlan : createPlan}
        </Button>
      </div>
    </Fragment>
  );
};

export default ChaptersPerDay;
