import React from "react";
import AuthProvider from "contexts/AuthContext";
import SnackbarNotificationsProvider from "components/SnackbarNotifications";
import { NotificationsProvider } from "components/Notifications/Context";
import { PlanProvider } from "components/FormPlan/Context";
import { ReportErrorProvider } from "components/ReportError";
import ModalProvider from "contexts/Modal";
import ChallengesProvider from "contexts/Challenge";
import { CheckoutProvider } from "contexts/Checkout";
import { UserProvider } from "contexts/User";
import { BibleProvider } from "contexts/Bible";
import { GlobalScrollProvider } from "contexts/GlobalScroll/Component";
import { ThemeProvider } from "contexts/Theme";

const GlobalContexts = ({ children }) => {
  return (
    <GlobalScrollProvider>
      <SnackbarNotificationsProvider>
        <BibleProvider>
          <ModalProvider>
            <AuthProvider>
              <PlanProvider>
                <ThemeProvider>
                  <UserProvider>
                    <CheckoutProvider>
                      <NotificationsProvider>
                        <ReportErrorProvider>
                          <ChallengesProvider> {children}</ChallengesProvider>
                        </ReportErrorProvider>
                      </NotificationsProvider>
                    </CheckoutProvider>
                  </UserProvider>
                </ThemeProvider>
              </PlanProvider>
            </AuthProvider>
          </ModalProvider>
        </BibleProvider>
      </SnackbarNotificationsProvider>
    </GlobalScrollProvider>
  );
};

export default GlobalContexts;
