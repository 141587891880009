import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  sawAnnouncement: false,
};

const challenge = handleActions(
  {
    ANNOUNCEMENT_SEEN: (state) => ({
      ...state,
      sawAnnouncement: true,
    }),
  },
  INITIAL_STATE
);

export default challenge;
