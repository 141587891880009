import { connect } from "react-redux";
import {
  reminderDefined,
  reminderLater,
  stopReminders,
  storePermissionDenied,
  saveSubscription,
} from "store/actions/notifications";

import NotificationsProvider from "./Component";

const mapDispatchToProps = (dispatch) => ({
  reminderDefined: (subscriptionToken, definedHour, definedMinute) =>
    dispatch(reminderDefined(subscriptionToken, definedHour, definedMinute)),
  reminderLater: (payload) => dispatch(reminderLater(payload)),
  storePermissionDenied: (payload) => dispatch(storePermissionDenied(payload)),
  stopReminders: (payload) => dispatch(stopReminders(payload)),
  saveSubscription: (payload) => dispatch(saveSubscription(payload)),
});

const mapStateToProps = ({
  notifications: {
    hasDefinedReminder,
    askAgainOn,
    reminderCounter,
    permissionDenied,
    showReminders,
    subscriptionToken,
    definedHour,
    definedMinute,
  },
}) => ({
  hasDefinedReminder,
  askAgainOn,
  reminderCounter,
  permissionDenied,
  showReminders,
  subscriptionToken,
  definedHour,
  definedMinute,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsProvider);
