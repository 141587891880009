import React from "react";
import { ReactComponent as QuestionsImg } from "assets/images/questions.svg";
import { noQuestionsYet } from "../messages";

const NoQuestions = () => {
  return (
    <div className="no-questions">
      <QuestionsImg />
      <h3>{noQuestionsYet}</h3>
    </div>
  );
};

export default NoQuestions;
