import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  memo,
  useEffect,
} from "react";
import { useQuery } from "hooks";
import { useHistory } from "react-router-dom";

export const ModalContext = createContext();

const checkSafeList = (modalConfig, currentPage) => {
  if (!modalConfig.length) return false;

  const safelistNotInformed =
    !modalConfig[modalConfig.length - 1].pagesSafelist;

  if (safelistNotInformed) return true;

  const isSafeListed =
    modalConfig[modalConfig.length - 1].pagesSafelist.includes(currentPage);

  return isSafeListed;
};

export const ModalProvider = ({ children }) => {
  const [modalsList, setModalsList] = useState([]);
  const [waitingList, setWaitingList] = useState([]);
  const [openNormalListModal, setOpenNormalListModal] = useState(false);
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [modalsToOpen, setModalsToOpen] = useState([]);
  const [closeFromWaitingList, setCloseFromWaitingList] = useState(null);
  const [waitingListStatus, setWaitingListStatus] = useState([]);
  const history = useHistory();
  const [shouldUpdateActiveModalProps, setShouldUpdateActiveModalProps] =
    useState({});
  const [specificModalToClose, setShouldCloseSpecificModal] = useState(null);
  const [specificModalToUpdate, setShouldUpdateSpecificModal] = useState(null);

  const { addQuery } = useQuery();

  useEffect(() => {
    const onGoBack = () => {
      setShouldCloseModal(true);
      window.removeEventListener("popstate", onGoBack);
    };

    if (openNormalListModal) {
      window.addEventListener("popstate", onGoBack);
    } else {
      window.removeEventListener("popstate", onGoBack);
    }

    return () => {
      window.removeEventListener("popstate", onGoBack);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNormalListModal]);

  useEffect(() => {
    if (specificModalToClose) {
      setModalsList(
        modalsList.filter((modal) => {
          return modal.id !== specificModalToClose.id;
        })
      );

      setShouldCloseSpecificModal(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificModalToClose]);

  useEffect(() => {
    if (specificModalToUpdate) {
      setModalsList(
        modalsList.map((modal) => {
          if (modal.title === specificModalToUpdate.title) {
            return { ...modal, ...specificModalToUpdate };
          }

          return modal;
        })
      );

      setShouldUpdateSpecificModal(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificModalToUpdate]);

  useEffect(() => {
    if (!shouldCloseModal && !!modalsToOpen.length) {
      setModalsList([...modalsList, ...modalsToOpen]);

      const oldModalToOpen = [...modalsToOpen];
      oldModalToOpen.pop();
      setModalsToOpen(oldModalToOpen);

      setOpenNormalListModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCloseModal, modalsToOpen]);

  const closeModal = useCallback(
    ({ thenGoTo } = {}) => {
      thenGoTo ? history.push(thenGoTo) : history.goBack();
      setOpenNormalListModal(false);
      setShouldCloseModal(true);
    },
    [history]
  );

  const closeAllModals = useCallback(() => {
    setOpenNormalListModal(false);
    setTimeout(() => setModalsList([]), 400);
  }, []);

  const closeWithoutDestroying = useCallback(
    () => setOpenNormalListModal(false),
    []
  );

  const closeSpecificModal = useCallback(({ id }) => {
    setShouldCloseSpecificModal({ id });
  }, []);

  const updateSpecificModal = useCallback((config) => {
    setShouldUpdateSpecificModal(config);
  }, []);

  useEffect(() => {
    if (closeFromWaitingList) {
      setWaitingListStatus(
        waitingListStatus.map((modal) => {
          return modal.id === closeFromWaitingList
            ? { ...modal, active: false }
            : modal;
        })
      );

      setCloseFromWaitingList(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeFromWaitingList]);

  useEffect(() => {
    if (shouldCloseModal) {
      const newModalsList = [...modalsList];
      newModalsList.pop();

      setTimeout(() => {
        setShouldCloseModal(false);
      }, 300);

      setShouldUpdateActiveModalProps({});
      setModalsList(newModalsList);

      const safeListed = checkSafeList(
        newModalsList,
        history.location.pathname
      );

      const shouldKeepModalOpen = newModalsList.length > 0 && safeListed;

      setOpenNormalListModal(shouldKeepModalOpen);

      if (!safeListed) setModalsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCloseModal]);

  const createModal = useCallback(
    (config) => {
      addQuery("modalIsOpen", true);

      setModalsToOpen((currentArray) => [
        ...currentArray,
        { onOverlayClick: closeModal, ...config, active: false },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal]
  );

  const openModalFromWaitingList = (id) => {
    setWaitingListStatus(
      waitingListStatus.map((modal) => {
        return modal.id === id ? { ...modal, active: true } : modal;
      })
    );
  };

  const requestCloseFromWaitingList = useCallback((id) => {
    setCloseFromWaitingList(id);
  }, []);

  const addModalToWaitingList = useCallback(
    (config) => {
      setWaitingList((currentList) => [
        ...currentList,
        {
          onOverlayClick: () => {
            requestCloseFromWaitingList(config.id);
          },
          ...config,
          active: false,
        },
      ]);

      setWaitingListStatus((currentList) => [
        ...currentList,
        { id: config.id, active: false },
      ]);
    },
    [requestCloseFromWaitingList]
  );

  const leaveWaitingList = useCallback(
    (filter) => {
      setWaitingList(
        waitingList.filter((modal) => {
          return modal.id !== filter.id;
        })
      );

      setWaitingListStatus(
        waitingListStatus.filter((modal) => {
          return modal.id !== filter.id;
        })
      );
    },
    [waitingList, waitingListStatus]
  );

  const openModal = useCallback(() => {
    setOpenNormalListModal(true);
  }, []);

  const normalListProps = useMemo(
    () => ({
      ...modalsList[modalsList.length - 1],
      active: openNormalListModal,
    }),
    [openNormalListModal, modalsList]
  );

  const updateActiveModalProps = useCallback((newProps) => {
    setShouldUpdateActiveModalProps(newProps);
  }, []);

  const noModalsOpen = useMemo(() => modalsList.length === 0, [modalsList]);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        createModal,
        closeWithoutDestroying,
        addModalToWaitingList,
        openModalFromWaitingList,
        leaveWaitingList,
        updateActiveModalProps,
        closeAllModals,
        closeSpecificModal,
        updateSpecificModal,
        noModalsOpen,
        normalListProps,
        waitingList,
        waitingListStatus,
        shouldUpdateActiveModalProps,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default memo(ModalProvider);
