import React, { Fragment, memo } from "react";
import { useModal } from "contexts/Modal/useModal";
import ChapterNameLoading from "./ChapterNameLoading";
import { ReactComponent as CloseIcon } from "assets/icons/x.svg";
import VersionsSelector from "./VersionsSelector";

const BibleHeader = ({
  isLoading,
  focusMode,
  chapterTitle,
  currentVersion,
  onChooseVersion,
}) => {
  const { closeModal } = useModal();

  return (
    <Fragment>
      {isLoading ? (
        <ChapterNameLoading focusMode={focusMode} />
      ) : (
        <div className="bible-reading__title">
          <h2>{chapterTitle ? chapterTitle : "Oops =("}</h2>

          <VersionsSelector
            isLoading={isLoading}
            currentVersion={currentVersion}
            chooseVersion={onChooseVersion}
          />
        </div>
      )}
      <div className="bible-reading__close" onClick={closeModal}>
        <CloseIcon />
      </div>
    </Fragment>
  );
};

export default memo(BibleHeader);
