const UA = navigator.userAgent;

const IOS = UA.match(/iPhone|iPad|iPod/);
const ANDROID = UA.match(/Android/);

export const PLATFORM = IOS ? "ios" : ANDROID ? "android" : "unknown";

const standalone = window.matchMedia("(display-mode: standalone)").matches;

export const isInstalled = !!(standalone || (IOS && !UA.match(/Safari/)));
