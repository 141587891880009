export const authentication = {
  authentication: {
    accountConfirmed: "Account confirmed 🤩",
    confirmingAccount: "Confirming account",
    somethingWentWrong: "Something went wrong, please try again",
    accountNotFound: "Account not found.",
    register: "Register.",
    emailRegistered: "Email already registered.",
    login: "Login",
    existingAccountUsing:
      "An account associated with this email was created using {{socialMedia}}. Please use it to login.",
    invalidSolicitation: "Invalid password reset request.",
    expiredSolicitation: "Password reset request expired.",
    askAgain: "Ask again",
    passwordChanged: "Password changed successfully.",
    wrongPassword: "Wrong password",
    emailNotFound: "Email not found.",

    forgotPasswordMessage: "Forgot password?",
    askEmailVerification: "Done! Please check your email :)",
    typeYourEmail:
      "No problem. Enter your email and we'll send you a password reset link:",

    loginTitle: "Login",
    loggingIn: "Logging in...",
    loginMsg: "Login",
    registerMsg: "register",
    loginWithFacebook: "Continue with Facebook",
    loginWithGoogle: "Continue with Google",
    loginWithApple: "Sign in with Apple",
    createAccount: "Create account",
    onRegistering: "By registering you agree with our",
    policyTerms: "terms of service and privacy policy",
    registering: "Registering...",
    registerMsgCaptalized: "Register",
    logYourself: "login",
    registerWithFacebook: "Register with Facebook",
    registerWithGoogle: "Register with Google",
    registerWithApple: "Sign up with Apple",
    finishWithFacebook: "Finish with Facebook",
    finishWithGoogle: "Finish with Google",
    finishWithApple: "Finish with Apple",

    changePassword: "Change my password",
    informYourPassword:
      "Enter your new Bible Journey password. If you skip this process your password will not be changed.",

    subtitle:
      "By having a Bible Journey account you get access to your Bible Plan on any device :)",
    sending: "Sending...",
    send: "Send",
    email: "Email",
    password: "Password",
    or: "or",
    name: "Name",
    // validation messages

    biggerName: "Please enter a longer name",
    validEmail: "Please enter a valid email address",
    biggerPassword: "Please enter a longer password",
    pleaseInform: "Please inform your",
    pleaseInformThe: "Please inform the",
    finishRegistration: "Finish registration",
    finishYourRegistration: "Finish your registration",
  },
};
