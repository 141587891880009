import React, { memo, useEffect, useState } from "react";
import FormLogin from "./FormLogin";
import FormRegister from "./FormRegister";
import {
  loginWithFacebook,
  loginWithGoogle,
  registerWithFacebook,
  or,
  logYourself,
  registerMsg,
} from "./messages";
import { GoogleButton } from "components/SocialLoginButtons/Google";
import { FacebookLoginButton } from "components/SocialLoginButtons/Facebook";
import { useAuth } from "contexts/AuthContext";
import { AppleButton } from "components/SocialLoginButtons/Apple";
import { registerWithApple } from "pages/Authentication/Register/messages";
import { loginWithApple } from "pages/Authentication/Login/messages";

const AuthBox = () => {
  const [isLogin, setIsLogin] = useState(true);

  const { setUsingAuthBox, setToggleAuthBox } = useAuth();

  useEffect(() => {
    setUsingAuthBox(true);
    setToggleAuthBox(() => () => setIsLogin(!isLogin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  return (
    <div className="auth-box">
      <FacebookLoginButton
        text={isLogin ? loginWithFacebook : registerWithFacebook}
      />
      <GoogleButton text={isLogin ? loginWithGoogle : loginWithGoogle} />
      <AppleButton text={isLogin ? loginWithApple : registerWithApple} />
      <hr style={{ margin: "32px 0" }} />
      {isLogin ? <FormLogin /> : <FormRegister />}
      {or}{" "}
      <span
        className="mt16 inline-block underline"
        onClick={() => setIsLogin(!isLogin)}
      >
        {isLogin ? registerMsg : logYourself}
      </span>
    </div>
  );
};

export default memo(AuthBox);
