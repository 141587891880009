const INITIAL_STATE = {
  openModal: false,
  bibleReadingInterval: {},
  bibleVersion: {
    abbr: "nvi",
    id: "nvi",
  },
  currentReading: {},
};

export default function bibleReading(state = INITIAL_STATE, action) {
  if (action.type === "START_READ_BIBLE") {
    return {
      ...state,
      bibleReadingInterval: action.readingInterval,
      openModal: true,
    };
  }

  if (action.type === "CLOSE_MODAL") {
    return {
      ...state,
      openModal: false,
    };
  }

  if (action.type === "CHOOSE_VERSION") {
    return {
      ...state,
      bibleVersion: {
        abbr: action.abbr,
        id: action.id,
      },
    };
  }

  if (action.type === "STORE_CURRENT_READING") {
    return {
      ...state,
      currentReading: {
        month: action.month,
        readingListIndex: action.readingListIndex,
      },
    };
  }

  return state;
}
