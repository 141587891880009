import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

const LoadingState = () => {
  return (
    <Fragment>
      <div style={{ marginBottom: "8px", marginTop: "4px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="90%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="92%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="100%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="50%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="80%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="100%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="95%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="65%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="97%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="94%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="90%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="16%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="100%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="80%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="40%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="80%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="82%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="70%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="90%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="90%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="80%"
          style={{ marginBottom: "4px" }}
        />
      </div>
      <div style={{ marginBottom: "8px" }}>
        <Skeleton
          className="skeleton"
          height={16}
          width="75%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="80%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="100%"
          style={{ marginBottom: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={16}
          width="40%"
          style={{ marginBottom: "4px" }}
        />
      </div>
    </Fragment>
  );
};

export default LoadingState;
