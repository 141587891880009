import React, { memo, useState, useEffect } from "react";
import { ReactComponent as XIcon } from "assets/icons/icon-x.svg";
import { emailRegex } from "utils";
import { useUser } from "contexts/User";

const EmailsBox = ({
  placeholder,
  initialEmails = [],
  onParticipantsChange = () => {},
  onTyping = () => {},
  theme = "",
}) => {
  const [emails, setEmails] = useState(initialEmails);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const user = useUser();

  useEffect(() => {
    onParticipantsChange(emails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  useEffect(() => {
    if (email) {
      setEmailIsValid(emailRegex.test(email));
    }
  }, [email]);

  const onInputChange = (e) => {
    onTyping();
    setEmail(e.target.value);
    setShowSuggestion(true);
  };

  const addEmailToList = (newEmail) => {
    if (emails.includes(newEmail) || newEmail !== user.email) {
      setEmails([...emails, newEmail]);
      setEmailIsValid(false);
    }
  };

  const onInputKeyPress = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && emailIsValid) {
      e.preventDefault();
      addEmailToList(email);
      setEmail("");
      setShowSuggestion(false);
    }
  };

  const onSuggestionClick = (e) => {
    addEmailToList(email);
    setEmail("");
    setShowSuggestion(false);
  };

  const onXClick = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  return (
    <div className={`emails-box emails-box--${theme}`}>
      {!!emails.length && (
        <div className="emails-box__list">
          {emails.map((email, index) => (
            <div className="emails-box__item" key={index}>
              <p>{email}</p>
              <XIcon onClick={() => onXClick(email)} />
            </div>
          ))}
        </div>
      )}

      <div className="emails-box__input">
        <input
          type="email"
          value={email}
          onChange={onInputChange}
          placeholder={placeholder}
          onKeyDown={onInputKeyPress}
        />

        {showSuggestion && !!email && emailIsValid && (
          <div
            className="emails-box__input-suggestion"
            onClick={onSuggestionClick}
          >
            {email}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(EmailsBox);
