import { i18n } from "translate/i18n";

export const challenges = i18n.t("messages.challenge.challenges");
export const youAreLeader = i18n.t("messages.challenge.youAreLeader");
export const youAreParticipant = i18n.t("messages.challenge.youAreParticipant");
export const createNewChallenge = i18n.t(
  "messages.challenge.createNewChallenge"
);
export const errorOnFetchingChallenges = i18n.t(
  "messages.challenge.errorOnFetchingChallenges"
);
export const participantsMsg = i18n.t("messages.challenge.participants");
export const subscribeOrBuy = i18n.t("messages.challenge.subscribeOrBuy");
