import moment from "moment";
import { genesis, revelation } from "./messages";

export const initialStartReading = {
  bookIndex: 0,
  chapter: 1,
  bibleReference: `${genesis} 1`,
};

export const initialEndReading = {
  bookIndex: 65,
  chapter: 22,
  bibleReference: `${revelation} 22`,
};

export const today = moment();

export const todayMorning = () => {
  const year = moment().year();
  const month = moment().month();
  const date = moment().date();

  const earliest = moment([year, month, date]).startOf("day");

  return earliest;
};

export const lastDayInYear = moment([moment().year(), 11, 31]);
