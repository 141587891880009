import { i18n } from "translate/i18n";

export const exceptionModalTitle = i18n.t(
  "messages.exceptionModal.exceptionModalTitle"
);
export const exceptionModalSubtitle = i18n.t(
  "messages.exceptionModal.exceptionModalSubtitle"
);
export const exceptionModalButton = i18n.t(
  "messages.exceptionModal.exceptionModalButton"
);
