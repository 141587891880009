import Toggler from "atoms/Toggler";
import { usePlan } from "components/FormPlan/Context";
import useRequest from "hooks/useRequest";
import React, { memo, useCallback, useEffect } from "react";
import { muteReminder } from "./messages";

const MuteReminder = ({ trackDelays, saveTrackDelays, onlyToggler }) => {
  const { shouldTrackDelays, planId, setShouldTrackDelays } = usePlan();

  const request = useRequest();

  useEffect(() => {
    if (shouldTrackDelays !== trackDelays) {
      request
        .put(`/plan/track-delays/${planId}`, { shouldTrackDelays: trackDelays })
        .then(() => setShouldTrackDelays(trackDelays));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrackDelays, trackDelays]);

  const toggleToggler = useCallback(() => {
    saveTrackDelays(!trackDelays);
  }, [saveTrackDelays, trackDelays]);

  if (onlyToggler) {
    return <Toggler active={!trackDelays} toggle={toggleToggler} />;
  }

  return (
    <div className="toggler-wrap">
      <Toggler active={!trackDelays} toggle={toggleToggler} />
      <p onClick={toggleToggler} className="p">
        {muteReminder}
      </p>
    </div>
  );
};

export default memo(MuteReminder);
