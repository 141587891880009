import React from "react";

import App from "components/App";
import Header from "components/App/Header";
import { Navbar } from "components/Navbar";
import { useChallenge } from "contexts/Challenge/useChallenge";
import MainCard from "components/MainCard";

const Wrapper = ({ children, cardTitle }) => {
  const { questionsPage, currentChallenge } = useChallenge();

  return (
    <App>
      <Navbar backTo={questionsPage} />
      <Header title={currentChallenge.title} color="white" />
      <MainCard title={cardTitle} centerTitle showBrandFooter>
        {children}
      </MainCard>
    </App>
  );
};

export default Wrapper;
