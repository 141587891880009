export const bottomBar = {
  bottomBar: {
    home: "Home",
    createAChallenge: "Create a challenge",
    menu: "Menu",
    challenges: "Challenges",
    ranking: "Ranking",
    plan: "Plan",
    questions: "Questions",
    configs: "Configs",
  },
};
