import React, {
  useCallback,
  createContext,
  memo,
  useEffect,
  useState,
} from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.theme);

  useEffect(() => {
    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    try {
      // Chrome & Firefox
      darkMediaQuery.addEventListener("change", (e) => {
        window.__setPreferredTheme(e.matches ? "dark" : "light");
        setTheme(e.matches ? "dark" : "light");
      });
    } catch (e1) {
      try {
        // Safari
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addListener(function (e) {
            window.__setPreferredTheme(e.matches ? "dark" : "light");
            setTheme(e.matches ? "dark" : "light");
          });
      } catch (e2) {
        console.error(e2);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = useCallback(() => {
    const newTheme = theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme-is-user-preference", true);

    setTheme(newTheme);

    window.__setPreferredTheme(newTheme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default memo(ThemeProvider);
