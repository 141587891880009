import moment from "moment";
import Bible from "utils/Bible";
import api from "./api";
import BiblicalPlan from "./BiblicalPlan";
import DateHandler from "./DateHandler";
import { planTypes } from "./planType";

const bible = new Bible();
const dateHandler = new DateHandler();
const biblePlan = new BiblicalPlan();

export const syncPlanWithDB = async ({
  readingCalendar,
  updateDelays,
  id,
  shouldTrackDelays,
  blockedWeekDays,
  quantityOfDays,
  quantityOfChapters,
  planType,
  delays,
  headers,
} = {}) => {
  return await api.put(
    `/plan/${id}`,
    {
      readingCalendar,
      shouldTrackDelays,
      blockedWeekDays,
      quantityOfDays,
      chaptersPerDay: quantityOfChapters,
      planType,
      ...(updateDelays && { delays: delays + 1 }),
    },
    headers
  );
};

export const completeBibleArray = bible.createArrayByReadingRange({
  startBookIndex: 0,
  endBookIndex: 65,
  startChapter: 1,
  endChapter: 22,
});

export const getInitialReading = (planv2) => {
  let found;
  completeBibleArray.find((item) => {
    return planv2.some((day) => {
      if (
        day.readingStart.book === item.book &&
        day.readingStart.chapter === item.chapter
      ) {
        found = day;
        return true;
      }

      return false;
    });
  });
  return found;
};

const decideOnPlanAlgorithm = ({
  startDate,
  endDate,
  readingStart,
  readingEnd,
  readPlan,
  blockedWeekDays,
  planType,
  quantityOfDays,
  quantityOfChapters,
}) => {
  if (planType === planTypes.default) {
    return biblePlan.buildPlanCalendar(
      startDate,
      endDate,
      readingStart,
      readingEnd,
      readPlan,
      blockedWeekDays
    );
  }

  if (planType === planTypes.quantityOfDays) {
    return biblePlan.buildPlanCalendarByQuantityOfDays(
      startDate,
      quantityOfDays,
      readingStart,
      readingEnd,
      readPlan,
      blockedWeekDays
    );
  }

  if (planType === planTypes.chaptersPerDay) {
    return biblePlan.buildPlanCalendarByChaptersPerDay(
      startDate,
      readingStart,
      readingEnd,
      Number(quantityOfChapters),
      readPlan,
      blockedWeekDays
    );
  }

  return biblePlan.buildPlanCalendar(
    startDate,
    endDate,
    readingStart,
    readingEnd,
    readPlan,
    blockedWeekDays
  );
};

export const getNextReadingArrayIndex = (plan, reading) => {
  let found;
  completeBibleArray.find((item, index, array) => {
    const nextItem =
      array[index === array.length - 1 ? array.length - 1 : index + 1];

    if (item.book === reading.book && item.chapter === reading.chapter) {
      plan.some((day, index) => {
        if (
          day.readingDetails.some(
            ({ book, chapter }) =>
              book === nextItem.book && chapter === nextItem.chapter
          )
        ) {
          found = index;
          return true;
        }

        return false;
      });
    }

    return false;
  });

  return Number.isSafeInteger(found) ? found : 0;
};

export const replan = async ({
  plan,
  blockedWeekDays,
  planType,
  quantityOfDays,
  quantityOfChapters,
}) => {
  const readPlan = plan.filter((day) => day.read);
  const { readingStart } = getInitialReading(plan);
  const { readingEnd, date: nonReadDate } = plan[plan.length - 1];
  const isFirstDay = 0;

  const startDate = dateHandler.decideOnSafeDate(
    blockedWeekDays,
    moment().clone(),
    isFirstDay
  );

  const endDate = moment(nonReadDate);

  let newPlan = decideOnPlanAlgorithm({
    startDate,
    endDate,
    readingStart,
    readingEnd,
    readPlan,
    blockedWeekDays,
    planType,
    quantityOfDays,
    quantityOfChapters,
  });

  readPlan.reverse().forEach((readDay) => {
    const indexFound = getNextReadingArrayIndex(newPlan, readDay.readingEnd);

    newPlan.splice(indexFound, 0, readDay);
  });

  newPlan = newPlan.map((item, index) => {
    const notUsingIdFromDB = Number.isSafeInteger(item._id);
    item._id = notUsingIdFromDB ? index : item._id;
    return item;
  });

  return newPlan;
};
