import { i18n } from "translate/i18n";

export const forgotPasswordMessage = i18n.t(
  "messages.authentication.forgotPasswordMessage"
);
export const askEmailVerification = i18n.t(
  "messages.authentication.askEmailVerification"
);
export const typeYourEmail = i18n.t(
  "messages.authentication.typeYourEmail"
);
