import { useContext } from "react";
import { NewChallengeContext } from "./Component";

const useNewChallenge = () => {
  const context = useContext(NewChallengeContext);
  if (!context)
    throw new Error(
      "useNewChallenge must be used within a NewChallengeProvider"
    );

  return context;
};

export default useNewChallenge;
