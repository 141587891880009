import React, { Fragment, useCallback, useEffect } from "react";

import { ExceptionModal } from "./Modals";
import { DefaultForm } from "./Forms";
import { MoreOptions } from "./components";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";
import Button from "components/Button";
import classNames from "classnames";
import { updatePlan } from "components/FormPlan/messages";
import { useModal } from "contexts/Modal/useModal";
import { areYouSure, changedMyMind } from "pages/Menu/messages";
import {
  planUpdateDisclaimer,
  thePlanWillChange,
  yesUpdateIt,
} from "../../messages";
import { loading } from "components/SocialLoginButtons/messages";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useChallenge } from "contexts/Challenge/useChallenge";

const FormPlan = () => {
  const {
    allInputsAreValid,
    createPlan,
    setIsLoading,
    isLoading,
    isViewingForm,
  } = useChallengePlan();
  const { createModal, closeModal } = useModal();
  const { planPage } = useChallenge();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onButtonClick = useCallback(() => {
    if (!allInputsAreValid()) return;

    createModal({
      title: areYouSure,
      subtitle: thePlanWillChange,
      firstButtonText: changedMyMind,
      secondButtonText: yesUpdateIt,
      onFirstButtonClick: closeModal,
      onSecondButtonClick: () => {
        closeModal();
        createPlan(() => {
          history.push(planPage);
        });
      },
      disclaimer: planUpdateDisclaimer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInputsAreValid, closeModal, createModal, history, planPage]);

  return (
    <Fragment>
      <ExceptionModal />
      <div className="form form-plan">
        <DefaultForm />
        <MoreOptions />
        <div
          className={classNames("default-button", {
            openMoreOptions: !isViewingForm,
          })}
        >
          <Button
            disabled={isLoading}
            theme="create-plan"
            onClick={onButtonClick}
          >
            {isLoading ? loading : updatePlan}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default FormPlan;
