import { i18n } from "translate/i18n";

export const close = i18n.t(`buttons.bibleReading.close`);
export const finishReading = i18n.t(`buttons.bibleReading.finishReading`);
export const bibleVersionMsg = i18n.t(`buttons.bibleReading.bibleVersion`);
export const soonOthers = i18n.t(`buttons.bibleReading.soonOthers`);
export const version = i18n.t(`buttons.bibleReading.version`);

export const getApiAbbreviation = (readingRange, readingIndex, api) =>
  i18n.t(
    `messages.bibleBooks.${readingRange?.[readingIndex]?.book}.apiAbbreviation.${api}`
  );

export const weCouldNotLoadChapter = i18n.t(`messages.weCouldNotLoadChapter`);
export const thePossibleReasons = i18n.t(`messages.thePossibleReasons`);
export const thePossibleReasons1 = i18n.t(`messages.thePossibleReasons1`);
export const thePossibleReasons2 = i18n.t(`messages.thePossibleReasons2`);
export const thePossibleReasons3 = i18n.t(`messages.thePossibleReasons3`);
export const whatYouCanDo = i18n.t(`messages.whatYouCanDo`);
export const whatYouCanDo1 = i18n.t(`messages.whatYouCanDo1`);
export const whatYouCanDo2 = i18n.t(`messages.whatYouCanDo2`);
export const whatYouCanDo3 = i18n.t(`messages.whatYouCanDo3`);

export const noVersionsOffline = i18n.t(`messages.noVersionsOffline`);
export const connectAndDownload = i18n.t(`messages.connectAndDownload`);
