import { i18n } from "../translate/i18n";

export default class BibleArray {
  books = [
    {
      name: i18n.t("messages.bibleBooks.genesis.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.genesis.abbreviation"),
      chapters: 50,
      verses: 1533,
      testment: i18n.t("messages.bibleBooks.genesis.testament"),
      id: "genesis"
    },
    {
      name: i18n.t("messages.bibleBooks.exodus.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.exodus.abbreviation"),
      chapters: 40,
      verses: 1213,
      testment: i18n.t("messages.bibleBooks.exodus.testament"),
      id: "exodus"
    },
    {
      name: i18n.t("messages.bibleBooks.leviticus.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.leviticus.abbreviation"),
      chapters: 27,
      verses: 859,
      testment: i18n.t("messages.bibleBooks.leviticus.testament"),
      id: "leviticus"
    },
    {
      name: i18n.t("messages.bibleBooks.numbers.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.numbers.abbreviation"),
      chapters: 36,
      verses: 1288,
      testment: i18n.t("messages.bibleBooks.numbers.testament"),
      id: "numbers"
    },
    {
      name: i18n.t("messages.bibleBooks.deuteronomy.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.deuteronomy.abbreviation"),
      chapters: 34,
      verses: 959,
      testment: i18n.t("messages.bibleBooks.deuteronomy.testament"),
      id: "deuteronomy"
    },
    {
      name: i18n.t("messages.bibleBooks.joshua.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.joshua.abbreviation"),
      chapters: 24,
      verses: 658,
      testment: i18n.t("messages.bibleBooks.joshua.testament"),
      id: "joshua"
    },
    {
      name: i18n.t("messages.bibleBooks.judges.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.judges.abbreviation"),
      chapters: 21,
      verses: 619,
      testment: i18n.t("messages.bibleBooks.judges.testament"),
      id: "judges"
    },
    {
      name: i18n.t("messages.bibleBooks.ruth.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.ruth.abbreviation"),
      chapters: 4,
      verses: 85,
      testment: i18n.t("messages.bibleBooks.ruth.testament"),
      id: "ruth"
    },
    {
      name: i18n.t("messages.bibleBooks.samuel1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.samuel1.abbreviation"),
      chapters: 31,
      verses: 811,
      testment: i18n.t("messages.bibleBooks.samuel1.testament"),
      id: "samuel1"
    },
    {
      name: i18n.t("messages.bibleBooks.samuel2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.samuel2.abbreviation"),
      chapters: 24,
      verses: 695,
      testment: i18n.t("messages.bibleBooks.samuel2.testament"),
      id: "samuel2"
    },
    {
      name: i18n.t("messages.bibleBooks.kings1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.kings1.abbreviation"),
      chapters: 22,
      verses: 817,
      testment: i18n.t("messages.bibleBooks.kings1.testament"),
      id: "kings1"
    },
    {
      name: i18n.t("messages.bibleBooks.kings2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.kings2.abbreviation"),
      chapters: 25,
      verses: 719,
      testment: i18n.t("messages.bibleBooks.kings2.testament"),
      id: "kings2"
    },
    {
      name: i18n.t("messages.bibleBooks.chronicles1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.chronicles1.abbreviation"),
      chapters: 29,
      verses: 942,
      testment: i18n.t("messages.bibleBooks.chronicles1.testament"),
      id: "chronicles1"
    },
    {
      name: i18n.t("messages.bibleBooks.chronicles2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.chronicles2.abbreviation"),
      chapters: 36,
      verses: 822,
      testment: i18n.t("messages.bibleBooks.chronicles2.testament"),
      id: "chronicles2"
    },
    {
      name: i18n.t("messages.bibleBooks.ezra.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.ezra.abbreviation"),
      chapters: 10,
      verses: 280,
      testment: i18n.t("messages.bibleBooks.ezra.testament"),
      id: "ezra"
    },
    {
      name: i18n.t("messages.bibleBooks.nehemiah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.nehemiah.abbreviation"),
      chapters: 13,
      verses: 406,
      testment: i18n.t("messages.bibleBooks.nehemiah.testament"),
      id: "nehemiah"
    },
    {
      name: i18n.t("messages.bibleBooks.esther.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.esther.abbreviation"),
      chapters: 10,
      verses: 167,
      testment: i18n.t("messages.bibleBooks.esther.testament"),
      id: "esther"
    },
    {
      name: i18n.t("messages.bibleBooks.job.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.job.abbreviation"),
      chapters: 42,
      verses: 1070,
      testment: i18n.t("messages.bibleBooks.job.testament"),
      id: "job"
    },
    {
      name: i18n.t("messages.bibleBooks.psalms.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.psalms.abbreviation"),
      chapters: 150,
      verses: 2461,
      testment: i18n.t("messages.bibleBooks.psalms.testament"),
      id: "psalms"
    },
    {
      name: i18n.t("messages.bibleBooks.proverbs.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.proverbs.abbreviation"),
      chapters: 31,
      verses: 915,
      testment: i18n.t("messages.bibleBooks.proverbs.testament"),
      id: "proverbs"
    },
    {
      name: i18n.t("messages.bibleBooks.ecclesiastes.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.ecclesiastes.abbreviation"),
      chapters: 12,
      verses: 222,
      testment: i18n.t("messages.bibleBooks.ecclesiastes.testament"),
      id: "ecclesiastes"
    },
    {
      name: i18n.t("messages.bibleBooks.solomonsongof.name"),
      bookAbbreviation: i18n.t(
        "messages.bibleBooks.solomonsongof.abbreviation"
      ),
      chapters: 8,
      verses: 117,
      testment: i18n.t("messages.bibleBooks.solomonsongof.testament"),
      id: "solomonsongof"
    },
    {
      name: i18n.t("messages.bibleBooks.isaiah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.isaiah.abbreviation"),
      chapters: 66,
      verses: 1292,
      testment: i18n.t("messages.bibleBooks.isaiah.testament"),
      id: "isaiah"
    },
    {
      name: i18n.t("messages.bibleBooks.jeremiah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.jeremiah.abbreviation"),
      chapters: 52,
      verses: 1364,
      testment: i18n.t("messages.bibleBooks.jeremiah.testament"),
      id: "jeremiah"
    },
    {
      name: i18n.t("messages.bibleBooks.lamentations.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.lamentations.abbreviation"),
      chapters: 5,
      verses: 154,
      testment: i18n.t("messages.bibleBooks.lamentations.testament"),
      id: "lamentations"
    },
    {
      name: i18n.t("messages.bibleBooks.ezekiel.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.ezekiel.abbreviation"),
      chapters: 48,
      verses: 1273,
      testment: i18n.t("messages.bibleBooks.ezekiel.testament"),
      id: "ezekiel"
    },
    {
      name: i18n.t("messages.bibleBooks.daniel.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.daniel.abbreviation"),
      chapters: 12,
      verses: 357,
      testment: i18n.t("messages.bibleBooks.daniel.testament"),
      id: "daniel"
    },
    {
      name: i18n.t("messages.bibleBooks.hosea.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.hosea.abbreviation"),
      chapters: 14,
      verses: 197,
      testment: i18n.t("messages.bibleBooks.hosea.testament"),
      id: "hosea"
    },
    {
      name: i18n.t("messages.bibleBooks.joel.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.joel.abbreviation"),
      chapters: 3,
      verses: 73,
      testment: i18n.t("messages.bibleBooks.joel.testament"),
      id: "joel"
    },
    {
      name: i18n.t("messages.bibleBooks.amos.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.amos.abbreviation"),
      chapters: 9,
      verses: 146,
      testment: i18n.t("messages.bibleBooks.amos.testament"),
      id: "amos"
    },
    {
      name: i18n.t("messages.bibleBooks.obadiah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.obadiah.abbreviation"),
      chapters: 1,
      verses: 21,
      testment: i18n.t("messages.bibleBooks.obadiah.testament"),
      id: "obadiah"
    },
    {
      name: i18n.t("messages.bibleBooks.jonah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.jonah.abbreviation"),
      chapters: 4,
      verses: 48,
      testment: i18n.t("messages.bibleBooks.jonah.testament"),
      id: "jonah"
    },
    {
      name: i18n.t("messages.bibleBooks.micah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.micah.abbreviation"),
      chapters: 7,
      verses: 105,
      testment: i18n.t("messages.bibleBooks.micah.testament"),
      id: "micah"
    },
    {
      name: i18n.t("messages.bibleBooks.nahum.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.nahum.abbreviation"),
      chapters: 3,
      verses: 47,
      testment: i18n.t("messages.bibleBooks.nahum.testament"),
      id: "nahum"
    },
    {
      name: i18n.t("messages.bibleBooks.habakkuk.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.habakkuk.abbreviation"),
      chapters: 3,
      verses: 56,
      testment: i18n.t("messages.bibleBooks.habakkuk.testament"),
      id: "habakkuk"
    },
    {
      name: i18n.t("messages.bibleBooks.zephaniah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.zephaniah.abbreviation"),
      chapters: 3,
      verses: 53,
      testment: i18n.t("messages.bibleBooks.zephaniah.testament"),
      id: "zephaniah"
    },
    {
      name: i18n.t("messages.bibleBooks.haggai.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.haggai.abbreviation"),
      chapters: 2,
      verses: 38,
      testment: i18n.t("messages.bibleBooks.haggai.testament"),
      id: "haggai"
    },
    {
      name: i18n.t("messages.bibleBooks.zechariah.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.zechariah.abbreviation"),
      chapters: 14,
      verses: 211,
      testment: i18n.t("messages.bibleBooks.zechariah.testament"),
      id: "zechariah"
    },
    {
      name: i18n.t("messages.bibleBooks.malachi.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.malachi.abbreviation"),
      chapters: 4,
      verses: 55,
      testment: i18n.t("messages.bibleBooks.malachi.testament"),
      id: "malachi"
    },
    {
      name: i18n.t("messages.bibleBooks.matthew.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.matthew.abbreviation"),
      chapters: 28,
      verses: 1071,
      testment: i18n.t("messages.bibleBooks.matthew.testament"),
      id: "matthew"
    },
    {
      name: i18n.t("messages.bibleBooks.mark.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.mark.abbreviation"),
      chapters: 16,
      verses: 678,
      testment: i18n.t("messages.bibleBooks.mark.testament"),
      id: "mark"
    },
    {
      name: i18n.t("messages.bibleBooks.luke.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.luke.abbreviation"),
      chapters: 24,
      verses: 1151,
      testment: i18n.t("messages.bibleBooks.luke.testament"),
      id: "luke"
    },
    {
      name: i18n.t("messages.bibleBooks.john.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.john.abbreviation"),
      chapters: 21,
      verses: 879,
      testment: i18n.t("messages.bibleBooks.john.testament"),
      id: "john"
    },
    {
      name: i18n.t("messages.bibleBooks.acts.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.acts.abbreviation"),
      chapters: 28,
      verses: 1007,
      testment: i18n.t("messages.bibleBooks.acts.testament"),
      id: "acts"
    },
    {
      name: i18n.t("messages.bibleBooks.romans.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.romans.abbreviation"),
      chapters: 16,
      verses: 433,
      testment: i18n.t("messages.bibleBooks.romans.testament"),
      id: "romans"
    },
    {
      name: i18n.t("messages.bibleBooks.corinthians1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.corinthians1.abbreviation"),
      chapters: 16,
      verses: 437,
      testment: i18n.t("messages.bibleBooks.corinthians1.testament"),
      id: "corinthians1"
    },
    {
      name: i18n.t("messages.bibleBooks.corinthians2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.corinthians2.abbreviation"),
      chapters: 13,
      verses: 256,
      testment: i18n.t("messages.bibleBooks.corinthians2.testament"),
      id: "corinthians2"
    },
    {
      name: i18n.t("messages.bibleBooks.galatians.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.galatians.abbreviation"),
      chapters: 6,
      verses: 149,
      testment: i18n.t("messages.bibleBooks.galatians.testament"),
      id: "galatians"
    },
    {
      name: i18n.t("messages.bibleBooks.ephesians.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.ephesians.abbreviation"),
      chapters: 6,
      verses: 155,
      testment: i18n.t("messages.bibleBooks.ephesians.testament"),
      id: "ephesians"
    },
    {
      name: i18n.t("messages.bibleBooks.philippians.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.philippians.abbreviation"),
      chapters: 4,
      verses: 104,
      testment: i18n.t("messages.bibleBooks.philippians.testament"),
      id: "philippians"
    },
    {
      name: i18n.t("messages.bibleBooks.colossians.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.colossians.abbreviation"),
      chapters: 4,
      verses: 95,
      testment: i18n.t("messages.bibleBooks.colossians.testament"),
      id: "colossians"
    },
    {
      name: i18n.t("messages.bibleBooks.thessalonians1.name"),
      bookAbbreviation: i18n.t(
        "messages.bibleBooks.thessalonians1.abbreviation"
      ),
      chapters: 5,
      verses: 89,
      testment: i18n.t("messages.bibleBooks.thessalonians1.testament"),
      id: "thessalonians1"
    },
    {
      name: i18n.t("messages.bibleBooks.thessalonians2.name"),
      bookAbbreviation: i18n.t(
        "messages.bibleBooks.thessalonians2.abbreviation"
      ),
      chapters: 3,
      verses: 47,
      testment: i18n.t("messages.bibleBooks.thessalonians2.testament"),
      id: "thessalonians2"
    },
    {
      name: i18n.t("messages.bibleBooks.timothy1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.timothy1.abbreviation"),
      chapters: 6,
      verses: 113,
      testment: i18n.t("messages.bibleBooks.timothy1.testament"),
      id: "timothy1"
    },
    {
      name: i18n.t("messages.bibleBooks.timothy2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.timothy2.abbreviation"),
      chapters: 4,
      verses: 83,
      testment: i18n.t("messages.bibleBooks.timothy2.testament"),
      id: "timothy2"
    },
    {
      name: i18n.t("messages.bibleBooks.titus.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.titus.abbreviation"),
      chapters: 3,
      verses: 46,
      testment: i18n.t("messages.bibleBooks.titus.testament"),
      id: "titus"
    },
    {
      name: i18n.t("messages.bibleBooks.philemon.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.philemon.abbreviation"),
      chapters: 1,
      verses: 25,
      testment: i18n.t("messages.bibleBooks.philemon.testament"),
      id: "philemon"
    },
    {
      name: i18n.t("messages.bibleBooks.hebrews.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.hebrews.abbreviation"),
      chapters: 13,
      verses: 303,
      testment: i18n.t("messages.bibleBooks.hebrews.testament"),
      id: "hebrews"
    },
    {
      name: i18n.t("messages.bibleBooks.james.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.james.abbreviation"),
      chapters: 5,
      verses: 108,
      testment: i18n.t("messages.bibleBooks.james.testament"),
      id: "james"
    },
    {
      name: i18n.t("messages.bibleBooks.peter1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.peter1.abbreviation"),
      chapters: 5,
      verses: 105,
      testment: i18n.t("messages.bibleBooks.peter1.testament"),
      id: "peter1"
    },
    {
      name: i18n.t("messages.bibleBooks.peter2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.peter2.abbreviation"),
      chapters: 3,
      verses: 61,
      testment: i18n.t("messages.bibleBooks.peter2.testament"),
      id: "peter2"
    },
    {
      name: i18n.t("messages.bibleBooks.john1.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.john1.abbreviation"),
      chapters: 5,
      verses: 105,
      testment: i18n.t("messages.bibleBooks.john1.testament"),
      id: "john1"
    },
    {
      name: i18n.t("messages.bibleBooks.john2.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.john2.abbreviation"),
      chapters: 1,
      verses: 13,
      testment: i18n.t("messages.bibleBooks.john2.testament"),
      id: "john2"
    },
    {
      name: i18n.t("messages.bibleBooks.john3.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.john3.abbreviation"),
      chapters: 1,
      verses: 15,
      testment: i18n.t("messages.bibleBooks.john3.testament"),
      id: "john3"
    },
    {
      name: i18n.t("messages.bibleBooks.jude.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.jude.abbreviation"),
      chapters: 1,
      verses: 25,
      testment: i18n.t("messages.bibleBooks.jude.testament"),
      id: "jude"
    },
    {
      name: i18n.t("messages.bibleBooks.revelation.name"),
      bookAbbreviation: i18n.t("messages.bibleBooks.revelation.abbreviation"),
      chapters: 22,
      verses: 404,
      testment: i18n.t("messages.bibleBooks.revelation.testament"),
      id: "revelation"
    },
  ];
}
