import React from "react";

import { startDateLabel, endDateLabel } from "../messages";

import { usePlan } from "../Context";

import DatePicker from "components/DatePicker";

const DatesInputs = ({ withEndDate }) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onBeforeTodayInitialDate,
    onAfterTodayInitialDate,
    blockedWeekDays,
  } = usePlan();

  return (
    <div className="form__row form-plan__inputs">
      <div className="input-wrap">
        <label htmlFor="" className="input-label">
          {startDateLabel}
        </label>
        <DatePicker
          onAfterTodayInitialDate={onAfterTodayInitialDate}
          onBeforeTodayInitialDate={onBeforeTodayInitialDate}
          date={startDate}
          onDateChange={setStartDate}
          blockedWeekDays={blockedWeekDays}
        />
      </div>
      {withEndDate && (
        <div className="input-wrap">
          <label htmlFor="" className="input-label">
            {endDateLabel}
          </label>
          <DatePicker
            blockedWeekDays={blockedWeekDays}
            date={endDate}
            onDateChange={setEndDate}
          />
        </div>
      )}
    </div>
  );
};

DatesInputs.defaultProps = {
  withEndDate: true,
};

export default DatesInputs;
