export const logEasterEgg = () =>
  console.log(
    `%c
                                                                          
    ██████╗ ██╗██████╗ ██╗     ███████╗██████╗ ██╗      █████╗ ███╗   ██╗ 
    ██╔══██╗██║██╔══██╗██║     ██╔════╝██╔══██╗██║     ██╔══██╗████╗  ██║ 
    ██████╔╝██║██████╔╝██║     █████╗  ██████╔╝██║     ███████║██╔██╗ ██║ 
    ██╔══██╗██║██╔══██╗██║     ██╔══╝  ██╔═══╝ ██║     ██╔══██║██║╚██╗██║ 
    ██████╔╝██║██████╔╝███████╗███████╗██║     ███████╗██║  ██║██║ ╚████║ 
    ╚═════╝ ╚═╝╚═════╝ ╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═══╝ 
                                                                          
`,
    "color: #fff; background-color: #6a2d56"
  );
