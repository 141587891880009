export const menu = {
  menu: {
    downloadPlanPdf: "Pobierz plan w PDF",
    downloadingPlanPdf: "Pobieranie...",
    editPlan: "Edytuj plan",
    shareBibleJourney: "Podziel się BibleJourneyem",
    giveFeedback: "Przekaż uwagi",
    donate: "Wesprzyj",
  },
};
