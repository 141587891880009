import { planTypes } from "components/FormPlan/Context/planType";
import {
  readingIntervalIsReverseError,
  startDateBiggerThenEndDateError,
} from "components/FormPlan/messages";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { useQuery } from "hooks";
import React, {
  createContext,
  memo,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { api, BiblicalPlan, sendErrorEmail, today } from "utils";
import { useChallenge } from "../useChallenge";
import {
  initialEndReading,
  initialStartReading,
  lastDayInYear,
} from "./contants";
const biblePlan = new BiblicalPlan();

export const ChallengePlanContext = createContext();

const ChallengePlanProvider = ({ children }) => {
  const [readersRanking, setReadersRanking] = useState([]);
  const [isLoadingRanking, setIsLoadingRanking] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(true);
  const [rankingIsLoaded, setRankingIsLoaded] = useState(false);
  const {
    challengeHeaders,
    request: challenge,
    currentChallenge,
  } = useChallenge();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [showException, setShowException] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [formError, setFormError] = useState("");
  const [startReading, setStartReading] = useState(initialStartReading);
  const [endReading, setEndReading] = useState(initialEndReading);
  const [blockedWeekDays, setBlockedWeekDays] = useState([]);
  const [planType, setPlanType] = useState(planTypes.default);
  const [quantityOfDays, setQuantityOfDays] = useState(90);
  const [trackDelays, setTrackDelays] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(lastDayInYear);
  const [quantityOfChapters, setQuantityOfChapters] = useState(3);
  const [plan, setPlan] = useState([]);
  const queryPlanType = useQuery("planType");
  const [isViewingForm, setIsViewingForm] = useState(true);
  const [planIsLoaded, setPlanIsLoaded] = useState(false);
  const [planId, setPlanId] = useState();
  const [activeMonth, setActiveMonth] = useState(0);
  const [planGroupedByMonth, setPlanGroupedByMonth] = useState([]);
  const [foundMonthToShow, setFoundMonthToShow] = useState(false);
  const [lastDayIndex, setLastDayIndex] = useState(0);
  const [planWasReset, setPlanWasReset] = useState(false);
  const [isFetchingPlan, setIsFetchingPlan] = useState(false);
  const [delays, setDelays] = useState(0);

  useEffect(() => {
    setFormError(null);
  }, [startDate, endDate, openMoreOptions]);

  const updatePlanOnBackend = useCallback(
    async ({ readingCalendar, then = () => {} } = {}) => {
      try {
        await challenge.put("plan/update", {
          newPlan: {
            readingCalendar,
            shouldTrackDelays: trackDelays,
            blockedWeekDays,
            quantityOfDays,
            chaptersPerDay: quantityOfChapters,
            planType,
            delays: 0,
          },
          planTemplateId: currentChallenge.planTemplate,
        });
      } catch (error) {
        setIsLoading(false);
        setShowException(true);
        sendErrorEmail(error, "updating challenge plan");
      } finally {
        setIsLoading(false);
        then();
      }
    },
    [
      challenge,
      currentChallenge,
      trackDelays,
      blockedWeekDays,
      quantityOfDays,
      planType,
      quantityOfChapters,
    ]
  );

  const createPlaByQuantityOfDays = useCallback(
    (callback = () => {}) => {
      setIsLoading(true);

      try {
        const plan = biblePlan.buildPlanCalendarByQuantityOfDays(
          startDate,
          quantityOfDays,
          startReading,
          endReading,
          [],
          blockedWeekDays
        );

        setPlan(plan);
        updatePlanOnBackend({ readingCalendar: plan });
      } catch (error) {
        sendErrorEmail(
          error,
          "creating challenge plan:createPlaByQuantityOfDays"
        );
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
        callback();
      }
    },
    [
      blockedWeekDays,
      endReading,
      openGeneralErrorSnackbar,
      quantityOfDays,
      startDate,
      startReading,
      updatePlanOnBackend,
    ]
  );

  const createPlaByQuantityOfChapters = useCallback(
    (callback = () => {}) => {
      setIsLoading(true);

      try {
        const plan = biblePlan.buildPlanCalendarByChaptersPerDay(
          startDate,
          startReading,
          endReading,
          Number(quantityOfChapters),
          [],
          blockedWeekDays
        );

        setPlan(plan);
        updatePlanOnBackend({ readingCalendar: plan });
      } catch (error) {
        sendErrorEmail(
          error,
          "creating challenge plan:createPlaByQuantityOfChapters"
        );
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
        callback();
      }
    },
    [
      blockedWeekDays,
      endReading,
      openGeneralErrorSnackbar,
      quantityOfChapters,
      startDate,
      startReading,
      updatePlanOnBackend,
    ]
  );

  const createNormalPlan = useCallback(
    (callback = () => {}) => {
      setIsLoading(true);

      try {
        const plan = biblePlan.buildPlanCalendar(
          startDate,
          endDate,
          startReading,
          endReading,
          [],
          blockedWeekDays
        );
        setPlan(plan);
        updatePlanOnBackend({ readingCalendar: plan });
      } catch (error) {
        sendErrorEmail(error, "creating challenge plan:createNormalPlan");
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
        callback();
      }
    },
    [
      blockedWeekDays,
      endDate,
      endReading,
      openGeneralErrorSnackbar,
      startDate,
      startReading,
      updatePlanOnBackend,
    ]
  );

  const readingIntervalIsReverse = useCallback(() => {
    const startBook = Number(startReading.bookIndex);
    const finishBook = Number(endReading.bookIndex);
    const startChapter = Number(startReading.chapter);
    const finishChapter = Number(endReading.chapter);

    return (
      (startBook === finishBook && startChapter > finishChapter) ||
      startBook > finishBook
    );
  }, [endReading, startReading]);

  const onBeforeTodayInitialDate = useCallback(() => setTrackDelays(false), []);

  const onAfterTodayInitialDate = useCallback(() => setTrackDelays(true), []);

  const startDateIsBiggerThenEndDate = useCallback(
    () => endDate.isBefore(startDate, "day"),
    [endDate, startDate]
  );

  const allInputsAreValid = useCallback(() => {
    if (startDateIsBiggerThenEndDate())
      return setFormError(startDateBiggerThenEndDateError);

    if (readingIntervalIsReverse()) {
      return setFormError(readingIntervalIsReverseError);
    }

    return true;
  }, [readingIntervalIsReverse, startDateIsBiggerThenEndDate]);

  const createPlan = useCallback(
    (callback = () => {}) => {
      switch (Number(queryPlanType)) {
        case planTypes.default:
          createNormalPlan(callback);
          break;
        case planTypes.quantityOfDays:
          createPlaByQuantityOfDays(callback);
          break;
        case planTypes.chaptersPerDay:
          createPlaByQuantityOfChapters(callback);
          break;
        default:
          createNormalPlan(callback);
      }
    },
    [
      createNormalPlan,
      createPlaByQuantityOfChapters,
      createPlaByQuantityOfDays,
      queryPlanType,
    ]
  );

  const loadReadersRanking = useCallback(async () => {
    setIsLoadingRanking(true);
    setRankingIsLoaded(false);
    try {
      await api
        .get("/challenge/plan/ranking", challengeHeaders)
        .then(({ data }) => {
          setReadersRanking(data.ranking);
        });
    } catch (err) {
      sendErrorEmail(err, "on getReadersRanking function");
      openGeneralErrorSnackbar();
    } finally {
      setIsLoadingRanking(false);
      setRankingIsLoaded(true);
    }
  }, [challengeHeaders, openGeneralErrorSnackbar]);

  const noReaders = useMemo(
    () => !readersRanking.length && !isLoadingRanking,
    [readersRanking, isLoadingRanking]
  );

  return (
    <ChallengePlanContext.Provider
      value={{
        readersRanking,
        isLoadingRanking,
        loadReadersRanking,
        setQuantityOfDays,
        createPlaByQuantityOfDays,
        allInputsAreValid,
        setShowException,
        setIsLoading,
        setStartReading,
        setEndReading,
        setBlockedWeekDays,
        setPlanType,
        setOpenMoreOptions,
        createPlaByQuantityOfChapters,
        setTrackDelays,
        setStartDate,
        setEndDate,
        setQuantityOfChapters,
        createNormalPlan,
        createPlan,
        setPlan,
        onBeforeTodayInitialDate,
        onAfterTodayInitialDate,
        quantityOfDays,
        showException,
        isLoading,
        openMoreOptions,
        formError,
        startReading,
        endReading,
        blockedWeekDays,
        planType,
        startDate,
        endDate,
        quantityOfChapters,
        trackDelays,
        plan,
        isViewingForm,
        setIsViewingForm,
        noReaders,
        rankingIsLoaded,
        isLoadingPlan,
        setIsLoadingPlan,
        planIsLoaded,
        setPlanIsLoaded,
        planId,
        setPlanId,
        activeMonth,
        setActiveMonth,
        planGroupedByMonth,
        setPlanGroupedByMonth,
        foundMonthToShow,
        setFoundMonthToShow,
        lastDayIndex,
        setLastDayIndex,
        planWasReset,
        setPlanWasReset,
        isFetchingPlan,
        setIsFetchingPlan,
        delays,
        setDelays,
      }}
    >
      {children}
    </ChallengePlanContext.Provider>
  );
};

export default memo(ChallengePlanProvider);
