import React, { createContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import BiblicalPlan from "utils/BiblicalPlan";
import DateHandler from "utils/DateHandler";
import { sendErrorEmail } from "utils/sendEmail";

import {
  startDateBiggerThenEndDateError,
  readingIntervalIsReverseError,
  errorOnFetchingPlan,
} from "../messages";

import {
  initialEndReading,
  initialStartReading,
  lastDayInYear,
  today,
  todayMorning,
} from "../constants";

import api from "utils/api";
import { useAuth } from "contexts/AuthContext";
import {
  getInitialReading,
  getNextReadingArrayIndex,
} from "components/PlanChecklistV2/ReplanModal/utils";
import moment from "moment";
import { planTypes } from "./planType";

const biblePlan = new BiblicalPlan();
const dateHandler = new DateHandler();

export const PlanContext = createContext();

const PlanProvider = ({
  blockedWeekDays: storedBlockedWeekDays = [],
  chaptersPerDay: storedChaptersPerDay = 3,
  quantityOfDays: storedQuantityOfDays = 90,
  planType: storedPlanType = 0,
  lastModified,
  trackDelays,
  storedTrackDelays,
  updatePlan,
  storePlan,
  children,
  planId,
  planv2,
  delays,
}) => {
  const [quantityOfChapters, setQuantityOfChapters] =
    useState(storedChaptersPerDay);
  const [shouldTrackDelays, setShouldTrackDelays] = useState(storedTrackDelays);
  const [blockedWeekDays, setBlockedWeekDays] = useState(storedBlockedWeekDays);
  const [quantityOfDays, setQuantityOfDays] = useState(storedQuantityOfDays);
  const [shouldAskConfirmation, setShouldAskConfirmation] = useState(false);
  const [startReading, setStartReading] = useState(initialStartReading);
  const [endReading, setEndReading] = useState(initialEndReading);
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [isFetchingPlan, setIsFetchingPlan] = useState(true);
  const [showException, setShowException] = useState(false);
  const [planType, setPlanType] = useState(storedPlanType);
  const [planWasReset, setPlanWasReset] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [initialDate, setInitialDate] = useState(today);
  const [endDate, setEndDate] = useState(lastDayInYear);
  const [isEditing, setIsEditing] = useState(false);
  const [askReplan, setAskReplan] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const { getHeaders, isLogged } = useAuth();
  const history = useHistory();
  const headers = getHeaders();

  const preventCrazyRedirectOnPlanPage = () => {
    setIsLoading(true);
  };

  useEffect(() => {
    const isOnAuthPages =
      history.location.pathname === "/login" ||
      history.location.pathname === "/register";
    isOnAuthPages && preventCrazyRedirectOnPlanPage();
  }, [history.location.pathname]);

  const hasPlanInLocalStorage = !!planv2.length;

  useEffect(() => {
    storedBlockedWeekDays.length && setBlockedWeekDays(storedBlockedWeekDays);
    storedPlanType !== planType && setPlanType(storedPlanType);
    storedChaptersPerDay !== quantityOfChapters &&
      setQuantityOfChapters(storedChaptersPerDay);
    storedQuantityOfDays !== quantityOfDays &&
      setQuantityOfDays(storedQuantityOfDays);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storedPlanType,
    storedBlockedWeekDays,
    storedChaptersPerDay,
    storedQuantityOfDays,
  ]);

  useEffect(() => {
    if (formError) setTimeout(() => setFormError(""), 3000);
    if (showException || formError) setIsLoading(false);
  }, [formError, showException]);

  useEffect(() => {
    trackDelays(shouldTrackDelays);
  }, [trackDelays, shouldTrackDelays]);

  const onBeforeTodayInitialDate = () => setShouldTrackDelays(false);

  const onAfterTodayInitialDate = () => setShouldTrackDelays(true);

  const startDateIsBiggerThenEndDate = () => endDate.isBefore(startDate, "day");

  const readingIntervalIsReverse = (startReading, endReading) => {
    const startBook = Number(startReading.bookIndex);
    const finishBook = Number(endReading.bookIndex);
    const startChapter = Number(startReading.chapter);
    const finishChapter = Number(endReading.chapter);

    return (
      (startBook === finishBook && startChapter > finishChapter) ||
      startBook > finishBook
    );
  };

  const decideOnPlanCreationFunction = () => {
    if (planType === planTypes.default) return handlePlanCreation();

    if (planType === planTypes.quantityOfDays)
      return createPlaByQuantityOfDays();

    if (planType === planTypes.chaptersPerDay)
      return createPlaByQuantityOfChapters();

    return handlePlanCreation();
  };

  const decideOnPlanAlgorithm = (
    startDate,
    endDate,
    readingStart,
    readingEnd,
    readPlan
  ) => {
    if (planType === planTypes.default) {
      return biblePlan.buildPlanCalendar(
        startDate,
        endDate,
        readingStart,
        readingEnd,
        readPlan,
        blockedWeekDays
      );
    }

    if (planType === planTypes.quantityOfDays) {
      return biblePlan.buildPlanCalendarByQuantityOfDays(
        startDate,
        quantityOfDays,
        readingStart,
        readingEnd,
        readPlan,
        blockedWeekDays
      );
    }

    if (planType === planTypes.chaptersPerDay) {
      return biblePlan.buildPlanCalendarByChaptersPerDay(
        startDate,
        readingStart,
        readingEnd,
        Number(quantityOfChapters),
        readPlan,
        blockedWeekDays
      );
    }

    return biblePlan.buildPlanCalendar(
      startDate,
      endDate,
      readingStart,
      readingEnd,
      readPlan,
      blockedWeekDays
    );
  };

  const replan = async () => {
    setIsLoading(true);

    try {
      const readPlan = planv2.filter((day) => day.read);
      const { readingStart } = getInitialReading(planv2);
      const { readingEnd, date: nonReadDate } = planv2[planv2.length - 1];
      const isFirstDay = 0;

      const startDate = dateHandler.decideOnSafeDate(
        blockedWeekDays,
        moment().clone(),
        isFirstDay
      );

      const endDate = moment(nonReadDate);

      let plan = decideOnPlanAlgorithm(
        startDate,
        endDate,
        readingStart,
        readingEnd,
        readPlan
      );

      readPlan.reverse().forEach((readDay) => {
        const indexFound = getNextReadingArrayIndex(plan, readDay.readingEnd);

        plan.splice(indexFound, 0, readDay);
      });

      plan = plan.map((item, index) => {
        const notUsingIdFromDB = Number.isSafeInteger(item._id);
        item._id = notUsingIdFromDB ? index : item._id;
        return item;
      });

      if (isLogged)
        return updatePlanOnBackend({
          readingCalendar: plan,
          updateLocally: true,
          updateDelays: true,
          then: () => setPlanWasReset(true),
          thenStopLoading: true,
          thenRedirect: false,
        });

      updatePlan(
        plan,
        blockedWeekDays,
        planType,
        quantityOfChapters,
        quantityOfDays
      ).then(() => {
        setPlanWasReset(true);
      });

      // setIsLoading(false);
      setAskReplan(false);
    } catch (err) {
      setIsLoading(false);
      setAskReplan(false);
      setShowException(true);
      sendErrorEmail(
        err,
        `on replan
        startDate: ${startDate}
        endDate: ${endDate}
        startDate: ${startDate}
        endDate: ${endDate}`
      );
    }
  };

  const fetchPlan = async () => {
    setIsFetchingPlan(true);

    try {
      const response = await api.get("/plan", headers);

      const {
        data: { plans },
      } = response;

      const hasPlanInBD = !!plans.length;
      const plan = plans?.[plans.length - 1]?.readingCalendar;
      const planId = plans?.[plans.length - 1]?._id;
      const dbBlockedWeekDays = plans?.[plans.length - 1]?.blockedWeekDays;
      const dbPlanType = plans?.[plans.length - 1]?.type;
      const dbChaptersPerDay = plans?.[plans.length - 1]?.chaptersPerDay;
      const dbQuantityOfDays = plans?.[plans.length - 1]?.quantityOfDays;

      if (hasPlanInBD && hasPlanInLocalStorage) {
        const bdPlanLastUpdate = moment(plans[plans.length - 1].updatedAt);
        const localPlanLastUpdate = moment(lastModified);

        const diff = localPlanLastUpdate.diff(bdPlanLastUpdate, "seconds");

        const localPlanIsMoreRecent = diff > 0;

        const plansAreEqual = biblePlan.plansAreEqual(planv2, plan);

        if (localPlanIsMoreRecent && !plansAreEqual) {
          return updatePlanOnBackend({
            readingCalendar: planv2,
            updateLocally: false,
            updateDelays: true,
            id: planId,
            thenRedirect: false,
            then: () => setIsFetchingPlan(false),
          });
        }

        if (!plansAreEqual) {
          await storePlan(
            plan,
            planId,
            dbBlockedWeekDays,
            dbPlanType,
            dbChaptersPerDay,
            dbQuantityOfDays
          );

          setIsLoading(false);
          return setIsFetchingPlan(false);
        }

        setIsLoading(false);
        return setIsFetchingPlan(false);
      }

      if (hasPlanInBD) {
        trackDelays(plans[0].shouldTrackDelays);
        await storePlan(
          plan,
          planId,
          dbBlockedWeekDays,
          dbPlanType,
          dbChaptersPerDay,
          dbQuantityOfDays
        );

        setIsLoading(false);
        return setIsFetchingPlan(false);
      }

      if (hasPlanInLocalStorage) {
        return storePlanOnDB(planv2, false, true);
      }

      history.push("/new");
    } catch (err) {
      if (!hasPlanInLocalStorage) setGeneralError(errorOnFetchingPlan);
      setIsLoading(false);
      setIsFetchingPlan(false);
    }
  };

  const isLate = useCallback(() => {
    const lastNonReadDateOBj = planv2.find((day) => !day.read);

    if (!lastNonReadDateOBj) return;

    const lastNonReadDate = moment(lastNonReadDateOBj.date);

    return lastNonReadDate.isBefore(todayMorning());
  }, [planv2]);

  const storePlanOnDB = async (
    readingCalendar,
    redirectToCreated = true,
    showGeneralError = false
  ) => {
    try {
      const response = await api.post(
        "/plan",
        {
          readingCalendar,
          shouldTrackDelays,
          blockedWeekDays,
          planType,
          chaptersPerDay: quantityOfChapters,
          quantityOfDays: quantityOfDays,
        },
        headers
      );

      const {
        data: { plan },
      } = response;

      storePlan(
        plan.readingCalendar,
        plan._id,
        blockedWeekDays,
        planType,
        quantityOfChapters,
        quantityOfDays
      ).then(() => {
        setIsLoading(false);
        setIsFetchingPlan(false);
        redirectToCreated && history.push("/created");
      });
    } catch (error) {
      showGeneralError && setGeneralError(errorOnFetchingPlan);
      setIsLoading(false);
    }
  };

  const updatePlanOnBackend = async ({
    readingCalendar,
    updateLocally,
    updateDelays,
    id,
    thenStopLoading = true,
    thenRedirect = true,
    then = () => {},
  } = {}) => {
    try {
      const response = await api.put(
        `/plan/${id || planId}`,
        {
          readingCalendar,
          shouldTrackDelays,
          blockedWeekDays,
          quantityOfDays,
          chaptersPerDay: quantityOfChapters,
          planType,
          ...(updateDelays && { delays: delays + 1 }),
        },
        headers
      );

      const {
        data: { plan },
      } = response;

      updateLocally
        ? await updatePlan(
            plan.readingCalendar,
            blockedWeekDays,
            planType,
            quantityOfChapters,
            quantityOfDays
          )
        : await storePlan(
            plan.readingCalendar,
            plan._id,
            blockedWeekDays,
            planType,
            quantityOfChapters,
            quantityOfDays
          );
      if (thenStopLoading) {
        setIsLoading(false);
        setPlanWasReset(true);
      }
      if (thenRedirect) {
        isEditing ? goToPlanPage() : goToCreatedPage();
      }
    } catch (error) {
      updateLocally
        ? await updatePlan(
            readingCalendar,
            blockedWeekDays,
            planType,
            quantityOfChapters,
            quantityOfDays
          )
        : await storePlan(
            readingCalendar,
            planId,
            blockedWeekDays,
            planType,
            quantityOfChapters,
            quantityOfDays
          );
      if (thenRedirect) {
        isEditing ? goToPlanPage() : goToCreatedPage();
      }
      setAskReplan(false);
      if (thenStopLoading) {
        setPlanWasReset(true);
        setIsLoading(false);
      }
    } finally {
      then();
    }
  };

  const allInputsAreValid = ({
    validateDays = true,
    validateChapters = true,
  } = {}) => {
    if (validateDays && startDateIsBiggerThenEndDate())
      return setFormError(startDateBiggerThenEndDateError);

    if (
      validateChapters &&
      readingIntervalIsReverse(startReading, endReading)
    ) {
      return setFormError(readingIntervalIsReverseError);
    }

    return true;
  };

  const goToCreatedPage = useCallback(() => {
    setIsLoading(true);
    history.push("/created");
  }, [history]);

  const goToPlanPage = useCallback(() => {
    history.push("/plan");
  }, [history]);

  const createPlaByQuantityOfChapters = async () => {
    setIsLoading(true);

    try {
      const plan = biblePlan.buildPlanCalendarByChaptersPerDay(
        startDate,
        startReading,
        endReading,
        Number(quantityOfChapters),
        [],
        blockedWeekDays
      );

      if (isEditing && isLogged)
        return updatePlanOnBackend({ readingCalendar: plan });
      if (!isEditing && isLogged) return storePlanOnDB(plan);

      storePlan(
        plan,
        null,
        blockedWeekDays,
        planType,
        quantityOfChapters,
        quantityOfDays
      ).then(() => {
        setPlanWasReset(true);
        isEditing ? goToPlanPage() : goToCreatedPage();
      });
    } catch (error) {
      setShowException(true);
      sendErrorEmail(
        error,
        `handlePlanCreation - on creating planv2
            startDate: ${startDate}
            endDate: ${endDate}
            startReading: ${JSON.stringify(startReading)}
            endReading: ${JSON.stringify(endReading)}`
      );
    }
  };

  const createPlaByQuantityOfDays = async () => {
    setIsLoading(true);

    try {
      const plan = biblePlan.buildPlanCalendarByQuantityOfDays(
        startDate,
        quantityOfDays,
        startReading,
        endReading,
        [],
        blockedWeekDays
      );

      if (isEditing && isLogged)
        return updatePlanOnBackend({ readingCalendar: plan });
      if (!isEditing && isLogged) return storePlanOnDB(plan);

      storePlan(
        plan,
        null,
        blockedWeekDays,
        planType,
        quantityOfChapters,
        quantityOfDays
      ).then(() => {
        setPlanWasReset(true);
        isEditing ? goToPlanPage() : goToCreatedPage();
      });
    } catch (error) {
      setShowException(true);
      sendErrorEmail(
        error,
        `handlePlanCreation - on creating planv2
            startDate: ${startDate}
            endDate: ${endDate}
            startReading: ${JSON.stringify(startReading)}
            endReading: ${JSON.stringify(endReading)}`
      );
    }
  };

  const handlePlanCreation = async () => {
    setIsLoading(true);
    try {
      const plan = biblePlan.buildPlanCalendar(
        startDate,
        endDate,
        startReading,
        endReading,
        [],
        blockedWeekDays
      );

      if (isEditing && isLogged)
        return updatePlanOnBackend({ readingCalendar: plan });
      if (!isEditing && isLogged) return storePlanOnDB(plan);

      storePlan(
        plan,
        null,
        blockedWeekDays,
        planType,
        quantityOfChapters,
        quantityOfDays
      ).then(() => {
        setPlanWasReset(true);
        isEditing ? goToPlanPage() : goToCreatedPage();
      });
    } catch (error) {
      setShowException(true);
      sendErrorEmail(
        error,
        `handlePlanCreation - on creating planv2
            startDate: ${startDate}
            endDate: ${endDate}
            startReading: ${JSON.stringify(startReading)}
            endReading: ${JSON.stringify(endReading)}`
      );
    }
  };

  return (
    <PlanContext.Provider
      value={{
        isLoading,
        setIsLoading,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startReading,
        setStartReading,
        endReading,
        setEndReading,
        formError,
        setFormError,
        showException,
        setShowException,
        shouldAskConfirmation,
        setShouldAskConfirmation,
        shouldTrackDelays,
        setShouldTrackDelays,
        onBeforeTodayInitialDate,
        onAfterTodayInitialDate,
        startDateIsBiggerThenEndDate,
        readingIntervalIsReverse,
        updatePlanOnBackend,
        handlePlanCreation,
        openMoreOptions,
        setOpenMoreOptions,
        isEditing,
        quantityOfDays,
        setQuantityOfDays,
        createPlaByQuantityOfDays,
        quantityOfChapters,
        setQuantityOfChapters,
        createPlaByQuantityOfChapters,
        blockedWeekDays,
        setBlockedWeekDays,
        initialDate,
        setInitialDate,
        setIsEditing,
        askConfirmation,
        setAskConfirmation,
        isLate,
        askReplan,
        setAskReplan,
        planType,
        setPlanType,
        generalError,
        setGeneralError,
        fetchPlan,
        hasPlanInLocalStorage,
        replan,
        decideOnPlanAlgorithm,
        decideOnPlanCreationFunction,
        allInputsAreValid,
        setPlanWasReset,
        planWasReset,
        isFetchingPlan,
        setIsFetchingPlan,
        plan: planv2,
        planId,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export default PlanProvider;
