import React, { memo } from "react";
import { useHistory } from "react-router";

const Button = React.forwardRef(
  (
    {
      disabled,
      greyOnDisabled = true,
      children,
      className,
      noBoxShadow,
      onClick,
      size = "default",
      variation = "",
      fullWidth = false,
      id,
      goTo,
      theme = "primary",
    },
    ref
  ) => {
    const history = useHistory();

    if (fullWidth) className += " full-width";

    if (greyOnDisabled) className += " greyOnDisabled";

    return disabled ? (
      <button
        disabled
        className={`button button--${size} ${variation} ${className}`}
        ref={ref}
        id={id}
      >
        {children}
      </button>
    ) : (
      <button
        className={`${className} ${
          noBoxShadow ? "no-shadow" : ""
        } button--${size} ${variation} button--${theme}`}
        onClick={goTo ? () => history.push(goTo) : onClick}
        ref={ref}
        id={id}
      >
        {children}
      </button>
    );
  }
);

export default memo(Button);
