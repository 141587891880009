import React, { Fragment, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import { FormFeedback } from ".";
import { getItemMaxHeightStyle } from "components/Accordion/getItemMaxHeightStyle";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";
import { DatesSelector } from "organisms";

const DefaultForm = () => {
  const [contentStyle, setContentStyle] = useState({});
  const contentRef = useRef(null);

  const {
    openMoreOptions,
    formError,
    blockedWeekDays,
    setBlockedWeekDays,
    onBeforeTodayInitialDate,
    onAfterTodayInitialDate,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = useChallengePlan();

  useEffect(() => {
    setTimeout(() => {
      setContentStyle(
        getItemMaxHeightStyle(!openMoreOptions, contentRef.current)
      );
    }, 100);
  }, [openMoreOptions, formError, contentRef]);

  return (
    <Fragment>
      <div
        ref={contentRef}
        style={contentStyle}
        className={classNames("default-form", { openMoreOptions })}
      >
        <ReadingInputs />
        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />
        <DatesSelector
          onBeforeTodayInitialDate={onBeforeTodayInitialDate}
          onAfterTodayInitialDate={onAfterTodayInitialDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          blockedWeekDays={blockedWeekDays}
        />
        <FormFeedback />
      </div>
    </Fragment>
  );
};

export default DefaultForm;
