import React, { useEffect, useState } from "react";
import { monthName } from "./messages";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as ArrowLeft } from "assets/images/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right.svg";

const ChecklistHeader = ({
  activeMonth,
  planGroupedByMonth,
  incrementMonth,
  decrementMonth,
}) => {
  const [monthNumber, setMonthNumber] = useState(null);
  const [year, setYear] = useState(null);

  const onArrowLeftClick = () => {
    decrementMonth();
  };

  const onArrowRightClick = () => {
    incrementMonth();
  };

  useEffect(() => {
    if (planGroupedByMonth[activeMonth]) {
      setMonthNumber(moment(planGroupedByMonth[activeMonth][0].date).month());
      setYear(moment(planGroupedByMonth[activeMonth][0].date).year());
    }
  }, [activeMonth, planGroupedByMonth]);

  return (
    <header className="plan-checklist__header">
      {activeMonth > 0 ? (
        <div onClick={onArrowLeftClick} className="arrow arrow-left">
          <ArrowLeft />
        </div>
      ) : (
        <i className="arrow"></i>
      )}

      <h3 className="plan-checklist__header-title">
        {Number.isSafeInteger(monthNumber) ? (
          monthName(monthNumber)
        ) : (
          <Skeleton className="skeleton" height={26} width={150} />
        )}
        <br />
        <span className="plan-checklist__header-subtitle">{year}</span>
      </h3>

      {activeMonth < planGroupedByMonth.length - 1 ? (
        <div onClick={onArrowRightClick} className="arrow arrow-right">
          <ArrowRight />
        </div>
      ) : (
        <i className="arrow "></i>
      )}
    </header>
  );
};

export default ChecklistHeader;
