import React, { useEffect } from "react";
import { IS_PT_LANGUAGE } from "utils";
import PTPrivacyPolicy from "./PTPrivacyPolicy";
import ENPrivacyPolicy from "./ENPrivacyPolicy";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return IS_PT_LANGUAGE ? <PTPrivacyPolicy /> : <ENPrivacyPolicy />;
};

export default PrivacyPolicy;
