import React, { Component } from "react";
import App from "../../components/App";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

class NovoPlano extends Component {
  render() {
    return (
      <App bg="warning" color="dark">
        <section className="section">
          <div className="container">
            <div className="section__title text-centered">
              <h2>Plano criado.</h2>
            </div>
            <div className="section__subtitle text-centered">
              <p> Faça download ou crie outro :) </p>
            </div>
            <div className="buttons">
              <Link
                to="/novo-plano"
                className="button button--danger color-white jsBuilPlanFromToday text-centered"
              >
                Novo plano
              </Link>

              <Button className="button button--primary  jsPersonalizedPlan">
                Download
              </Button>
            </div>
          </div>
        </section>
      </App>
    );
  }
}

export default NovoPlano;
