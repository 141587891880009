import MenuCard from "molecules/MenuCard";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useReportError } from "components/ReportError";
import { isMobileDevice } from "utils";
import { copyText } from "utils/copyText";
import {
  giveFeedback,
  mobileText,
  shareBibleJourney,
  reportError,
} from "../messages";
import { textCopied } from "translate/globalMessages";
import { useModal } from "contexts/Modal/useModal";
import FeedbackForm from "organisms/FeedbackForm";

const BibleJourney = () => {
  const { openReportErrorModal } = useReportError();
  const [textIsCopied, setTextIsCopied] = useState(false);
  const { createModal, closeModal } = useModal();

  const handleShareBibleJourney = useCallback(() => {
    const url = "http://bit.ly/downloadBibleJourney";

    if (!isMobileDevice) {
      copyText(url);
      setTimeout(() => {
        setTextIsCopied(false);
      }, 4000);
      return setTextIsCopied(true);
    }

    const text = mobileText;

    navigator.share({
      title: "Bible Journey",
      text,
      url,
    });
  }, []);

  const onGiveFeedbackClick = useCallback(() => {
    createModal({
      children: <FeedbackForm onMessageSent={closeModal} />,
    });
  }, [closeModal, createModal]);

  const menu = useMemo(
    () => ({
      header: {
        title: "Bible Journey",
      },
      items: [
        {
          description: reportError,
          pointer: true,
          onClick: openReportErrorModal,
        },
        {
          description: textIsCopied ? textCopied : shareBibleJourney,
          pointer: true,
          onClick: handleShareBibleJourney,
        },
        {
          description: giveFeedback,
          pointer: true,
          onClick: onGiveFeedbackClick,
        },
      ],
    }),
    [
      openReportErrorModal,
      textIsCopied,
      handleShareBibleJourney,
      onGiveFeedbackClick,
    ]
  );

  return <MenuCard header={menu.header} items={menu.items} />;
};

export default memo(BibleJourney);
