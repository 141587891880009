import classNames from "classnames";
import React from "react";

const Header = ({
  title,
  subtitle,
  additionalComponent,
  centered,
  children,
}) => {
  return (
    <div className="app__header">
      <div className="app__headings">
        {title && (
          <h1
            className={classNames(`app__title`, { "text-centered": centered })}
          >
            {title}
          </h1>
        )}
        {subtitle && <p className={`app__subtitle`}>{subtitle}</p>}
        {additionalComponent}
        {children}
      </div>
    </div>
  );
};

export default Header;
