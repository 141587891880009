import { planTypes } from "components/FormPlan/Context/planType";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { useUser } from "contexts/User";
import { useHeaders, useQuery } from "hooks";
import React, { createContext, memo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { today, BiblicalPlan, sendErrorEmail, api } from "utils";
import { IS_PT_LANGUAGE } from "utils/language";
import { useChallenge } from "../useChallenge";
import {
  initialEndReading,
  initialStartReading,
  lastDayInYear,
} from "./contants";

export const NewChallengeContext = createContext();

const biblePlan = new BiblicalPlan();

const NewChallengeProvider = ({ children }) => {
  const [challengeName, setChallengeName] = useState("");
  const [showException, setShowException] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [formError] = useState("");
  const [startReading, setStartReading] = useState(initialStartReading);
  const [endReading, setEndReading] = useState(initialEndReading);
  const [blockedWeekDays, setBlockedWeekDays] = useState([]);
  const [planType, setPlanType] = useState(planTypes.default);
  const [quantityOfDays, setQuantityOfDays] = useState(90);
  const [trackDelays, setTrackDelays] = useState(true);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(lastDayInYear);
  const [quantityOfChapters, setQuantityOfChapters] = useState(3);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const [plan, setPlan] = useState([]);
  const queryPlanType = useQuery("planType");
  const [participants, setParticipants] = useState([]);
  const headers = useHeaders();
  const { name: userName } = useUser();
  const history = useHistory();
  const { setViewingChallenge } = useChallenge();

  const createPlaByQuantityOfDays = useCallback(
    (callback = () => {}) => {
      setIsLoading(true);

      let plan;
      try {
        plan = biblePlan.buildPlanCalendarByQuantityOfDays(
          startDate,
          quantityOfDays,
          startReading,
          endReading,
          [],
          blockedWeekDays
        );

        setPlan(plan);
      } catch (error) {
        sendErrorEmail(
          error,
          "creating challenge plan:createPlaByQuantityOfDays"
        );
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
        callback(plan);
      }
    },
    [
      blockedWeekDays,
      endReading,
      openGeneralErrorSnackbar,
      quantityOfDays,
      startDate,
      startReading,
    ]
  );

  const createPlaByQuantityOfChapters = useCallback(
    (callback = () => {}) => {
      setIsLoading(true);

      let plan;

      try {
        plan = biblePlan.buildPlanCalendarByChaptersPerDay(
          startDate,
          startReading,
          endReading,
          Number(quantityOfChapters),
          [],
          blockedWeekDays
        );

        setPlan(plan);
      } catch (error) {
        sendErrorEmail(
          error,
          "creating challenge plan:createPlaByQuantityOfChapters"
        );
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
        callback(plan);
      }
    },
    [
      blockedWeekDays,
      endReading,
      openGeneralErrorSnackbar,
      quantityOfChapters,
      startDate,
      startReading,
    ]
  );

  const createNormalPlan = useCallback(
    (callback = () => {}) => {
      setIsLoading(true);

      let plan;
      try {
        plan = biblePlan.buildPlanCalendar(
          startDate,
          endDate,
          startReading,
          endReading,
          [],
          blockedWeekDays
        );
        setPlan(plan);
      } catch (error) {
        sendErrorEmail(error, "creating challenge plan:createNormalPlan");
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
        callback(plan);
      }
    },
    [
      blockedWeekDays,
      endDate,
      endReading,
      openGeneralErrorSnackbar,
      startDate,
      startReading,
    ]
  );

  const allInputsAreValid = () => {
    return true;
  };

  const createPlan = useCallback(
    (callback) => {
      switch (Number(queryPlanType)) {
        case planTypes.default:
          createNormalPlan(callback);
          break;
        case planTypes.quantityOfDays:
          createPlaByQuantityOfDays(callback);
          break;
        case planTypes.chaptersPerDay:
          createPlaByQuantityOfChapters(callback);
          break;
        default:
          createNormalPlan(callback);
      }
    },
    [
      createNormalPlan,
      createPlaByQuantityOfChapters,
      createPlaByQuantityOfDays,
      queryPlanType,
    ]
  );

  const createChallenge = useCallback(
    async (plan) => {
      setIsLoading(true);

      try {
        headers.headers.language = IS_PT_LANGUAGE ? "PT" : "EN";

        const { data } = await api.post(
          "/challenges/add",
          {
            challengeTitle: challengeName,
            participants,
            startDate,
            owner_name: userName,
            finishDate: endDate,
            ...(plan.length > 0 && {
              plan: {
                readingCalendar: plan,
                shouldTrackDelays: trackDelays,
                blockedWeekDays,
                planType,
                chaptersPerDay: quantityOfChapters,
                quantityOfDays,
              },
            }),
          },
          headers
        );

        const challengeId = data.challenge._id;
        setViewingChallenge(challengeId);

        history.push(
          `/challenge/${challengeId}/participants/add?isNewChallenge=true`
        );
      } catch (error) {
        sendErrorEmail(error, "creating challenge:createChallenge");
        openGeneralErrorSnackbar();
      } finally {
        setIsLoading(false);
      }
    },
    [
      blockedWeekDays,
      challengeName,
      endDate,
      headers,
      history,
      openGeneralErrorSnackbar,
      participants,
      planType,
      quantityOfChapters,
      quantityOfDays,
      setViewingChallenge,
      startDate,
      trackDelays,
      userName,
    ]
  );

  return (
    <NewChallengeContext.Provider
      value={{
        setQuantityOfDays,
        createPlaByQuantityOfDays,
        allInputsAreValid,
        setShowException,
        setIsLoading,
        setStartReading,
        setEndReading,
        setBlockedWeekDays,
        setPlanType,
        setOpenMoreOptions,
        createPlaByQuantityOfChapters,
        setChallengeName,
        setTrackDelays,
        setStartDate,
        setEndDate,
        setQuantityOfChapters,
        createNormalPlan,
        createPlan,
        setParticipants,
        createChallenge,
        quantityOfDays,
        challengeName,
        showException,
        isLoading,
        openMoreOptions,
        formError,
        startReading,
        endReading,
        blockedWeekDays,
        planType,
        startDate,
        endDate,
        quantityOfChapters,
        trackDelays,
        plan,
        participants,
      }}
    >
      {children}
    </NewChallengeContext.Provider>
  );
};

export default memo(NewChallengeProvider);
