import React, { memo } from "react";
import classNames from "classnames";

const Toggler = ({ active, toggle }) => {
  return (
    <div className={classNames("toggler", { active })} onClick={toggle}></div>
  );
};

Toggler.defaultProps = {
  active: false,
  toggle: () => {},
};

export default memo(Toggler);
