import React, { Fragment, memo } from "react";

import Form from "./Form";
import {
  finishWithFacebook,
  finishWithGoogle,
  registerWithFacebook,
  registerWithGoogle,
  finishWithApple,
  registerWithApple,
} from "./messages";
import { GoogleButton } from "components/SocialLoginButtons/Google";
import { FacebookLoginButton } from "components/SocialLoginButtons/Facebook";
import { useQuery } from "hooks";
import { AppleButton } from "components/SocialLoginButtons/Apple";

const LoginCard = () => {
  const finishRegister = useQuery("finishRegister");

  return (
    <Fragment>
      <FacebookLoginButton
        text={finishRegister ? finishWithFacebook : registerWithFacebook}
      />
      <GoogleButton
        text={finishRegister ? finishWithGoogle : registerWithGoogle}
      />
      <AppleButton
        text={finishRegister ? finishWithApple : registerWithApple}
      />

      <hr style={{ margin: "32px 0" }} />
      <Form />
    </Fragment>
  );
};

export default memo(LoginCard);
