import { useAuth } from "contexts/AuthContext";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { confirmingAccount } from "./messages";

const EmailConfirmation = () => {
  const { token } = useParams();
  const { handleAccountConfirmation } = useAuth();

  useEffect(() => {
    handleAccountConfirmation(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article>
      <p>{confirmingAccount}...</p>
    </article>
  );
};

export { EmailConfirmation };
