import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import PermissionDenied from "./Modals/PermissionDenied";
import SomethingWentWrong from "./Modals/SomethingWentWrong";

const Notifications = () => {
  const {
    location: { pathname },
  } = useHistory();

  if (pathname !== "/menu" && pathname !== "/plan") return null;

  return (
    <Fragment>
      <PermissionDenied />
      <SomethingWentWrong />
    </Fragment>
  );
};

export default Notifications;
