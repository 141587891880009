import React, {
  createContext,
  useState,
  memo,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { api, toPrice } from "utils";
import { useHeaders } from "hooks";
import { month } from "pages/Challenges/Presentation/components/messages";
import { useModal } from "contexts/Modal/useModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  accessPremium,
  createFirstChallenge,
  premiumApproved,
} from "./messages";

import { i18n } from "translate/i18n";
import useRequest from "hooks/useRequest";

export const UserContext = createContext();

export const UserProvider = ({ children, saveUserPicture, user }) => {
  const [premium, setPremium] = useState({ notLoaded: true });
  const headers = useHeaders();
  const isLogged = useMemo(() => !!user.token, [user.token]);
  const [isLoadingPremiumInfo, setIsLoadingPremiumInfo] = useState(true);
  const [shouldUpdatePremiumInfo, setShouldUpdatePremiumInfo] = useState(null);
  const { createModal, closeModal } = useModal();
  const history = useHistory();

  const request = useRequest();

  useEffect(() => {
    if (!user.picture) {
      request
        .get("/user/picture")
        .then((response) => saveUserPicture(response.data.picture));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.picture]);

  useEffect(() => {
    if (shouldUpdatePremiumInfo) {
      const newPremium = { ...premium, ...shouldUpdatePremiumInfo };

      setPremium({
        ...newPremium,
        notPremium: false,
        amountPurchases: premium?.amountPurchases
          ? premium.amountPurchases + 1
          : 1,
      });

      setShouldUpdatePremiumInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdatePremiumInfo]);

  useEffect(() => {
    isLogged && getSubscription();
    isLogged && handleUserLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (premium.origin === "android") {
      api
        .post(
          "/checkout/android/validate",
          {
            purchaseToken: premium.purchaseToken,
            subscription_id: "bibleplan_subscription_0",
          },
          headers
        )
        .then((response) => {
          if (response.data.canceled || response.data.expired) {
            api.delete("/premium/android", headers);
            return setPremium({ notPremium: true });
          }

          console.log("debug response", {
            pendingApproval: premium.pendingApproval,
            premiumGranted: response.data.premiumGranted,
            premium,
            response,
          });

          if (premium.pendingApproval && response.data.premiumGranted) {
            setPremium({
              ...premium,
              notPremium: false,
              pendingApproval: false,
            });

            api.delete("/checkout/android/pending", headers).then(() => {
              createModal({
                title: premiumApproved,
                subtitle: accessPremium,
                firstButtonText: createFirstChallenge,
                onFirstButtonClick: () => {
                  closeModal();
                  history.push("/challenges/new/name");
                },
              });
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premium]);

  const handleUserLanguage = async () => {
    const { data } = await api.get("/language", headers);

    const languageIsDifferent = data.language !== i18n.language;

    if (!data.language || languageIsDifferent) {
      await api.post("/language", { language: i18n.language }, headers);
    }
  };

  const getSubscription = async () => {
    setIsLoadingPremiumInfo(true);

    const { data } = await api.get("/premium", headers);

    if (!data.premiumAccess) {
      setPremium({ notPremium: true });
      return setIsLoadingPremiumInfo(false);
    }

    const name = data?.premiumAccess?.product_title;

    const price = toPrice({
      cents: data?.premiumAccess?.amount_paid,
      currency: data?.premiumAccess?.currency_paid,
    });

    let title = `${name} ${price}`;

    if (data.premiumAccess.type === "subscription") {
      title += `/${month}`;
    }

    setPremium({
      title,
      type: data.premiumAccess.type,
      customerId: data.premiumAccess.customer_id,
      subscriptionId: data.premiumAccess?.subscription_id,
      amountPurchases: data.premiumAccess?.amountPurchases,
      purchaseToken: data.premiumAccess?.purchaseToken,
      origin: data.premiumAccess?.origin,
      pendingApproval: data.premiumAccess?.pendingApproval,
      notPremium: data.premiumAccess?.pendingApproval,
    });

    setIsLoadingPremiumInfo(false);
  };

  const updatePremiumInfo = useCallback((premium) => {
    setShouldUpdatePremiumInfo(premium);
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...user,
        premium,
        isLogged,
        isLoadingPremiumInfo,
        updatePremiumInfo,
        getSubscription,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default memo(UserProvider);
