import React, { memo, useCallback, useState } from "react";
import EmailsBox from "organisms/EmailsBox";
import Button from "components/Button";
import {
  addTheParticipantsPlaceholder,
  atLeastTwoEmails,
} from "pages/Challenges/New/messages";
import { loading } from "components/SocialLoginButtons/messages";
import { sendInvite } from "../messages";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { copyText, sendErrorEmail } from "utils";
import { useModal } from "contexts/Modal/useModal";
import { useUser } from "contexts/User";
import { ReactComponent as IconCopy } from "assets/icons/copy.svg";
import { ReactComponent as IconShare } from "assets/icons/share.svg";
import { isDesktopViewport } from "utils/viewport";
import { useChallengeInvites } from "contexts/Challenge/ChallengeInvites";
import {
  copyLink,
  hereIsTheLink,
  inviteViaEmail,
  linkCopied,
  shareLink,
  throughLink,
} from "./messages";

const AddParticipants = ({ requestFetchParticipants }) => {
  const [participants, setParticipants] = useState([]);
  const [showError, setShowError] = useState(false);
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { request: challenge } = useChallenge();
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const { name: userName } = useUser();
  const { inviteLink } = useChallengeInvites();
  const [textCopied, setTextCopied] = useState(false);

  const onButtonClick = useCallback(async () => {
    if (participants.length < 1) {
      return setShowError(true);
    }

    setIsLoading(true);

    try {
      await challenge.post("participants/add", {
        participants,
        owner_name: userName,
      });
    } catch (error) {
      sendErrorEmail(error, "creating challenge:createChallenge");
      openGeneralErrorSnackbar();
    } finally {
      closeModal();
      setIsLoading(false);
      requestFetchParticipants();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  const onCopyClick = useCallback(() => {
    copyText(inviteLink);
    setTextCopied(true);

    setTimeout(() => setTextCopied(false), 2000);
  }, [inviteLink]);

  const onShareClick = useCallback(() => {
    const url = inviteLink;

    const text = hereIsTheLink;

    navigator.share({
      title: "Bible Journey",
      text,
      url,
    });
  }, [inviteLink]);

  return (
    <div className="add-participants">
      <div className="add-participants__section">
        <h4 className="add-participants__title">{throughLink}</h4>
        <p className="add-participants__link">{inviteLink}</p>
        <div className="add-participants__share-link">
          <div
            className="add-participants__share-link-button"
            onClick={onCopyClick}
          >
            <IconCopy />
            <span>{textCopied ? linkCopied : copyLink}</span>
          </div>
          {!isDesktopViewport && !!navigator.share && (
            <div
              className="add-participants__share-link-button"
              onClick={onShareClick}
            >
              <IconShare />
              <span>{shareLink}</span>
            </div>
          )}
        </div>
      </div>
      <div className="add-participants__section">
        <h4 className="add-participants__title">{inviteViaEmail}</h4>

        <EmailsBox
          initialEmails={participants}
          onTyping={() => setShowError(false)}
          onParticipantsChange={setParticipants}
          placeholder={addTheParticipantsPlaceholder}
          theme="default"
        />
        {showError && <div className="input-error">{atLeastTwoEmails}</div>}
        <Button
          onClick={onButtonClick}
          disabled={isLoading}
          size="medium"
          variation="square-rounded"
          theme="primary"
        >
          {isLoading ? loading : sendInvite}
        </Button>
      </div>
    </div>
  );
};

export default memo(AddParticipants);
