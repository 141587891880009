import React from "react";
import { ReactComponent as Brand } from "../../assets/images/logotipo-89x12.svg";

const Footer = (props) => {
  return (
    <footer className={`footer bg-${props.bg}`}>
      <div className="container">
        <div className="footer__logotipo">
          <Brand />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
