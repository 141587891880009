import React from "react";
import ReactDOM from "react-dom";
import { isNativeIOS } from "utils/devices.js";
import { logEasterEgg } from "utils/logEasterEgg.js";
import App from "./App.js";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(<App />, document.getElementById("root"));

if ("caches" in window) {
  caches.delete("bibleplan-app");
}

if (!isNativeIOS) serviceWorkerRegistration.register();

logEasterEgg();
