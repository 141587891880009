const Logout = ({ handleLogout, resetLocalPlan, resetPlanV1 }) => {
  localStorage.clear();
  // to force several requests that the app does to be done again
  window.location.href = "/login";
  // history.push("/login");

  return null;
};

export default Logout;
