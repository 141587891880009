export const buttons = {
  buttons: {
    bibleReading: {
      finishReading: "Finish reading",
      close: "Close",
      bibleVersion: "Bible version",
      soonOthers: "Other versions coming soon",
      version: "Version",
    },
    editPlan: {
      updatePlan: "Update plan",
    },
    askConfirmationModal: {
      firstButtonText: "Keep current plan",
      secondButtonText: "Recreate the entire plan",
    },
    planCreation: {
      createPlan: "Create plan",
      createPlanLoading: "Creating plan...",
    },
    delayedReadingModal: {
      firstButton: "It is not necessary",
      secondButton: "Adapt plan",
    },
    donateOptionsModal: {
      firstButton: "One-time donation",
      secondButton: "Recurring donation",
    },
    askDonationsModal: {
      firstButton: "Remind-me later",
      secondButton: "Donate via Gumroad",
    },
  },
};
