import { bibleBooksAbbreviations } from "./bibleBooksAbbreviations";

export const bookIndexByBookAbbreviation = (bookAbbreviation) => {
  let found = null;

  bibleBooksAbbreviations.find((item, index) => {
    if (Object.keys(item).some((i) => item[i] === bookAbbreviation)) {
      found = index;
      return found;
    }

    return false;
  });

  return found;
};
