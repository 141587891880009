import { useHistory } from "react-router-dom";
import { api } from "utils";

const useJoinChallenge = () => {
  const history = useHistory();

  return ({ userToken, challengeToJoin } = {}) => {
    const headers = {
      headers: {
        authorization: `Bearer ${userToken}`,
        challenge_id: challengeToJoin,
      },
    };

    return api.put(`/challenge/join`, {}, headers).then(() => {
      history.push({
        pathname: `/challenge/${challengeToJoin}/ranking`,
        search: `?isNewParticipant=true`,
      });
    });
  };
};

export default useJoinChallenge;
