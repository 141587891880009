import { NewPlan } from "pages/NewPlan";
import Download from "pages/Download";
import Index from "pages/Index/";
import { CreatedPlan } from "pages/CreatedPlan";
import { Plan } from "pages/Plan";
import PrivacyPolicy from "pages/PrivacyPolicy";
import { Register } from "pages/Authentication/Register";
import { Login } from "pages/Authentication/Login";
import { ForgotPassword } from "pages/Authentication/ForgotPassword";
import { ResetPassword } from "pages/Authentication/ResetPassword";
import { EditPlan } from "pages/EditPlan";
import { Logout } from "pages/Authentication/Logout";
import { EmailConfirmation } from "pages/EmailConfirmation";
import DownloadRedirect from "pages/DownloadRedirect";
import ChallengesPresentation from "pages/Challenges/Presentation";
import Ranking from "pages/Challenges/Challenge/Ranking";
import ChallengePlan from "pages/Challenges/Challenge/Plan";
import Questions from "pages/Challenges/Challenge/Questions/ListQuestions";
import Configs from "pages/Challenges/Challenge/Configs";
import Menu from "pages/Menu";
import Challenges from "pages/Challenges";
import NewChallenge from "pages/Challenges/New";
import AddQuestion from "pages/Challenges/Challenge/Questions/AddQuestion";
import ViewQuestion from "pages/Challenges/Challenge/Questions/ViewQuestion";
import EditQuestion from "pages/Challenges/Challenge/Questions/EditQuestion";
import EditParticipantsPage from "pages/Challenges/Challenge/Configs/EditParticipants/Page";
import UpdatePlan from "pages/Challenges/Challenge/Configs/UpdatePlan";
import IOSEULA from "pages/PrivacyPolicy/IOSEULA";
import ToChallengePresentation from "atoms/PageRedirects/ToChallengePresentation";
import NewChallengeParticipants from "pages/Challenges/New/Participants";
import InviteRegister from "pages/Challenges/Invite/Register";
import InviteLogin from "pages/Challenges/Invite/Login";
import ErrorReporting from "pages/ErrorReporting";

const exact = true;

export const routesData = [
  { path: "/", exact, component: Index },
  { path: "/new", component: NewPlan },
  { path: "/download", component: Download },
  { path: "/created", component: CreatedPlan },
  { path: "/plan/edit", component: EditPlan },
  { path: "/politica-de-privacidade", component: PrivacyPolicy },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-of-use", component: IOSEULA },
  { path: "/register", component: Register },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token/:email", component: ResetPassword },
  { path: "/plan", exact, component: Plan },
  { path: "/plano", exact, component: Plan },
  { path: "/logout", exact, component: Logout },
  { path: "/email-confirmation/:token", exact, component: EmailConfirmation },
  { path: "/download-app", exact, component: DownloadRedirect },
  {
    path: "/desafio-plano-de-leitura",
    exact,
    component: ToChallengePresentation,
  },
  {
    path: "/bible-reading-challenge",
    exact,
    component: ToChallengePresentation,
  },
  { path: "/menu", exact, component: Menu },
  {
    path: "/challenges/presentation",
    exact,
    component: ChallengesPresentation,
  },
  {
    path: "/challenges",
    exact,
    component: Challenges,
  },
  { path: "/challenge/:idChallenge/ranking", component: Ranking },
  { path: "/challenge/:idChallenge/plan", component: ChallengePlan },
  { path: "/challenge/:idChallenge/questions", exact, component: Questions },
  { path: "/challenge/:idChallenge/questions/add", component: AddQuestion },
  {
    path: "/challenge/:idChallenge/question/:idQuestion",
    exact,
    component: ViewQuestion,
  },
  {
    path: "/challenge/:idChallenge/question/:idQuestion/edit",
    exact,
    component: EditQuestion,
  },
  {
    path: "/challenge/:idChallenge/configs/participants/edit",
    exact,
    component: EditParticipantsPage,
  },
  {
    path: "/challenge/:idChallenge/configs/plan/edit",
    exact,
    component: UpdatePlan,
  },
  { path: "/challenge/:idChallenge/configs", component: Configs, exact },
  { path: "/challenges/new", component: NewChallenge },
  {
    path: "/challenge/:idChallenge/participants/add",
    exact,
    component: NewChallengeParticipants,
  },
  {
    path: "/c/invite/:idChallenge",
    exact,
    component: InviteRegister,
  },
  {
    path: "/c/invite/:idChallenge/login",
    exact,
    component: InviteLogin,
  },
  { path: "/report-error", exact, component: ErrorReporting },
];
