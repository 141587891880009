import React, { createContext, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications as IOSPushNotifications } from "@capacitor/push-notifications";

import { isIOSNativePlatform } from "utils/devices";
import api from "utils/api";
import { sendErrorEmail } from "utils/sendEmail";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getUserTimeZone } from "utils/timezone";
import { title, body } from "./messages";
import { useAuth } from "contexts/AuthContext";
import { useHistory } from "react-router";
import { useModal } from "contexts/Modal/useModal";
import {
  alarmDefined,
  alarmDefinedParagraph1,
  alarmDefinedParagraph2,
  close,
  notificationConfigTitle,
  save,
} from "../Modals/messages";
import { loading } from "components/SocialLoginButtons/messages";
import { TimePicker } from "components/TimePicker";

const firebaseConfig = {
  apiKey: "AIzaSyA5O3iQUH-UdceQkEFVepcyCdbpLwtoVtE",
  authDomain: "bibleplan-push.firebaseapp.com",
  projectId: "bibleplan-push",
  storageBucket: "bibleplan-push.appspot.com",
  messagingSenderId: "214140120098",
  appId: "1:214140120098:web:80b861460158199f886e7b",
  measurementId: "G-3VZNNYH77H",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
getAnalytics(app);

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
});

export const NotificationsContext = createContext();

const askPermissions = async () => {
  if (isIOSNativePlatform) return await LocalNotifications.requestPermissions();
  else return await Notification.requestPermission();
};

const handleNotificationsPermissions = async ({
  onGranted = () => {},
  onDenied = () => {},
  then = () => {},
}) => {
  const result = await askPermissions();
  const { display } = result;
  const permissionGranted = display === "granted" || result === "granted";

  if (permissionGranted) onGranted();
  if (display === "denied") onDenied();
  then();
};

const checkPermissions = async ({
  onGranted = () => {},
  onDenied = () => {},
  onNotDefined = () => {},
} = {}) =>
  await LocalNotifications.checkPermissions().then(({ display }) => {
    if (display === "granted") return onGranted();
    if (display === "denied") return onDenied();
    onNotDefined();
  });

const defineShouldRemember = (askAgainOn, reminderCounter, showReminders) => {
  return (
    moment().diff(moment(askAgainOn), "days") === 0 &&
    reminderCounter > 0 &&
    showReminders
  );
};

const NotificationsProvider = ({
  children,
  hasDefinedReminder,
  askAgainOn,
  reminderCounter,
  permissionDenied,
  storePermissionDenied,
  reminderLater,
  stopReminders,
  showReminders,
  reminderDefined,
  saveSubscription,
  subscriptionToken,
  definedHour,
  definedMinute,
}) => {
  const shouldAskToContinue =
    reminderCounter > 0 && reminderCounter % 3 === 0 && showReminders;
  const shouldRemember = defineShouldRemember(
    askAgainOn,
    reminderCounter,
    showReminders
  );

  const [showInitialModal, setShowInitialModal] = useState(
    reminderCounter === 0 && !permissionDenied && showReminders
  );

  const [selectedHour, setSelectedHour] = useState({
    hour: definedHour,
    minute: definedMinute,
  });

  const [showNotificationConfig, setShowNotificationConfig] = useState(false);

  const [showPermissionDeniedModal, setShowPermissionDeniedModal] =
    useState(false);

  const [showAlarmDefinedModal, setShowAlarmDefinedModal] = useState(false);
  const [shouldScheduleNotification, setShouldScheduleNotification] =
    useState(false);

  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const { getHeaders } = useAuth();
  const history = useHistory();
  const headers = getHeaders();

  const closeConfigModal = () => {
    setShowNotificationConfig(false);
    closeSpecificModal({ id: notificationConfigTitle });
  };

  const onPermissionGranted = () => {
    setShowInitialModal(false);
    setShowNotificationConfig(true);
  };

  const { updateSpecificModal, createModal, closeModal, closeSpecificModal } =
    useModal();

  useEffect(() => {
    if (shouldScheduleNotification) {
      setShouldScheduleNotification(false);
      scheduleNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScheduleNotification]);

  useEffect(() => {
    if (showAlarmDefinedModal && !somethingWentWrong) {
      createModal({
        id: alarmDefined,
        title: alarmDefined,
        text: (
          <article>
            {alarmDefinedParagraph1(selectedHour)}
            <p>{alarmDefinedParagraph2}</p>
          </article>
        ),
        onSecondButtonClick: onStopReminders,
        secondButtonText: close,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlarmDefinedModal]);

  useEffect(() => {
    if (showNotificationConfig) {
      createModal({
        id: notificationConfigTitle,
        title: notificationConfigTitle,
        onSecondButtonClick: () => {
          updateSpecificModal({
            title: notificationConfigTitle,
            secondButtonText: loading,
            secondButtonDisabled: true,
          });
          onAlarmHourSelected();
        },
        secondButtonText: save,
        renderChildrenInsteadOfText: true,
        onOverlayClick: closeModal,
        children: <TimePicker />,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotificationConfig]);

  useEffect(() => {
    !showReminders && closeAllModals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReminders]);

  const closeAllModals = () => {
    setShowAlarmDefinedModal(false);
    setShowNotificationConfig(false);
    setShowPermissionDeniedModal(false);
    closeConfigModal();
    closeSpecificModal({ id: alarmDefined });
    setShowInitialModal(false);
  };

  const onPermissionDenied = () => {
    setShowInitialModal(false);
    setShowPermissionDeniedModal(true);
    storePermissionDenied();
  };

  const onAlarmHourSelected = () => {
    setShouldScheduleNotification(true);
  };

  const onRemindLater = () => {
    setShowInitialModal(false);
    reminderLater();
  };

  const onStopReminders = () => {
    stopReminders();
    closeAllModals();
  };

  const onConfigureReminder = async () => {
    const result = await askPermissions();
    const { display } = result;
    const permissionGranted = display === "granted" || result === "granted";

    if (permissionGranted) onPermissionGranted();
    else onPermissionDenied();
  };

  const closeSomethingWentWrongModal = () => setSomethingWentWrong(false);

  useEffect(() => {
    if (isIOSNativePlatform) {
      // On success, we should be able to receive notifications
      IOSPushNotifications.addListener("registration", (token) => {
        savePushSubscription(token.value);
      });

      // Some issue with our setup and push will not work
      IOSPushNotifications.addListener("registrationError", (error) => {
        console.log("error>>", error);
        sendErrorEmail(
          JSON.stringify(error),
          "IOSPushNotifications.addListener"
        );
      });

      // Method called when tapping on a notification
      IOSPushNotifications.addListener(
        "pushNotificationActionPerformed",
        ({ notification }) => {
          notification?.data?.action === "open" &&
            history?.push(notification?.data?.url);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePushSubscription = useCallback(
    async (subscriptionToken) => {
      const timezone = getUserTimeZone();

      const {
        data: { notification },
      } = await api.post(
        "/notifications/save-subscription",
        {
          subscriptionToken,
          timezone,
        },
        headers
      );

      saveSubscription(notification.subscriptionToken);
    },
    [headers, saveSubscription]
  );

  const handleSaveSubscription = async ({ then = () => {} } = {}) => {
    if (!isIOSNativePlatform) {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        await getToken(messaging, {
          vapidKey:
            "BBKMQh4XZ-KQfbNfSQONAyBqJU0OeRj0KS7KBTn_JW8r42Qg79PruSmLrqVT-Zg_sLS6q2jTJCgvn20zaPoF1kw",
          serviceWorkerRegistration: registration,
        }).then(async (subscriptionToken) => {
          savePushSubscription(subscriptionToken);
        });
      } catch (err) {
        sendErrorEmail(err, `When defining web notification`);
      } finally {
        then();
      }
    } else {
      try {
        await IOSPushNotifications.requestPermissions().then(
          async ({ receive }) => {
            if (receive === "granted") {
              IOSPushNotifications.register();
            }
          }
        );
      } catch (err) {
        setSomethingWentWrong(true);
        sendErrorEmail(err, `When defining IOS notification`);
      } finally {
        then();
      }
    }
  };

  const scheduleNotifications = async () => {
    const { hour, minute } = selectedHour;

    const timezone = getUserTimeZone();
    const notifications = [];

    const at = new Date();
    at.setHours(hour);
    at.setMinutes(minute);

    const notificationConfig = {
      title,
      body,
      id: 0,
      schedule: {
        repeats: true,
        every: "day",
        on: {
          hour,
          minute,
          second: 0,
        },
        actionTypeId: "",
        extra: null,
      },
    };

    if (!isIOSNativePlatform) {
      try {
        const registration = await navigator.serviceWorker.getRegistration();

        await getToken(messaging, {
          vapidKey:
            "BBKMQh4XZ-KQfbNfSQONAyBqJU0OeRj0KS7KBTn_JW8r42Qg79PruSmLrqVT-Zg_sLS6q2jTJCgvn20zaPoF1kw",
          serviceWorkerRegistration: registration,
        })
          .then(async (subscriptionToken) => {
            const {
              data: { notification },
            } = await api.post(
              "/notifications/add-push",
              {
                hour,
                minute,
                subscriptionToken,
                timezone,
                title,
                body,
              },
              headers
            );

            reminderDefined(notification.subscriptionToken, hour, minute);
            closeConfigModal();
            setShowAlarmDefinedModal(true);
          })
          .catch(() => {
            reminderDefined(subscriptionToken, hour, minute);
            closeConfigModal();
            setSomethingWentWrong(true);
          });
      } catch (err) {
        console.log("err>>>", err);

        // reminderDefined(subscriptionToken, hour, minute);
        // closeConfigModal();
        // setSomethingWentWrong(true);
        // sendErrorEmail(err, `When defining web notification`);
      }
    } else {
      notifications.push(notificationConfig);

      try {
        await LocalNotifications.schedule({
          notifications: notifications,
        });
        closeConfigModal();

        setShowAlarmDefinedModal(true);
      } catch (err) {
        setSomethingWentWrong(true);
        sendErrorEmail(err, `When defining IOS notification`);
      }
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        hasDefinedReminder,
        askAgainOn,
        reminderCounter,
        shouldRemember,
        showPermissionDeniedModal,
        showInitialModal,
        showNotificationConfig,
        showAlarmDefinedModal,
        selectedHour,
        shouldAskToContinue,
        somethingWentWrong,
        closeSomethingWentWrongModal,
        onRemindLater,
        askPermissions,
        checkPermissions,
        onPermissionGranted,
        onPermissionDenied,
        setSelectedHour,
        onAlarmHourSelected,
        setShowAlarmDefinedModal,
        onConfigureReminder,
        onStopReminders,
        closeConfigModal,
        handleSaveSubscription,
        handleNotificationsPermissions,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
