import { i18n } from "translate/i18n";

export const headerTitle = i18n.t(`messages.challengeLP.headerTitle`);
export const headerContent = i18n.t(`messages.challengeLP.headerContent`);
export const moreAboutIt = i18n.t(`messages.challengeLP.moreAboutIt`);
export const startNow = i18n.t(`messages.challengeLP.startNow`);
export const howItWorks = i18n.t(`messages.challengeLP.howItWorks`);
export const giveAName = i18n.t(`messages.challengeLP.giveAName`);
export const useCreativity = i18n.t(`messages.challengeLP.useCreativity`);
export const defineDates = i18n.t(`messages.challengeLP.defineDates`);
export const oneTwoMonths = i18n.t(`messages.challengeLP.oneTwoMonths`);
export const createAPlan = i18n.t(`messages.challengeLP.createAPlan`);
export const theWholeBible = i18n.t(`messages.challengeLP.theWholeBible`);
export const addParticipants = i18n.t(`messages.challengeLP.addParticipants`);
export const asYouWant = i18n.t(`messages.challengeLP.asYouWant`);
export const challengeLaunched = i18n.t(
  `messages.challengeLP.challengeLaunched`
);
export const partOfRanking = i18n.t(`messages.challengeLP.partOfRanking`);
export const rewardOnRead = i18n.t(`messages.challengeLP.rewardOnRead`);
export const surpriseQuestion = i18n.t(`messages.challengeLP.surpriseQuestion`);
export const seeNow = i18n.t(`messages.challengeLP.seeNow`);
export const play = i18n.t(`messages.challengeLP.play`);
export const chooseYourPlan = i18n.t(`messages.challengeLP.chooseYourPlan`);
export const monthPrice = i18n.t(`messages.challengeLP.monthPrice`);
export const month = i18n.t(`messages.challengeLP.month`);
export const monthPriceDesc = i18n.t(`messages.challengeLP.monthPriceDesc`);
export const subscribeNow = i18n.t(`messages.challengeLP.subscribeNow`);
export const subscribeNowDisc = i18n.t(`messages.challengeLP.subscribeNowDisc`);
export const onePaymentPrice = i18n.t(`messages.challengeLP.onePaymentPrice`);
export const onePaymentPriceDesc = i18n.t(
  `messages.challengeLP.onePaymentPriceDesc`
);
export const payNow = i18n.t(`messages.challengeLP.payNow`);
export const payNowDisc = i18n.t(`messages.challengeLP.payNowDisc`);
export const closeVideo = i18n.t(`messages.challengeLP.closeVideo`);
export const firstSignIn = i18n.t(`messages.challengeLP.firstSignIn`);
export const subscribeWithCard = i18n.t(
  `messages.challengeLP.subscribeWithCard`
);
export const yesProceed = i18n.t(`messages.challengeLP.yesProceed`);
export const haveAlreadySubscription = i18n.t(
  "messages.challengeLP.haveAlreadySubscription"
);
export const checkoutUnavailableMsg = i18n.t(
  "messages.challengeLP.checkoutUnavailable"
);
export const paymentSuccess = i18n.t("messages.challengeLP.paymentSuccess");
export const weWillRedirect = i18n.t("messages.challengeLP.weWillRedirect");
export const paymentProcessing = i18n.t(
  "messages.challengeLP.paymentProcessing"
);
export const unsuccessfulPayment = i18n.t(
  "messages.challengeLP.unsuccessfulPayment"
);
export const verifyingStatus = i18n.t("messages.challengeLP.verifyingStatus");
export const purchaseFailed = i18n.t("messages.challengeLP.purchaseFailed");
export const pendingPurchase = i18n.t("messages.challengeLP.pendingPurchase");
export const waitWhileWeValidate = i18n.t(
  "messages.challengeLP.waitWhileWeValidate"
);
export const thanksForInterest = i18n.t(
  "messages.challengeLP.thanksForInterest"
);
export const choseBetweenEmailOrWhatsapp = i18n.t(
  "messages.challengeLP.choseBetweenEmailOrWhatsapp"
);
export const callMeOnWhatsapp = i18n.t("messages.challengeLP.callMeOnWhatsapp");
export const emailMe = i18n.t("messages.challengeLP.emailMe");
export const hiIWantToKnowMoreAboutBibleJourney = i18n.t(
  "messages.challengeLP.hiIWantToKnowMoreAboutBibleJourney"
);
