import React, { memo, useEffect } from "react";
import App from "components/App";
import Header from "components/App/Header";

import { Input } from "components/Form";
import Button from "components/Button";
import { Navbar } from "components/Navbar";
import { useAuth } from "../../../contexts/AuthContext";
import { EMAIL_REQUIRED } from "../validationSchema";
import { send, sending, subtitle, title } from "../messages";

import {
  forgotPasswordMessage,
  askEmailVerification,
  typeYourEmail,
} from "./messages";
import MainCard from "components/MainCard";

const ForgotPassword = () => {
  const {
    forgotPassword,
    isSubmitSuccessful,
    isLoading,
    handleSubmit,
    register,
    errors,
    reset,
  } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <App>
      <Navbar backTo="/login" onlyBackArrow={true} />
      <Header title={title} subtitle={subtitle} color="white" />
      <MainCard centerTitle title={forgotPasswordMessage}>
        {isSubmitSuccessful ? (
          <div className="form__row">
            <p className="font-size-2 text-centered">{askEmailVerification}</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(forgotPassword)}
            className="form jsFormNewPlan"
          >
            <div className="form__row">
              <p className="font-size-2">{typeYourEmail}</p>
            </div>
            <div className="form__row full-width">
              <Input
                label="E-mail"
                name="email"
                type="email"
                placeholder="billy@graham.com"
                {...register("email", EMAIL_REQUIRED)}
                error={errors?.email?.message}
              />
            </div>
            <div className="form__row">
              <Button
                size="medium"
                className="button--success  mt8"
                variation="square-rounded"
                theme="primary"
                disabled={isLoading}
              >
                {isLoading ? sending : send}
              </Button>
            </div>
          </form>
        )}
      </MainCard>
    </App>
  );
};

export default memo(ForgotPassword);
