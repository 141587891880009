import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as IconClock } from "assets/images/icon-clock.svg";
import { isMobileDevice } from "utils/devices";
import { useNotifications } from "components/Notifications/Context";

const withZeroAtLeft = (hour) => (hour < 10 ? `0${hour}` : hour);

const TimePicker = () => {
  const { selectedHour, setSelectedHour } = useNotifications();
  const [hour, setHour] = useState(withZeroAtLeft(selectedHour.hour) || "17");
  const [minute, setMinute] = useState(
    withZeroAtLeft(selectedHour.minute) || "00"
  );
  const minuteInputRef = useRef(null);

  useEffect(() => {
    setSelectedHour({ hour: Number(hour), minute: Number(minute) });
  }, [hour, minute, setSelectedHour]);

  const onHourChange = ({ target: { value } }) => {
    value = Number(value);
    const onlyNumbers = /^\d*\.?\d*$/.test(value);
    const insideRange = value >= 0 && value <= 23;

    if (onlyNumbers && insideRange) setHour(String(value).padStart(2, "0"));
    else setHour(hour);

    if (String(value).length === 2 && isMobileDevice)
      minuteInputRef.current.focus();
  };

  const onMinuteChange = ({ target: { value } }) => {
    value = Number(value);
    const onlyNumbers = /^\d*\.?\d*$/.test(value);
    const insideRange = value >= 0 && value <= 59;

    if (onlyNumbers && insideRange) setMinute(String(value).padStart(2, "0"));
    else setMinute(minute);
  };

  const onMinuteBlur = ({ target: { value } }) => {
    if (value === "") setMinute("00");
  };

  const onHourBlur = ({ target: { value } }) => {
    if (value === "") setHour("00");
  };

  const onTimePickerChange = ({ target: { value } }) => {
    const splitted = value.split(":");
    const extractedHour = splitted[0];
    const extractedMinute = splitted[1];
    if (!extractedHour || !extractedMinute) return;
    setHour(extractedHour);
    setMinute(extractedMinute);
  };

  return (
    <div className="TimePicker">
      <div className="TimePicker__inside">
        <div className="TimePicker__input-wrapper">
          <div className="TimePicker__input">
            <input
              type="number"
              inputMode="numeric"
              value={hour}
              max={23}
              min={0}
              className="TimePicker__input-hour"
              onChange={onHourChange}
              onBlur={onHourBlur}
            />
            <span className="TimePicker__input-colon">:</span>
            <input
              type="number"
              inputMode="numeric"
              value={minute}
              max={59}
              min={0}
              ref={minuteInputRef}
              onChange={onMinuteChange}
              onBlur={onMinuteBlur}
              className="TimePicker__input-minute"
            />
          </div>
          <div className="TimePicker__input-icon">
            <IconClock />
            <input type="time" onChange={onTimePickerChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { TimePicker };
