import ChecklistItem from "./Component";
import { connect } from "react-redux";
import { storeLastModified, toggleReadStatus } from "store/actions/planv2";

const mapDispatchToProps = (dispatch) => ({
  toggleReadStatus: (payload) => dispatch(toggleReadStatus(payload)),
  storeLastModified: (payload) => dispatch(storeLastModified(payload)),
});

const mapStateToProps = ({
  planv2: { planv2, planId, trackDelays, delays },
}) => ({
  planv2,
  planId,
  trackDelays,
  delays,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistItem);
