import { useContext } from "react";
import { ChallengeRankingContext } from "./index";

const useChallengeRanking = () => {
  const context = useContext(ChallengeRankingContext);

  if (!context)
    throw new Error(
      "useChallengeRanking must be used within a ChallengeRankingProvider"
    );

  return context;
};

export default useChallengeRanking;
