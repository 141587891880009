export const planCreation = {
  planCreation: {
    formTitle: "Crie agora:",
    startDate: "Data inicial",
    endDate: "Data final",
    startReading: "Leitura inicial",
    endReading: "Leitura final",
    feedbacks: {
      isCrossYear:
        "Por enquanto, só é possível selecionar datas de um mesmo ano",
      startDateIsBiggerThenEndDate:
        "Selecione uma Data Final que não venha antes da Data Inicial.",
      readingIntervalIsReverse:
        "Selecione uma Leitura Final que seja posterior à Leitura Inicial.",
    },
    moreOptions: "Outras opções",
    selectQuantity: "Selecionar a quantidade de dias",
    selectChaptersPerDay: "Selecionar a quantidade de capítulos por dia",
    chaptersPerDay: "capítulos por dia",
    didntFind: "Não encontrou a sua opção?",
    sendUsAMessage:
      "Envie-nos uma mensagem detalhando o  seu plano e entraremos em contato com você:",
    writeHere: "Escreva aqui...",
    howWeGetInTouch: "Como entramos em contato?",
    wantUsToGetInTouch: "Quer que entramos em contato? (opcional)",
    emailPlaceholder: "Informe seu e-mail, WhatsApp ou outro.",
    saySomething: "Opa, não quer nos dizer nada? :)",
    sent: "Enviado :)",
    days: "dias",
    errorOnFetchingPlan:
      "Oops! Tivemos um problema ao acessar o plano. Por favor, tente novamente.",
  },
};
