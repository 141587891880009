import React, { memo } from "react";
import BibleJourney from "./Cards/BibleJourney";
import Challenges from "./Cards/Challenges";
import Plan from "./Cards/Plan";
import Reminder from "./Cards/Reminder";
import Subscription from "./Cards/Subscription";
import UserArea from "./Cards/UserArea";
import LoginButton from "./Cards/LoginButton";
import { madeBy, menu } from "./messages";
import LanguageSelector from "./Cards/LanguageSelector";
import SocialFooter from "components/SocialFooter";
import ThemeSelector from "./Cards/Theme";

const Menu = () => {
  return (
    <div className="menu">
      <div className="menu__inside fade-up">
        <div className="menu__title">
          <h1>{menu}</h1>
        </div>
        <UserArea />
        <div className="menu__cards">
          <Challenges />
          <BibleJourney />
          <Plan />
          <Reminder />
          <LanguageSelector />
          <ThemeSelector />
          <Subscription />
          <LoginButton />
          <SocialFooter />
          <MadeBy />
        </div>
      </div>
    </div>
  );
};

const MadeBy = () => (
  <div className="made-by">
    <p>
      {madeBy}{" "}
      <a href="https://azabraao.me" target="_blank" rel="noopener noreferrer">
        Abraão Azevedo
      </a>
    </p>
  </div>
);

export default memo(Menu);
