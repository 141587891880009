import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";

const Loader = () => {
  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          marginBottom: "32px",
        }}
      >
        <Skeleton
          className="skeleton"
          width="100%"
          height={60}
          style={{ marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={24}
          width={150}
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={38}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={38}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={38}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
        <Skeleton
          className="skeleton"
          height={38}
          width="100%"
          style={{ borderRadius: "8px", marginBottom: "16px" }}
        />
      </div>
      <div
        style={{
          marginBottom: "32px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Skeleton
          className="skeleton"
          height={22}
          width={60}
          style={{ borderRadius: "4px" }}
        />
        <Skeleton
          className="skeleton"
          height={35}
          width={166}
          style={{ borderRadius: "8px" }}
        />
      </div>
    </div>
  );
};

export default memo(Loader);
