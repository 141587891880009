export const planCreation = {
  formTitle: "Utwórz teraz:",
  startDate: "Data rozpoczęcia",
  endDate: "Data zakończenia",
  startReading: "Rozpocznij czytanie",
  endReading: "Zakończ czytanie",
  feedbacks: {
    isCrossYear:
      "Obecnie możliwe jest wyłącznie wybranie zakresu dat z tego samego roku",
    startDateIsBiggerThenEndDate:
      "Wybierz datę zakończenia, która nie przypada przed początkiem.",
    readingIntervalIsReverse:
      "Wybierz koniec czytania, który jest późniejszy niż początek.",
  },
};
