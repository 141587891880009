import { i18n } from "translate/i18n";

export const title = " ";
export const subtitle = i18n.t("messages.authentication.subtitle");
export const sending = i18n.t("messages.authentication.sending");
export const send = i18n.t("messages.authentication.send");
export const email = i18n.t("messages.authentication.email");
export const password = i18n.t("messages.authentication.password");
export const or = i18n.t("messages.authentication.or");
export const name = i18n.t("messages.authentication.name");
//  validation messages

export const biggerName = i18n.t("messages.authentication.biggerName");
export const validEmail = i18n.t("messages.authentication.validEmail");
export const biggerPassword = i18n.t(
  "messages.authentication.biggerPassword"
);
export const pleaseInform = i18n.t(
  "messages.authentication.pleaseInform"
);
