import React, { useState } from "react";
import { ReactComponent as EyeOpened } from "assets/images/icon-eye-opened.svg";
import { ReactComponent as EyeClosed } from "assets/images/icon-eye-closed.svg";
import { Link } from "react-router-dom";
import { forgotPassword } from "./messages";

const Input = React.forwardRef(
  (
    {
      label,
      type,
      placeholder,
      error,
      name,
      showForgotPasswordLink,
      onBlur,
      onChange,
      autoComplete,
      defaultValue,
      onKeyDown,
      autoFocus,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => setShowPassword(!showPassword);

    return (
      <div className="input-wrap align-left full-width">
        <div className="input-wrap__header">
          {label && (
            <label htmlFor={name} className="input-label primary">
              {label}
            </label>
          )}
          {type === "password" && showForgotPasswordLink && (
            <Link
              to="/forgot-password"
              tabIndex="-1"
              className="font-size-0 color-black tdn mb8"
            >
              {forgotPassword}
            </Link>
          )}
        </div>
        <div className="input-wrap__input">
          <input
            type={showPassword ? "text" : type}
            className={`input full-width primary text-left ${
              !!error && "error"
            } `}
            name={name}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            autoComplete={autoComplete ? "on" : "off"}
            defaultValue={defaultValue}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
          />
          {type === "password" && (
            <Icons
              togglePassword={togglePassword}
              showPassword={showPassword}
            />
          )}
        </div>
        {!!error && <span className="input-error">{error}</span>}
      </div>
    );
  }
);

const Icons = ({ togglePassword, showPassword }) => {
  return (
    <>
      {showPassword ? (
        <EyeOpened onClick={togglePassword} className="input-icon" />
      ) : (
        <EyeClosed onClick={togglePassword} className="input-icon" />
      )}
    </>
  );
};

Input.defaultProps = {
  label: "",
  type: "text",
  onBlur: () => {},
  onChange: () => {},
};

export default Input;
