import React, { Component } from "react";
import BibleData from "../../utils/BibleData";
import { ReactComponent as CloseIcon } from "assets/images/icon-close.svg";
import { i18n } from "../../translate/i18n";

class Picker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentIsVisible: true,
      bible: [],
      bookIndex: 0,
      bookAbbreviation: "Gn",
      bookChapter: null,
      bookAmountChapters: 50,
      _isMounted: false,
    };
    this.hidePicker = this.hidePicker.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.handleBookListClick = this.handleBookListClick.bind(this);
    this.handleChapterListClick = this.handleChapterListClick.bind(this);
    this.myRef = React.createRef();
  }

  componentWillMount() {
    this.setState({
      bible: new BibleData(),
    });
  }

  componentDidMount() {
    document.addEventListener("click", this.togglePicker);
    this.setState({
      _isMounted: true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.togglePicker);
  }

  togglePicker(event) {
    let ref = this.myRef;
    let childrens = Array.from(ref.current.querySelectorAll("*"));
    childrens.push(ref.current);

    function matchClick() {
      for (let i = 0; i < childrens.length; i++) {
        if (event.target === childrens[i]) {
          return true;
        }
      }
    }

    if (!matchClick()) {
      this.hidePicker();
    }

    if (ref.current.previousElementSibling === event.target) {
      this.showPicker();
    }
  }

  getChaptersArray(amountChapters) {
    let array = [];
    for (let i = 1; i <= amountChapters; i++) {
      array.push(i);
    }
    return array;
  }

  giveBookActiveFeedback(event) {
    this.resetSelectedBookFeedBack();
    event.target.classList.add("active");
  }

  giveChapterActiveFeedback(event) {
    this.resetSelectedChapterFeedBack();
    event.target.classList.add("active");
  }

  resetSelectedChapterFeedBack() {
    let selectedChapter = document.querySelector(".chapter.active");
    !!selectedChapter && selectedChapter.classList.remove("active");
  }

  resetSelectedBookFeedBack() {
    let selectedBook = document.querySelector(".book.active");
    !!selectedBook && selectedBook.classList.remove("active");
  }

  handleBookListClick(event) {
    let bookAbbreviation = event.target.getAttribute("abbreviation");
    let bookIndex = event.target.getAttribute("index");
    let bookAmountChapters = event.target.getAttribute("chapters");

    this.resetSelectedChapterFeedBack();
    this.giveBookActiveFeedback(event);

    this.setState({
      bookAbbreviation: bookAbbreviation,
      bookIndex: bookIndex,
      bookAmountChapters: bookAmountChapters,
      bookChapter: null,
    });
  }

  handleChapterListClick(event) {
    let chapter = event.target.getAttribute("chapter");

    this.giveChapterActiveFeedback(event);

    this.setState(
      {
        bookChapter: chapter,
      },
      () => {
        this.changeBiblePickerInput();
      }
    );

    this.hidePicker();
  }

  mapBooks(books) {
    let activeBook = this.state.bookAbbreviation;
    let listBooks = books.map((book, index) => (
      <span
        className={`book jsBookPick ${
          book.bookAbbreviation === activeBook ? "active" : ""
        }`}
        onClick={this.handleBookListClick}
        abbreviation={book.bookAbbreviation}
        key={book.index + book.name}
        index={index}
        chapters={book.chapters}
      >
        {book.name}
      </span>
    ));

    return listBooks;
  }

  mapChapters(chapters) {
    let listChapters = chapters.map((chapter, index) => (
      <span
        className={`chapter jsChapterPick`}
        chapter={chapter}
        onClick={this.handleChapterListClick.bind(this)}
        key={index + chapter}
      >
        {chapter}
      </span>
    ));

    return listChapters;
  }

  showPicker() {
    this.setState({
      componentIsVisible: true,
    });
  }

  hidePicker() {
    this.setState({
      componentIsVisible: false,
    });
  }

  changeBiblePickerInput() {
    let inputFormated = `${this.state.bookAbbreviation} ${this.state.bookChapter}`;
    this.props.onChangePicker(
      inputFormated,
      this.state.bookIndex,
      this.state.bookChapter
    );
  }

  render() {
    let books = this.mapBooks(this.state.bible.books);
    let amountChapters = this.getChaptersArray(this.state.bookAmountChapters);
    let chapters = this.mapChapters(amountChapters);

    return (
      <div
        ref={this.myRef}
        className={`bible-picker jsBiblePicker ${
          this.state.componentIsVisible ? "" : "hidden"
        }`}
      >
        <div className="bible-picker__header">
          <span className="bible-picker__close" onClick={this.hidePicker}>
            <CloseIcon className="bible-picker__close-icon" />
          </span>
        </div>
        <div className="bible-picker__columns">
          <div className="bible-picker__column">
            <header className="column__header">
              <h4 className="header__title">
                {i18n.t("titles.biblePicker.book")}
              </h4>
            </header>
            <div className="books">{books}</div>
          </div>
          <div className="bible-picker__column">
            <header className="column__header">
              <h4 className="header__title">
                {i18n.t("titles.biblePicker.chapter")}
              </h4>
            </header>
            <div className="chapters">{chapters}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Picker;
