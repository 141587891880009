export const buttons = {
  buttons: {
    bibleReading: {
      finishReading: "Concluir leitura",
      close: "Fechar",
      bibleVersion: "Versão da Bíblia",
      soonOthers: "Outras versões em breve",
      version: "Versão",
    },
    editPlan: {
      updatePlan: "Atualizar plano",
    },
    askConfirmationModal: {
      firstButtonText: "Manter plano atual",
      secondButtonText: "Recriar todo o plano",
    },
    planCreation: {
      createPlan: "Criar plano",
      createPlanLoading: "Criando plano...",
    },
    delayedReadingModal: {
      firstButton: "Não precisa",
      secondButton: "Adaptar plano",
    },
    donateOptionsModal: {
      firstButton: "Doação única",
      secondButton: "Doação recorrente",
    },
    askDonationsModal: {
      firstButton: "Lembre-me depois",
      secondButton: "Doar via Gumroad",
    },
  },
};
