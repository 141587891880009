import React from "react";
import Wrapper from "../Wrapper";
import { updatePlan } from "components/FormPlan/messages";
import { FormPlan } from "./FormPlan";
import withChallenge from "HOCs/withChallenge";

const UpdatePlan = () => {
  return (
    <Wrapper cardTitle={updatePlan}>
      <FormPlan />
    </Wrapper>
  );
};

export default withChallenge(UpdatePlan);
