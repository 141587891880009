import React, { memo, useMemo } from "react";
import MenuCard from "molecules/MenuCard";
import { configureReminder, editReminder, reminderMsg } from "../messages";
import { useNotifications } from "components/Notifications/Context";

const Reminder = () => {
  const {
    onConfigureReminder,
    hasDefinedReminder,
    selectedHour: { hour, minute },
  } = useNotifications();

  const menu = useMemo(
    () => ({
      header: {
        title: reminderMsg,
      },
      items: [
        {
          description: hasDefinedReminder ? editReminder : configureReminder,
          onClick: onConfigureReminder,
          pointer: true,
          Component: hasDefinedReminder && (
            <span>
              {hour < 10 ? `0${hour}` : hour}:
              {minute < 10 ? `0${minute}` : minute}
            </span>
          ),
        },
      ],
    }),
    [hasDefinedReminder, onConfigureReminder, hour, minute]
  );

  return <MenuCard header={menu.header} items={menu.items} />;
};

export default memo(Reminder);
