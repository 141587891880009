import React, { memo } from "react";
import App from "components/App";
import Header from "components/App/Header";
import { Input } from "components/Form";
import Button from "components/Button";
import { useAuth } from "../../../contexts/AuthContext";
import { PASSWORD_SCHEMA } from "../validationSchema";
import { useParams } from "react-router-dom";
import { changePassword, informYourPassword } from "./messages";
import { password, send, sending, subtitle, title } from "../messages";
import { Navbar } from "components/Navbar";
import MainCard from "components/MainCard";

const ForgotPassword = () => {
  const { token, email } = useParams();

  const { resetPassword, isLoading, handleSubmit, register, errors } =
    useAuth();

  const onSubmit = (data) => {
    resetPassword({ token, email, ...data });
  };

  return (
    <App>
      <Navbar backTo="/plan" onlyBackArrow={true} />

      <Header title={title} subtitle={subtitle} color="white" />

      <MainCard showBrandFooter centerTitle title={changePassword}>
        <form onSubmit={handleSubmit(onSubmit)} className="form jsFormNewPlan">
          <div className="form__row">
            <p className="font-size-2">{informYourPassword}</p>
          </div>
          <div className="form__row full-width">
            <Input
              label={password}
              name="password"
              type="password"
              placeholder="*********"
              {...register("password", PASSWORD_SCHEMA)}
              error={errors?.password?.message}
              showForgotPasswordLink={true}
            />
          </div>
          <div className="form__row">
            <Button
              size="medium"
              className="button--success  mt8"
              variation="square-rounded"
              theme="primary"
              disabled={isLoading}
            >
              {isLoading ? sending : send}
            </Button>
          </div>
        </form>
      </MainCard>
    </App>
  );
};

export default memo(ForgotPassword);
