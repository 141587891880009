import { connect } from "react-redux";
import { updatePlan } from "store/actions/planv2";
import ReplanModal from "./Component";

const mapStateToProps = ({
  planv2: { planv2, trackDelays, planId, delays },
}) => ({
  planv2,
  trackDelays,
  planId,
  delays,
});

const mapDispatchToProps = (dispatch) => ({
  updatePlan: (plan) => dispatch(updatePlan(plan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplanModal);
