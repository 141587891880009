import React from "react";
import { usePlan } from "../Context";

const FormFeedback = () => {
  const { formError } = usePlan();

  if (formError) return <div className="form-feedback">{formError}</div>;

  return null;
};

export default FormFeedback;
