export const bibleBooks = {
  bibleBooks: {
    0: {
      name: "Gênesis",
      abbreviation: "Gn",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "gn",
        bibleBrain: "GEN",
      },
    },
    genesis: {
      name: "Gênesis",
      abbreviation: "Gn",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "gn",
        bibleBrain: "GEN",
      },
    },
    1: {
      name: "Êxodo",
      abbreviation: "Êx",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ex",
        bibleBrain: "EXO",
      },
    },
    exodus: {
      name: "Êxodo",
      abbreviation: "Êx",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ex",
        bibleBrain: "EXO",
      },
    },
    2: {
      name: "Levítico",
      abbreviation: "Lv",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "lv",
        bibleBrain: "LEV",
      },
    },
    leviticus: {
      name: "Levítico",
      abbreviation: "Lv",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "lv",
        bibleBrain: "LEV",
      },
    },
    3: {
      name: "Números",
      abbreviation: "Nm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "nm",
        bibleBrain: "NUM",
      },
    },
    numbers: {
      name: "Números",
      abbreviation: "Nm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "nm",
        bibleBrain: "NUM",
      },
    },
    4: {
      name: "Deuteronômio",
      abbreviation: "Dt",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "dt",
        bibleBrain: "DEU",
      },
    },
    deuteronomy: {
      name: "Deuteronômio",
      abbreviation: "Dt",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "dt",
        bibleBrain: "DEU",
      },
    },
    5: {
      name: "Josué",
      abbreviation: "Js",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "js",
        bibleBrain: "JOS",
      },
    },
    joshua: {
      name: "Josué",
      abbreviation: "Js",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "js",
        bibleBrain: "JOS",
      },
    },
    6: {
      name: "Juízes",
      abbreviation: "Jz",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jz",
        bibleBrain: "JDG",
      },
    },
    judges: {
      name: "Juízes",
      abbreviation: "Jz",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jz",
        bibleBrain: "JDG",
      },
    },
    7: {
      name: "Rute",
      abbreviation: "Rt",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "rt",
        bibleBrain: "RUT",
      },
    },
    ruth: {
      name: "Rute",
      abbreviation: "Rt",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "rt",
        bibleBrain: "RUT",
      },
    },
    8: {
      name: "1Samuel",
      abbreviation: "1Sm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "1sm",
        bibleBrain: "1SA",
      },
    },
    samuel1: {
      name: "1Samuel",
      abbreviation: "1Sm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "1sm",
        bibleBrain: "1SA",
      },
    },
    9: {
      name: "2Samuel",
      abbreviation: "2Sm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "2sm",
        bibleBrain: "2SA",
      },
    },
    samuel2: {
      name: "2Samuel",
      abbreviation: "2Sm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "2sm",
        bibleBrain: "2SA",
      },
    },
    10: {
      name: "1Reis",
      abbreviation: "1Rs",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "1rs",
        bibleBrain: "1KI",
      },
    },
    kings1: {
      name: "1Reis",
      abbreviation: "1Rs",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "1rs",
        bibleBrain: "1KI",
      },
    },
    11: {
      name: "2Reis",
      abbreviation: "2Rs",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "2rs",
        bibleBrain: "2KI",
      },
    },
    kings2: {
      name: "2Reis",
      abbreviation: "2Rs",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "2rs",
        bibleBrain: "2KI",
      },
    },
    12: {
      name: "1Crônicas",
      abbreviation: "1Cr",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "1cr",
        bibleBrain: "1CH",
      },
    },
    chronicles1: {
      name: "1Crônicas",
      abbreviation: "1Cr",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "1cr",
        bibleBrain: "1CH",
      },
    },
    13: {
      name: "2Crônicas",
      abbreviation: "2Cr",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "2cr",
        bibleBrain: "2CH",
      },
    },
    chronicles2: {
      name: "2Crônicas",
      abbreviation: "2Cr",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "2cr",
        bibleBrain: "2CH",
      },
    },
    14: {
      name: "Esdras",
      abbreviation: "Ed",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ed",
        bibleBrain: "EZR",
      },
    },
    ezra: {
      name: "Esdras",
      abbreviation: "Ed",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ed",
        bibleBrain: "EZR",
      },
    },
    15: {
      name: "Neemias",
      abbreviation: "Ne",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ne",
        bibleBrain: "NEH",
      },
    },
    nehemiah: {
      name: "Neemias",
      abbreviation: "Ne",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ne",
        bibleBrain: "NEH",
      },
    },
    16: {
      name: "Ester",
      abbreviation: "Et",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "et",
        bibleBrain: "EST",
      },
    },
    esther: {
      name: "Ester",
      abbreviation: "Et",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "et",
        bibleBrain: "EST",
      },
    },
    17: {
      name: "Jó",
      abbreviation: "Jó",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "job",
        bibleBrain: "JOB",
      },
    },
    job: {
      name: "Jó",
      abbreviation: "Jó",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "job",
        bibleBrain: "JOB",
      },
    },
    18: {
      name: "Salmos",
      abbreviation: "Sl",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "sl",
        bibleBrain: "PSA",
      },
    },
    psalms: {
      name: "Salmos",
      abbreviation: "Sl",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "sl",
        bibleBrain: "PSA",
      },
    },
    19: {
      name: "Provérbios",
      abbreviation: "Pv",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "pv",
        bibleBrain: "PRO",
      },
    },
    proverbs: {
      name: "Provérbios",
      abbreviation: "Pv",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "pv",
        bibleBrain: "PRO",
      },
    },
    20: {
      name: "Eclesiastes",
      abbreviation: "Ec",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ec",
        bibleBrain: "ECC",
      },
    },
    ecclesiastes: {
      name: "Eclesiastes",
      abbreviation: "Ec",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ec",
        bibleBrain: "ECC",
      },
    },
    21: {
      name: "Cantares",
      abbreviation: "Ct",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ct",
        bibleBrain: "SNG",
      },
    },
    solomonsongof: {
      name: "Cantares",
      abbreviation: "Ct",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ct",
        bibleBrain: "SNG",
      },
    },
    22: {
      name: "Isaías",
      abbreviation: "Is",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "is",
        bibleBrain: "ISA",
      },
    },
    isaiah: {
      name: "Isaías",
      abbreviation: "Is",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "is",
        bibleBrain: "ISA",
      },
    },
    23: {
      name: "Jeremias",
      abbreviation: "Jr",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jr",
        bibleBrain: "JER",
      },
    },
    jeremiah: {
      name: "Jeremias",
      abbreviation: "Jr",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jr",
        bibleBrain: "JER",
      },
    },
    24: {
      name: "Lamentações",
      abbreviation: "Lm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "lm",
        bibleBrain: "LAM",
      },
    },
    lamentations: {
      name: "Lamentações",
      abbreviation: "Lm",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "lm",
        bibleBrain: "LAM",
      },
    },
    25: {
      name: "Ezequiel",
      abbreviation: "Ez",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ez",
        bibleBrain: "EZK",
      },
    },
    ezekiel: {
      name: "Ezequiel",
      abbreviation: "Ez",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ez",
        bibleBrain: "EZK",
      },
    },
    26: {
      name: "Daniel",
      abbreviation: "Dn",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "dn",
        bibleBrain: "DAN",
      },
    },
    daniel: {
      name: "Daniel",
      abbreviation: "Dn",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "dn",
        bibleBrain: "DAN",
      },
    },
    27: {
      name: "Oseias",
      abbreviation: "Os",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "os",
        bibleBrain: "HOS",
      },
    },
    hosea: {
      name: "Oseias",
      abbreviation: "Os",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "os",
        bibleBrain: "HOS",
      },
    },
    28: {
      name: "Joel",
      abbreviation: "Jl",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jl",
        bibleBrain: "JOL",
      },
    },
    joel: {
      name: "Joel",
      abbreviation: "Jl",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jl",
        bibleBrain: "JOL",
      },
    },
    29: {
      name: "Amós",
      abbreviation: "Am",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "am",
        bibleBrain: "AMO",
      },
    },
    amos: {
      name: "Amós",
      abbreviation: "Am",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "am",
        bibleBrain: "AMO",
      },
    },
    30: {
      name: "Obadias",
      abbreviation: "Ob",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ob",
        bibleBrain: "OBA",
      },
    },
    obadiah: {
      name: "Obadias",
      abbreviation: "Ob",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ob",
        bibleBrain: "OBA",
      },
    },
    31: {
      name: "Jonas",
      abbreviation: "Jn",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jn",
        bibleBrain: "JON",
      },
    },
    jonah: {
      name: "Jonas",
      abbreviation: "Jn",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "jn",
        bibleBrain: "JON",
      },
    },
    32: {
      name: "Miqueias",
      abbreviation: "Mq",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "mq",
        bibleBrain: "MIC",
      },
    },
    micah: {
      name: "Miqueias",
      abbreviation: "Mq",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "mq",
        bibleBrain: "MIC",
      },
    },
    33: {
      name: "Naum",
      abbreviation: "Na",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "na",
        bibleBrain: "NAM",
      },
    },
    nahum: {
      name: "Naum",
      abbreviation: "Na",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "na",
        bibleBrain: "NAM",
      },
    },
    34: {
      name: "Habacuque",
      abbreviation: "Hc",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "hc",
        bibleBrain: "HAB",
      },
    },
    habakkuk: {
      name: "Habacuque",
      abbreviation: "Hc",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "hc",
        bibleBrain: "HAB",
      },
    },
    35: {
      name: "Sofonias",
      abbreviation: "Sf",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "sf",
        bibleBrain: "ZEP",
      },
    },
    zephaniah: {
      name: "Sofonias",
      abbreviation: "Sf",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "sf",
        bibleBrain: "ZEP",
      },
    },
    36: {
      name: "Ageu",
      abbreviation: "Ag",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ag",
        bibleBrain: "HAG",
      },
    },
    haggai: {
      name: "Ageu",
      abbreviation: "Ag",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ag",
        bibleBrain: "HAG",
      },
    },
    37: {
      name: "Zacarias",
      abbreviation: "Zc",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "zc",
        bibleBrain: "ZEC",
      },
    },
    zechariah: {
      name: "Zacarias",
      abbreviation: "Zc",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "zc",
        bibleBrain: "ZEC",
      },
    },
    38: {
      name: "Malaquias",
      abbreviation: "Ml",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ml",
        bibleBrain: "MAL",
      },
    },
    malachi: {
      name: "Malaquias",
      abbreviation: "Ml",
      testament: "Velho testamento",
      apiAbbreviation: {
        aBibliaDigital: "ml",
        bibleBrain: "MAL",
      },
    },
    39: {
      name: "Mateus",
      abbreviation: "Mt",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "mt",
        bibleBrain: "MAT",
      },
    },
    matthew: {
      name: "Mateus",
      abbreviation: "Mt",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "mt",
        bibleBrain: "MAT",
      },
    },
    40: {
      name: "Marcos",
      abbreviation: "Mc",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "mc",
        bibleBrain: "MRK",
      },
    },
    mark: {
      name: "Marcos",
      abbreviation: "Mc",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "mc",
        bibleBrain: "MRK",
      },
    },
    41: {
      name: "Lucas",
      abbreviation: "Lc",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "lc",
        bibleBrain: "LUK",
      },
    },
    luke: {
      name: "Lucas",
      abbreviation: "Lc",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "lc",
        bibleBrain: "LUK",
      },
    },
    42: {
      name: "João",
      abbreviation: "Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "jo",
        bibleBrain: "JHN",
      },
    },
    john: {
      name: "João",
      abbreviation: "Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "jo",
        bibleBrain: "JHN",
      },
    },
    43: {
      name: "Atos",
      abbreviation: "At",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "at",
        bibleBrain: "ACT",
      },
    },
    acts: {
      name: "Atos",
      abbreviation: "At",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "at",
        bibleBrain: "ACT",
      },
    },
    44: {
      name: "Romanos",
      abbreviation: "Rm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "rm",
        bibleBrain: "ROM",
      },
    },
    romans: {
      name: "Romanos",
      abbreviation: "Rm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "rm",
        bibleBrain: "ROM",
      },
    },
    45: {
      name: "1Coríntios",
      abbreviation: "1Co",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1co",
        bibleBrain: "1CO",
      },
    },
    corinthians1: {
      name: "1Coríntios",
      abbreviation: "1Co",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1co",
        bibleBrain: "1CO",
      },
    },
    46: {
      name: "2Coríntios",
      abbreviation: "2Co",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2co",
        bibleBrain: "2CO",
      },
    },
    corinthians2: {
      name: "2Coríntios",
      abbreviation: "2Co",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2co",
        bibleBrain: "2CO",
      },
    },
    47: {
      name: "Gálatas",
      abbreviation: "Gl",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "gl",
        bibleBrain: "GAL",
      },
    },
    galatians: {
      name: "Gálatas",
      abbreviation: "Gl",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "gl",
        bibleBrain: "GAL",
      },
    },
    48: {
      name: "Efésios",
      abbreviation: "Ef",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "ef",
        bibleBrain: "EPH",
      },
    },
    ephesians: {
      name: "Efésios",
      abbreviation: "Ef",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "ef",
        bibleBrain: "EPH",
      },
    },
    49: {
      name: "Filipenses",
      abbreviation: "Fp",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "fp",
        bibleBrain: "PHP",
      },
    },
    philippians: {
      name: "Filipenses",
      abbreviation: "Fp",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "fp",
        bibleBrain: "PHP",
      },
    },
    50: {
      name: "Colossenses",
      abbreviation: "Cl",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "cl",
        bibleBrain: "COL",
      },
    },
    colossians: {
      name: "Colossenses",
      abbreviation: "Cl",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "cl",
        bibleBrain: "COL",
      },
    },
    51: {
      name: "1Tessalonicenses",
      abbreviation: "1Ts",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1ts",
        bibleBrain: "1TH",
      },
    },
    thessalonians1: {
      name: "1Tessalonicenses",
      abbreviation: "1Ts",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1ts",
        bibleBrain: "1TH",
      },
    },
    52: {
      name: "2Tessalonicenses",
      abbreviation: "2Ts",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2ts",
        bibleBrain: "2TH",
      },
    },
    thessalonians2: {
      name: "2Tessalonicenses",
      abbreviation: "2Ts",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2ts",
        bibleBrain: "2TH",
      },
    },
    53: {
      name: "1Timóteo",
      abbreviation: "1Tm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1tm",
        bibleBrain: "1TI",
      },
    },
    timothy1: {
      name: "1Timóteo",
      abbreviation: "1Tm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1tm",
        bibleBrain: "1TI",
      },
    },
    54: {
      name: "2Timóteo",
      abbreviation: "2Tm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2tm",
        bibleBrain: "2TI",
      },
    },
    timothy2: {
      name: "2Timóteo",
      abbreviation: "2Tm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2tm",
        bibleBrain: "2TI",
      },
    },
    55: {
      name: "Tito",
      abbreviation: "Tt",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "tt",
        bibleBrain: "TIT",
      },
    },
    titus: {
      name: "Tito",
      abbreviation: "Tt",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "tt",
        bibleBrain: "TIT",
      },
    },
    56: {
      name: "Filemom",
      abbreviation: "Fm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "fm",
        bibleBrain: "PHM",
      },
    },
    philemon: {
      name: "Filemom",
      abbreviation: "Fm",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "fm",
        bibleBrain: "PHM",
      },
    },
    57: {
      name: "Hebreus",
      abbreviation: "Hb",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "hb",
        bibleBrain: "HEB",
      },
    },
    hebrews: {
      name: "Hebreus",
      abbreviation: "Hb",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "hb",
        bibleBrain: "HEB",
      },
    },
    58: {
      name: "Tiago",
      abbreviation: "Tg",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "tg",
        bibleBrain: "JAS",
      },
    },
    james: {
      name: "Tiago",
      abbreviation: "Tg",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "tg",
        bibleBrain: "JAS",
      },
    },
    59: {
      name: "1Pedro",
      abbreviation: "1Pe",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1pe",
        bibleBrain: "1PE",
      },
    },
    peter1: {
      name: "1Pedro",
      abbreviation: "1Pe",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1pe",
        bibleBrain: "1PE",
      },
    },
    60: {
      name: "2Pedro",
      abbreviation: "2Pe",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2pe",
        bibleBrain: "2PE",
      },
    },
    peter2: {
      name: "2Pedro",
      abbreviation: "2Pe",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2pe",
        bibleBrain: "2PE",
      },
    },
    61: {
      name: "1João",
      abbreviation: "1Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1jo",
        bibleBrain: "1JN",
      },
    },
    john1: {
      name: "1João",
      abbreviation: "1Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "1jo",
        bibleBrain: "1JN",
      },
    },
    62: {
      name: "2João",
      abbreviation: "2Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2jo",
        bibleBrain: "2JN",
      },
    },
    john2: {
      name: "2João",
      abbreviation: "2Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "2jo",
        bibleBrain: "2JN",
      },
    },
    63: {
      name: "3João",
      abbreviation: "3Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "3jo",
        bibleBrain: "3JN",
      },
    },
    john3: {
      name: "3João",
      abbreviation: "3Jo",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "3jo",
        bibleBrain: "3JN",
      },
    },
    64: {
      name: "Judas",
      abbreviation: "Jd",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "jd",
        bibleBrain: "JUD",
      },
    },
    jude: {
      name: "Judas",
      abbreviation: "Jd",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "jd",
        bibleBrain: "JUD",
      },
    },
    65: {
      name: "Apocalipse",
      abbreviation: "Ap",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "ap",
        bibleBrain: "REV",
      },
    },
    revelation: {
      name: "Apocalipse",
      abbreviation: "Ap",
      testament: "Novo testamento",
      apiAbbreviation: {
        aBibliaDigital: "ap",
        bibleBrain: "REV",
      },
    },
  },
};
