import { emailRegex } from "utils";
import {
  biggerName,
  biggerPassword,
  pleaseInform,
  validEmail,
} from "./messages";

const generalRequiredMessage = (name) => `${pleaseInform} ${name}`;

export const NAME_SCHEMA = {
  minLength: {
    value: 2,
    message: biggerName,
  },
  required: {
    value: true,
    message: generalRequiredMessage("nome"),
  },
};

export const EMAIL_SCHEMA = {
  pattern: {
    value: emailRegex,
    message: validEmail,
  },
  required: {
    value: true,
    message: generalRequiredMessage("email"),
  },
};

export const PASSWORD_SCHEMA = {
  minLength: {
    value: 6,
    message: biggerPassword,
  },
  required: {
    value: true,
    message: generalRequiredMessage("senha"),
  },
};

export const EMAIL_REQUIRED = {
  required: {
    value: true,
    message: generalRequiredMessage("email"),
  },
};

export const PASSWORD_REQUIRED = {
  required: {
    value: true,
    message: generalRequiredMessage("senha"),
  },
};
