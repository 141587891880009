import React, { Fragment, useMemo } from "react";
import withChallenge from "HOCs/withChallenge";
import Podium from "./components/Podium";
import RankingFilter from "./components/RankingFilter";
import RankingHeader from "./components/RankingHeader";
import RankingTable from "./components/RankingTable";
import NotificationsHandler from "./components/NotificationsHandler";
import PodiumLoading from "./components/PodiumLoading";
import RankingLoading from "./components/RankingLoading";
import { useChallenge } from "contexts/Challenge/useChallenge";
import { useChallengePlan } from "contexts/Challenge/ChallengePlan";
import useChallengeRanking from "contexts/Challenge/Ranking/useChallengeRanking";
import NoParticipants from "../components/NoParticipants";
import MainCard from "components/MainCard";

const RankingPage = () => {
  const { isLoadingChallenge } = useChallenge();
  const { isLoadingRanking } = useChallengePlan();
  const { noParticipants, viewReadingRanking } = useChallengeRanking();

  const isLoading = useMemo(
    () => isLoadingChallenge || (isLoadingRanking && viewReadingRanking),
    [isLoadingChallenge, isLoadingRanking, viewReadingRanking]
  );

  return (
    <Fragment>
      <NotificationsHandler />
      {isLoading ? <PodiumLoading /> : <Podium />}

      <MainCard showBrandFooter>
        {noParticipants ? (
          <NoParticipants />
        ) : (
          <Fragment>
            <RankingFilter />
            <div className="table">
              <RankingHeader />
              <div className="table__body">
                {isLoading ? <RankingLoading /> : <RankingTable />}
              </div>
            </div>
          </Fragment>
        )}
      </MainCard>
    </Fragment>
  );
};

export default withChallenge(RankingPage);
