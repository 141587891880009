import { connect } from "react-redux";
import { trackDelays } from "store/actions/planv2";
import MuteReminder from "./Component";

const mapStateToProps = ({ planv2: { trackDelays } }) => ({
  trackDelays,
});

const mapDispatchToProps = (dispatch) => ({
  saveTrackDelays: (payload) => dispatch(trackDelays(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuteReminder);
