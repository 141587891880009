import React, { memo } from "react";
import App from "components/App";
import Header from "components/App/Header";
import { loginTitle, registerMsg } from "./messages";
import { or, subtitle, title } from "../messages";
import { Navbar } from "components/Navbar";
import LoginCard from "./LoginCard";
import { Link } from "react-router-dom";
import MainCard from "components/MainCard";

const Login = () => (
  <App>
    <Navbar backTo="/plan" onlyBackArrow={true} />
    <Header title={title} subtitle={subtitle} color="white" />
    <MainCard title={loginTitle} centerTitle showBrandFooter>
      <LoginCard />

      <div className="text-centered mt24">
        <span>
          {or} <Link to="/register">{registerMsg}</Link>
        </span>
      </div>
    </MainCard>
  </App>
);

export default memo(Login);
