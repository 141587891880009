import React, { memo, useEffect, useMemo } from "react";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useBible } from "contexts/Bible";
import { useGlobalScroll } from "contexts/GlobalScroll";
import { useTheme } from "contexts/Theme/useTheme";

const defaultStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1001,
  height: "env(safe-area-inset-top)",
};

//Setting to default to avoid unexpected behavior
const setStatusBarDarkStyle = async () => {
  await StatusBar.setStyle({ style: Style.Default });
};

const setStatusBarLightStyle = async () => {
  await StatusBar.setStyle({ style: Style.Default });
};

const IOSStatusBarHandler = () => {
  const { isOnTop } = useGlobalScroll(true);
  const { isReading } = useBible();

  const [theme] = useTheme();

  const isUsingDarkTheme = useMemo(() => theme === "dark", [theme]);

  const style = useMemo(() => {
    return {
      default: {
        ...defaultStyle,
        background: isUsingDarkTheme ? "#121212" : "#6a2d56",
      },
      bibleOpen: {
        ...defaultStyle,
        background: isUsingDarkTheme ? "#2c2c2c" : "#ffffff",
      },
    };
  }, [isUsingDarkTheme]);

  useEffect(() => {
    if (isReading) {
      document.body.classList.add("isReading");
      !isUsingDarkTheme && setStatusBarLightStyle();
    } else {
      document.body.classList.remove("isReading");
      setStatusBarDarkStyle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReading]);

  if (isOnTop) return null;

  return <div style={isReading ? style.bibleOpen : style.default} />;
};

export default memo(IOSStatusBarHandler);
