import { connect } from "react-redux";
import { chooseVersion } from "store/actions/bibleReading";

import Bible from "./Component";

const mapDispatchToProps = (dispatch) => ({
  chooseVersion: (payload) => dispatch(chooseVersion(payload)),
});

const mapStateToProps = ({ bibleReading: { bibleVersion } }) => ({
  bibleVersion,
});

export default connect(mapStateToProps, mapDispatchToProps)(Bible);
