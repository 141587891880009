export const planCreation = {
  planCreation: {
    formTitle: "Create now:",
    startDate: "Start date",
    endDate: "End date",
    startReading: "Start reading",
    endReading: "End reading",
    feedbacks: {
      isCrossYear:
        "For now, it is only possible to select dates from the same year",
      startDateIsBiggerThenEndDate:
        "Select an end date not earlier than start date",
      readingIntervalIsReverse:
        "Select a reading end that is later than the start.",
    },
    moreOptions: "Other options",
    selectQuantity: "Select a number of days",
    selectChaptersPerDay: "Select chapters per day",
    chaptersPerDay: "chapters per day",
    didntFind: "Didn't find your option?",
    sendUsAMessage:
      "Send us a message detailing your plan and we'll get back to you:",
    writeHere: "Write here...",
    howWeGetInTouch: "How do we get in touch?",
    wantUsToGetInTouch: "Want us to get in touch? (optional)",
    emailPlaceholder: "Your E-mail, WhatsApp or other means.",
    saySomething: "Oops! Don't you want to say something? :)",
    sent: "Sent :)",
    days: "days",
    errorOnFetchingPlan:
      "Oops! We had a problem on trying to access your plan. Please try again.",
  },
};
