import React, { useState } from "react";
import { ReactComponent as GoogleLogo } from "assets/images/icon-google.svg";
import { useAuth } from "contexts/AuthContext";
import Button from "components/Button";
import { loading } from "../messages";
import "@codetrix-studio/capacitor-google-auth";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

const IOSGoogleButton = ({ text }) => {
  const { handleGoogleLogin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const onButtonClick = async () => {
    setIsLoading(true);

    const googleUser = await GoogleAuth.signIn(null);

    const user = {
      name: googleUser.name,
      email: googleUser.email,
      picture: googleUser.imageUrl,
      googleId: googleUser.id,
    };

    handleGoogleLogin(user, (status) => setIsLoading(status));
  };

  return (
    <Button
      size="medium"
      className="social-button google font-regular"
      variation="square-rounded"
      theme="primary"
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <GoogleLogo />

      {isLoading ? loading : text}
    </Button>
  );
};

export default IOSGoogleButton;
