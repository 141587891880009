import React, { Fragment, useEffect } from "react";

import { ExceptionModal } from "./Modals";

import { DefaultForm } from "./Forms";
import { MoreOptions } from "./components";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const FormPlan = () => {
  const { setIsLoading } = useNewChallenge();

  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ExceptionModal />
      <div className="form form-plan form-plan--new-challenge ">
        <DefaultForm />
        <MoreOptions />
      </div>
    </Fragment>
  );
};

export default FormPlan;
