import React, { Fragment, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import WeekDaysSelector from "components/WeekDaysSelector";
import ReadingInputs from "../components/ReadingInputs";
import { FormFeedback } from ".";
import { getItemMaxHeightStyle } from "components/Accordion/getItemMaxHeightStyle";
import { useNewChallenge } from "contexts/Challenge/NewChallenge";

const DefaultForm = () => {
  const [contentStyle, setContentStyle] = useState({});
  const contentRef = useRef(null);

  const { openMoreOptions, formError, blockedWeekDays, setBlockedWeekDays } =
    useNewChallenge();

  useEffect(() => {
    setTimeout(() => {
      setContentStyle(
        getItemMaxHeightStyle(!openMoreOptions, contentRef.current)
      );
    }, 100);
  }, [openMoreOptions, formError, contentRef]);

  return (
    <Fragment>
      <div
        ref={contentRef}
        style={contentStyle}
        className={classNames("default-form", { openMoreOptions })}
      >
        <ReadingInputs />
        <WeekDaysSelector
          blockedWeekDays={blockedWeekDays}
          onChange={setBlockedWeekDays}
        />
        <FormFeedback />
      </div>
    </Fragment>
  );
};

export default DefaultForm;
